<template>
  <div v-if="showModal" class="modal-window overflow-y-auto fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center" :class="showModal ? 'show' : 'hide'">
    <div class="fixed inset-0 transition-opacity" :class="showModal ? 'ease-in duration-200 opacity-100' : 'ease-out duration-200 opacity-0'">
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>
    <div class="w-full sm:w-2/3 bg-white rounded-lg overflow-auto shadow-xl transform transition-all" role="dialog" aria-modal="true" aria-labelledby="modal-headline" :class="showModal ? 'ease-in duration-200 opacity-100 translate-y-0 sm:scale-100' : 'ease-out duration-300 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'">
      <div class="modal-window-split--content relative">
        <div class="bg-white p-5 xl:p-8 max-h-full">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center w-full sm:mt-0 sm:text-left">
              <div class="mt-2">
                <div class="my-4">
                  <div class="w-full flex justify-between items-center">
                    <div class="w-3/4">
                      <h4 class="font-bold text-xl text-left">
                        {{ selectedProductBundleData.name }}
                      </h4>
                      <p class="text-gray-400 text-base font-medium text-left">
                        {{ extractBundleBrands(selectedProductBundleData) }}
                      </p>
                    </div>
                    <div v-if="projectExist || selectedRoomId" class="flex justify-center">
                      <div class="flex items-center">
                        <span class="inline-flex relative z-0">
                          <button type="button" :disabled="selectedProductBundleData.qty <= 1" class="inline-flex relative focus:z-10 items-center p-2 text-sm font-medium text-white bg-blue-light hover:bg-blue-dark disabled:bg-gray-200 rounded-md border focus:border-blue-light disabled:cursor-not-allowed focus:outline-none" @click="decreaseSelProdQty">
                            <HeroIcon icon="Minus" class="w-4 h-4" />
                          </button>
                          <span class="inline-flex relative justify-center items-center py-2 mx-1 w-14 text-sm font-medium text-gray-700 bg-gray-100 rounded-md">
                            {{ selectedProductBundleData.qty }}
                          </span>
                          <button type="button" class="inline-flex relative focus:z-10 items-center p-2 -ml-px text-sm font-medium text-white bg-blue-light hover:bg-blue-dark rounded-md border border-blue-light focus:border-blue-light focus:outline-none" @click="increaseSelProdQty">
                            <HeroIcon icon="Plus" class="w-4 h-4" />
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="!projectExist" class="bg-gray-50 p-4 rounded-md shadow border mb-4">
                  <div class="grid grid-cols-6 gap-4">
                    <div class="col-span-3 sm:col-span-2">
                      <label for="installTime" class="block text-xs xl:text-sm font-medium text-gray-700">
                        Select Project</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <select id="priority" v-model="selectedProjectId" name="priority" required class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-xs xl:text-sm" @change="getProject">
                          <option :value="null" selected disabled>
                            -- Choose Project --
                          </option>
                          <option v-for="(projectName, index) in projects" :key="index" :value="projectName.id">
                            {{ projectName.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div v-if="loading" class="col-span-3 sm:col-span-2">
                      <svg id="L4" height="60px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 -25 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                        <circle fill="#77d183" stroke="none" cx="6" cy="50" r="6">
                          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1" />    
                        </circle>
                        <circle fill="#85b177" stroke="none" cx="26" cy="50" r="6">
                          <animate
                            attributeName="opacity"
                            dur="1s"
                            values="0;1;0"
                            repeatCount="indefinite" 
                            begin="0.2"
                          />       
                        </circle>
                        <circle fill="#0e2d29" stroke="none" cx="46" cy="50" r="6">
                          <animate
                            attributeName="opacity"
                            dur="1s"
                            values="0;1;0"
                            repeatCount="indefinite" 
                            begin="0.3"
                          />     
                        </circle>
                      </svg>
                    </div>
                    <div v-if="selectedProject" class="col-span-3 sm:col-span-2">
                      <label
                        for="quantity"
                        class="block text-xs xl:text-sm font-medium text-gray-700"
                      >Select Floor</label>
          
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <select
                          id="priority"
                          v-model="selectedFloor"
                          name="priority"
                          required
                          class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-xs xl:text-sm"
                          @change="selectedRoomId = null"
                        >
                          <option :value="null" selected disabled>
                            -- Choose Floor --
                          </option>
                          <option
                            v-for="(floor, index) in selectedProject.floors"
                            :key="index"
                            :value="floor"
                          >
                            {{ floor.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                        
                    <div v-if="selectedFloor" class="col-span-3 sm:col-span-2">
                      <label
                        for="installTime"
                        class="block text-xs xl:text-sm font-medium text-gray-700"
                      >
                        Select Room
                      </label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <select
                          id="priority"
                          v-model="selectedRoomId"
                          name="priority"
                          required
                          class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-xs xl:text-sm"
                        >
                          <option :value="null" selected disabled>
                            -- Choose Floor --
                          </option>
                          <option
                            v-for="(room, index) in selectedFloor.rooms"
                            :key="index"
                            :value="room.id"
                          >
                            {{ room.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="addProductErrors" class="p-4 mb-4 bg-red-50 rounded-md">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <svg class="w-5 h-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                      </svg>
                    </div>
                    <div class="ml-3">
                      <ul v-for="(v, k) in addProductErrors" :key="k" class="text-sm font-medium text-red-800">
                        <li v-for="error in v" :key="error">
                          {{ error }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div v-if="projectExist || selectedRoomId" class="bg-gray-50 p-4 rounded-md shadow border mb-4 overflow-y-auto">
                  <div v-for="(product, productIndex) in selectedProductBundleData.grouped_products" :key="productIndex" class="p-2 bg-gray-50 border" :class="[productIndex === 0 && 'rounded-t-md', productIndex === (selectedProductBundleData.grouped_products.length - 1) && 'rounded-b-md']">
                    <div class="flex justify-between items-center w-full cursor-pointer" @click="selectProduct(productIndex)">
                      <div class="flex justify-between items-center w-full">
                        <div class="flex items-center">
                          <div class="flex justify-center items-center mr-4 w-20 h-20 bg-gradient-to-br from-gray-100 to-gray-400 rounded-md border">
                            <img v-if="product.product && product.product.first_image.url" class="object-contain p-2" :src="product.product.first_image.url">
                            <svg v-else class="w-16 h-16 opacity-25" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                            </svg>
                          </div>
                          <div>
                            <h4 class="text-sm font-bold text-blue-dark">
                              {{ product.product.name }} - ({{ product.qty }})
                            </h4>
                            <p class="text-xs text-gray-400">
                              {{ product.product.brand }}
                            </p>
                            <p class="mt-2 text-xs text-gray-900">
                              {{ product.supplier.name }}
                            </p>
                          </div>
                        </div>
                        <ChevronDownIcon />
                      </div>
                    </div>
                    <div v-show="activeProductIndex === productIndex" class="p-4 mt-4 border-t-2">
                      <div class="overflow-hidden rounded-t-md border border-blue-light">
                        <div class="flex justify-between items-center p-4 text-white bg-gradient-to-r from-blue-dark hover:from-gray-700 to-gray-700 cursor-pointer" @click="selectAccordion('global-properties')">
                          <div class="flex items-center">
                            <div class="flex-shrink-0 p-3 bg-gradient-to-br from-blue-light to-blue-dark rounded-md">
                              <svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clip-rule="evenodd" />
                              </svg>
                            </div>

                            <div class="ml-4">
                              <h3 class="font-medium">
                                Global Properties
                              </h3>
                              <p class="text-xs text-lime">
                                Anything set here will affect all Products in this Project. Some settings like 'Product Description' will impact all Projects.
                              </p>
                            </div>
                          </div>

                          <div class="flex justify-center items-center w-6 h-6 bg-blue-light rounded-full">
                            <svg v-if="activeAccordion === 'global-properties'" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                            </svg>
                            <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                            </svg>
                          </div>
                        </div>
                        <div v-show="activeAccordion === 'global-properties'" class="grid grid-cols-6 gap-4 py-6 px-4 bg-white">
                          <div class="col-span-3">
                            <div class="flex justify-between">
                              <label for="customer_price" class="block text-sm font-medium text-gray-700">Customer Price</label>
                              <span class="text-xs text-gray-500">(Incl. VAT)</span>
                            </div>
                            <div class="relative mt-1 rounded-md shadow-sm">
                              <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                <span class="sm:text-sm text-gray-500">
                                  {{ currency }}
                                </span>
                              </div>
                              <input
                                id="customer_price"
                                v-model="product.retail_price"
                                type="number"
                                step="0.01"
                                required
                                min="0"
                                name="customer_price"
                                class="pl-7 form-input"
                                :class="hasErrors('customer_price', addProductErrors) &&'form-input-error'"
                              >
                            </div>
                            <p class="mt-2 text-xs text-gray-500">
                              This will be the price regardless of Supplier.
                            </p>
                          </div>

                          <div v-if="projectStages.length > 0" class="col-span-3">
                            <label for="project_stage" class="block text-sm font-medium text-gray-700">Project Install Stage</label>
                            <select id="project_stage" v-model="selectedProductData.installStage" name="project_stage" required class="select-input">
                              <option :value="null" selected disabled>
                                -- Choose Project Stage --
                              </option>
                              <option v-for="(projectStage, index) in projectStages" :key="index" :value="projectStage.id">
                                {{ projectStage.name }}
                              </option>
                            </select>
                            <p class="mt-2 text-xs text-gray-500">
                              The stage at which this Product will be installed.
                            </p>
                          </div>

                          <div class="block col-span-6" aria-hidden="true">
                            <div class="py-5">
                              <div class="border-t border-gray-200" />
                            </div>
                          </div>

                          <div class="col-span-6">
                            <div class="flex justify-between items-center mb-2">
                              <div>
                                <h3 class="text-sm font-medium text-gray-700">
                                  Cables
                                </h3>
                                <p class="mt-1 texxt-xs text-gray-500">
                                  Cables specified here will appear on your cable schedule.
                                </p>
                              </div>

                              <button type="button" class="btn-primary-sm" @click="showAddCableToProductModal = true">
                                <!-- <HeronIcon icon="Plus" class="mr-1 -ml-1 w-4 h-4" /> -->
                                Add Cable
                              </button>
                            </div>

                            <div class="flex items-start p-4 my-4 bg-blue-dark rounded-md border">
                              <div class="flex items-center h-5">
                                <input id="saveAsDefaultCables" v-model="saveCablesAsDefault" name="saveAsDefaultCables" :value="true" type="checkbox" class="w-4 h-4 text-blue-light rounded border-blue-dark focus:ring-blue-light">
                              </div>
                              <div class="ml-3 text-sm">
                                <label for="saveAsDefaultCables" class="font-bold text-white">Save as Product Default</label>
                                <p class="text-xs text-gray-50">
                                  These cables will become the default for all <span class="font-bold">{{ product.name }}s</span>.
                                </p>
                                <span class="inline-flex items-center py-1 px-2 mt-2 text-xs text-white bg-blue-light bg-opacity-25 rounded-md">
                                  <HeroIcon icon="InformationCircle" class="mr-2 w-4 h-4 text-lime" /> Note: Previously specified {{ product.name }}s in existing Projects will not be affected.
                                </span>
                              </div>
                            </div>

                            <div class="flex flex-col">
                              <div class="overflow-x-auto -my-2 sm:-mx-6 lg:-mx-8">
                                <div class="inline-block py-2 sm:px-6 lg:px-8 min-w-full align-middle">
                                  <div class="overflow-hidden sm:rounded-lg border border-gray-200">
                                    <table class="min-w-full divide-y divide-gray-200">
                                      <thead class="bg-gray-50">
                                        <tr>
                                          <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                            Cable
                                          </th>
                                          <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                            Purpose
                                          </th>
                                          <th scope="col" class="relative py-3 px-6">
                                            <span class="sr-only">Edit</span>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody v-if="product.cables && product.cables.length > 0">
                                        <tr v-for="(cable, cableIdx) in product.cables" :key="cableIdx" class="bg-white" :class="(cableIdx % 2) === 0 ? 'bg-white' : 'bg-gray-50'">
                                          <td class="py-3 px-6 text-xs font-medium text-gray-900 whitespace-nowrap">
                                            {{ cable.cable }}
                                          </td>
                                          <td class="py-3 px-6 text-xs text-gray-500 whitespace-nowrap">
                                            {{ cable.purpose }}
                                          </td>
                                          <td class="py-3 px-6 text-sm font-medium text-right whitespace-nowrap">
                                            <button type="button" class="p-2 rounded-full" @click="deleteCable(cableIdx)">
                                              <svg class="w-5 h-5 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                              </svg>
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                      <tbody v-else>
                                        <tr class="bg-white">
                                          <td colspan="3" class="py-4 px-6 text-xs font-medium text-center text-gray-500 whitespace-nowrap">
                                            No Cables
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="overflow-hidden mb-4 rounded-b-md border border-blue-light">
                        <div class="flex justify-between items-center p-4 text-white bg-gradient-to-r from-blue-dark hover:from-gray-700 to-gray-700 cursor-pointer" @click="selectAccordion('installation-time')">
                          <div class="flex items-center">
                            <div class="flex-shrink-0 p-3 bg-gradient-to-br from-green-light to-green-dark rounded-md">
                              <svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
                              </svg>
                            </div>

                            <div class="ml-4">
                              <h3 class="font-medium">
                                Installation Time
                              </h3>
                              <p class="text-xs text-lime">
                                Setting an installation time will allow you to better quote for your labour.
                              </p>
                            </div>
                          </div>

                          <div class="flex justify-center items-center w-6 h-6 bg-blue-light rounded-full">
                            <svg v-if="activeAccordion === 'installation-time'" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                            </svg>
                            <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                            </svg>
                          </div>
                        </div>

                        <div v-show="activeAccordion === 'installation-time'" class="py-6 px-4">
                          <p class="text-sm text-gray-600">
                            <!-- This will be the installation times for <span class="font-bold underline">all</span> occurances of a <span class="font-bold text-blue-light">{{ selectedProduct.name }}</span> in <span class="font-bold text-blue-light">{{ roomName }}</span>. -->
                          </p>

                          <div v-if="teamPositions.length > 0">
                            <div class="flex items-center p-4 my-6 bg-gray-50 rounded-md border shadow">
                              <input id="saveAsDefaultInstallTime" v-model="product.updateDefaultInstallInfo" type="checkbox" :value="true" class="mr-4 text-blue-dark rounded border-gray-300 focus:ring-indigo-500">
                              <div class="flex flex-col">
                                <label for="saveAsDefaultInstallTime" class="font-bold">Save as Install Time Defaults</label>
                                <p class="text-xs text-gray-600">
                                  This will update your default install time(s) for <span class="font-bold text-blue-light">{{ product.name }}</span>.
                                </p>
                                <p class="mt-1 text-xs text-gray-600">
                                  <span class="font-bold">Note:</span> Previously added install times in other Projects will remain unaffected.
                                </p>
                              </div>
                            </div>

                            <div class="overflow-hidden sm:rounded-lg border-b border-gray-200 shadow">
                              <table class="w-full divide-y divide-gray-200">
                                <thead class="bg-gray-50">
                                  <tr>
                                    <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                      Installation Type
                                    </th>
                                    <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                                      Install Time
                                    </th>
                                    <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                                      Total Install Time
                                    </th>
                                  </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                  <tr v-for="(installTime, posIdx) in product.installTimeDetails" :key="posIdx">
                                    <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                                      {{ installTime.position }}
                                    </td>
                                    <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                                      <div class="flex rounded-md shadow-sm">
                                        <input v-model="installTime.install_time_mins" type="number" min="0" class="block flex-1 mt-0 w-full sm:text-sm text-right rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 form-input">
                                        <span class="inline-flex items-center px-3 text-sm text-gray-500 bg-gray-50 rounded-r-md border border-l-0 border-gray-300">
                                          {{ 'minute' | pluralize(installTime.install_time_mins) }}
                                        </span>
                                      </div>
                                    </td>
                                    <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                                      {{ 'minute' | pluralize((installTime.install_time_mins * product.qty), true) }}
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot class="bg-gray-50">
                                  <tr>
                                    <td scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                      Total Install Time:
                                    </td>
                                    <td scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                                      {{ 'minute' | pluralize(productInstallTime(product.installTimeDetails), true) }}
                                    </td>
                                    <td scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                                      {{ 'minute' | pluralize(productTotalInstallTime(product.installTimeDetails, product.qty), true) }}
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                          <div v-else>
                            You haven't added any team positions. To associate install time with products, add at least one Team Position.
                          </div>
                        </div>
                      </div>
                      

                      <!-- <div class="grid grid-cols-2 sm:grid-cols-3 gap-4">
                        <div v-for="item in parseInt(product.qty || 0)" :key="item">
                          <div v-if="product.product.system_design.hasOwnProperty('numberOfSpeakers') && product.product.system_design.numberOfSpeakers === 'Pair'">
                            <div class="input-group">
                              <label class="block text-sm font-medium text-gray-700">Location 1</label>
                              <input v-model="product.details[item - 1].position" type="text" class="form-input">
                            </div>
                            <div class="input-group">
                              <label class="block text-sm font-medium text-gray-700">Location 2</label>
                              <input v-model="product.details[item - 1].position2" type="text" class="form-input">
                            </div>
                          </div>
                          <div v-else-if="product.product.system_design.hasOwnProperty('numberOfSpeakers')">
                            <label class="block text-sm font-medium text-gray-700">Input {{ item }} Purpose</label>
                            <input v-model="product.details[item - 1].position" type="text" class="form-input">
                          </div>
                        </div>
                      </div> -->
 
                      <div v-if="isRakoWiringModule(product)" class="grid grid-cols-3 gap-4">
                        <div v-for="(item, idx) in parseInt(product.qty || 0)" :key="idx">
                          <label>Module {{ idx + 1 }} Name</label><br>
                          <input v-model="product.details[item - 1].moduleName" type="text" :placeholder="`Enter Module ${idx + 1} Name`" class="form-input">
                        </div>
                      </div>
         
                      <div v-if="isKeypad(product)" class="grid grid-cols-3 gap-4">
                        <div v-for="(item, index) in parseInt(product.qty || 0)" :key="item">
                          <label>Keypad Name {{ index + 1 }}</label>
                          <input v-model="product.details[item - 1].name" type="text" placeholder="Enter Keypad Name" class="form-input">
                        </div>
                      </div>

                      
                      <div v-if="isRakoLEDModule(product)">
                        <div v-for="(item, index) in parseInt(product.qty || 0)" :key="index" class="grid grid-cols-3 gap-4">
                          <div v-for="(idx, t) in parseInt(product.system_design.numberOfChannels)" :key="idx" class="row conts--input">
                            <label>Channel Name {{ idx }}</label>
                            <input v-model="product.details[item - 1].channels[t]" type="text" placeholder="Enter channel name" class="form-input">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-for="(product, productIndex) in selectedProductBundleData.grouped_custom_products" :key="`custom_${productIndex}`" class="p-2 bg-gray-50 border">
                    <div class="flex justify-between items-center w-full cursor-pointer" @click="selectProduct(`custom_${productIndex}`)">
                      <div class="flex justify-between items-center w-full">
                        <div class="flex items-center">
                          <div class="flex justify-center items-center mr-4 w-20 h-20 bg-gradient-to-br from-gray-100 to-gray-400 rounded-md border">
                            <img v-if="product.product.image_url" class="object-contain p-2" :src="product.product.image_url">
                            <svg v-else class="w-16 h-16 opacity-25" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                            </svg>
                          </div>
                          <div>
                            <h4 class="text-sm font-bold text-blue-dark">
                              {{ product.product.name }} <span class="inline-flex justify-center items-center p-1 ml-2 w-6 h-6 text-white bg-blue-light rounded-full">{{ product.qty }}</span>
                            </h4>
                            <p class="text-xs text-gray-500">
                              {{ product.product.brand }}
                            </p>
                            <p class="inline-block py-1 px-3 mt-2 text-xs font-medium text-white bg-blue-dark rounded-md shadow">
                              Custom
                            </p>
                          </div>
                        </div>
                        <div class="flex justify-center items-center w-6 h-6 text-white bg-blue-light rounded-full">
                          <ChevronUpIcon v-if="activeProductIndex === `custom_${productIndex}`" class="w-4 h-4" />
                          <ChevronDownIcon v-else class="w-4 h-4" />
                        </div>
                      </div>
                    </div>
                    <div v-show="activeProductIndex === `custom_${productIndex}`" class="p-4 mt-4 border-t-2">
                      <div class="overflow-hidden rounded-t-md border border-blue-light">
                        <div class="flex justify-between items-center p-4 text-white bg-gradient-to-r from-blue-dark hover:from-gray-700 to-gray-700 cursor-pointer" @click="selectAccordion('global-properties')">
                          <div class="flex items-center">
                            <div class="flex-shrink-0 p-3 bg-gradient-to-br from-blue-light to-blue-dark rounded-md">
                              <svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clip-rule="evenodd" />
                              </svg>
                            </div>

                            <div class="ml-4">
                              <h3 class="font-medium">
                                Global Properties
                              </h3>
                              <p class="text-xs text-lime">
                                Anything set here will affect all Products in this Project. Some settings like 'Product Description' will impact all Projects.
                              </p>
                            </div>
                          </div>

                          <div class="flex justify-center items-center w-6 h-6 bg-blue-light rounded-full">
                            <svg v-if="activeAccordion === 'global-properties'" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                            </svg>
                            <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                            </svg>
                          </div>
                        </div>
                        <div v-show="activeAccordion === 'global-properties'" class="grid grid-cols-6 gap-4 py-6 px-4 bg-white">
                          <div v-if="!productBundle.show_as_bundle" class="col-span-6">
                            <div class="flex justify-between">
                              <label for="product_description" class="block text-sm font-medium text-gray-700">Product Description</label>
                              <span class="text-xs text-gray-500">Optional</span>
                            </div>
                            <div class="mt-1">
                              <input v-model="selectedProductData.description" type="text" name="product_description" class="form-input" placeholder="e.g. In-Ceiling Speaker" aria-describedby="product-name-override">
                            </div>
                            <p class="mt-2 text-xs text-gray-500">
                              A brief description of what the product is, shown on the Customers Sales Quote.
                            </p>
                          </div>

                          <div v-if="!productBundle.show_as_bundle" class="col-span-6">
                            <div class="flex justify-between">
                              <label for="name_override" class="block text-sm font-medium text-gray-700">Product Name Override</label>
                              <span class="text-xs text-gray-500">Optional</span>
                            </div>
                            <div class="mt-1">
                              <!-- <input v-model="selectedProduct.name_override" type="text" name="name_override" class="form-input" placeholder="e.g. In-Ceiling Speaker" aria-describedby="product-name-override"> -->
                            </div>
                            <p class="mt-2 text-xs text-gray-500">
                              The Product Name shown on the Customers Sales Quote.
                            </p>
                          </div>

                          <div class="col-span-3">
                            <div class="flex justify-between">
                              <label for="customer_price" class="block text-sm font-medium text-gray-700">Customer Price</label>
                              <span class="text-xs text-gray-500">(Incl. VAT)</span>
                            </div>
                            <div class="relative mt-1 rounded-md shadow-sm">
                              <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                <span class="sm:text-sm text-gray-500">
                                  {{ currency }}
                                </span>
                              </div>
                              <input
                                id="customer_price"
                                v-model="product.retail_price"
                                type="number"
                                step="0.01"
                                required
                                min="0"
                                name="customer_price"
                                class="pl-7 form-input"
                                :class="hasErrors('customer_price', addProductErrors) &&'form-input-error'"
                              >
                            </div>
                            <p class="mt-2 text-xs text-gray-500">
                              This will be the price regardless of Supplier.
                            </p>
                          </div>

                          <div v-if="projectStages.length > 0" class="col-span-3">
                            <label for="project_stage" class="block text-sm font-medium text-gray-700">Project Install Stage</label>
                            <select id="project_stage" v-model="selectedProductData.installStage" name="project_stage" required class="select-input">
                              <option :value="null" selected disabled>
                                -- Choose Project Stage --
                              </option>
                              <option v-for="(projectStage, index) in projectStages" :key="index" :value="projectStage.id">
                                {{ projectStage.name }}
                              </option>
                            </select>
                            <p class="mt-2 text-xs text-gray-500">
                              The stage at which this Product will be installed.
                            </p>
                          </div>

                          <div v-if="!productBundle.show_as_bundle" class="flex col-span-3 justify-between items-center py-4 cursor-pointer">
                            <div class="flex flex-col cursor-pointer" @click="selectedProductData.show_on_sales_quote = !selectedProductData.show_on_sales_quote">
                              <p class="text-sm font-medium text-gray-700">
                                Show as Line Item on Sales Quote
                              </p>
                              <p class="mt-2 text-xs text-gray-500">
                                If hidden, item will be shown in Additional Costs section.
                              </p>
                            </div>
                            <button
                              type="button"
                              :class="selectedProductData.show_on_sales_quote ? 'bg-blue-light' : 'bg-gray-200'"
                              class="inline-flex relative flex-shrink-0 ml-4 w-11 h-6 rounded-full border-2 border-transparent focus:ring-2 focus:ring-blue-light focus:ring-offset-2 transition-colors duration-200 ease-in-out cursor-pointer focus:outline-none"
                              @click="selectedProductData.show_on_sales_quote = !selectedProductData.show_on_sales_quote"
                            >
                              <span :class="selectedProductData.show_on_sales_quote ? 'translate-x-5' : 'translate-x-0'" class="inline-block w-5 h-5 bg-white rounded-full ring-0 shadow transition duration-200 ease-in-out transform" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="overflow-hidden mb-4 rounded-b-md border border-blue-light">
                        <div class="flex justify-between items-center p-4 text-white bg-gradient-to-r from-blue-dark hover:from-gray-700 to-gray-700 cursor-pointer" @click="selectAccordion('installation-time')">
                          <div class="flex items-center">
                            <div class="flex-shrink-0 p-3 bg-gradient-to-br from-green-light to-green-dark rounded-md">
                              <svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
                              </svg>
                            </div>

                            <div class="ml-4">
                              <h3 class="font-medium">
                                Installation Time
                              </h3>
                              <p class="text-xs text-lime">
                                Setting an installation time will allow you to better quote for your labour.
                              </p>
                            </div>
                          </div>

                          <div class="flex justify-center items-center w-6 h-6 bg-blue-light rounded-full">
                            <svg v-if="activeAccordion === 'installation-time'" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                            </svg>
                            <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                            </svg>
                          </div>
                        </div>

                        <div v-show="activeAccordion === 'installation-time'" class="py-6 px-4">
                          <p class="text-sm text-gray-600">
                            <!-- This will be the installation times for <span class="font-bold underline">all</span> occurances of a <span class="font-bold text-blue-light">{{ selectedProduct.name }}</span> in <span class="font-bold text-blue-light">{{ roomName }}</span>. -->
                          </p>

                          <div v-if="teamPositions.length > 0">
                            <div class="flex items-center p-4 my-6 bg-gray-50 rounded-md border shadow">
                              <input id="saveAsDefaultInstallTime" v-model="product.updateDefaultInstallInfo" type="checkbox" :value="true" class="mr-4 text-blue-dark rounded border-gray-300 focus:ring-indigo-500">
                              <div class="flex flex-col">
                                <label for="saveAsDefaultInstallTime" class="font-bold">Save as Install Time Defaults</label>
                                <p class="text-xs text-gray-600">
                                  This will update your default install time(s) for <span class="font-bold text-blue-light">{{ product.name }}</span>.
                                </p>
                                <p class="mt-1 text-xs text-gray-600">
                                  <span class="font-bold">Note:</span> Previously added install times in other Projects will remain unaffected.
                                </p>
                              </div>
                            </div>

                            <div class="overflow-hidden sm:rounded-lg border-b border-gray-200 shadow">
                              <table class="w-full divide-y divide-gray-200">
                                <thead class="bg-gray-50">
                                  <tr>
                                    <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                      Installation Type
                                    </th>
                                    <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                                      Install Time
                                    </th>
                                    <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                                      Total Install Time
                                    </th>
                                  </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                  <tr v-for="(installTime, posIdx) in product.installTimeDetails" :key="posIdx">
                                    <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                                      {{ installTime.position }}
                                    </td>
                                    <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                                      <div class="flex rounded-md shadow-sm">
                                        <input v-model="installTime.install_time_mins" type="number" min="0" class="block flex-1 mt-0 w-full sm:text-sm text-right rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 form-input">
                                        <span class="inline-flex items-center px-3 text-sm text-gray-500 bg-gray-50 rounded-r-md border border-l-0 border-gray-300">
                                          {{ 'minute' | pluralize(installTime.install_time_mins) }}
                                        </span>
                                      </div>
                                    </td>
                                    <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                                      {{ 'minute' | pluralize((installTime.install_time_mins * product.qty), true) }}
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot class="bg-gray-50">
                                  <tr>
                                    <td scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                      Total Install Time:
                                    </td>
                                    <td scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                                      {{ 'minute' | pluralize(productInstallTime(product.installTimeDetails), true) }}
                                    </td>
                                    <td scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                                      {{ 'minute' | pluralize(productTotalInstallTime(product.installTimeDetails, product.qty), true) }}
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                          <div v-else>
                            You haven't added any team positions. To associate install time with products, add at least one Team Position.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bg-white flex justify-end mt-6 bg-opacity-75">
                  <button type="button" class="btn-primary-outline-md" @click="closeModal">
                    Cancel
                  </button>
                  <button type="button" class="ml-2 w-full sm:w-auto btn-primary-md" :disabled="addingProductBundleToRoom" @click="addBundleToProject(selectedProductBundleData)">
                    <svg v-if="addingProductBundleToRoom" class="flex-shrink-0 mr-2 -ml-1 w-5 h-5 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
                      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                    <svg v-else class="flex-shrink-0 mr-2 -ml-1 w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z" />
                    </svg>
                    {{ addingProductBundleToRoom ? 'Adding ' : 'Add' }} to Project
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AddCableToProductModal
      form-name="addCableToProduct"
      :show="showAddCableToProductModal"
      :close="closeAddCableToProductModal"
      :cables="cables"
      :cable-purposes="cablePurposes"
      @addCableToProduct="addCableToProduct"
    />
  </div>
</template>

<script>
import { ChevronUpIcon, ChevronDownIcon } from "@vue-hero-icons/outline";
import AddCableToProductModal from './AddCableToProductModal.vue';
export default {
   components: {
    ChevronUpIcon,
    ChevronDownIcon,
    AddCableToProductModal
  },
  props: {
    showModal: Boolean,
    productBundle: {
      type: Object,
      default: () => {},
      required: false
    },
    projects: {
      type: Array,
      default: () => [],
      required: false
    },
    projectExist: Boolean
  },
  data() {
    return {
      selectedProjectId: null,
      selectedRoomId: null,
      selectedProject: null,
      selectedFloor: null,
      loading: false,
      qty: 1,
      activeAccordion: null,
      sselectedProductBundleData: {
        name: '',
        brands: '',
        grouped_products: [],
        grouped_custom_products: []
      },
      selectedProductData: {
        qty: 1,
        install_time_mins: 0,
        customerPrice: 0,
        supplierTradePrice: 0,
        projectStage: null,
        nameOverride: '',
        description: '',
        show_on_sales_quote: true,
        details: {
          itemSpecific: {}
        },
        supplier: null,
        installTimeDetails: [],
        team_install_info_default: [],
        cables: [],
        products: [],
        networkRange: {}
      },
      addProductErrors: null,
      saveCablesAsDefault: true,
      addingProductBundleToRoom: false,
      activeProductIndex: null,
      showAddCableToProductModal: false,
      saveNetworkRangesAsDefault: true
    };
  },
  computed: {
    project() {
      return this.$store.state.project.project;
    },
    projectStages() {
      return this.$store.state.system.projectStages;
    },
    currency() {
      return this.$store.state.auth.user.team.team_currency.symbol;
    },
    categories() {
      return this.$store.state.system.categories;
    },
    teamsAvailableSuppliers() {
      const suppliers = this.$store.state.auth.user.team.suppliers;
      const availableSuppliers = [];
      suppliers.map(el => availableSuppliers.push(el.supplier));
      return availableSuppliers;
    },
    roomId() {
      return this.$route.params.roomId;
    },
    teamPositions () {
      return this.$store.state.system.teamPositions;
    },
    cables() {
      return this.$store.state.system.productSettings ? this.$store.state.system.productSettings.cables : [];
    },
    cablePurposes() {
      return this.$store.state.system.productSettings ? this.$store.state.system.productSettings.cablePurposes : [];
    },
    networkRanges(){
       return this.$store.state.system.productSettings ? this.$store.state.system.productSettings.networkRanges : [];
    },
    projectNetworkRanges() {
      return [this.$store.state.project.project.network_range_1, this.$store.state.project.project.network_range_2, this.$store.state.project.project.network_range_3];
    }
  },
  watch: {
    productBundle: [{
      handler: 'getBundleProduct'
    }]
  },
  mounted() {
    this.getBundleProduct(this.productBundle);
  },
  methods: {
    getBundleProduct(productBundle) {
      if (productBundle) {
        this.selectedProductBundleData = {...productBundle, qty: 1};

        // Initialise Install Times
        this.selectedProductBundleData.grouped_products.forEach((elem) => {
          // Set up any Product Cables
          if (elem.product.default_cables) {
            elem.cables = elem.product.default_cables.cables;
          } else if (elem.product && elem.product.system_design && elem.product.system_design.hasOwnProperty('cables')) {
            elem.cables = JSON.parse(JSON.stringify(elem.product.system_design.cables));
          }

          const installTimeDetails = [];
          this.teamPositions.forEach((el) => {
            const details = { ...el };
            const defaultInstallTime = elem.team_install_info_default.find((s) => s.team_position_id === details.id);
            details.install_time_mins = defaultInstallTime ? defaultInstallTime.install_time_mins : 0;
            installTimeDetails.push(details);
          });
          elem.installTimeDetails = installTimeDetails;
        });

        // Initialise Install Times for Custom Products
        this.selectedProductBundleData.grouped_custom_products.forEach((elem) => {
          const installTimeDetails = [];
          this.teamPositions.forEach((el) => {
            const details = { ...el };
            const defaultInstallTime = elem.team_install_info_default.find((s) => s.team_position_id === details.id);
            details.install_time_mins = defaultInstallTime ? defaultInstallTime.install_time_mins : 0;
            installTimeDetails.push(details);
          });
          elem.installTimeDetails = installTimeDetails;
        });
      }
    },
    groupBy(arr) {
      return arr.reduce((obj, item) => {
        const key = `${item.product_id}_${item.supplier.id}`;

        // If the key doesn't exist yet, create it
        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
          obj[key] = [];
        }

        // Push the value to the object
        obj[key].push(item);

        // Return the object to the next item in the loop
        return obj;
      }, {});
    },
    groupByCustom(arr) {
      return arr.reduce((obj, item) => {
        const key = `custom_${item.product.id}`;

        // If the key doesn't exist yet, create it
        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
          obj[key] = [];
        }

        // Push the value to the object
        obj[key].push(item);

        // Return the object to the next item in the loop
        return obj;
      }, {});
    },
    getProject() {
      this.selectedProject = null;
      this.selectedFloor = null;
      this.selectedRoomId = null;
      this.loading = true;
      this.axios.get(`/api/projects/${this.selectedProjectId}`).then((response) => {
        this.selectedProject = {
          id: response.data.id,
          name: response.data.name,
          floors: response.data.floors,
          network_range_1: response.data.network_range_1,
          network_range_2: response.data.network_range_2,
          network_range_3: response.data.network_range_3,
          stage: response.data.stage
        };
      }).finally(() => {
        this.loading = false;
      });
    },
    cancelAddProductBundle() {
      this.$emit('cancelAddProductBundle');
    },
    hasErrors(field, errors) {
      if (errors) {
        return Object.prototype.hasOwnProperty.call(errors, field);
      }
      return false;
    },
    selectProduct(productIndex) {
      if (this.activeProductIndex === productIndex) {
        this.activeProductIndex = null;
      } else {
        this.activeProductIndex = productIndex;
      }
    },
    selectAccordion(accordion) {
      if (this.activeAccordion === accordion) {
        this.activeAccordion = null;
      } else {
        this.activeAccordion = accordion;
      }
    },
    decreaseSelProdQty() {
      if (this.selectedProductBundleData.qty > 1) {
        this.selectedProductBundleData.qty--;
      }
    },
    increaseSelProdQty() {
      this.selectedProductBundleData.qty++;
    },
    closeAddProductModal() {
      this.$emit("close");
      Object.assign(this.$data, this.$options.data.call(this));
    },
    addBundleToProject(bundle) {
      let projectId, roomId;
      projectId = this.projectExist ? this.project.id : this.selectedProjectId;
      roomId = this.projectExist ? this.roomId : this.selectedRoomId;

      this.axios.post(`/api/projects/${projectId}/room/${roomId}/add-product-bundle`, bundle).then((res) => {

        this.$notify({type: "success", title: "Bundle Product Added", text: `${bundle.qty} x ${bundle.name} added`});
        this.closeAddProductModal();
      }).finally(() => {
          this.$forceUpdate();
          this.$router.push({
            name: "projectRoomProducts",
            params: { projectId, roomId }
            });
          this.closeModal();
        });
    },
    productInstallTime(installTimes) {
      let installTime = 0;
      installTimes.forEach((el) => {
        installTime += parseInt(el.install_time_mins);
      });
      return installTime;
    },
    productTotalInstallTime(installTimes, qty) {
      const totalInstallTime = this.productInstallTime(installTimes) * qty;
      return totalInstallTime;
    },
    isRakoWiringModule(product) {
      if (!product.system_design || !product.system_design.rakoProductType) {
        return false;
      }
      return product.system_design.rakoProductType === 'Wiring Module';
    },
    isKeypad(product) {
      if (!product.system_design || !product.system_design.rakoProductType || product.system_design.rakoProductType !== 'Keypad') {
        return false;
      }
      return product.system_design.rakoProductType === 'Keypad';
    },
    isRakoLEDModule(product) {
      if (!product.system_design || !product.system_design.rakoProductType) {
        return false;
      }
      return product.system_design.rakoProductType === 'LED Driver';
    },
    closeModal() {
      this.selectedProjectId = null;
      this.selectedProject = null;
      this.selectedFloor = null;
      this.selectedRoomId = null;
      this.activeAccordion = null;
      this.$emit("close");
    },
    closeAddCableToProductModal() {
      this.showAddCableToProductModal = false;
    },
    addCableToProduct(cable, purpose) {
      if (this.activeProductIndex !== null ) {
        this.selectedProductBundleData.grouped_products[this.activeProductIndex].cables.push({ cable, purpose });
      }
    },
    deleteCable(index) {
      if (this.activeProductIndex !== null ) {
        this.selectedProductBundleData.grouped_products[this.activeProductIndex].cables.splice(index, 1);
      }
    },
    getBundleRetailPrice(bundle) {
      let total = 0;
      bundle.grouped_products.forEach((el) => {
        total += (el.product_pricing.retail_price * el.qty);
      });

      bundle.grouped_custom_products.forEach((el) => {
        total += (el.product.retail_price * el.qty);
      });

      return total;
    },
    extractBundleBrands(bundle) {
      const brands = [];

      // Normal Products
      bundle.grouped_products.forEach(el => {
        if (!brands.includes(el.product.brand)) {
          brands.push(el.product.brand);
        }
      });

      // Custom Products
      bundle.grouped_custom_products.forEach(el => {
        if (!brands.includes(el.product.brand)) {
          brands.push(el.product.brand);
        }
      });

      return brands.sort().join(', ');
    }
  }
};
</script>