<template>
  <div v-if="products.length > 0" class="flex items-center">
    <div class="flex overflow-hidden mr-2">
      <div v-if="products.length >= 3">
        <span v-for="(item, index) in 3" :key="index">
          <img
            v-if="uniqueProducts[item] && uniqueProducts[item].product && uniqueProducts[item].product.first_image"
            class="inline-block h-8 w-8 rounded-full text-white border-solid border-2 border-white shadow-md"
            :class="index !== 0 && '-ml-2'" :src="uniqueProducts[item].product.image_url"
          >
        </span>
        <p v-if="numRemainingProducts > 0 && showNoProducts" class="text-xs mt-1">
          + {{ numRemainingProducts }} more
        </p>
      </div>
      <div v-else>
        <span v-for="(product, index) in uniqueProducts" :key="index">
          <img
            v-if="product && product.product.first_image"
            class="inline-block h-8 w-8 rounded-full text-white border-solid border-2 border-white shadow-md"
            :class="index !== 0 && '-ml-2'" :src="product.product.image_url"
          >
        </span>
      </div>
    </div>
    <p v-if="numRemainingProducts > 0 && !showNoProducts" class="text-xs mt-1">
      + {{ numRemainingProducts }} more
    </p>
  </div>
</template>

<script>
export default {
  props: {
    products: {
      type: Array,
      required: true
    },
    showNoProducts: {
      type: Boolean,
      required: false,
      default: false
    },
    totalProducts: {
      type: Number,
      required: true
    }
  },
  computed: {
    numRemainingProducts() {
      return this.totalProducts - 3;
    },
    uniqueProducts() {
      const uniqueProducts = Array.from(new Set(this.products.map(a => a.product_id))).map(id => {
        return this.products.find(a => a.product_id === id);
      });
      return uniqueProducts;
    }
  }
};
</script>
