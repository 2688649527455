<template>
  <div class="shell-container">
    <header class="router-header flex flex-wrap transition ease-in-out container px-8">
      <div class="flex-1 text-right" />
      <h3 class="flex-10 sans-serif text-2xl xl:text-4xl text-center font-semibold">
        Search
      </h3>
      <div class="flex-1 text-right" />
    </header>

    <div class="container">
      <ais-instant-search index-name="products" :search-client="searchClient">
        <ais-autocomplete>
          <div slot-scope="{ currentRefinement, indices, refine }" class="relative">
            <div class="relative rounded-md shadow-sm">
              <div class="flex absolute inset-y-0 left-0 items-center px-3 border-r pointer-events-none" aria-hidden="true">
                <HeroIcon icon="Search" class="w-4 h-4 text-blue-light" type="solid" />
              </div>

              <input
                type="search"
                :value="currentRefinement"
                placeholder="Search Products"
                class="pl-12 py-4 form-input"
                :class="currentRefinement ? 'rounded-b-none' : ''"
                @input="refine($event.currentTarget.value)"
              >
            </div>

            <div v-if="currentRefinement" class="overflow-y-auto absolute z-50 -mt-1 w-full max-h-96 bg-white rounded-b-md border border-gray-300 shadow-lg">
              <ul v-for="index in indices" :key="index.indexId">
                <li>
                  <ul class="divide-y">
                    <li v-for="product in index.hits" :key="product.objectID" class="overflow-hidden py-2 px-4 hover:bg-gray-50 cursor-pointer" @click="viewProduct(product)">
                      <div class="flex flex-col justify-between items-center">
                        <div class="flex items-center w-full">
                          <div class="flex justify-center items-center mr-4 w-16 h-16 rounded-md border">
                            <img v-if="product.first_image" class="object-contain w-12 h-12" :src="`${apiUrl}/${product.first_image.src}`">
                            <HeroIcon v-else icon="Cube" class="w-6 h-6 text-gray-400" />
                          </div>
                          <div class="w-3/4">
                            <h4 class="font-medium">
                              {{ product.name }}
                            </h4>
                            <p class="text-xs text-gray-400">
                              {{ product.brand }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </ais-autocomplete>
      </ais-instant-search>
      <div class="products-grid grid grid-cols-2 gap-2 sm:grid-cols-4 sm:gap-4 sm:px-8">
        <div v-for="product in randomProducts" :key="product.index" class="products-grid--product bg-gradient-180 text-center">
          <div class="product--image">
            <img v-if="product.first_image" :src="product.first_image.url" :alt="product.title">
          </div>
          <span class="sans-serif block mt-5 font-semibold text-xs xl:text-base text-white pt-3">{{ product.name }}</span>
          <p class="text-xs font-semibold xl:font-bold text-gray-200 mb-5 mt-2">
            {{ product.supplier_pricing.highestRetailPrice | currency }}
          </p>
          <button class="bg-gradient-green rounded-md py-2 px-4 mb-5 font-medium text-xs text-white button-primary" @click="viewProduct(product)">
            View Product
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';

export default {
  data() {
    return {
      searchClient: algoliasearch('YYZ8HSYHEC', 'a8f22615ff61a9432eb5d4d3b9342966'),
      loading: true,
      randomProducts: null
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    categories() {
      return this.$store.state.system.categories;
    },
    loadedPercent() {
      return this.$store.getters.loadedPercent;
    },
     hasSearchTerms() {
      return (this.searchQuery !== null && this.searchQuery !== '');
    },
    apiUrl() {
      return process.env.VUE_APP_API_HTTPS;
    }
  },
  mounted() {
    this.randomProduct();
  },
  methods: {
    randomProduct() {
      this.axios.get('/api/products/random').then(res => {
        this.randomProducts = res.data;
      });
    },
     viewProduct(product) {
      this.$router.push({ name: 'viewProduct', params: { productId: product.id } });
    }
  }
};
</script>