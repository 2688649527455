import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import project from './project';
import system from './system';
import integrations from './integrations';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    project,
    system,
    integrations
  }
});