import Vue from 'vue';
import Router from 'vue-router';
import VueRouter from 'vue-router';
import store from '@/store/index.js';
import Shell from '../layouts/Shell.vue';
import ProjectShell from '../layouts/ProjectShell.vue';
import Search from '../views/Search.vue';
import Product from '../views/Product.vue';
import Browse from '../views/Browse.vue';
import BuilderProjects from '../modules/builder/views/BuilderProjects.vue';
import BuilderProjectOverview from '../modules/builder/views/BuilderProjectOverview.vue';
import BuilderProjectRooms from '../modules/builder/views/BuilderProjectRooms.vue';
import BuilderProjectRoomProducts from '../modules/builder/views/BuilderProjectRoomProducts.vue';
import BuilderProductCategory from '../modules/builder/views/BuilderProductCategory.vue';

Vue.use(Router);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/',
    redirect: '/builder'
  },
  {
    path: '/',
    name: 'shell',
    component: Shell,
    title: 'Specifi',
    children: [
      {
        path: 'search',
        name: 'search',
        component: Search,
        title: 'Search',
        meta: { transitionName: 'slide' }
      }
    ]
  },
  {
    path: '/builder',
    component: Shell,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'projects',
        component: BuilderProjects,
        title: 'Projects'
      },
      { 
        path: ':projectId', 
        component: ProjectShell,
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: 'overview',
            name: 'projectOverview',
            component: BuilderProjectOverview,
            title: 'Project Overview',
            meta: { transitionName: 'slide' }
          },
          {
            path: 'rooms',
            name: 'projectRooms',
            component: BuilderProjectRooms,
            title: 'Rooms',
            meta: { transitionName: 'slide' }
          },
          {
            path: 'rooms/:roomId/category/:categorySlug',
            name: 'projectRoomProductCategory',
            component: BuilderProductCategory,
            title: 'Product Category',
            meta: { transitionName: 'slide' }
          },
          {
            path: 'rooms/:roomId/products',
            name: 'projectRoomProducts',
            component: BuilderProjectRoomProducts,
            title: 'Products',
            meta: { transitionName: 'slide' }
          },
          {
            path: 'rooms/:roomId/products/view/:productId',
            name: 'projectRoomProduct',
            component: Product,
            title: 'Product',
            meta: { transitionName: 'slide' }
          },
          {
            path: 'rooms/:roomId/custom-product/view/:productId',
            name: 'projectRoomCustomProduct',
            component: Product,
            title: 'Product',
            meta: { transitionName: 'slide' }
          },
          {
            path: 'rooms/:roomId/bundle-product/view/:productId',
            name: 'projectRoomBundleProduct',
            component: Product,
            title: 'Product',
            meta: { transitionName: 'slide' }
          }
        ]
      }
    ]
  },
  {
    path: '/browse',
    component: Shell,
    children: [
      { 
        path: '/browse', 
        component: ProjectShell,
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: '/',
            name: 'browse',
            component: Browse,
            title: 'Browse',
            meta: { transitionName: 'slide' }
          },
          {
            path: 'category/:categorySlug',
            name: 'browseCategory',
            component: BuilderProductCategory,
            title: 'Browse Category',
            meta: { transitionName: 'slide' }
          },
          {
            path: 'category/:categorySlug/product/:productId',
            name: 'viewCategoryProduct',
            component: Product,
            title: 'Browse Category Product',
            meta: { transitionName: 'slide' }
          },
          {
            path: 'product/:productId',
            name: 'viewProduct',
            component: Product,
            title: 'View Product',
            meta: { transitionName: 'slide' }
          }
        ]
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/authenticated'];

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.matched.some((record) => record.meta.permission) && !to.matched.some((record) => {
      if (!isAuthenticated) {
        next('/login');
        return;
      }

      if (store.state.auth.user.id === store.state.auth.user.team.owner_id) {
        return true;
      }

      const rolePermissions = store.state.auth.user.team.access_permissions.find((el) => {
        // If they don't have a User Role - they have no access
        if (!store.state.auth.user.role) {
          return false;
        }
        return el.name === store.state.auth.user.role.name;
      });
    
      const hasPermission = rolePermissions ? rolePermissions.permissions.filter((el) => el.name === record.meta.permission).length > 0 : false;
      return hasPermission;
    })) {
      next('/');
      return;
    }

    if (isAuthenticated) {
      next();
      return;
    }
    next('/login');
  } else if (to.matched.some((record) => record.meta.checkAuth)) {
    if (isAuthenticated) {
      next('/');
    }
    next();
  } else {
    next();
  }
});


export default router;
