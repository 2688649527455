import axios from 'axios';

export default {
  namespaced: true,
  state: {
    project: null,
    projects: []
  },
  getters: {
    project(state) {
      return state.project;
    },
    projects(state) {
      return state.projects;
    }
  },
  mutations: {
    SET_PROJECT(state, value) {
      state.project = value;
    },
    SET_PROJECTS(state, value) {
      state.projects = value;
    },
    ADD_PROJECT(state, value) {
      state.projects = value;
    },
    setInitialised(state, status) {
      state.initialised = status;
    },
    DELETE_PROJECT(state, projectId) {
      const currProjects = state.projects;
      currProjects.splice(state.projects.findIndex(element => element.id == projectId), 1);
      state.projects.sort((a, b) => a.lastUpdated > b.lastUpdated ? 1 : -1);
    },
    DELETE_ROOM(state, { floorId, roomId }) {
      const floors = state.project.floors;
      const floorIndex = floors.findIndex(floor => floor.id === floorId);
      const floor = floors[floorIndex];
      const delRoomIndex = floor.rooms.findIndex(room => room.id === roomId);
      floor.rooms.splice(delRoomIndex, 1);
      floors[floorIndex] = floor;
      state.project.floors = floors;
    },
    UPDATE_ROOM(state, { floorId, room }) {
      const floors = state.project.floors;
      const floorIndex = floors.findIndex(floor => floor.id === floorId);
      const floor = floors[floorIndex];
      const roomIndex = floor.rooms.findIndex(roomIdx => roomIdx.id === room.id);
      floor.rooms[roomIndex].type = room.type;
      floor.rooms[roomIndex].name = room.name;
      floor.rooms[roomIndex].distance_from_rack = room.distance_from_rack;
      floor.rooms[roomIndex].ceiling_depth = room.ceiling_depth;
      floor.rooms[roomIndex].ethernet_points = room.ethernet_points;
      floor.rooms[roomIndex].phone_points = room.phone_points;
      floors[floorIndex] = floor;
      state.project.floors = floors;
    },
    ADD_ROOM(state, { room }) {
      const floors = state.project.floors;
      const updFloorIndex = floors.findIndex(floor => floor.id === room.floor_id);
      floors[updFloorIndex].rooms.push(room);
      state.project.floors = floors;
    },
    // UPGRADE_PRODUCT(state, { upgradeProduct, newProducts, room }) {
    //   const floors = state.project.floors;
    //   const floorIndex = floors.findIndex(floor => floor.id === room.floor_id);
    //   const floor = floors[floorIndex];
    //   const roomIndex = floor.rooms.findIndex(roomIdx => roomIdx.id === room.id);
    //   const allIndexes = floor.rooms[roomIndex].room_products.map((elm, idx) => (elm.product_id === upgradeProduct.current_product_id) && (elm.supplier_id === upgradeProduct.current_supplier_id) ? idx : '').filter(String);
    //   const productsWithOldProductsRemoved = floor.rooms[roomIndex].room_products.filter((el, idx) => !allIndexes.includes(idx));
    //   const roomProductsUpd = [].concat(productsWithOldProductsRemoved, newProducts);
    //   floor.rooms[roomIndex].room_products = roomProductsUpd;
    //   floors[floorIndex] = floor;
    //   state.project.floors = floors;
    // },

    // UPGRADE_PRODUCT_ADDED_TO_PRODUCT (state, { product, productDetails, room  }) {
    //   const floors = state.project.floors;
    //   const floorIndex = floors.findIndex(floor => floor.id === room.floor_id);
    //   const floor = floors[floorIndex];
    //   const roomIndex = floor.rooms.findIndex(roomIdx => roomIdx.id === room.id);
    //   const allIndexes = floor.rooms[roomIndex].room_products.map((elm, idx) => elm.product_id === product.product_id ? idx : '').filter(String);
    //   allIndexes.forEach((el) => {
    //     floor.rooms[roomIndex].room_products[el].upgrade_products.push(productDetails);
    //   });
    //   floors[floorIndex] = floor;
    //   state.project.floors = floors;

    // },
    ADD_PRODUCT(state, { floorId, room, product }) {
      const floors = state.project.floors;
      
      product.productsAdded.forEach((product) => {
        const floorIndex = floors.findIndex((floor) => {
          const room_ids = floor.rooms.map(el => el.id);
          return room_ids.includes(product.room_id);
        });
        const floor = floors[floorIndex];
        const updRoomIndex = floor.rooms.findIndex((room) => room.id === product.room_id);
        floor.rooms[updRoomIndex].room_products.push(product);
        floors[floorIndex] = floor;
      });

      // Add any additional products - i.e. Rako pre-populated products
      product.additionalProductsAdded.forEach((product) => {
        const floorIndex = floors.findIndex((floor) => {
          const room_ids = floor.rooms.map(el => el.id);
          return room_ids.includes(product.room_id);
        });
        const floor = floors[floorIndex];
        const updRoomIndex = floor.rooms.findIndex((room) => room.id === product.room_id);
        floor.rooms[updRoomIndex].room_products.push(product);
        floors[floorIndex] = floor;
      });
      state.project.floors = floors;
    },
    ADD_CUSTOM_PRODUCT(state, { floorId, room, product }) {
      const floors = state.project.floors;
      const floorIndex = floors.findIndex(floor => floor.id === floorId);
      const floor = floors[floorIndex];

      const roomIndex = floor.rooms.findIndex(roomIdx => roomIdx.id === room);

      floor.rooms[roomIndex].room_custom_products.push(product);

      floors[floorIndex] = floor;
      state.project.floors = floors;
    },
    REMOVE_PRODUCT(state, { floorId, room, product, supplier }) {
      const floors = state.project.floors;
      const floorIndex = floors.findIndex(floor => floor.id === floorId);
      const floor = floors[floorIndex];

      const roomIndex = floor.rooms.findIndex(roomIdx => roomIdx.id === room);

      const productRoomIndex = lastIndexOf(floor.rooms[roomIndex].room_products, product, supplier); 

      floor.rooms[roomIndex].room_products.splice(productRoomIndex, 1);

      floors[floorIndex] = floor;
      state.project.floors = floors;
    },
    REMOVE_CUSTOM_PRODUCT(state, { floorId, room, product }) {
      const floors = state.project.floors;
      const floorIndex = floors.findIndex(floor => floor.id === floorId);
      const floor = floors[floorIndex];

      const roomIndex = floor.rooms.findIndex(roomIdx => roomIdx.id === room);

      const productRoomIndex = lastIndexOf(floor.rooms[roomIndex].room_custom_products, product); 

      floor.rooms[roomIndex].room_custom_products.splice(productRoomIndex, 1);

      floors[floorIndex] = floor;
      state.project.floors = floors;
    },
    ADD_PLACEHOLDER_PRODUCT(state, { floorId, room, product }) {
      const floors = state.project.floors;
      const floorIndex = floors.findIndex(floor => floor.id === floorId);
      const floor = floors[floorIndex];

      const roomIndex = floor.rooms.findIndex(roomIdx => roomIdx.id === room);
      const idx = floor.rooms[roomIndex].room_placeholder_products.findIndex(el => el.id === product);

      floor.rooms[roomIndex].room_placeholder_products[idx].qty = floor.rooms[roomIndex].room_placeholder_products[idx].qty + 1;

      floors[floorIndex] = floor;
      state.project.floors = floors;
    },
    REMOVE_PLACEHOLDER_PRODUCT(state, { floorId, room, product }) {
      const floors = state.project.floors;
      const floorIndex = floors.findIndex(floor => floor.id === floorId);
      const floor = floors[floorIndex];

      const roomIndex = floor.rooms.findIndex(roomIdx => roomIdx.id === room);
      const idx = floor.rooms[roomIndex].room_placeholder_products.findIndex(el => el.id === product);

      if (floor.rooms[roomIndex].room_placeholder_products[idx].qty > 1) {
        floor.rooms[roomIndex].room_placeholder_products[idx].qty = floor.rooms[roomIndex].room_placeholder_products[idx].qty - 1;
      } else {
        floor.rooms[roomIndex].room_placeholder_products.splice(idx, 1);
      }

      floors[floorIndex] = floor;
      state.project.floors = floors;
    },
    REMOVE_ALL_PRODUCT(state, { floorId, room, product, supplier }) {
      const floors = state.project.floors;
      const floorIndex = floors.findIndex(floor => floor.id === floorId);
      const floor = floors[floorIndex];

      const roomIndex = floor.rooms.findIndex(roomIdx => roomIdx.id === room);

      const roomProducts =  floor.rooms[roomIndex].room_products;
      const updProducts = roomProducts.filter(el => el.product_id !== product || el.supplier_id !== supplier);
      floor.rooms[roomIndex].room_products = updProducts;

      floors[floorIndex] = floor;
      state.project.floors = floors;
    },
    UPDATE_PROJECT(state, project) {   
      state.projects = [
        ...state.projects.filter(element => element.id !== project.id),
        project
      ];
      state.projects.sort((a, b) => new Date(a.updated_at) < new Date(b.updated_at) ? 1 : -1);
    },
    UPDATE_PRODUCT_INFO(state, { floorId, room, product }){
      const floors = state.project.floors;
      const floorIndex = floors.findIndex(floor => floor.id === floorId);
      const floor = floors[floorIndex];

      const roomIndex = floor.rooms.findIndex(roomIdx => roomIdx.id === room.id);
      const roomProducts =  floor.rooms[roomIndex].room_products;
      const ProductIdx = roomProducts.findIndex(el => el.product_id === product.product_id);
      const updProducts = [...roomProducts];

      updProducts[ProductIdx] = product;
      floor.rooms[roomIndex].room_products = updProducts;
      floors[floorIndex] = floor;
      state.project.floors = floors;
    }, 
    UPDATE_CUSTOM_PRODUCT(state, { floorId, room, product }){
      const floors = state.project.floors;
      const floorIndex = floors.findIndex(floor => floor.id === floorId);
      const floor = floors[floorIndex];

      const roomIndex = floor.rooms.findIndex(roomIdx => roomIdx.id === room);
      const customProducts =  floor.rooms[roomIndex].room_custom_products;
      const customProductIdx = customProducts.findIndex(el => el.product_id === product.product_id);
      const updCustomProducts = [...customProducts];
      
      updCustomProducts[customProductIdx] = product;
      floor.rooms[roomIndex].room_custom_products  = updCustomProducts;
      floors[floorIndex] = floor;
      state.project.floors = floors;
    }
  },
  actions: {
    init({ dispatch, commit }) {
      return Promise.all([
        dispatch('getProjects'),
        dispatch('getProject')
      ]).then(() => {
        commit('setInitialised', true);
      });
    },
    getProjects({ commit, state }) {
      if (!state.projects.length) {
        return axios.get(`/api/projects/all-projects`).then((projects) => {
          commit('SET_PROJECTS', projects.data);
          return projects;
        });
      }
      return state.projects;
    },
    getProject({ commit }, projectId) {
      if(projectId) {
        axios.get(`/api/projects/${projectId}/design`).then((response) => {

          const project = {
            id: response.data.id,
            name: response.data.name,
            floors: response.data.floors,
            network_range_1: response.data.network_range_1,
            network_range_2: response.data.network_range_2,
            network_range_3: response.data.network_range_3,
            stage: response.data.stage
          };

          commit('SET_PROJECT', project);
        });
      } else { 
        commit('SET_PROJECT', null);
      }
    },
    addProject({ commit }) {
      axios.get(`/api/projects/all-projects`).then((projects) => {
        commit('ADD_PROJECT', projects.data);
      });
    },
    updateProject({ commit }, project) {
      commit('UPDATE_PROJECT', project);
    },
    removeProject({ commit }, projectId) {
      commit('DELETE_PROJECT', projectId);
    },
    addRoom({ commit }, { room }) {
      commit('ADD_ROOM', { room });
    },
    removeRoom({ commit }, { floorId, roomId }) {
      commit('DELETE_ROOM', { floorId, roomId });
    },
    updateRoom({ commit }, { floorId, room }) {
      commit('UPDATE_ROOM', { floorId, room });
    },
    addProduct({ commit }, { floorId, room, product }) {
      commit('ADD_PRODUCT', { floorId, room, product });
    },
    addCustomProduct({ commit }, { floorId, room, product }) {
      commit('ADD_CUSTOM_PRODUCT', { floorId, room, product });
    },
    updateCustomProduct({ commit }, { floorId, room, product }) {
      commit('UPDATE_CUSTOM_PRODUCT', { floorId, room, product });
    },
    removeProduct({ commit }, { floorId, room, product, supplier }) {
      commit('REMOVE_PRODUCT', { floorId, room, product, supplier });
    },
    removeCustomProduct({ commit }, { floorId, room, product }) {
      commit('REMOVE_CUSTOM_PRODUCT', { floorId, room, product });
    },
    removePlaceholderProduct({ commit }, { floorId, room, product }) {
      commit('REMOVE_PLACEHOLDER_PRODUCT', { floorId, room, product });
    },
    addPlaceholderProduct({ commit }, { floorId, room, product }) {
      commit('ADD_PLACEHOLDER_PRODUCT', { floorId, room, product });
    },
    removeAllProduct({ commit }, { floorId, room, product, supplier }) {
      commit('REMOVE_ALL_PRODUCT', { floorId, room, product, supplier });
    },
    // updateProductUpgraded({ commit }, { upgradeProduct, newProducts, room }) {
    //   commit('UPGRADE_PRODUCT', { upgradeProduct, newProducts, room  });
    // },
    // upgradeProductAddedToProduct({ commit }, { product, productDetails, room }) {
    //   commit('UPGRADE_PRODUCT_ADDED_TO_PRODUCT', {product, productDetails, room });
    // },
    updateProductInfo({ commit }, { floorId, room, product }) {
      commit('UPDATE_PRODUCT_INFO', { floorId, room, product });
    },
    addProductToRoom({ commit, state }, updDetails) {
      const project = { ...state.project };
      const floors = [...project.floors];

      // Add the products that have been directly added to the room
      updDetails.productsAdded.forEach((product) => {
        const floorIndex = floors.findIndex((floor) => {
          const room_ids = floor.rooms.map(el => el.id);
          return room_ids.includes(product.room_id);
        });
        const floor = floors[floorIndex];
        const updRoomIndex = floor.rooms.findIndex((room) => room.id === product.room_id);
        floor.rooms[updRoomIndex].room_products.push(product);
        floors[floorIndex] = floor;

        // Update Projects overall Costs
        // project.selected_version.value += product.customer_price;
        // project.selected_version.cost += product.trade_price;
      });

      // Add any additional products - i.e. Rako pre-populated products
      updDetails.additionalProductsAdded.forEach((product) => {
        const floorIndex = floors.findIndex((floor) => {
          const room_ids = floor.rooms.map(el => el.id);
          return room_ids.includes(product.room_id);
        });
        const floor = floors[floorIndex];
        const updRoomIndex = floor.rooms.findIndex((room) => room.id === product.room_id);
        floor.rooms[updRoomIndex].room_products.push(product);
        floors[floorIndex] = floor;

        // Update Projects overall Costs
        // project.selected_version.value += product.customer_price;
        // project.selected_version.cost += product.trade_price;
      });

      project.floors = floors;

      commit('SET_PROJECT', project);
    },
    incrementProductBundleInRoom({ commit, state }, updDetails) {
      const project = {...state.project};
      const floors = [...project.floors];
      const floorIndex = floors.findIndex(floor => floor.id === updDetails.floor_id);
      const floor = floorIndex !== -1 ? floors[floorIndex] : commsFloors[commsFloorIndex];
      const updRoomIndex = floor.rooms.findIndex(room => room.id === updDetails.room_id);
      floor.rooms[updRoomIndex].room_product_bundles.push(updDetails.productDetails);
      floors[floorIndex] = floor;
      project.floors = floors;

      // Update Projects overall Costs
      let valueIncrease = 0;
      let costIncrease = 0;

      // Normal Products
      updDetails.productDetails.bundle_products.forEach((bundleProducts) => {
        valueIncrease += bundleProducts.customer_price;
        costIncrease += bundleProducts.trade_price;
      });

      // Custom Products
      updDetails.productDetails.bundle_custom_products.forEach((customBundleProducts) => {
        valueIncrease += customBundleProducts.customer_price;
        costIncrease += customBundleProducts.trade_price;
      });

      // project.selected_version.value += valueIncrease;
      // project.selected_version.cost += costIncrease;

      commit('SET_PROJECT', project);
    },
    removeProductBundleFromRoom({ commit, state }, updDetails) {
      const project = {...state.project};
      const floors = [...project.floors];
      const floorIndex = floors.findIndex(floor => floor.id === updDetails.floor_id);
      const floor = floors[floorIndex];
      const updRoomIndex = floor.rooms.findIndex(room => room.id === updDetails.room_id);
      const prodIdx = lastIndexOf(floor.rooms[updRoomIndex].room_product_bundles, updDetails.productDetails.id);
      floor.rooms[updRoomIndex].room_product_bundles.splice(prodIdx, 1);
      floors[floorIndex] = floor;
      project.floors = floors;

      // Update Projects overall Costs
      let valueDecrease = 0;
      let costDecrease = 0;

      // Normal Products
      updDetails.productDetails.products.forEach((bundleProducts) => {
        valueDecrease += bundleProducts.customer_price;
        costDecrease += bundleProducts.trade_price;
      });

      // Custom Products
      updDetails.productDetails.customProducts.forEach((customBundleProducts) => {
        valueDecrease += customBundleProducts.customer_price;
        costDecrease += customBundleProducts.trade_price;
      });

      // project.selected_version.value -= valueDecrease;
      // project.selected_version.cost -= costDecrease;

      commit('SET_PROJECT', project);
    },
    removeAllProductBundlesFromRoom({ commit, state }, updDetails) {
      const project = {...state.project};
      const floors = [...project.floors];
      const floorIndex = floors.findIndex(floor => floor.id === updDetails.floor_id);
      const floor = floorIndex !== -1 ? floors[floorIndex] : commsFloors[commsFloorIndex];
      const updRoomIndex = floor.rooms.findIndex(room => room.id === updDetails.room_id);
      const roomProducts = floor.rooms[updRoomIndex].room_product_bundles;
      const updProducts = roomProducts.filter(el =>  el.team_product_bundle_id !== updDetails.productDetails.id);
      floor.rooms[updRoomIndex].room_product_bundles = updProducts;
      floors[floorIndex] = floor;
      project.floors = floors;

      // Update Projects overall Costs
      let valueDecrease = 0;
      let costDecrease = 0;
      for (let i = 0; i < updDetails.productDetails.qty; i++) {
        // Normal Products
        updDetails.productDetails.products.forEach((bundleProducts) => {
          valueDecrease += bundleProducts.customer_price;
          costDecrease += bundleProducts.trade_price;
        });

        // Custom Products
        updDetails.productDetails.customProducts.forEach((customBundleProducts) => {
          valueDecrease += customBundleProducts.customer_price;
          costDecrease += customBundleProducts.trade_price;
        });
      }

      // project.selected_version.value -= valueDecrease;
      // project.selected_version.cost -= costDecrease;

      commit('SET_PROJECT', project);
    },
    addCustomProductToRoom({ commit, state }, updDetails) {
      const project = {...state.project};
      const floors = [...project.floors];
      const floorIndex = floors.findIndex(floor => floor.id === updDetails.floor_id);
      const floor = floors[floorIndex];
      const updRoomIndex = floor.rooms.findIndex(room => room.id === updDetails.room_id);

      for (let i = 0; i < updDetails.qty; i++) {
        floor.rooms[updRoomIndex].room_custom_products.push(updDetails.productDetails);
        // Update Projects overall Costs
        // project.selected_version.value += updDetails.productDetails.customer_price;
        // project.selected_version.cost += updDetails.productDetails.product.trade_price;
      }

      floors[floorIndex] = floor;
      project.floors = floors;
      commit('SET_PROJECT', project);
    },
    addProductBundleToRoom({ commit, state }, updDetails) {
      const project = {...state.project};
      const floors = [...project.floors];
      const floorIndex = floors.findIndex(floor => floor.id === updDetails.floor_id);
      const floor = floors[floorIndex];
      const updRoomIndex = floor.rooms.findIndex(room => room.id === updDetails.room_id);


      // Update Projects overall Costs
      let valueIncrease = 0;
      let costIncrease = 0;

      for (let i = 0; i < updDetails.qty; i++) {
        floor.rooms[updRoomIndex].room_product_bundles.push(updDetails.productDetails);

        // Normal Products
        updDetails.productDetails.bundle_products.forEach((bundleProducts) => {
          valueIncrease += bundleProducts.customer_price;
          costIncrease += bundleProducts.trade_price;
        });

        // Custom Products
        updDetails.productDetails.bundle_custom_products.forEach((customBundleProducts) => {
          valueIncrease += customBundleProducts.customer_price;
          costIncrease += customBundleProducts.trade_price;
        });
      }

      // project.selected_version.value += valueIncrease;
      // project.selected_version.cost += costIncrease;

      floors[floorIndex] = floor;
      project.floors = floors;
      commit('SET_PROJECT', project);
    }
  }
};
function lastIndexOf(array, product, supplier) {
  for(let i = array.length - 1; i >= 0; i--){
    if((array[i].product_id === product) && (array[i].supplier_id === supplier))
      return i;
  }
  return -1;
}