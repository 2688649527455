<template>
  <div
    class="modal-window fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center"
    :class="showModal ? 'show' : 'hide'"
  >
    <div
      class="fixed inset-0 transition-opacity"
      :class="showModal ? 'ease-in duration-200 opacity-100' : 'ease-out duration-200 opacity-0'"
    >
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>

    <div
      class="modal-window-split bg-white rounded-lg overflow-hidden shadow-xl transform transition-all"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
      :class="showModal ? 'ease-in duration-200 opacity-100 translate-y-0 sm:scale-100' : 'ease-out duration-300 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'"
    >
      <div v-if="preview" class="modal-window-split--img">
        <img :src="preview" :alt="projectDuplicateData.name">
        <div class="modal-window-split--img-change">
          <label
            for="duplicate-proj-file-upload"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-gradient-900 text-base leading-6 font-medium text-white shadow-sm hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
            </svg>
            Change image
          </label>
          <input
            id="duplicate-proj-file-upload"
            ref="duplicateProjFileUpdate"
            type="file"
            accept="image/*"
            capture="camera"
            @change="onChangeFileUpload()"
          >
        </div>
      </div>
      <div v-else class="modal-window-split--img bg-gradient">
        <div class="modal-window-split--img-upload flex items-center">
          <label for="duplicate-proj-file-upload">
            <div
              class="bg-white bg-opacity-25 text-white font-bold py-2 px-2 rounded-full focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150"
            >
              <div class="bg-white bg-opacity-25 text-white font-bold py-1 px-1 rounded-full">
                <div class="bg-white text-white font-bold py-4 px-4 rounded-full">
                  <img src="@/assets/img/icons/camera-upload.svg" alt="Rooms">
                </div>
              </div>
            </div>
            <span class="text-xl text-white">Take or upload a photo</span>
          </label>
          <input
            id="duplicate-proj-file-upload"
            ref="duplicateProjFileUpdate"
            type="file"
            accept="image/*"
            capture="camera"
            @change="onChangeFileUpload()"
          >
        </div>
      </div>
      <div class="modal-window-split--content">
        <div class="bg-white px-2 pt-2 pb-2 xl:px-4 xl:pt-5 xl:pb-4 sm:p-8 sm:pb-4">
          <div
            class="mb-2 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-lime sm:mx-0 sm:h-10 sm:w-10"
          >
            <svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
              />
            </svg>
          </div>
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center w-full sm:mt-0 sm:text-left">
              <h3
                id="modal-headline"
                class="text-lg xl:text-2xl leading-8 font-bold text-gray-900"
              >
                Duplicate Project
              </h3>

              <div class="mt-2">
                <form class="w-full">
                  <div class="overflow-hidden sm:rounded-md">
                    <div class="px-2 py-2 bg-white xl:p-6">
                      <div class="grid grid-cols-6 gap-4 xl:gap-6">
                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="name_duplicate"
                            class="block text-xs xl:text-sm font-medium text-gray-700"
                          >Project Name</label>
                          <input
                            id="name_duplicate"
                            v-model="projectDuplicateData.name"
                            type="text"
                            name="name_duplicate"
                            required
                            placeholder="Project Name"
                            class="mt-1 appearance-none block w-full px-3 py-2 border text-xs xl:text-base border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="priority_duplicate"
                            class="block text-xs xl:text-sm font-medium text-gray-700"
                          >Project Priority</label>
                          <select
                            id="priority_duplicate"
                            v-model="projectDuplicateData.priority"
                            name="priority"
                            required
                            class="mt-1 block w-full py-2 px-3 border text-xs xl:text-base border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                            <option :value="null" selected disabled>
                              -- Choose Priority --
                            </option>
                            <option>Enquiry Only</option>
                            <option>Confirmed</option>
                            <option>Urgent</option>
                          </select>
                        </div>

                        <template v-if="customers.length > 0">
                          <div class="col-span-6 sm:col-span-3">
                            <label
                              for="name"
                              class="block text-xs xl:text-sm font-medium text-gray-700"
                            >Existing Customer</label>
                            <div class="flex items-center mt-4">
                              <div class="flex mr-4">
                                <input
                                  id="is_existing_customer_duplicate"
                                  v-model="projectDuplicateData.existing_customer"
                                  name="existing_customers"
                                  type="radio"
                                  :value="true"
                                  class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                >
                                <label
                                  for="is_existing_customer_duplicate"
                                  class="ml-3 block text-xs xl:text-sm font-medium text-gray-700"
                                >Yes</label>
                              </div>
                              <div class="flex items-center">
                                <input
                                  id="not_existing_customer_duplicate"
                                  v-model="projectDuplicateData.existing_customer"
                                  name="existing_customers"
                                  type="radio"
                                  :value="false"
                                  class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                >
                                <label
                                  for="not_existing_customer_duplicate"
                                  class="ml-3 block text-xs xl:text-sm font-medium text-gray-700"
                                >No</label>
                              </div>
                            </div>
                          </div>

                          <div
                            v-if="projectDuplicateData.existing_customer"
                            class="col-span-6 sm:col-span-3"
                          >
                            <label
                              for="customer_name_duplicate"
                              class="block text-xs xl:text-sm font-medium text-gray-700"
                            >Customer</label>
                            <select
                              id="priority"
                              v-model="projectDuplicateData.customer"
                              name="priority"
                              required
                              class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                              <option :value="null" selected disabled>
                                -- Choose Customer --
                              </option>
                              <option
                                v-for="(customer, index) in customers"
                                :key="index"
                                :value="customer.id"
                              >
                                {{ customer.fullname }}
                              </option>
                            </select>
                          </div>

                          <br>
                        </template>

                        <div
                          v-if="!projectDuplicateData.existing_customer"
                          class="col-span-6 sm:col-span-3"
                        >
                          <label class="block text-xs xl:text-sm font-medium text-gray-700">Customer First Name</label>

                          <input
                            id="customer_firstname_duplicate"
                            v-model="projectDuplicateData.customer_firstname"
                            type="text"
                            name="customer_firstname_duplicate"
                            required
                            placeholder="e.g. John"
                            class="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                        </div>

                        <div
                          v-if="!projectDuplicateData.existing_customer"
                          class="col-span-6 sm:col-span-3"
                        >
                          <label
                            for="customer_surname_duplicate"
                            class="block text-xs xl:text-sm font-medium text-gray-700"
                          >Customer Surname</label>

                          <input
                            id="customer_surname_duplicate"
                            v-model="projectDuplicateData.customer_surname"
                            type="text"
                            name="customer_surname_duplicate"
                            required
                            placeholder="e.g. Doe"
                            class="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                        </div>

                        <div
                          v-if="!projectDuplicateData.existing_customer"
                          class="col-span-6 sm:col-span-3"
                        >
                          <label
                            class="block text-xs xl:text-sm font-medium text-gray-700"
                          >Customer Phone Number</label>

                          <input
                            id="customer_phone_duplicate"
                            v-model="projectDuplicateData.customer_phone"
                            type="text"
                            name="customer_phone_duplicate"
                            placeholder="e.g. 028 9145 5565"
                            class="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                        </div>

                        <div
                          v-if="!projectDuplicateData.existing_customer"
                          class="col-span-6 sm:col-span-3"
                        >
                          <label class="block text-xs xl:text-sm font-medium text-gray-700">Customer Email</label>

                          <input
                            id="customer_email_duplicate"
                            v-model="projectDuplicateData.customer_email"
                            type="email"
                            name="customer_email_duplicate"
                            placeholder="e.g. john@example.com"
                            class="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                        </div>

                        <div
                          v-if="!projectDuplicateData.existing_customer"
                          class="col-span-6 sm:col-span-3"
                        >
                          <label class="block text-xs xl:text-sm font-medium text-gray-700">Address Line 1</label>
                          <input
                            id="street_address_line_duplicate"
                            v-model="projectDuplicateData.address_line_1"
                            type="text"
                            name="street_address_line_duplicate"
                            :required="!projectDuplicateData.existing_customer"
                            placeholder="e.g. Dunlop Commercial Park"
                            class="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                        </div>

                        <div
                          v-if="!projectDuplicateData.existing_customer"
                          class="col-span-6 sm:col-span-3"
                        >
                          <label class="block text-xs xl:text-sm font-medium text-gray-700">Address Line 2</label>
                          <input
                            id="street_address_line2_duplicate"
                            v-model="projectDuplicateData.address_line_2"
                            type="text"
                            name="street_address_line2_duplicate"
                            placeholder="e.g. 3 Balloo Link"
                            class="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                        </div>

                        <div
                          v-if="!projectDuplicateData.existing_customer"
                          class="col-span-6 sm:col-span-6 lg:col-span-2"
                        >
                          <label for="city" class="block text-xs xl:text-sm font-medium text-gray-700">City</label>
                          <input
                            id="street_address_city_duplicate"
                            v-model="projectDuplicateData.city"
                            type="text"
                            name="street_address_city_duplicate"
                            placeholder="e.g. Bangor"
                            :required="!projectDuplicateData.existing_customer"
                            class="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                        </div>

                        <div
                          v-if="!projectDuplicateData.existing_customer"
                          class="col-span-6 sm:col-span-3 lg:col-span-2"
                        >
                          <label
                            for="state"
                            class="block text-xs xl:text-sm font-medium text-gray-700"
                          >State / Province</label>
                          <input
                            id="street_address_state_duplicate"
                            v-model="projectDuplicateData.county"
                            type="text"
                            name="street_address_state_duplicate"
                            placeholder="e.g. Co. Down"
                            :required="!projectDuplicateData.existing_customer"
                            class="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                        </div>

                        <div
                          v-if="!projectDuplicateData.existing_customer"
                          class="col-span-6 sm:col-span-3 lg:col-span-2"
                        >
                          <label
                            for="postal_code"
                            class="block text-xs xl:text-sm font-medium text-gray-700"
                          >ZIP / Postal</label>
                          <input
                            id="street_address_zip_duplicate"
                            v-model="projectDuplicateData.postcode"
                            type="text"
                            name="street_address_zip_duplicate"
                            placeholder="BT19 7HJ"
                            required
                            class="mt-1 uppercase appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-gradient text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              @click="duplicateProject"
            >Duplicate Project</button>
          </span>
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto sm:ml-3">
            <button
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              @click="closeModal"
            >Cancel</button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    showModal: Boolean,
    projectDuplicateData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      file: "",
      preview: "",
      duplicateProjectErrors: {},
      customers: []
    };
  },
  mounted() {
    this.getCustomers();
  },
  methods: {
    getCustomers() {
      this.axios.get("/api/customers").then(customers => {
        this.customers = customers.data;
      });
    },
    onChangeFileUpload() {
      this.file = this.$refs.duplicateProjFileUpdate.files[0];
      this.preview = URL.createObjectURL(this.file);
    },
    duplicateProject() {
      this.duplicateProjectErrors = {};
      const projectData = { ...this.projectDuplicateData };

      const formData = new FormData();
      if (this.file) {
        formData.append("projectImage", this.file);
      }

      this.axios
        .post(
          `/api/projects/${this.projectDuplicateData.id}/duplicate`,
          projectData
        )
        .then(project => {
          this.$notify({
            type: "success",
            title: "Success",
            text: "Project duplicated successfully."
          });

          this.$store.dispatch("project/init");
          this.$router.push({
            name: "projectRooms",
            params: { projectId: project.data.id }
          });
          this.$router.go(); // Force reload component
        })
        .catch(error => {
          this.$notify({
            type: "error",
            title: "Error",
            text:
              "There was an error duplicating the project. Please check the data and try again."
          });
          this.duplicateProjectErrors = error.response.data.errors;
        });
    },
    closeModal() {
      this.file = "";
      this.preview = "";
      this.$emit("closeModal");
    }
  }
};
</script>