import axios from 'axios';

export default {
  namespaced: true,
  state: {
    linkedIntegrations: [],
    errorWithIntegrationLink: false
  },
  mutations: {
    SET_LINKED_INTEGRATION(state, value) {
      state.linkedIntegrations.push(value);
    },
    SET_ERROR_WITH_INTEGRATION_LINK(state, integration) {
      console.log(integration);
      state.errorWithIntegrationLink = true;
    }
  },
  getters: {
    isLinkedToAccountsPackage: state => {
      return state.linkedIntegrations.length > 0;
    }
  },
  actions: {
    async getLinkedAccountsPackage({ commit }) {
      await axios.get('/api/integrations').then((response) => {
        const integrations = response.data;
        integrations.forEach((integration) => {
          commit('SET_LINKED_INTEGRATION', integration);

          if (!integration.active) {
            commit('SET_ERROR_WITH_INTEGRATION_LINK', integration.name);
          }
        });
      }).catch(() => {
        commit('SET_LINKED_INTEGRATION', null);
      });
    },
    disconnectedFromIntegration({ commit }) {
      commit('SET_LINKED_INTEGRATION', null);
    }
  }
};
