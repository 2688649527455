<template>
  <div class="slide-modal bottom" :class="showModal ? 'slide-modal-active' : ''">
    <div class="slide-modal--overlay" @click="closeModal()" />
    <div class="slide-modal--container">
      <div class="slide-modal--container-content flex justify-between">
        <div class="flex justify-between items-center">
          <h4 class="sans-serif text-3xl font-semibold mr-10 text-white">
            Change Category
          </h4>
        </div>
        <div class="flex justify-between items-center">
          <div class="mr-3">
            <input v-model="searchTerm" class="appearance-none block w-full flex-1 bg-gray-100 text-black border rounded-full py-1 pl-3 pr-6  xl:py-3 xl:pl-5 xl:pr-10 leading-6 text-sm xl:text-lg focus:outline-none focus:bg-white" placeholder="Search Categories">
          </div>
          <button
            class="btn-circle bg-lime hover:bg-white-500 text-white font-bold py-2 px-2 rounded-full" @click="closeModal()"
          >
            <img src="@/assets/img/icons/close.svg" alt="Close">
          </button>
        </div>
      </div>

      <swiper
        ref="categorySwiper"
        class="swiper cat-selector f-7"
        :options="swiperOptions"
        :auto-update="true"
        :auto-destroy="false"
        :delete-instance-on-destroy="false"
        :cleanup-styles-on-destroy="true"
      >
        <swiper-slide v-for="(category, index) in filteredCategories()" :key="index">
          <div class="group relative p-6 text-left text-blue-dark hover:text-white bg-gray-50 hover:bg-blue-light rounded-md transition-all ease-in-out focus:outline-none bg-opacity-20" @click="goToCategory(category, category.id)">
            <h3 class="text-lg font-semibold text-center text-white sm:text-left">
              {{ category.name }}
            </h3>
            <h4 class="mt-1 text-xs font-normal text-center sm:text-left text-white group-hover:text-white">
              {{ 'Product' | pluralize(category.linked_products_count, true) }}
            </h4>

            <div class="flex justify-center sm:justify-end w-full">
              <div v-if="category.image_url" class="flex items-center w-14 h-14">
                <img :src="category.image_url" class="object-cover">
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide v-if="customProducts.length > 0">
          <div class="group relative p-6 text-left text-blue-dark hover:text-white bg-gray-50 hover:bg-blue-light rounded-md transition-all ease-in-out focus:outline-none bg-opacity-20" @click="goToCustom">
            <h3 class="text-lg font-semibold text-center text-white sm:text-left">
              Custom Products
            </h3>
            <h4 class="mt-1 text-xs font-normal text-center sm:text-left text-white group-hover:text-white">
              {{ 'Product' | pluralize(customProducts.length, true) }}
            </h4>
            <div class="flex justify-center sm:justify-end w-full py-3">
              <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" viewBox="0 0 24 24" fill="#ffff">
                  <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
                  <path fill-rule="evenodd" d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" clip-rule="evenodd" />
                </svg>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide v-if="favouriteProducts.length > 0">
          <div class="group relative p-6 text-left text-blue-dark hover:text-white bg-gray-50 hover:bg-blue-light rounded-md transition-all ease-in-out focus:outline-none bg-opacity-20" @click="goToFavourite">
            <h3 class="text-lg font-semibold text-center text-white sm:text-left">
              Favourite Products
            </h3>
            <h4 class="mt-1 text-xs font-normal text-center sm:text-left text-white group-hover:text-white">
              {{ 'Product' | pluralize(favouriteProducts.length, true) }}
            </h4>
            <div class="flex justify-center sm:justify-end w-full py-3">
              <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" viewBox="0 0 24 24" fill="#DEDD2C">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
              </div>
            </div>
          </div>
        </swiper-slide>

        <div
          slot="button-prev"
          :class="$isTouchDevice && 'hide'"
          class="swiper-btn swiper-btn-prev"
        >
          <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-12 h-12">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </div>
        <div
          slot="button-next"
          :class="$isTouchDevice && 'hide'"
          class="swiper-btn swiper-btn-next"
        >
          <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-12 h-12">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </div>
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: Boolean
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-btn-next",
          prevEl: ".swiper-btn-prev"
        },
        loop: false,
        loopedSlides: 3,
        spaceBetween: 20,
        slidesPerView: "auto",
        slidesPerGroup: 3,
        touchRatio: 1
      },
      searchTerm: ''
    };
  },
  computed: {
    categories() {
      return this.$store.state.system.categories;
    },
    customProducts() {
      return this.$store.state.system.customProducts;
    },
    favouriteProducts() {
      return this.$store.state.system.favouriteProducts;
    }
  },
  methods: {
    filteredCategories() {
      let filtered = this.searchTerm ? this.categories.filter(category => category.name.replace(/-|\s/g,"").toLowerCase().includes(this.searchTerm.replace(/-|\s/g,"").toLowerCase())) : this.categories;
      return filtered;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    goToCategory(category, catId) {
      this.$store.dispatch("system/getCurrentCategory", catId).then(() => {
        if(this.$route.params.projectId) {
          this.$router.push({
            name: "projectRoomProductCategory",
            params: { categorySlug: this.getCategorySlug(category.name) }
          });
        } else {
          this.$router.push({ name: 'browseCategory', params: { categorySlug: this.getCategorySlug(category.name) }});
        }
      });
    },
    goToCustom() {
      if(this.$route.params.projectId) {
        this.$router.push({
          name: "projectRoomProductCategory",
          params: { categorySlug: "custom-product" }
        });
      } else {
        this.$router.push({
          name: "browseCategory",
          params: { categorySlug: "custom-product" }
        });
      }
    },
    goToFavourite() {
      if(this.$route.params.projectId) {
        this.$router.push({
          name: "projectRoomProductCategory",
          params: { categorySlug: "favourite-product" }
        });
      } else {
          this.$router.push({
          name: "browseCategory",
          params: { categorySlug: "favourite-product" }
        });
      }
    },
    getCategorySlug(category) {
      return category.replace(/\s+/g, "-").toLowerCase();
    }
  }
};
</script>