<template>
  <Modal title="Edit Custom Product" :show="show" size="large" :close="closeEditProductModal">
    <template v-slot:content>
      <div v-if="editProductData && editProductData.productData && editProductData.productData.products.length > 0" class="relative">
        <div class="my-4">
          <div class="w-full flex justify-between items-center">
            <div class="flex items-center mb-4 sm:mb-0 w-1/2">
              <div
                v-if="editProductData.productData.products[0].product.image_url"
                class="flex items-center w-16 h-16 mr-4"
              >
                <img class="object-cover" :src="editProductData.productData.products[0].product.image_url">
              </div>
              <div class="w-3/4">
                <h4 class="font-medium text-lg text-left">
                  {{ editProductData.productData.name }}
                </h4>
                <p class="text-gray-400 text-xs text-left">
                  {{ editProductData.productData.brand }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <h3 class="mb-2 font-semibold">
          Upgrade Options
        </h3>

        <div class="overflow-hidden mb-6 w-full rounded-md border border-gray-200">
          <div class="grid grid-cols-1 sm:grid-cols-3 divide-y sm:divide-y-0 divide-x-0 sm:divide-x divide-gray-200">
            <div v-for="index in 3" :key="index">
              <div v-if="hasOption(index)" class="grid grid-cols-4 max-w-full h-full">
                <div v-if="editProductData.products[0].upgrade_products[index - 1].upgrade_product.image_url" class="hidden sm:flex flex-shrink-0 items-center w-full h-full border-r border-gray-200">
                  <img class="object-cover" :src="editProductData.products[0].upgrade_products[index - 1].upgrade_product.image_url">
                </div>
                <div v-if="editProductData.products[0].upgrade_products[index - 1].upgrade_product.first_image" class="hidden sm:flex flex-shrink-0 items-center w-full h-full border-r border-gray-200">
                  <img class="object-cover" :src="editProductData.products[0].upgrade_products[index - 1].upgrade_product.first_image.url">
                </div>
                <div class="col-span-3">
                  <div class="p-4">
                    <p class="text-sm font-medium truncate">
                      {{ editProductData.products[0].upgrade_products[index - 1].upgrade_product.name }}
                    </p>
                    <p class="text-xs text-gray-500">
                      {{ getDifferenceInPrice(editProductData.products[0].upgrade_products[index - 1], editProductData.productData) }}
                    </p>
                  </div>

                  <div class="flex w-full border-t border-gray-200 divide-x divide-gray-200">
                    <button type="button" class="flex focus:z-10 justify-center items-center py-2 w-1/2 text-xs font-medium text-red-600 bg-white hover:bg-gray-100 focus:outline-none" @click="removeUpgradeOption(editProductData.products[0].upgrade_products[index - 1])">
                      <HeroIcon icon="Trash" class="mr-2 w-4 h-4" />
                      Delete
                    </button>
                    <button type="button" class="flex focus:z-10 justify-center items-center py-2 w-1/2 text-xs font-medium text-center text-green-600 bg-white hover:bg-gray-100 focus:outline-none" @click="upgradeToProduct(editProductData.products[0].upgrade_products[index - 1])">
                      <HeroIcon icon="TrendingUp" class="mr-2 w-4 h-4" />
                      Upgrade
                    </button>
                  </div>
                </div>
              </div>
              <button v-else class="flex justify-center items-center py-6 w-full h-full text-sm text-gray-500 hover:bg-gray-50" @click="showUpgradeProductModal(editProductData.products[0])">
                <svg class="mr-2 w-5 h-5 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Add Option {{ index }}
              </button>
            </div>
          </div>
        </div>

        <div class="border divide-y divide-gray-700 mt-6">
          <div class="flex relative justify-between items-center p-4 text-white bg-black cursor-pointer" @click="selectAccordion('global-properties')">
            <div class="absolute inset-0 bg-gradient-to-br from-blue-dark via-green-dark to-blue-dark opacity-75" />
            <div class="flex relative z-20 items-center">
              <div class="flex-shrink-0 p-3 bg-blue-light bg-opacity-25 rounded-sm">
                <HeroIcon icon="Globe" class="w-6 h-6 text-blue-light" />
              </div>

              <div class="ml-4">
                <h3 class="text-lg font-medium">
                  Global Properties
                </h3>
                <p class="text-xs">
                  Anything set here will affect all Products in this Project. Some settings like 'Product Description' will impact all Projects.
                </p>
              </div>
            </div>

            <div class="flex relative z-20 justify-center items-center w-6 h-6 bg-blue-light rounded-sm">
              <svg v-if="activeAccordion === 'global-properties'" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
              </svg>
              <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>
          <div v-show="activeAccordion === 'global-properties'" class="bg-gray-50 px-4 py-6 grid grid-cols-6 gap-4">
            <div class="col-span-6">
              <div class="flex justify-between">
                <label for="product_description" class="block text-sm font-medium text-gray-700">Product Description</label>
                <span class="text-xs text-gray-500">Optional</span>
              </div>
              <div class="mt-1">
                <input v-model="editProductData.productData.description" type="text" name="product_description" class="form-input" placeholder="e.g. In-Ceiling Speaker" aria-describedby="product-name-override">
              </div>
              <p class="mt-2 text-xs text-gray-500">
                A brief description of what the product is, shown on the Customers Sales Quote.
              </p>
            </div>

            <div class="col-span-3">
              <label for="customer_price" class="block text-sm font-medium text-gray-700">Customer Price</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span class="text-gray-500 sm:text-sm">
                    {{ currency }}
                  </span>
                </div>
                <input id="customer_price" v-model="editProductData.productData.customer_price" type="number" step="0.01" name="customer_price" class="form-input pl-7">
              </div>
              <p class="mt-2 text-xs text-gray-500">
                This will be the price regardless of Supplier.
              </p>
            </div>

            <div v-if="projectStages.length > 0" class="col-span-3">
              <label for="project_stage" class="block text-sm font-medium text-gray-700">Project Install Stage</label>
              <select id="project_stage" v-model="editProductData.productData.install_stage" name="project_stage" required class="select-input">
                <option :value="null" selected disabled>
                  -- Choose Project Stage --
                </option>
                <option v-for="(projectStage, index) in projectStages" :key="index" :value="projectStage.id">
                  {{ projectStage.name }}
                </option>
              </select>
              <p class="mt-2 text-xs text-gray-500">
                The stage at which this Product will be installed.
              </p>
            </div>
          </div>
          
          <div class="flex relative justify-between items-center p-4 text-white bg-black cursor-pointer" @click="selectAccordion('installation-time')">
            <div class="absolute inset-0 bg-gradient-to-br from-blue-dark via-green-dark to-blue-dark opacity-75" />

            <div class="flex relative z-20 items-center">
              <div class="flex-shrink-0 p-3 bg-blue-light bg-opacity-25 rounded-sm">
                <HeroIcon icon="Clock" class="w-6 h-6 text-blue-light" />
              </div>

              <div class="ml-4">
                <h3 class="text-lg font-medium">
                  Installation Time
                </h3>
                <p class="text-xs">
                  Setting an installation time will allow you to better quote for your labour.
                </p>
              </div>
            </div>


            <div class="flex relative z-20 justify-center items-center w-6 h-6 bg-blue-light rounded-sm">
              <svg v-if="activeAccordion === 'installation-time'" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
              </svg>
              <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>

          <div v-show="activeAccordion === 'installation-time'" class="bg-gray-50 px-4 py-6">
            <p class="text-sm text-gray-600">
              This will be the installation times for all occurances of a <span class="text-blue-light font-bold">{{ editProductData.productData.name }}</span> in this room.
            </p>

            <div v-if="teamPositions.length > 0">
              <div class="flex items-center p-4 my-6 bg-gradient-to-br from-black via-green-dark to-black rounded-sm">
                <input id="saveAsDefaultInstallTime" v-model="editProductData.updateDefaultInstallInfo" type="checkbox" :value="true" class="focus:ring-indigo-500 text-blue-dark border-gray-300 rounded mr-4">
                <div class="flex flex-col">
                  <label for="saveAsDefaultInstallTime" class="font-bold text-white">Save as Install Time Defaults</label>
                  <p class="text-xs text-gray-200">
                    This will update your default install time(s) for <span class="text-blue-light font-bold">{{ editProductData.productData.name }}</span>.
                  </p>
                  <p class="text-xs text-gray-200">
                    <span class="font-bold">Note:</span> Previously added install times in other Projects will remain unaffected.
                  </p>
                </div>
              </div>

              <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Installation Type
                      </th>
                      <th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Install Time
                      </th>
                      <th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Total Install Time
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="(installTime, posIdx) in editProductData.installTimeDetails" :key="posIdx">
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ installTime.position }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-900">
                        <div class="flex rounded-md shadow-sm">
                          <input v-model="installTime.install_time_mins" type="number" min="0" class="text-right form-input mt-0 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300">
                          <span class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                            {{ 'minute' | pluralize(installTime.install_time_mins) }}
                          </span>
                        </div>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-900">
                        {{ 'minute' | pluralize(getTotalInstallTypeTime(installTime.id), true) }}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot class="bg-gray-50">
                    <tr>
                      <td scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Total Install Time: 
                      </td>
                      <td scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {{ 'minute' | pluralize(productInstallTime(), true) }}
                      </td>
                      <td scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {{ 'minute' | pluralize(productTotalInstallTime(), true) }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div v-else>
              You haven't added any team positions. To associate install time with products, add at least one Team Position.
            </div>
          </div>
          <div class="overflow-hidden rounded-b-sm">
            <div class="flex relative justify-between items-center p-4 text-white bg-black cursor-pointer" @click="selectAccordion('set-label')">
              <div class="absolute inset-0 bg-gradient-to-br from-blue-dark via-green-dark to-blue-dark opacity-75" />

              <div class="flex relative z-20 items-center">
                <div class="flex-shrink-0 p-3 bg-blue-light bg-opacity-25 rounded-sm">
                  <HeroIcon icon="ClipboardList" class="w-6 h-6 text-blue-light" />
                </div>

                <div class="ml-4">
                  <h3 class="font-medium">
                    Serial Number
                  </h3>
                  <p class="text-xs text-gray-200">
                    Set serial number &amp; product associated position, module or label name.
                  </p>
                </div>
              </div>

              <div class="flex relative z-20 justify-center items-center w-6 h-6 bg-blue-light rounded-sm">
                <svg v-if="activeAccordion === 'set-label'" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
                <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            </div>

            <div v-show="activeAccordion === 'set-label'" class="py-6 px-4">
              <div class="overflow-hidden sm:rounded-lg border">
                <table class="w-full">
                  <thead class="table-head">
                    <tr>
                      <th scope="col" class="table-heading">
                        {{ 'Product' | pluralize(editProductData.qty) }}
                      </th>
                      <th scope="col" class="table-heading">
                        Label
                      </th>
                      <th scope="col" class="table-heading">
                        Serial No
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="(item, index) in editProductData.productData.products" :key="index">
                      <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                        <label class="form-label">Label {{ index + 1 }}</label>
                      </td>
                      <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                        <input v-model="item.label" type="text" :placeholder="`Enter Label ${index + 1} Name`" class="form-input">
                      </td>
                      <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                        <input v-model="item.serial_no" type="text" placeholder="Serial No" class="form-input">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </template>
    <template v-slot:buttons>
      <button
        type="button"
        form="updatePlaceholderProduct"
        class="w-full inline-flex justify-center rounded-md border border-transparent text-white bg-blue-light shadow-sm px-4 py-2 text-base font-medium hover:bg-blue-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
        @click="updateCustomProduct"
      >
        Save {{ 'Product' | pluralize(editProductData.qty) }}
      </button>

      <button
        type="button"
        class="w-full inline-flex justify-center rounded-md border border-blue-light text-blue-light shadow-sm px-4 py-2 text-base font-medium hover:bg-blue-light hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
        @click="closeEditProductModal"
      >
        Cancel
      </button>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";

export default {
  components: {
    Modal
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    editProductData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeAccordion: null,
      newInstallDetails: {
        team_position_id: null,
        install_time_mins: 0
      }
    };
  },
  computed: {
    projectStages() {
      return this.$store.state.system.projectStages;
    },
    currency() {
      return this.$store.state.auth.user.team.team_currency.symbol;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    roomId() {
      return this.$route.params.roomId;
    },
    teamPositions () {
      return this.$store.state.system.teamPositions;
    }
  },
  methods: {
    getDifferenceInPrice(newProduct, currentProduct) {
      const diff = newProduct.customer_price - currentProduct.customer_price;
      const posNeg = diff > 0 ? '+' : '-';
      const totalDiff = parseFloat(Math.abs(diff * currentProduct.products.length)).toFixed(2);
      const formattedWithCurrency = this.$options.filters.currency(totalDiff);
      return `${posNeg} ${formattedWithCurrency}`;
    },
    hasOption(index) {
      return !!this.editProductData.products[0].upgrade_products[index - 1];
    },
    closeEditProductModal() {
      this.activeAccordion = null;
      this.$emit("close");
    },
    updateCustomProduct() {
      this.axios
        .put(
          `/api/projects/${this.projectId}/room/${this.editProductData.productData.products[0].room_id}/custom-product`,
          { editProductData: this.editProductData })
        .then((res) => {
          this.$emit('updated', res.data);
          this.closeEditProductModal();
          this.$notify({
            type: "success",
            title: `1 x ${this.editProductData.productData.name} updated`
          });
        });
    },
    productInstallTime () {
      let installTime = 0;
      this.editProductData.install_info.forEach((el) => {
        installTime += parseInt(el.install_time_mins);
      });
      return installTime;
    },
    productTotalInstallTime () {
      const totalInstallTime = this.productInstallTime() * this.editProductData.qty;
      return totalInstallTime;
    },
    selectAccordion (accordion) {
      if (this.activeAccordion === accordion) {
        this.activeAccordion = null;
      } else {
        this.activeAccordion = accordion;
      }
    },
    getTotalInstallTypeTime (positionId) {
      const posInstallTimeDetails = this.editProductData.installTimeDetails.find(el => el.id === positionId);
      return (posInstallTimeDetails.install_time_mins * this.editProductData.productData.products.length);
    },
    showUpgradeProductModal(currentProduct) {
      currentProduct.modal_type = 'Custom';
      this.$emit('showUpgradeProductModal', currentProduct);
    },
    upgradeToProduct(upgradeProduct) {
      this.upgradingProduct = true;
      this.axios.post(`/api/projects/${this.projectId}/room/${this.editProductData.products[0].room_id ? this.editProductData.products[0].room_id : this.roomId}/upgrade-product/${upgradeProduct.id}`).then((res) => {
        this.$emit('productUpgraded', upgradeProduct, res.data);
      }).finally(() => {
        this.upgradingProduct = false;
      });
    },
    removeUpgradeOption(upgradeProduct) {
      this.axios.delete(`/api/projects/remove-upgrade-product/${upgradeProduct.id}`).then(() => {
        this.$emit('upgradeCustomProductRemoved', upgradeProduct.id);
      }).catch(() => {
        this.$notify({type: "error", title: `Something wnet wrong, Please try again`});
      });
    }
  }
};
</script>