<template>
  <div>
    <swiper 
      v-if="products.length" 
      ref="productsSwiper"
      class="swiper product-selector f-3"
      :options="swiperOptions"
      :auto-update="true"
      :auto-destroy="false"
      :delete-instance-on-destroy="false"
      :cleanup-styles-on-destroy="true"
    >
      <swiper-slide v-for="(product, index) in products.filter(Boolean)" :key="index">
        <div v-if="!product.user_id">
          <div v-if="!isFavouriteProduct" class="slide--image">
            <img v-if="product.first_image && !isCustomProduct" :src="product.first_image.url" alt="Specifi">
            <img v-else-if="product.grouped_products && product.grouped_products.length > 0 && isBundleProduct" :src="product.grouped_products[0].product.first_image.url" alt="Specifi">
            <img v-else :src="product.image_url" alt="Specifi">
            <div v-if="isBundleProduct" class="absolute right-0 -top-4 p-2">
              <ProductsBundlePreview
                :products="product.grouped_products"
                :total-products="product.products_count"
                :custom-products="product.grouped_custom_products"
                :total-custom-products="product.custom_products_count"
              />
            </div>
          </div>
          <div v-else class="slide--image">
            <img v-if="product.product.first_image" class="object-contain w-full h-full" :src="product.product.first_image.url">
            <img v-else-if="product.product.image_url" class="object-contain w-full h-full" :src="product.product.image_url">
          </div>
          <div class="slide--padded p-3 xl:p-4 text-center">
            <h3 class="slide--title sans-serif text-xl xl:text-4xl my-2">
              {{ product.name }}
            </h3>
            <p v-if="isBundleProduct" class="text-md font-semibold xl:font-bold text-lime mt-2 mb-1">
              {{ getBundleRetailPrice(product) | currency }}
            </p>
            <p v-else-if="product.supplier_pricing && product.supplier_pricing.highestRetailPrice" class="text-md font-semibold xl:font-bold text-lime mt-2 mb-1">
              {{ product.supplier_pricing.highestRetailPrice | currency }}
            </p>
            <p v-else-if="product.retail_price" class="text-md font-semibold xl:font-bold text-lime mt-2 mb-1">
              {{ product.retail_price | currency }}
            </p>
            <div class="flex justify-around">
              <button v-if="(product.product_type === 'App\\Models\\Product' && isFavouriteProduct) || (!isCustomProduct && !isBundleProduct && !isFavouriteProduct)" class="inline-flex items-center px-3 py-2 bg-gradient-green rounded-md mt-6 mb-16 font-medium text-xs text-white button-primary" @click="addProductToProject(product)">
                <svg xmlns="http://www.w3.org/2000/svg" class="inline-block h-3 w-3 mr-1" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                </svg>
                Add To Project
              </button>
              <button v-if="(product.product_type === 'App\\Models\\Product' && isFavouriteProduct) || (!isCustomProduct && !isBundleProduct && !isFavouriteProduct)" class="inline-flex items-center px-3 py-2 bg-gradient-green rounded-md mt-6 mb-16 font-medium text-xs text-white button-primary" @click="viewProduct(product)">
                <svg xmlns="http://www.w3.org/2000/svg" class="inline-block h-3 w-3 mr-1" viewBox="0 0 20 20" fill="#fff">
                  <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                  <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                </svg>
                View Product
              </button>
              <button v-else-if="(product.product_type === 'App\\Models\\TeamCustomProduct' && isFavouriteProduct) || isCustomProduct" class="bg-gradient-green rounded-md py-3 px-6 mt-6 mb-16 font-medium text-xs text-white button-primary hover:opacity-75" @click="addCustomProduct(product)">
                + Add To Project
              </button>
              <button v-else-if="isBundleProduct" class="bg-gradient-green rounded-md py-3 px-6 mt-6 mb-16 font-medium text-xs text-white button-primary hover:opacity-75" @click="addBundleProduct(product)">
                + Add To Project
              </button>
            </div>
          </div>
        </div>
        <div v-if="product.user_id && product.product">
          <div v-if="!isFavouriteProduct" class="slide--image">
            <img v-if="product.product.first_image && !isCustomProduct" :src="product.product.first_image.url" alt="Specifi">
            <img v-else-if="product.products.length > 0 && isBundleProduct" :src="product.products[0].product.first_image.url" alt="Specifi">
            <img v-else :src="product.product.image_url" alt="Specifi">
            <div v-if="isBundleProduct" class="absolute right-0 -top-4 p-2">
              <ProductsBundlePreview
                :products="product.products"
                :total-products="product.products.length"
                :custom-products="product.custom_products"
                :total-custom-products="product.custom_products.length"
              />
            </div>
          </div>
          <div v-else class="slide--image">
            <img v-if="product.product.first_image" class="object-contain w-full h-full" :src="product.product.first_image.url">
            <img v-else-if="product.product.image_url" class="object-contain w-full h-full" :src="product.product.image_url">
          </div>
          <div class="slide--padded p-3 xl:p-4 text-center">
            <h3 class="slide--title sans-serif text-xl xl:text-4xl my-2">
              {{ product.product.name }}
            </h3>
            <p v-if="product.product.supplier_pricing && product.product.supplier_pricing.highestRetailPrice" class="text-md font-semibold xl:font-bold text-lime mt-2 mb-1">
              {{ product.product.supplier_pricing.highestRetailPrice | currency }}
            </p>
            <p v-else-if="product.product.retail_price" class="text-md font-semibold xl:font-bold text-lime mt-2 mb-1">
              {{ product.product.retail_price | currency }}
            </p>
            <div class="flex justify-around">
              <button v-if="(product.product_type === 'App\\Models\\Product' && isFavouriteProduct) || (!isCustomProduct && !isBundleProduct && !isFavouriteProduct)" class="inline-flex items-center px-3 py-2 bg-gradient-green rounded-md mt-6 mb-16 font-medium text-xs text-white button-primary" @click="addProductToProject(product.product)">
                <svg xmlns="http://www.w3.org/2000/svg" class="inline-block h-3 w-3 mr-1" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                </svg>
                Add To Project
              </button>
              <button v-if="(product.product_type === 'App\\Models\\Product' && isFavouriteProduct) || (!isCustomProduct && !isBundleProduct && !isFavouriteProduct)" class="inline-flex items-center px-3 py-2 bg-gradient-green rounded-md mt-6 mb-16 font-medium text-xs text-white button-primary" @click="viewProduct(product.product)">
                <svg xmlns="http://www.w3.org/2000/svg" class="inline-block h-3 w-3 mr-1" viewBox="0 0 20 20" fill="#fff">
                  <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                  <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                </svg>
                View Product
              </button>
              <button v-else-if="(product.product_type === 'App\\Models\\TeamCustomProduct' && isFavouriteProduct) || isCustomProduct" class="bg-gradient-green rounded-md py-3 px-6 mt-6 mb-16 font-medium text-xs text-white button-primary hover:opacity-75" @click="addCustomProduct(product.product)">
                + Add To Project
              </button>
              <button v-else-if="isBundleProduct" class="bg-gradient-green rounded-md py-3 px-6 mt-6 mb-16 font-medium text-xs text-white button-primary hover:opacity-75" @click="addBundleProduct(product.product)">
                + Add To Project
              </button>
            </div>
          </div>
        </div>
      </swiper-slide>
      <div slot="button-prev" :class="$isTouchDevice && 'hide'" class="swiper-btn swiper-btn-prev">
        <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-12 h-12">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
        </svg>
      </div>
      <div slot="button-next" :class="$isTouchDevice && 'hide'" class="swiper-btn swiper-btn-next">
        <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-12 h-12">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
        </svg>
      </div>
    </swiper>
    <AddCustomProductModal
      :show-modal="showAddCustomProduct"
      :product="customProduct"
      :projects="allProjects"
      :project-exist="projectExists"
      @close="showAddCustomProduct = false" 
    />

    <AddBundleProductModal
      :show-modal="showAddBundleProduct"
      :product-bundle="bundleProduct"
      :projects="allProjects"
      :project-exist="projectExists"
      @close="showAddBundleProduct = false" 
    />

    <add-product-to-project
      :show-modal="showAddProductToProjectModal"
      :product="selectedProduct"
      :selected-product-data="selectedProductData"
      :room-id=" parseInt(roomId)"
      @success="successfullyAdded"
      @closeModal="closeAddToProjectModal"
    />
  </div>
</template>

<script>
import AddProductToProject from "../modals/AddProductToProjectModal.vue";
import AddCustomProductModal from '../modals/AddCustomProductModal.vue';
import AddBundleProductModal from '../modals/AddBundleProductModal.vue';
import ProductsBundlePreview from '@/components/ProductsBundlePreview.vue';

export default {
   components: {
    AddCustomProductModal,
    AddProductToProject,
    ProductsBundlePreview,
    AddBundleProductModal
  },
  props: {
   products: {
      type: Array,
      required: true
    },
   isCustomProduct: Boolean,
   isBundleProduct: Boolean,
   isFavouriteProduct: Boolean
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-btn-next',
          prevEl: '.swiper-btn-prev'
        },
        loop: false,
        loopedSlides: 5,
        spaceBetween: 20,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 1,
        slideToClickedSlide: true,
        freeMode: true,
        freeModeSticky: true,
        freeModeMomentumRatio: .2,
        keyboard: {
          enabled: true
        }
      },
      showAddProductToProjectModal: false,
      showAddCustomProduct: false,
      customProduct: null,
      showAddBundleProduct: false,
      bundleProduct: null,
      allProjects: null,
      selectedProduct: {},
      selectedProductData: {
        qty: 1,
        installTimeMins: 0,
        install_time_mins: 0,
        customerPrice: 0,
        projectStage: null,
        nameOverride: "",
        description: "",
        details: {
          itemSpecific: {}
        },
        installTimeDetails: [],
        team_install_info_default: [],
        show_on_sales_quote: true,
        cables: []
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.productsSwiper.$swiper;
    },
    canAddProduct() {
      return this.$route.name !== 'browseCategory';
    },
    projectExists(){
      return this.$route.params.projectId ? true : false;
    },
    roomId() {
      return this.$route.params.roomId;
    },
    projectStages() {
      return this.$store.state.system.projectStages;
    },
    teamPositions() {
      return this.$store.state.system.teamPositions;
    }
  },
  mounted() {
     this.getProjects();
  },
  methods: {
    getProjects() {
      if (this.isCustomProduct || this.isBundleProduct || this.isFavouriteProduct) {
        this.axios.get(`/api/projects/all-projects`).then((projects) => {
            this.allProjects = projects.data;
        });
      }
    },
    viewProduct(product) {
      if (this.$route.name === 'browseCategory') {
        this.$router.push({ name: 'viewCategoryProduct', params: { productId: product.id } });
      } else {
        let routeName = 'projectRoomProduct';
        if (this.isCustomProduct) {
          routeName = 'projectRoomCustomProduct';
        } 
        if (this.isBundleProduct) {
          routeName = 'projectRoomBundleProduct';
        } 
        this.$router.push({ name: routeName, params: { productId: product.id } });
      }
    },
    addCustomProduct(product) {
      this.customProduct = product;
      this.$set(this.customProduct, 'qty', 1);
      this.customProduct.customer_price = product.retail_price;
      this.showAddCustomProduct = true;
    },
    addBundleProduct(product) {
      this.bundleProduct = product;
      this.$set(this.bundleProduct, 'qty', 1);
      this.showAddBundleProduct = true;
    },
    addProduct(product) {
      this.$emit('addProductToProject', product);
    },
    addProductToProject(product) {
      this.selectedProduct = product;
      this.selectedProductData.installTimeDetails = [];
      this.selectedProductData.installTimeMins = 0;
      this.selectedProductData.installStage = null;
      this.selectedProductData.description = null;

      this.selectedProductData.installTimeMins = product.install_time || 0;
      this.selectedProductData.installStage = product.install_stage || null;
      this.selectedProductData.description = product.product_description || null;

       // Setup Install Times
      this.teamPositions.forEach((el) => {
        const details = { ...el };
        details.install_time_mins = 0;
        const defaultInstallTime = product.team_install_info_default.find(s => s.team_position_id === details.id);
        details.install_time_mins = defaultInstallTime ? defaultInstallTime.install_time_mins : 0;
        this.selectedProductData.installTimeDetails.push(details);
      });
      this.showAddProductToProjectModal = true;
    },
    successfullyAdded(qtyAdded) {
      this.qtyAdded = qtyAdded;
      this.closeAddToProjectModal();
      this.$notify({
        type: "success",
        title: "Product Added",
        text: `${qtyAdded} x products added to project.`
      });

      const { projectId, roomId } = this.$route.params;
      this.$router.push({
        name: "projectRoomProducts",
        params: { projectId, roomId }
      });
    },
    closeAddToProjectModal(){
      this.selectedProduct = {};
      this.showAddProductToProjectModal = false;
      this.selectedProductData = {
        qty: 1,
        installTimeMins: 0,
        install_time_mins: 0,
        customerPrice: 0,
        projectStage: null,
        nameOverride: "",
        description: "",
        details: {
          itemSpecific: {}
        },
        installTimeDetails: [],
        team_install_info_default: []
      };
    },
    getBundleRetailPrice(bundle) {
      let total = 0;
      bundle.grouped_products.forEach((el) => {
        total += (el.product_pricing.retail_price * el.qty);
      });

      bundle.grouped_custom_products.forEach((el) => {
        total += (el.product.retail_price * el.qty);
      });
      return total;
    }
  }
};
</script>

<style lang="scss">
</style>
