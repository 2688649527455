<template>
  <div
    :class="showModal ? 'show' : 'hide'"
    class="modal-window fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center"
  >
    <div
      :class="showModal ? 'ease-in duration-200 opacity-100' : 'ease-out duration-200 opacity-0'"
      class="fixed inset-0 transition-opacity"
    >
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>

    <!-- New Room Form -->
    <div
      class="modal-window-split bg-white rounded-lg overflow-hidden shadow-xl transform transition-all"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
      :class="showModal ? 'ease-in duration-200 opacity-100 translate-y-0 sm:scale-100' : 'ease-out duration-300 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'"
    >
      <div class="modal-window-split--img">
        <img src="@/assets/img/placeholders/floor.jpg">
      </div>

      <div class="modal-window-split--content">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-8 sm:pb-4">
          <div
            class="mb-4 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-lime sm:mx-0 sm:h-10 sm:w-10"
          >
            <svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
          </div>
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center w-full sm:mt-0 sm:text-left">
              <h3
                id="modal-headline"
                class="text-lg xl:text-2xl leading-8 font-bold text-gray-900 mb-4"
              >
                New Floor
              </h3>
              <div class="mt-2">
                <form class="w-full">
                  <div class="grid grid-cols-6 gap-4 xl:gap-6">
                    <div class="col-span-6 sm:col-span-3">
                      <div class="flex justify-between">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >Floor name</label>
                      </div>
                      <input
                        v-model="newFloorData.name"
                        class="appearance-none block w-full text-xs xl:text-base bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                      >
                    </div>
                    <div class="col-span-6 sm:col-span-3">
                      <label for="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Floor Type</label>
                      <select id="priority" v-model="newFloorData.isComms" name="priority" required class="block py-2 px-3 mt-1 w-full sm:text-sm bg-gray-200 rounded-md border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 shadow-sm focus:outline-none">
                        <option :value="true">
                          Comms Floor
                        </option>
                        <option :value="false">
                          Floor
                        </option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-gradient text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              @click="createNewFloor"
            >Create Floor</button>
          </span>
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto sm:ml-3">
            <button
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              @click="closeModal"
            >Cancel</button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: Boolean,
    projectId: {
      type: Number,
      default: 0,
      required: false
    },
    floor: {
      type: Number,
      default: 0, 
      required: false
    },
    projectrooms: {
      type: Array,
      default: () => [],
      required: false
    }
  },
  data() {
    return {
      floorName: "",
      newFloorData: {
        name: null,
        isComms: false
      }
    };
  },
  computed: {
    project() {
      return this.$store.state.project.project;
    }
  },
  methods: {
    createNewFloor() {
      this.axios
        .post(`/api/projects/${this.$route.params.projectId}/add-floor`,{ floor: this.newFloorData }).then((response) => {
          const newFloor = response.data;
          newFloor.rooms = [];
          const floors = [...this.project.floors];
          floors.push(newFloor);
          this.project.floors = floors;
          this.closeModal();
          this.$notify({
            type: "success",
            title: "New Floor Added"
          });
        });
    },
    closeModal() {
      this.$emit("closeAddFloorModal");
    }
  }
};
</script>