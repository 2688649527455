<template>
  <div class="shell-container">
    <header class="router-header flex flex-wrap content-center items-center transition ease-in-out container px-8">
      <div class="flex-1 text-right" />
      <h3 class="flex-10 sans-serif text-2xl xl:text-4xl text-center font-semibold">
        Browse Products
      </h3>
      <ul class="flex flex-1 text-right flex-col fabs">
        <li>
          <div class="h-full bg-gray-200 -mr-4 rounded-l-full transition-all duration-1000 ease-in-out" :class="showSearch ? 'w-4/5' : 'w-0'">
            <input v-model="searchTerm" :class="!showSearch ? 'hidden' : ''" class="w-full py-2 px-6 bg-transparent outline-none" placeholder="Search Categories">
          </div>
          <button class="bg-gradient hover:opacity-50 text-white font-bold p-1 xl:p-2 rounded-full" @click="showSearch = !showSearch">
            <svg v-if="!showSearch" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="search w-5 h-5 xl:w-6 xl:h-6">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
            <svg v-else fill="none" viewBox="0 0 24 24" stroke="currentColor" class="x w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </li>
      </ul>
    </header>
 
    <swiper 
      ref="categorySwiper" 
      class="swiper cat-selector f-7 bg-gradient pb-10" 
      :options="swiperOptions"
      :auto-update="true"
      :auto-destroy="false"
      :delete-instance-on-destroy="false"
      :cleanup-styles-on-destroy="true"
    >
      <swiper-slide v-for="(category, index) in filteredCategories()" :key="index">
        <div class="group relative p-6 text-left text-blue-dark hover:text-white bg-gray-50 hover:bg-blue-light rounded-md transition-all ease-in-out focus:outline-none bg-opacity-20" @click="goToCategory(category, category.id)">
          <h3 class="text-lg font-semibold text-white text-center sm:text-left">
            {{ category.name }}
          </h3>
          <h4 class="mt-1 text-xs font-normal text-center text-white sm:text-left group-hover:text-white">
            {{ 'Product' | pluralize(category.products_count, true) }}
          </h4>
          <div class="flex justify-center sm:justify-end w-full">
            <div v-if="category.image_url" class="flex items-center w-14 h-14">
              <img :src="category.image_url" class="object-cover">
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide v-if="customProducts.length > 0">
        <div class="group relative p-6 text-left text-blue-dark hover:text-white bg-gray-50 hover:bg-blue-light rounded-md transition-all ease-in-out focus:outline-none bg-opacity-20" @click="goToCustom">
          <h3 class="text-lg font-semibold text-center text-white sm:text-left">
            Custom Products
          </h3>
          <h4 class="mt-1 text-xs font-normal text-center text-white sm:text-left group-hover:text-white">
            {{ 'Product' | pluralize(customProducts.length, true) }}
          </h4>
          <div class="flex justify-center sm:justify-end w-full py-3">
            <div class="flex items-center">
              <svg class="text-white w-8 h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
                <path fill-rule="evenodd" d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" clip-rule="evenodd" />
              </svg>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide v-if="favouriteProducts.length > 0">
        <div class="group relative p-6 text-left text-blue-dark hover:text-white bg-gray-50 hover:bg-blue-light rounded-md transition-all ease-in-out focus:outline-none bg-opacity-20" @click="goToFavourite">
          <h3 class="text-lg font-semibold text-center text-white sm:text-left">
            Favourite Products
          </h3>
          <h4 class="mt-1 text-xs font-normal text-center text-white sm:text-left group-hover:text-white">
            {{ 'Product' | pluralize(favouriteProducts.length, true) }}
          </h4>
          <div class="flex justify-center sm:justify-end w-full py-3">
            <div class="flex items-center">
              <svg class="w-8 h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#DEDD2C">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
            </div>
          </div>
        </div>
      </swiper-slide>
      <div slot="button-prev" :class="$isTouchDevice && 'hide'" class="swiper-btn swiper-btn-prev">
        <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-12 h-12">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
        </svg>
      </div>
      <div slot="button-next" :class="$isTouchDevice && 'hide'" class="swiper-btn swiper-btn-next">
        <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-12 h-12">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
        </svg>
      </div>
    </swiper>

    <div class="container">
      <div class="products-grid grid grid-cols-2 gap-2 sm:grid-cols-4 sm:gap-4 sm:px-8">
        <div v-for="product in randomProducts" :key="product.index" class="products-grid--product bg-gradient-180 text-center">
          <div class="product--image">
            <img v-if="product.first_image" :src="product.first_image.url" :alt="product.title">
          </div>
          <span class="sans-serif block mt-5 font-semibold text-white text-xs xl:text-base pt-2">{{ product.name }}</span>
          <p class="text-xs font-semibold xl:font-bold text-gray-200 mb-5 mt-2">
            {{ product.supplier_pricing.highestRetailPrice | currency }}
          </p>
          <button class="bg-gradient-green rounded-md py-2 px-4 mb-5 font-medium text-xs text-white button-primary" @click="viewProduct(product)">
            View Product
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      showAddProduct: false,
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-btn-next',
          prevEl: '.swiper-btn-prev'
        },
        loop: false,
        loopedSlides: 3,
        spaceBetween: 20,
        slidesPerView: "auto",
        slidesPerGroup: 3,
        touchRatio: 1,
        keyboard: {
          enabled: true
        }
      },
      randomProducts: null,
      showSearch: false,
      searchTerm: ''
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    categories() {
      return this.$store.state.system.categories;
    },
    loadedPercent() {
      return this.$store.getters.loadedPercent;
    },
    customProducts() {
      return this.$store.state.system.customProducts;
    },
    favouriteProducts() {
      return this.$store.state.system.favouriteProducts;
    }
  },
  mounted() {
    this.randomProduct();
    this.getProductSettings();
    this.getTeamSpecificProducts();
    this.getProjectInstallDefaults();
  },
  
  methods: {
    ...mapActions({
      getProject: 'project/getProject',
      getProjectInstallDefaults: 'system/getProjectInstallDefaults',
      getProductSettings: 'system/getProductSettings',
      getTeamSpecificProducts: 'system/getTeamSpecificProducts'
    }),
    filteredCategories() {
      let filtered = this.searchTerm ? this.categories.filter(category => category.name.replace(/-|\s/g,"").toLowerCase().includes(this.searchTerm.replace(/-|\s/g,"").toLowerCase())) : this.categories;
      return filtered;
    },
    randomProduct() {
      this.axios.get('/api/products/random').then(res => {
        this.randomProducts = res.data;
      });
    },
    viewProduct(product) {
      this.$router.push({ name: 'viewProduct', params: { productId: product.id } });
    },
    goToCategory(category, catId) {
      this.$store.dispatch('system/getCurrentCategory', catId).then(() => {
        this.$router.push({ name: 'browseCategory', params: { categorySlug: this.getCategorySlug(category.name) } });
      });
    },
    goToFavourite() {
      this.$router.push({
        name: "browseCategory",
        params: { categorySlug: "favourite-product" }
      });
    },
    getCategorySlug(category) {
      return category.replace(/\s+/g, "-").toLowerCase();
    },
    goToCustom() {
      this.$router.push({
        name: "browseCategory",
        params: { categorySlug: "custom-product" }
      });
    }
  }
};
</script>