<template>
  <div
    class="modal-window absolute sm:fixed top-1 sm:top-0 sm:bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center"
    :class="showModal ? 'show' : 'hide'"
  >
    <div
      class="fixed inset-0 transition-opacity"
      :class="showModal ? 'ease-in duration-200 opacity-100' : 'ease-out duration-200 opacity-0'"
    >
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>

    <!-- New Room Form -->
    <div
      class="w-1/2 bg-white rounded-lg overflow-hidden shadow-xl transform transition-all"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
      :class="showModal ? 'ease-in duration-200 opacity-100 translate-y-0 sm:scale-100' : 'ease-out duration-300 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'"
    >
      <div class="modal-window-split--content relative">
        <div class="bg-white px-5 pt-5 pb-16 xl:p-8 xl:pb-20 max-h-full overflow-x-scroll">
          <div
            class="mb-4 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-lime sm:mx-0 sm:h-10 sm:w-10"
          >
            <svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
          </div>
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center w-full sm:mt-0 sm:text-left">
              <h3
                id="modal-headline"
                class="text-lg xl:text-2xl leading-8 font-bold text-gray-900 mb-4 xl:mb-8"
              >
                New Room
              </h3>
              <div class="mt-2">
                <form id="addRoom" class="w-full" @submit.prevent="createNewRoom">
                  <div class="grid grid-cols-6 gap-4 xl:gap-6">
                    <div class="col-span-6 sm:col-span-3">
                      <label
                        for="customer_name"
                        class="block text-xs xl:text-sm font-medium text-gray-700"
                      >Room Type</label>
                      <select
                        id="priority"
                        v-model="newRoomData.isComms"
                        name="priority"
                        required
                        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option :value="1">
                          Comms Room
                        </option>
                        <option :value="0">
                          Room
                        </option>
                      </select>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="name" class="block text-xs xl:text-sm font-medium text-gray-700">Room Name</label>
                      <input
                        id="floorName"
                        v-model="newRoomData.name"
                        type="text"
                        name="floorName"
                        required
                        placeholder="e.g. Living Room"
                        class="form-input"
                      >
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label
                        for="name"
                        class="block text-xs xl:text-sm font-medium text-gray-700"
                      >Distance From Rack (m)</label>
                      <input
                        id="floorName"
                        v-model="newRoomData.distanceFromRack"
                        type="number"
                        name="floorName"
                        placeholder="e.g. 10"
                        class="form-input"
                      >
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label
                        for="name"
                        class="block text-xs xl:text-sm font-medium text-gray-700"
                      >Ceiling Depth (mm)</label>
                      <input
                        id="floorName"
                        v-model="newRoomData.ceilingDepth"
                        type="number"
                        name="floorName"
                        placeholder="e.g. 300"
                        class="form-input"
                      >
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label
                        for="name"
                        class="block text-xs xl:text-sm font-medium text-gray-700"
                      >Ethernet Points</label>
                      <input
                        id="floorName"
                        v-model="newRoomData.ethernetPoints"
                        type="text"
                        name="floorName"
                        placeholder="e.g. 4"
                        class="form-input"
                      >
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="name" class="block text-xs xl:text-sm font-medium text-gray-700">Phone Points</label>
                      <input
                        id="floorName"
                        v-model="newRoomData.phonePoints"
                        type="text"
                        name="floorName"
                        placeholder="e.g. 2"
                        class="form-input"
                      >
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label
                        for="customer_name"
                        class="block text-xs xl:text-sm font-medium text-gray-700"
                      >Integrated Audio/Video?</label>
                      <select
                        id="priority"
                        v-model="newRoomData.integratedAV"
                        name="priority"
                        required
                        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option>Yes</option>
                        <option selected>
                          No
                        </option>
                      </select>
                    </div>

                    <div class="col-span-6">
                      <label
                        for="customer_name"
                        class="block text-xs xl:text-sm font-medium text-gray-700"
                      >Scope</label>
                      <textarea
                        id="about"
                        v-model="newRoomData.scope"
                        name="about"
                        rows="3"
                        class="form-input"
                        placeholder="e.g. The scope of work for the room."
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse absolute bottom-0 w-full bg-white bg-opacity-75"
        >
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              type="submit"
              class="btn-primary-md bg-gradient"
              form="addRoom"
            >Create Room</button>
          </span>
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto sm:ml-3">
            <button
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              @click="closeModal()"
            >Cancel</button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: Boolean,
    projectId: {
      type: Number,
      required: true
    },
    floor: {
      type: Number,
      required: true
    }
  },
  data: function() {
    return this.initialData();
  },
  methods: {
    initialData() {
      return {
        file: "",
        preview: "",
        newRoomData: {
          isComms: 0,
          floor: null,
          name: null,
          distanceFromRack: null,
          ceilingDepth: null,
          ethernetPoints: null,
          phonePoints: null,
          integratedAV: "No",
          scope: null
        },
        newRoomErrors: {}
      };
    },
    onChangeFileUpload() {
      this.file = this.$refs.newRoomImage.files[0];
      this.preview = URL.createObjectURL(this.file);
    },
    createNewRoom() {
      this.newRoomData.floor = this.floor;
      this.axios
        .post(
          `/api/projects/${this.$route.params.projectId}/add-room`,
          this.newRoomData
        )
        .then(response => {
          this.$emit("addRoom", response.data);
          this.closeModal();
        })
        .finally(() => {
          const lastSlide =
            this.$parent.$refs.roomsSwiper.$swiper.slides.length - 1;
          this.$parent.$refs.roomsSwiper.$swiper.slideTo(lastSlide, 0);
        });
    },
    closeModal() {
      Object.assign(this.$data, this.initialData());
      this.$emit("closeModal");
    },
    selectValue(value) {
      this.newRoom.name = value;
    }
  }
};
</script>