<template>
  <div>
    <Modal title="Add Cable to Product" :show="show" :close="close">
      <template v-slot:content>
        <form :id="formName" class="grid grid-cols-3 gap-4" @submit.prevent="addCableToProduct">
          <div class="col-span-3">
            <label for="cable" class="block text-sm font-medium">Cable</label>
            <select v-model="cable" name="cable" required class="select-input">
              <option :value="null" selected>
                -- Choose Cable --
              </option>
              <option v-for="(avCable, index) in cables" :key="index">
                {{ avCable.cable }}
              </option>
            </select>
          </div>
          <div class="col-span-3">
            <label class="block text-sm font-medium">Purpose</label>
            <select v-model="purpose" required class="select-input">
              <option :value="null" selected>
                -- Choose Purpose --
              </option>
              <option value="User Selected">
                User Selected
              </option>
              <option v-for="(avPurpose, index) in cablePurposes" :key="index">
                {{ avPurpose.purpose }}
              </option>
            </select>
          </div>
        </form>
      </template>
      <template v-slot:buttons>
        <button type="submit" :form="formName" class="btn-primary-md">
          Add Cable
        </button>
        <button type="button" class="mr-2 btn-white-md" @click="close">
          Cancel
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';

export default {
  components: {
    Modal
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    formName: {
      type: String,
      required: true
    },
    close: {
      type: Function,
      required: false,
      default: null
    },
    cables: {
      type: Array,
      required: true
    },
    cablePurposes: {
      type: Array,
      required: false,
      default: null
    }
  },
  data() {
    return {
      cable: null,
      purpose: null
    };
  },
  methods: {
    addCableToProduct() {
      this.$emit('addCableToProduct', this.cable, this.purpose);
      this.$notify({type: "success", title: "Cable Added ", text: `Cable added to product`});
      this.closeModal();
    },
    closeModal() {
      this.close();
      this.cable = null;
      this.purpose = null;
    }
  }
};
</script>