<template>
  <div>
    <div v-if="loadingCustomProducts" class="flex flex-col justify-center items-center py-48">
      <svg class="w-8 h-8 text-blue-light animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
      </svg>
      <h1 class="mt-6 text-xl font-semibold text-blue-dark">
        Loading Custom Products
      </h1>
    </div>
    <div v-else-if="!selectedCustomProductData.product">
      <div class="pb-2 mb-4 w-full">
        <div class="relative mb-4 rounded-sm">
          <div class="flex absolute inset-y-0 left-0 items-center px-3 border-r pointer-events-none" aria-hidden="true">
            <svg class="w-4 h-4 text-blue-light" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
            </svg>
          </div>
          <input id="search" v-model="searchQuery" type="search" name="search" class="pl-12 form-input" placeholder="Search Custom Products" @input="debounceSearch">
        </div>
      </div>
      <div v-if="customProducts.length > 0" class="grid grid-cols-2 sm:grid-cols-4 gap-x-6 gap-y-8">
        <div v-for="(product, index) in customProducts" :key="index" class="group relative text-left text-blue-dark cursor-pointer focus:outline-none" @click="selectCustomProduct(product)">
          <div>
            <div class="relative">
              <div class="flex overflow-hidden relative justify-center items-center p-4 mb-2 space-x-6 w-full h-52 max-h-52 bg-white rounded-sm border">
                <img v-if="product.image_url" class="object-contain w-full h-full" :src="product.image_url">
                <svg v-else class="w-12 h-12 opacity-25" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                </svg>
              </div>
              <div class="absolute top-2 right-2 z-20 p-1 bg-white rounded-full" @click="toggleCustomFavourite(product)">
                <svg class="w-4 h-4 hover:text-yellow-300" :class="product.is_favourite ? 'text-yellow-300' : 'text-gray-400'" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
              </div>
              <div class="w-full">
                <h3 class="text-sm font-semibold">
                  {{ product.name }}
                </h3>
                <p class="text-xs text-gray-400">
                  {{ product.brand }}
                </p>
                <div>
                  <p v-if="!clientMode" class="mt-1 text-xs font-semibold">
                    {{ product.trade_price | currency }}
                  </p>
                  <p v-else class="mt-1 text-xs font-semibold">
                    {{ product.retail_price | currency }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        :from="from"
        :to="to"
        :total-items="totalCustomProducts"
        :page="currentPage"
        text="Custom Product"
        :items-per-page="12"
        @page-change="getCustomProducts"
      />
    </div>

    <div v-if="selectedCustomProductData && selectedCustomProductData.product">
      <nav aria-label="Back">
        <button class="btn-primary-md" @click="cancelSelectedCustomProduct">
          <svg class="flex-shrink-0 mr-1 -ml-1 w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
          </svg>
          Back
        </button>
      </nav>

      <div class="my-4">
        <div class="flex justify-between items-center w-full">
          <div class="flex items-center mb-4 sm:mb-0 w-full">
            <div v-if="selectedCustomProductData.product.image_url" class="flex justify-center items-center mr-4 w-32 h-32 bg-gray-50 rounded-sm border">
              <img class="object-contain p-2" :src="selectedCustomProductData.product.image_url">
            </div>
            <div class="w-3/4">
              <h4 class="text-lg font-semibold text-blue-dark">
                {{ selectedCustomProductData.product.name }}
              </h4>
              <p class="text-sm text-gray-400">
                {{ selectedCustomProductData.product.brand }}
              </p>
              <p v-if="selectedCustomProductData.product.supplier" class="mt-1 text-sm text-gray-400">
                {{ selectedCustomProductData.product.supplier.name }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Global Properties -->
      <div class="border divide-y divide-gray-700">
        <div class="overflow-hidden bg-white rounded-t-sm">
          <div class="flex relative justify-between items-center p-4 text-white bg-black cursor-pointer" @click="selectAccordion('global-properties')">
            <div class="absolute inset-0 bg-gradient-to-br from-blue-dark via-green-dark to-blue-dark opacity-75" />

            <div class="flex relative z-20 items-center">
              <div class="flex-shrink-0 p-3 bg-blue-light bg-opacity-25 rounded-sm">
                <HeroIcon icon="Globe" class="w-6 h-6 text-blue-light" />
              </div>

              <div class="ml-4">
                <h3 class="font-medium">
                  Global Properties
                </h3>
                <p class="text-xs">
                  Anything set here will affect all Products in this Project. Some settings like 'Product Description' will impact all Projects.
                </p>
              </div>
            </div>

            <div class="flex relative z-20 justify-center items-center w-6 h-6 bg-blue-light rounded-sm">
              <svg v-if="activeAccordion === 'global-properties'" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
              </svg>
              <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>
          <div v-show="activeAccordion === 'global-properties'" class="grid grid-cols-6 gap-4 py-6 px-4 bg-gray-50">
            <div class="col-span-3">
              <label for="customer_price" class="block text-sm font-medium text-gray-700">Customer Price</label>
              <div class="relative mt-1 rounded-md shadow-sm">
                <div class="flex absolute inset-y-0 left-0 items-center px-3 sm:text-sm text-gray-500 pointer-events-none">
                  {{ currency }}
                </div>
                <input id="customer_price" v-model="selectedCustomProductData.customer_price" type="number" step="0.01" name="customer_price" class="pl-12 form-input">
              </div>
              <p class="mt-2 text-xs text-gray-500">  
                This will be the price regardless of Supplier.
              </p>
            </div>

            <div class="col-span-3">
              <label for="trade_price" class="block text-sm font-medium text-gray-700">Trade Price</label>
              <div class="relative mt-1 rounded-md shadow-sm">
                <div class="flex absolute inset-y-0 left-0 items-center px-3 sm:text-sm text-gray-500 border-r pointer-events-none">
                  {{ currency }}
                </div>
                <input id="trade_price" v-model="selectedCustomProductData.trade_price" type="number" step="0.01" name="trade_price" class="pl-12 form-input">
              </div>
            </div>
          </div>
        </div>

        <!-- Installation Times -->
        <div class="overflow-hidden rounded-b-sm">
          <div class="flex relative justify-between items-center p-4 text-white bg-black cursor-pointer" @click="selectAccordion('installation-time')">
            <div class="absolute inset-0 bg-gradient-to-br from-blue-dark via-green-dark to-blue-dark opacity-75" />
            <div class="flex relative z-20 items-center">
              <div class="flex-shrink-0 p-3 bg-blue-light bg-opacity-25 rounded-sm">
                <HeroIcon icon="Clock" class="w-6 h-6 text-blue-light" />
              </div>
              <div class="ml-4">
                <h3 class="font-medium">
                  Installation Time
                </h3>
                <p class="text-xs">
                  Setting an installation time will allow you to better quote for your labour.
                </p>
              </div>
            </div>

            <div class="flex relative z-20 justify-center items-center w-6 h-6 bg-blue-light rounded-sm">
              <svg v-if="activeAccordion === 'installation-time'" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
              </svg>
              <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>

          <div v-show="activeAccordion === 'installation-time'" class="py-6 px-4 bg-gray-50">
            <p class="text-sm text-gray-600">
              This will be the installation times for all occurances of a <span class="font-bold text-blue-light">{{ selectedCustomProductData.product.name }}</span> in this room when upgraded.
            </p>

            <div v-if="teamPositions.length > 0">
              <div class="flex items-center p-4 my-6 bg-white rounded-md border shadow">
                <!-- <input id="saveAsDefaultInstallTime" v-model="productData.updateDefaultInstallInfo" type="checkbox" :value="true" class="mr-4 text-blue-dark rounded border-gray-300 focus:ring-indigo-500"> -->
                <div class="flex flex-col">
                  <label for="saveAsDefaultInstallTime" class="font-bold">Save as Install Time Defaults</label>
                  <p class="text-xs text-gray-600">
                    This will update your default install time(s) for <span class="font-bold text-blue-light">{{ selectedCustomProductData.product.name }}</span>.
                  </p>
                  <p class="text-xs text-gray-600">
                    <span class="font-bold">Note:</span> Previously added install times in other Projects will remain unaffected.
                  </p>
                </div>
              </div>

              <div class="overflow-hidden sm:rounded-lg border-b border-gray-200 shadow">
                <table class="w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                        Installation Type
                      </th>
                      <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                        Install Time
                      </th>
                      <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                        Total Install Time
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="selectedCustomProductData.installTimeDetails && selectedCustomProductData.installTimeDetails.length > 0" class="bg-white divide-y divide-gray-200">
                    <tr v-for="(installTime, posIdx) in selectedCustomProductData.installTimeDetails" :key="posIdx">
                      <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                        {{ installTime.position }}
                      </td>
                      <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                        <div class="flex rounded-md shadow-sm">
                          <input v-model="installTime.install_time_mins" type="number" min="0" class="block flex-1 mt-0 w-full sm:text-sm text-right rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 form-input">
                          <span class="inline-flex items-center px-3 text-sm text-gray-500 bg-gray-50 rounded-r-md border border-l-0 border-gray-300">
                            {{ 'minute' | pluralize(installTime.install_time_mins) }}
                          </span>
                        </div>
                      </td>
                      <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                        {{ 'minute' | pluralize(getTotalInstallTypeTime(installTime.id), true) }}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot class="bg-gray-50">
                    <tr>
                      <td scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                        Total Install Time:
                      </td>
                      <td scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                        {{ 'minute' | pluralize(productInstallTime(), true) }}
                      </td>
                      <td scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                        {{ 'minute' | pluralize(productTotalInstallTime(), true) }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div v-else>
              You haven't added any team positions. To associate install time with products, add at least one Team Position.
            </div>
          </div>
        </div>
      </div>    
    </div>

    <div v-if="customProducts.length === 0 && !loadingCustomProducts" class="flex flex-col justify-center items-center my-16">
      <svg xmlns="http://www.w3.org/2000/svg" class="w-32 h-32" viewBox="0 0 647.63626 632.17383">
        <path d="M687.3279,276.08691H512.81813a15.01828,15.01828,0,0,0-15,15v387.85l-2,.61005-42.81006,13.11a8.00676,8.00676,0,0,1-9.98974-5.31L315.678,271.39691a8.00313,8.00313,0,0,1,5.31006-9.99l65.97022-20.2,191.25-58.54,65.96972-20.2a7.98927,7.98927,0,0,1,9.99024,5.3l32.5498,106.32Z" transform="translate(-276.18187 -133.91309)" fill="#f2f2f2" />
        <path d="M725.408,274.08691l-39.23-128.14a16.99368,16.99368,0,0,0-21.23-11.28l-92.75,28.39L380.95827,221.60693l-92.75,28.4a17.0152,17.0152,0,0,0-11.28028,21.23l134.08008,437.93a17.02661,17.02661,0,0,0,16.26026,12.03,16.78926,16.78926,0,0,0,4.96972-.75l63.58008-19.46,2-.62v-2.09l-2,.61-64.16992,19.65a15.01489,15.01489,0,0,1-18.73-9.95l-134.06983-437.94a14.97935,14.97935,0,0,1,9.94971-18.73l92.75-28.4,191.24024-58.54,92.75-28.4a15.15551,15.15551,0,0,1,4.40966-.66,15.01461,15.01461,0,0,1,14.32032,10.61l39.0498,127.56.62012,2h2.08008Z" transform="translate(-276.18187 -133.91309)" fill="#3f3d56" />
        <path d="M398.86279,261.73389a9.0157,9.0157,0,0,1-8.61133-6.3667l-12.88037-42.07178a8.99884,8.99884,0,0,1,5.9712-11.24023l175.939-53.86377a9.00867,9.00867,0,0,1,11.24072,5.9707l12.88037,42.07227a9.01029,9.01029,0,0,1-5.9707,11.24072L401.49219,261.33887A8.976,8.976,0,0,1,398.86279,261.73389Z" transform="translate(-276.18187 -133.91309)" fill="#6b7280" />
        <circle cx="190.15351" cy="24.95465" r="20" fill="#6b7280" />
        <circle cx="190.15351" cy="24.95465" r="12.66462" fill="#fff" />
        <path d="M878.81836,716.08691h-338a8.50981,8.50981,0,0,1-8.5-8.5v-405a8.50951,8.50951,0,0,1,8.5-8.5h338a8.50982,8.50982,0,0,1,8.5,8.5v405A8.51013,8.51013,0,0,1,878.81836,716.08691Z" transform="translate(-276.18187 -133.91309)" fill="#e6e6e6" />
        <path d="M723.31813,274.08691h-210.5a17.02411,17.02411,0,0,0-17,17v407.8l2-.61v-407.19a15.01828,15.01828,0,0,1,15-15H723.93825Zm183.5,0h-394a17.02411,17.02411,0,0,0-17,17v458a17.0241,17.0241,0,0,0,17,17h394a17.0241,17.0241,0,0,0,17-17v-458A17.02411,17.02411,0,0,0,906.81813,274.08691Zm15,475a15.01828,15.01828,0,0,1-15,15h-394a15.01828,15.01828,0,0,1-15-15v-458a15.01828,15.01828,0,0,1,15-15h394a15.01828,15.01828,0,0,1,15,15Z" transform="translate(-276.18187 -133.91309)" fill="#3f3d56" />
        <path d="M801.81836,318.08691h-184a9.01015,9.01015,0,0,1-9-9v-44a9.01016,9.01016,0,0,1,9-9h184a9.01016,9.01016,0,0,1,9,9v44A9.01015,9.01015,0,0,1,801.81836,318.08691Z" transform="translate(-276.18187 -133.91309)" fill="#6b7280" />
        <circle cx="433.63626" cy="105.17383" r="20" fill="#6b7280" />
        <circle cx="433.63626" cy="105.17383" r="12.18187" fill="#fff" />
      </svg>
      <h2 class="mt-6 text-xl font-bold">
        No custom products.
      </h2>
      <h3 class="mt-1 text-sm text-gray-500">
        You haven't added any custom products. When you add a custom product, it will then appear in here.
      </h3>
    </div>
      
    <div class="flex justify-end mt-6">
      <button v-if="selectedCustomProductData && selectedCustomProductData.product" type="button" class="btn-primary-md" @click="addCustomProductToProject">
        <svg class="flex-shrink-0 w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z" />
        </svg>
        Add to Project
      </button>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/MinimalPagination.vue';
export default {
  components: {
    Pagination
  },
  props: {
    productToUpgrade: {
      type: Object,
      required: false,
      default: null
    },
    roomId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data() {
    return {
      searchResults: null,
      debounce: null,
      selectedCustomProductData: {
        product: null,
        installTimeMins: 0,
        customer_price: 0,
        trade_price: 0,
        nameOverride: '',
        installTimeDetails: []
      },
      favouritesType: 'team_favourites',
      loadingCustomProducts: false,
      from: 1,
      to: 12,
      currentPage: 1,
      customProducts: [],
      totalCustomProducts: 0,
      searchQuery: null,
      showAddCustomProduct: false,
      activeAccordion: null
    };
  },
  computed: {
    teamPositions() {
      return this.$store.state.system.teamPositions;
    },
    clientMode() {
      return this.$store.state.system.clientMode;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    project() {
      return this.$store.state.project.project;
    },
    currency() {
      return this.$store.state.auth.user.team.team_currency.symbol;
    }
  },
  mounted() {
    this.getCustomProducts();
  },
  methods: {
    getCustomProducts(page = 1) {
      this.loadingCustomProducts = true;
      axios.get('/api/products/custom-products', {
        params: {
          page: page,
          projectId: this.project.id,
          searchQuery:  this.searchQuery
        }
      }).then((res) => {
        this.from = res.data.from;
        this.to = res.data.to;
        this.currentPage = res.data.current_page;;
        this.customProducts = res.data.data;
        this.totalCustomProducts = res.data.total;
      }).finally(() => {
        this.loadingCustomProducts = false;
      });
    },
    debounceSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getCustomProducts();
      }, 600);
    },
    selectCustomProduct(product) {
      this.selectedCustomProductData.product = product;
      this.selectedCustomProductData.customer_price = product.retail_price;
      this.selectedCustomProductData.trade_price = product.trade_price;

      this.teamPositions.forEach((el) => {
        const details = { ...el };
        details.install_time_mins = 0;
        const defaultInstallTime = product.team_install_info_default.find((s) => s.team_position_id === details.id);
        details.install_time_mins = defaultInstallTime ? defaultInstallTime.install_time_mins : 0;
        this.selectedCustomProductData.installTimeDetails.push(details);
      });
    },
    toggleCustomFavourite(product) {
      this.axios.post(`/api/products/${product.id}/toggle-custom-favourite`).then(() => {
        const idx = this.customProducts.findIndex((el) => el.id === product.id);
        const prodUpd = [...this.customProducts];
        prodUpd[idx].is_favourite = !prodUpd[idx].is_favourite;
        this.customProducts = prodUpd;
        this.$Notification.success('Success', 'Product favourited');
      }).catch(() => {
        this.$Notification.error('Something went wrong!', 'Please try again.');
      });
    },
    addCustomProductToProject() {
      const productDetails = {
        current_product: this.productToUpgrade,
        upgrade_product: this.selectedCustomProductData.product.id,
        customer_price: this.selectedCustomProductData.customer_price,
        install_info: this.selectedCustomProductData.installTimeDetails,
        trade_price: this.selectedCustomProductData.trade_price
      };

      this.axios.post(`/api/projects/${this.projectId}/room/${this.roomId}/add-upgrade-custom-product`, productDetails).then((res) => { 
        this.$emit('upgradeCustomProductAdded', this.productToUpgrade, res.data);
        this.closeAddCustomProductModal();
      });
    },
    closeAddCustomProductModal() {
      this.$emit('close');
      this.selectedCustomProductData = {
        customer_price: 0,
        trade_price: 0,
        product: null,
        products: [],
        installTimeDetails: []
      };
    },
    productInstallTime() {
      let installTime = 0;
      this.selectedCustomProductData.installTimeDetails.forEach((el) => {
        installTime += parseInt(el.install_time_mins);
      });
      return installTime;
    },
    productTotalInstallTime() {
      const totalInstallTime = this.productInstallTime();
      return totalInstallTime;
    },
    getTotalInstallTypeTime(positionId) {
      const posInstallTimeDetails = this.selectedCustomProductData.installTimeDetails.find((el) => el.id === positionId);
      return (posInstallTimeDetails.install_time_mins);
    },
    cancelSelectedCustomProduct(){
      this.selectedCustomProductData = {
        customer_price: null,
        trade_price: 0,
        product: null,
        products: [],
        installTimeDetails: []
      };
    },
    selectAccordion(accordion) {
      if (this.activeAccordion === accordion) {
        this.activeAccordion = null;
      } else {
        this.activeAccordion = accordion;
      }
    }
  }
};
</script>

<style scoped>
  .hiddenText {
    color: transparent;
    text-shadow: 0 0 6px rgb(255, 255, 255);
  }
</style>
