import axios from 'axios';
import SalesTax from 'sales-tax';

export default {
  namespaced: true,
  state: {
    authenticated: false,
    user: null,
    includeVAT: true,
    defaultProductVATRate: 0,
    defaultLabourVATRate: 0
  },
  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      return state.user;
    },
    defaultProductVATRate(state) {
      return state.defaultProductVATRate;
    }
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, value) {
      state.user = value;
    },
    SET_INCLUDE_VAT(state, value) {
      state.includeVAT = value;
    },
    SET_TAX_RATES(state, value) {
      const { product, labour } = value;
      state.defaultProductVATRate = 1 + (product / 100);
      state.defaultLabourVATRate =  1 + (labour / 100);
    },
    SET_PROFILE_IMAGE(state, value) {
      state.user.image_url = value;
    },
    SET_TEAM_ABOUT_US(state, value) {
      state.user.team.about_us = value;
    },
    SET_TEAM_SUBSCRIPTION(state) {
      state.user.team.trial_status = null;
    }
  },
  actions: {
    async signIn({ dispatch }, credentials) {
      await axios.get('/sanctum/csrf-cookie');
      await axios.post('/api/login', credentials).catch((error) => {
        throw error;
      });
      return dispatch('me');
    },
    async logout({ dispatch }) {
      await axios.post('/api/logout');
      return dispatch('logoutUser');
    },
    logoutUser({ commit }) {
      commit('SET_AUTHENTICATED', false);
      commit('SET_USER', null);
      commit('SET_TAX_RATES', { product: 0, labour: 0 });
    },
    me({ commit }) {
      return axios.get('/api/user').then((response) => {
        commit('SET_AUTHENTICATED', true);
        commit('SET_USER', response.data);

        if (response.data.team.include_vat) {
          commit('SET_TAX_RATES', { product: response.data.team.default_product_vat_rate, labour: response.data.team.default_labour_vat_rate });
        } else {
          commit('SET_INCLUDE_VAT', false);
        }

        // const taxRate = response.data.team.currency === 3 ? 'IE' : 'GB';
        // SalesTax.getSalesTax(taxRate).then((res) => {
        //   commit('SET_TAX_RATE', res.rate);
        // });
      }).catch(() => {
        commit('SET_AUTHENTICATED', false);
        commit('SET_USER', null);
        commit('SET_TAX_RATES', { product: 0, labour: 0 });
      });
    },
    updateMe({ commit }, updatedDetails) {
      commit('SET_USER', updatedDetails);
    },
    updateProfileImage({ commit }, profileImage = null) {
      commit('SET_PROFILE_IMAGE', profileImage);
    },
    updateAboutUs({ commit }, updAboutUs) {
      commit('SET_TEAM_ABOUT_US', updAboutUs);
    },
    updateTeamSubscription({ commit }) {
      commit('SET_TEAM_SUBSCRIPTION');
    }
  }
};