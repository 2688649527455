import Vue from 'vue';
import router from './router';
import App from './App.vue';
import axios from './http';
import VueAxios from 'vue-axios';
import store from './store/index';
import VueMoment from 'vue-moment';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VuePluralize from 'vue-pluralize';
import VDatepicker from 'v-calendar';
import InstantSearch from 'vue-instantsearch';
import Notifications from 'vue-notification';
import isTouchDevice from './isTouchDevice';
import '@/assets/css/index.css';
import '@/assets/css/animate.min.css';
import 'swiper/css/swiper.css';
import HeroIcons from '@/components/HeroIcons.vue';

Vue.use(VueAxios, axios);
Vue.use(VueMoment);
Vue.use(VuePluralize);
Vue.use(VDatepicker);
Vue.use(VueAwesomeSwiper); /* { default options with global component } */
Vue.use(Notifications);
Vue.use(InstantSearch);
Vue.use(isTouchDevice);
Vue.component('HeroIcon', HeroIcons);

var numeral = require('numeral');

Vue.config.productionTip = false;

Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0.00");
});

Vue.filter("currency", (val) => {
  const currSymbol = store.state.auth.user.team.team_currency.symbol;
  return `${currSymbol}${parseFloat(val).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
});

Vue.filter('userCurrency', function (val = '') {
  if (val === null || isNaN(val)) {
    return '';
  }

  const settings = JSON.parse(window.localStorage.getItem('settings'));
  try {
    const curr = (settings.currency === 'eur') ? '€' : '£';

    if (val === '') {
      return curr;
    }

    if (curr === '€') {
      val = val * settings.eurRate;
    }

    const formattedNum = parseFloat(val).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return `${curr}${formattedNum}`;
  } catch (e) {
    return '';
  }
});

store.dispatch('auth/me').then(() => {
  new Vue({
    store,
    router,
    render: h => h(App)
  }).$mount('#app');
});
