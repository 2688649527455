<template>
  <div
    v-if="showModal"
    class="modal-window fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center"
    :class="showModal ? 'show' : 'hide'"
  >
    <div class="fixed inset-0 transition-opacity" :class="showModal ? 'ease-in duration-200 opacity-100' : 'ease-out duration-200 opacity-0'">
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>
    <div
      v-if="editProductData && editProductData.product"
      class="modal-window-split bg-white rounded-lg overflow-hidden shadow-xl transform transition-all"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
      :class="showModal ? 'ease-in duration-200 opacity-100 translate-y-0 sm:scale-100' : 'ease-out duration-300 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'"
    >
      <div class="modal-window-split--product-img bg-gradient">
        <img v-if="editProductData.product" :src="editProductData.product.img">
      </div>

      <div class="modal-window-split--content relative">
        <div class="bg-white px-4 pt-5 pb-8 sm:p-8 max-h-full overflow-x-scroll">
          <div class="mb-4 flex items-center gap-4">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-6 w-6 xl:h-12 xl:w-12 rounded-full bg-lime sm:mx-0 sm:h-10 sm:w-10">
              <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="pencil w-5 h-5 xl:w-6 xl:h-6">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
              </svg>
            </div>
            <h3 id="modal-headline" class="text-lg xl:text-2xl leading-8 font-bold text-gray-900">
              Edit Product
            </h3>
          </div>
          <div v-if="editProductData && editProductData.product" class="sm:flex sm:items-start">
            <div class="mt-3 text-center w-full sm:mt-0 sm:text-left">
              <h4 class="text-lg font-semibold text-blue-light">
                {{ editProductData.product.name }}
              </h4>
              <h5 class="text-sm text-gray-400">
                {{ editProductData.product.brand }}
              </h5>
              <div class="mt-2">
                <div class="w-full">
                  <div>
                    <h3 class="font-semibold text-base xl:text-lg mb-2">
                      Upgrade Options
                    </h3>
                    <div class="w-full border border-gray-300 rounded-lg overflow-hidden mb-6">
                      <div class="grid grid-cols-1 sm:grid-cols-3 divide-y sm:divide-y-0 divide-x-0 sm:divide-x divide-gray-300">
                        <div v-for="index in 3" :key="index">
                          <div v-if="hasOption(index)" class="flex h-full">
                            <div v-if="editProductData.products[0].upgrade_products[index - 1].upgrade_product.first_image" class="hidden sm:flex items-center w-24 h-full bg-gradient-to-br from-blue-light to-blue-dark">
                              <img class="object-cover" :src="editProductData.products[0].upgrade_products[index - 1].upgrade_product.first_image.url">
                            </div>
                            <div v-else-if="editProductData.products[0].upgrade_products[index - 1].upgrade_product.image_url" class="hidden sm:flex items-center w-24 h-full bg-gradient-to-br from-blue-light to-blue-dark">
                              <img class="object-cover" :src="editProductData.products[0].upgrade_products[index - 1].upgrade_product.image_url">
                            </div>
                            <div class="w-full">
                              <div class="p-4">
                                <p class="text-sm font-bold truncate">
                                  {{ editProductData.products[0].upgrade_products[index - 1].upgrade_product.name }}
                                </p>
                                <p class="text-xs text-gray-400">
                                  {{ getDifferenceInPrice(editProductData.products[0].upgrade_products[index - 1], editProductData.products) }}
                                </p>
                              </div>
                              <div class="w-full flex shadow-sm divide-x divide-gray-300">
                                <button type="button" class="w-1/2 flex items-center justify-center py-2 border-t border-gray-300 bg-white text-xs font-medium text-red-600 hover:bg-gray-50 focus:z-10 focus:outline-none" @click="removeUpgradeOption(editProductData.products[0].upgrade_products[index - 1])">
                                  <svg class="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                  </svg>
                                  Delete
                                </button>
                                <button type="button" class="w-1/2 flex items-center justify-center text-center py-2 border-t border-gray-300 bg-white text-xs font-medium text-green-600 hover:bg-gray-50 focus:z-10 focus:outline-none" @click="upgradeToProduct(editProductData.products[0].upgrade_products[index - 1])">
                                  <svg class="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                                  </svg>
                                  Upgrade
                                </button>
                              </div>
                            </div>
                          </div>
                          <button v-else class="hidden sm:flex items-center justify-center w-full h-full py-6 text-gray-400 hover:bg-gray-50 text-sm" @click="showUpgradeProductModal(editProductData.products[0])">
                            <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <span class="text-xs xl:text-base">Add Option {{ index }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-4">
                    <div class="border divide-y divide-gray-700 mt-10">
                      <div class="flex relative justify-between items-center p-4 text-white bg-black cursor-pointer" @click="selectAccordion('global-properties')">
                        <div class="absolute inset-0 bg-gradient-to-br from-blue-dark via-green-dark to-blue-dark opacity-75" />

                        <div class="flex relative z-20 items-center">
                          <div class="flex-shrink-0 p-3 bg-blue-light bg-opacity-25 rounded-sm">
                            <HeroIcon icon="Globe" class="w-6 h-6 text-blue-light" />
                          </div>

                          <div class="ml-4">
                            <h3 class="text-lg font-medium">
                              Global Properties
                            </h3>
                            <p class="text-xs">
                              Anything set here will affect all Products in this Project. Some settings like 'Product Description' will impact all Projects.
                            </p>
                          </div>
                        </div>

                        <div class="flex relative z-20 justify-center items-center w-6 h-6 bg-blue-light rounded-sm">
                          <svg v-if="activeAccordion === 'global-properties'" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                          </svg>
                          <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                          </svg>
                        </div>
                      </div>
                      <div v-show="activeAccordion === 'global-properties'" class="bg-gray-50 px-4 py-6 grid grid-cols-6 gap-4">
                        <div class="col-span-6">
                          <div class="flex justify-between">
                            <label for="product_description" class="block text-xs xl:text-sm font-medium text-gray-700">
                              Product Description
                            </label>
                            <span class="text-xs text-gray-500">Optional</span>
                          </div>
                          <div class="mt-1">
                            <input
                              v-model="editProductData.description"
                              type="text"
                              name="product_description"
                              class="form-input"
                              placeholder="e.g. In-Ceiling Speaker"
                              aria-describedby="product-name-override"
                            >
                          </div>
                          <p class="mt-2 text-xs text-gray-500">
                            A brief description of what the product is, shown on the Customers Sales Quote.
                          </p>
                        </div>

                        <div class="col-span-6">
                          <div class="flex justify-between">
                            <label
                              for="name_override"
                              class="block text-xs xl:text-sm font-medium text-gray-700"
                            >Product Name Override</label>
                            <span class="text-xs text-gray-500">Optional</span>
                          </div>
                          <div class="mt-1">
                            <input
                              v-model="editProductData.nameOverride"
                              type="text"
                              name="name_override"
                              class="form-input"
                              placeholder="e.g. In-Ceiling Speaker"
                              aria-describedby="product-name-override"
                            >
                          </div>
                          <p
                            class="mt-2 text-xs text-gray-500"
                          >
                            The Product Name shown on the Customers Sales Quote.
                          </p>
                        </div>

                        <div class="col-span-3">
                          <label
                            for="customer_price"
                            class="block text-xs xl:text-sm font-medium text-gray-700"
                          >Customer Price</label>
                          <div class="mt-1 relative rounded-md shadow-sm">
                            <div
                              class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                            >
                              <span class="text-gray-500 text-xs xl:text-sm">{{ currency }}</span>
                            </div>
                            <input
                              id="customer_price"
                              v-model="editProductData.customer_price"
                              type="number"
                              step="0.01"
                              name="customer_price"
                              class="form-input pl-7"
                            >
                          </div>
                          <p
                            class="mt-2 text-xs text-gray-500"
                          >
                            This will be the price regardless of Supplier.
                          </p>
                        </div>

                        <div v-if="projectStages.length > 0" class="col-span-3">
                          <label
                            for="project_stage"
                            class="block text-xs xl:text-sm font-medium text-gray-700"
                          >Project Install Stage</label>
                          <select
                            id="project_stage"
                            v-model="editProductData.install_stage"
                            name="project_stage"
                            required
                            class="select-input"
                          >
                            <option :value="null" selected disabled>
                              -- Choose Project Stage --
                            </option>
                            <option
                              v-for="(projectStage, index) in projectStages"
                              :key="index"
                              :value="projectStage.id"
                            >
                              {{ projectStage.name }}
                            </option>
                          </select>
                          <p
                            class="mt-2 text-xs text-gray-500"
                          >
                            The stage at which this Product will be installed.
                          </p>
                        </div>
                        <div class="block col-span-6" aria-hidden="true">
                          <div class="py-5">
                            <div class="border-t border-gray-200" />
                          </div>
                        </div>

                        <div class="col-span-6">
                          <div class="flex justify-between items-center mb-4">
                            <div>
                              <h3 class="text-sm font-medium text-gray-700">
                                Cables
                              </h3>
                              <p class="mt-1 text-xs text-gray-500">
                                Cables specified here will appear on your cable schedule.
                              </p>
                            </div>

                            <button type="button" class="btn-primary-sm" @click="showAddCableToProductModal = true">
                              <PlusIcon class="mr-1 -ml-1 w-4 h-4" />
                              Add Cable
                            </button>
                          </div>

                          <div class="flex items-start p-4 my-4 bg-blue-dark rounded-md border">
                            <div class="flex items-center h-5">
                              <input id="saveAsDefaultCables" v-model="editProductData.saveCablesAsDefault" name="saveAsDefaultCables" :value="true" type="checkbox" class="w-4 h-4 text-blue-light rounded border-blue-dark focus:ring-blue-light">
                            </div>
                            <div class="ml-3 text-sm">
                              <label for="saveAsDefaultCables" class="font-bold text-white">Save as Product Default</label>
                              <p class="text-xs text-gray-50">
                                These cables will become the default for <span class="font-bold">{{ editProductData.name }}</span>.
                              </p>
                              <span class="inline-flex items-center py-1 px-2 mt-2 text-xs text-white bg-blue-light bg-opacity-25 rounded-md">
                                <InformationCircleIcon class="mr-2 w-4 h-4 text-lime" /> Note: Previously specified {{ editProductData.name }} in existing Projects will not be affected.
                              </span>
                            </div>
                          </div>

                          <div class="flex flex-col">
                            <div class="overflow-x-auto -my-2 sm:-mx-6 lg:-mx-8">
                              <div class="inline-block py-2 sm:px-6 lg:px-8 min-w-full align-middle">
                                <div class="overflow-hidden sm:rounded-lg border">
                                  <table class="min-w-full divide-y divide-gray-200">
                                    <thead class="bg-gray-50">
                                      <tr>
                                        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                          Cable
                                        </th>
                                        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                          Purpose
                                        </th>
                                        <th scope="col" class="relative py-3 px-6">
                                          <span class="sr-only">Edit</span>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody v-if="editProductData.cables.length > 0">
                                      <tr v-for="(cable, cableIdx) in editProductData.cables" :key="cableIdx" class="bg-white" :class="(cableIdx % 2) === 0 ? 'bg-white' : 'bg-gray-50'">
                                        <td class="py-3 px-6 text-xs font-medium text-gray-900 whitespace-nowrap">
                                          {{ cable.cable }}
                                        </td>
                                        <td class="py-3 px-6 text-xs text-gray-500 whitespace-nowrap">
                                          {{ cable.purpose }}
                                        </td>
                                        <td class="py-3 px-6 text-sm font-medium text-right whitespace-nowrap">
                                          <button type="button" class="p-2 rounded-full" @click="deleteCable(cableIdx)">
                                            <svg class="w-5 h-5 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                            </svg>
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tbody v-else>
                                      <tr class="bg-white">
                                        <td colspan="3" class="py-4 px-6 text-xs font-medium text-center text-gray-500 whitespace-nowrap">
                                          No Cables
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
               

                      <div class="rounded-b-sm bg-white overflow-hidden">
                        <div class="flex relative justify-between items-center p-4 text-white bg-black cursor-pointer" @click="selectAccordion('installation-time')">
                          <div class="absolute inset-0 bg-gradient-to-br from-blue-dark via-green-dark to-blue-dark opacity-75" />

                          <div class="flex relative z-20 items-center">
                            <div class="flex-shrink-0 p-3 bg-blue-light bg-opacity-25 rounded-sm">
                              <HeroIcon icon="Clock" class="w-6 h-6 text-blue-light" />
                            </div>

                            <div class="ml-4">
                              <h3 class="text-lg font-medium">
                                Installation Time
                              </h3>
                              <p class="text-xs">
                                Setting an installation time will allow you to better quote for your labour.
                              </p>
                            </div>
                          </div>

                          <div class="flex relative z-20 justify-center items-center w-6 h-6 bg-blue-light rounded-sm">
                            <svg v-if="activeAccordion === 'installation-time'" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                            </svg>
                            <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                            </svg>
                          </div>
                        </div>

                        <div v-show="activeAccordion === 'installation-time'" class="bg-gray-50 px-4 py-6">
                          <p class="text-xs xl:text-sm text-gray-600">
                            This will be the installation times for all occurances of a
                            <span class="text-xs xl:text-sm text-blue-light font-bold">{{ editProductData.product.name }}</span> in this room.
                          </p>

                          <div v-if="teamPositions.length > 0">
                            <div class="flex items-center p-4 my-6 bg-gradient-to-br from-black via-green-dark to-black rounded-sm">
                              <input
                                id="saveAsDefaultInstallTime"
                                v-model="editProductData.updateDefaultInstallInfo"
                                type="checkbox"
                                :value="true"
                                class="focus:ring-indigo-500 text-blue-dark border-gray-300 rounded mr-4"
                              >
                              <div class="flex flex-col">
                                <label for="saveAsDefaultInstallTime" class="text-xs text-white xl:text-base font-bold">
                                  Save as Install Time Defaults
                                </label>
                                <p class="text-xxs xl:text-xs text-gray-200">
                                  This will update your default install time(s) for
                                  <span class="text-xxs xl:text-xs text-blue-light font-bold">{{ editProductData.product.name }}</span>.
                                </p>
                                <p class="text-xxs xl:text-xs text-gray-200">
                                  <span class="text-xxs xl:text-xs font-bold">Note:</span> Previously added install times in other Projects will remain unaffected.
                                </p>
                              </div>
                            </div>

                            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                              <table class="w-full divide-y divide-gray-200">
                                <thead class="bg-gray-50">
                                  <tr>
                                    <th scope="col" class="px-2 xl:px-6 py-3 text-left text-xxs xl:text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      Installation Type
                                    </th>
                                    <th scope="col" class="px-2 xl:px-6 py-3 text-right text-xxs xl:text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      Install Time
                                    </th>
                                    <th scope="col" class="px-2 xl:px-6 py-3 text-right text-xxs xl:text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      Total Install Time
                                    </th>
                                  </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                  <tr v-for="(installTime, posIdx) in editProductData.installTimeDetails" :key="posIdx">
                                    <td class="px-2 xl:px-6 py-4 whitespace-nowrap text-xxs xl:text-sm font-medium text-gray-900">
                                      {{ installTime.position }}
                                    </td>
                                    <td class="px-2 xl:px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-900">
                                      <div class="flex rounded-md shadow-sm">
                                        <input v-model="installTime.install_time_mins" type="number" class="text-right form-input mt-0 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-l-md text-xxs xl:text-sm border-gray-300">
                                        <span class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-xxs xl:text-sm">
                                          {{ 'minute' | pluralize(installTime.install_time_mins) }}
                                        </span>
                                      </div>
                                    </td>
                                    <td class="px-2 xl:px-6 py-4 whitespace-nowrap text-right text-xxs xl:text-sm font-medium text-gray-900">
                                      {{ 'minute' | pluralize(getTotalInstallTypeTime(installTime.id), true) }}
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot class="bg-gray-50">
                                  <tr>
                                    <td scope="col" class="px-6 py-3 text-left text-xxs xl:text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      Total Install Time: 
                                    </td>
                                    <td scope="col" class="px-6 py-3 text-right text-xxs xl:text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      {{ 'minute' | pluralize(productInstallTime(), true) }}
                                    </td>
                                    <td scope="col" class="px-6 py-3 text-right text-xxs xl:text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      {{ 'minute' | pluralize(productTotalInstallTime(), true) }}
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                          <div v-else>
                            You haven't added any team positions. To associate install time with products, add at least one Team Position.
                          </div>
                        </div>
                      </div>
                      <div class="overflow-hidden">
                        <div class="flex relative justify-between items-center p-4 text-white bg-black cursor-pointer" @click="selectAccordion('set-label')">
                          <div class="absolute inset-0 bg-gradient-to-br from-blue-dark via-green-dark to-blue-dark opacity-75" />

                          <div class="flex relative z-20 items-center">
                            <div class="flex-shrink-0 p-3 bg-blue-light bg-opacity-25 rounded-sm">
                              <HeroIcon icon="ClipboardList" class="w-6 h-6 text-blue-light" />
                            </div>

                            <div class="ml-4">
                              <h3 class="font-medium">
                                Serial Number
                              </h3>
                              <p class="text-xs text-gray-200">
                                Set serial number &amp; product associated position, module or label name.
                              </p>
                            </div>
                          </div>

                          <div class="flex relative z-20 justify-center items-center w-6 h-6 bg-blue-light rounded-sm">
                            <svg v-if="activeAccordion === 'set-label'" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                            </svg>
                            <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                            </svg>
                          </div>
                        </div>

                        <div v-show="activeAccordion === 'set-label'" class="py-6 px-4">
                          <div class="overflow-hidden sm:rounded-lg border">
                            <table class="w-full">
                              <thead class="table-head">
                                <tr>
                                  <th scope="col" class="table-heading">
                                    {{ 'Product' | pluralize(editProductData.products.length) }}
                                  </th>
                                  <th scope="col" class="table-heading">
                                    Label
                                  </th>
                                  <th scope="col" class="table-heading">
                                    Serial No
                                  </th>
                                </tr>
                              </thead>
                              <tbody v-if="hasProductSpecificQuestions && !isLabel && !isKeypad && !isRakoWiringModule && !(numberOfChannels > 0)" class="bg-white divide-y divide-gray-200">
                                <tr v-for="(item, index) in editProductData.products" :key="index">
                                  <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                                    <div v-if="item.product.system_design.numberOfSpeakers === 'Pair'">
                                      <label class="border-0 form-input">Location 1</label>
                                      <label class="border-0 form-input">Location 2</label>
                                    </div>
                                    <div v-else-if="item.product.system_design.numberOfSpeakers">
                                      <label class="form-label">Input {{ index + 1 }} Purpose</label>
                                    </div>
                                  </td>
                                  <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                                    <div v-if="item.product.system_design.numberOfSpeakers === 'Pair'">
                                      <input v-model="item.details.details.position" type="text" placeholder="Positon 1" class="form-input">
                                      <input v-model="item.details.details.position2" type="text" placeholder="Position 2" class="form-input">
                                    </div>
                                    <div v-else-if="item.product.system_design.numberOfSpeakers">
                                      <input v-model="item.details.details.position" type="text" placeholder="Position" class="form-input">
                                    </div>
                                  </td>
                                  <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                                    <input v-model="item.serial_no" type="text" placeholder="Serial No" class="form-input">
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-if="isRakoWiringModule" class="bg-white divide-y divide-gray-200">
                                <tr v-for="(item, index) in editProductData.products" :key="index">
                                  <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                                    <label class="form-label">Module {{ index + 1 }} Name</label>
                                  </td>
                                  <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                                    <input v-model="item.details.details.moduleName" type="text" :placeholder="`Enter Module ${index + 1} Name`" class="form-input">
                                  </td>
                                  <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                                    <input v-model="item.serial_no" type="text" placeholder="Serial No" class="form-input">
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-if="isKeypad" class="bg-white divide-y divide-gray-200">
                                <tr v-for="(item, index) in editProductData.products" :key="index">
                                  <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                                    <label class="form-label">Keypad Name {{ index + 1 }}</label>
                                  </td>
                                  <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                                    <input v-model="item.details.details.name" type="text" :placeholder="`Enter Keypad ${index + 1} Name`" class="form-input">
                                  </td>
                                  <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                                    <input v-model="item.serial_no" type="text" placeholder="Serial No" class="form-input">
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-if="numberOfChannels > 0" class="bg-white divide-y divide-gray-200">
                                <tr v-for="(item, index) in editProductData.products" :key="index">
                                  <div v-for="(idx, t) in numberOfChannels" :key="idx" class="row conts--input">
                                    <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                                      <label class="form-label">Channel Name {{ idx }}</label>
                                    </td>
                                    <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                                      <input v-model="item.details.details.channels[t]" type="text" placeholder="Enter channel name" class="form-input">
                                    </td>
                                    <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                                      <input v-model="item.serial_no" type="text" placeholder="Serial No" class="form-input">
                                    </td>
                                  </div>
                                </tr>
                              </tbody>
                              <tbody v-if="isLabel" class="bg-white divide-y divide-gray-200">
                                <tr v-for="(item, index) in editProductData.products" :key="index">
                                  <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                                    <label class="form-label">Label {{ index + 1 }}</label>
                                  </td>
                                  <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                                    <input v-model="item.label" type="text" :placeholder="`Enter Label ${index + 1} Name`" class="form-input">
                                  </td>
                                  <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                                    <input v-model="item.serial_no" type="text" placeholder="Serial No" class="form-input">
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div v-if="xeroAccountLinked && editProductData.product.xeroConfig" class="overflow-hidden bg-white rounded-b-sm">
                        <div class="flex relative justify-between items-center p-4 text-white bg-black cursor-pointer" @click="selectAccordion('xero-settings')">
                          <div class="absolute inset-0 bg-gradient-to-br from-blue-dark via-green-dark to-blue-dark opacity-75" />

                          <div class="flex relative z-20 items-center">
                            <div class="flex-shrink-0 p-3 bg-blue-light bg-opacity-25 rounded-sm">
                              <svg class="w-6 h-6" viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
                                <path fill="#13B5EA" d="M22.457 45.49c12.402 0 22.456-10.072 22.456-22.495C44.913 10.57 34.86.5 22.457.5 10.054.5 0 10.57 0 22.995 0 35.418 10.054 45.49 22.457 45.49" />
                                <path fill="#fff" d="M10.75 22.935l3.832-3.85a.688.688 0 0 0-.977-.965l-3.83 3.833-3.845-3.84a.687.687 0 0 0-.966.979l3.832 3.837-3.83 3.84a.688.688 0 1 0 .964.981l3.84-3.842 3.825 3.827a.685.685 0 0 0 1.184-.473.68.68 0 0 0-.2-.485l-3.83-3.846m22.782.003c0 .69.56 1.25 1.25 1.25a1.25 1.25 0 0 0-.001-2.5c-.687 0-1.246.56-1.246 1.25m-2.368 0c0-1.995 1.62-3.62 3.614-3.62 1.99 0 3.613 1.625 3.613 3.62s-1.622 3.62-3.613 3.62a3.62 3.62 0 0 1-3.614-3.62m-1.422 0c0 2.78 2.26 5.044 5.036 5.044s5.036-2.262 5.036-5.043c0-2.78-2.26-5.044-5.036-5.044a5.046 5.046 0 0 0-5.036 5.044m-.357-4.958h-.21c-.635 0-1.247.2-1.758.595a.696.696 0 0 0-.674-.54.68.68 0 0 0-.68.684l.002 8.495a.687.687 0 0 0 1.372-.002v-5.224c0-1.74.16-2.444 1.648-2.63.14-.017.288-.014.29-.014.406-.015.696-.296.696-.675a.69.69 0 0 0-.69-.688m-13.182 4.127c0-.02.002-.04.003-.058a3.637 3.637 0 0 1 7.065.055H16.2zm8.473-.13c-.296-1.403-1.063-2.556-2.23-3.296a5.064 5.064 0 0 0-5.61.15 5.098 5.098 0 0 0-1.973 5.357 5.08 5.08 0 0 0 4.274 3.767c.608.074 1.2.04 1.81-.12a4.965 4.965 0 0 0 1.506-.644c.487-.313.894-.727 1.29-1.222.006-.01.014-.017.022-.027.274-.34.223-.826-.077-1.056-.254-.195-.68-.274-1.014.156-.072.104-.153.21-.24.315-.267.295-.598.58-.994.802-.506.27-1.08.423-1.69.427-1.998-.023-3.066-1.42-3.447-2.416a3.716 3.716 0 0 1-.153-.58l-.01-.105h7.17c.982-.022 1.51-.717 1.364-1.51z" />
                              </svg>
                            </div>

                            <div class="ml-4">
                              <h3 class="text-lg font-medium">
                                Xero Settings
                              </h3>
                              <p class="text-xs">
                                Link the Products in this Project to a Xero Account and Tax Rate. 
                              </p>
                            </div>
                          </div>

                          <div class="flex relative z-20 justify-center items-center w-6 h-6 bg-blue-light rounded-sm">
                            <svg v-if="activeAccordion === 'xero-settings'" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                            </svg>
                            <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                            </svg>
                          </div>
                        </div>
                        <div v-show="activeAccordion === 'xero-settings'" class="grid grid-cols-6 gap-4 py-6 px-4">
                          <div class="col-span-3">
                            <label class="form-label">Purchasing Account</label>
                            <select v-model="editProductData.product.xeroConfig.account_code" class="mb-2 select-input" required>
                              <option :value="null" selected disabled>
                                Choose Account
                              </option>
                              <optgroup v-for="(group, groupIndex) in groupedXeroAccountCodes" :key="groupIndex" :label="group.name">
                                <option v-for="(code, codeIdx) in group.accounts" :key="codeIdx" :value="code.Code">
                                  {{ code.Code }} - {{ code.Name }}
                                </option>
                              </optgroup>
                            </select>
                          </div>
                          <div class="col-span-3">
                            <label class="form-label">Tax Rate</label>
                            <select v-model="editProductData.product.xeroConfig.tax_type" class="mb-2 select-input" required>
                              <option :value="null" selected disabled>
                                Choose Tax Rate
                              </option>
                              <option v-for="(taxRate, taxRateIdx) in linkedIntegrations.find(el => el.name === 'Xero').taxRates" :key="`tax_${taxRateIdx}`" :value="taxRate.TaxType">
                                {{ taxRate.Name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-class="transform opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                    >
                      <div v-show="upgradingProduct" class="absolute z-50 top-0 bg-white w-full h-full bg-opacity-75 flex flex-col items-center justify-center">
                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 mb-4 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                        </svg>
                        <h3 class="font-bold text-xl">
                          Upgrading Product
                        </h3>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-100 flex justify-between p-4 bg-opacity-75">
          <button type="button" class="btn-danger-md mr-2" @click="deleteProduct">
            <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="hidden sm:block mr-2 -ml-1 w-4 h-4"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /></svg>
            Delete Product
          </button>

          <div>
            <button type="button" class="btn-white-md mr-2" @click="closeModal">
              Cancel
            </button>
            <button type="button" class="btn-primary-md" @click="updateProducts">
              Save Product
            </button>
          </div>
        </div>
      </div>
    </div>

    <delete-product-modal
      :show-modal="showDeleteProductModal"
      :project-id="parseInt(project.id)"
      :floor="floor"
      :product-id="parseInt(editProductData.product.id)"
      :room-id="roomId ? parseInt(roomId) : parseInt(editProductData.product.products[0].room_id)"
      :supplier-id="parseInt(editProductData.product.products[0].supplier_id)"
      :product="editProductData"
      @closeDeleteProductModal="closeDeleteProductModal"
      @removeProductFromSwiper="removeProductFromRoom"
      @removeAllProductFromSwiper="removeAllProductFromRoom"
    />

    <AddCableToProductModal
      form-name="addCableToEditProduct"
      :show="showAddCableToProductModal"
      :close="closeAddCableToProductModal"
      :cables="cables"
      :cable-purposes="cablePurposes"
      @addCableToProduct="addCableToProduct"
    />
  </div>
</template>

<script>
import DeleteProductModal from "./DeleteProductModal.vue";
import AddCableToProductModal from './AddCableToProductModal.vue';
import { PlusIcon, InformationCircleIcon } from "@vue-hero-icons/solid";

export default {
  components: {
    DeleteProductModal,
    AddCableToProductModal,
    PlusIcon, InformationCircleIcon
  },
  props: {
    showModal: Boolean,
    editProduct: {
      type: Object,
      default: () => {}, 
      required: false
    },
    floor: {
      type: Number,
      default: 0,
      required: false
    }
  },
  data() {
    return {
      activeAccordion: null,
      loaded: true,
      showDeleteProductModal: false,
      upgradingProduct: false,
      newInstallDetails: {
        team_position_id: null,
        install_time_mins: 0
      },
      showAddCableToProductModal: false
    };
  },
  computed: {
    editProductData: {
        get: function () {
          return this.editProduct;
        },
        set: function () {
       }
    },
    isLabel() {
      if (this.isRakoWiringModule || this.isKeypad || this.numberOfChannels || this.hasProductSpecificQuestions.length > 0) {
          return false; 
      } else return true;
    },
    xeroAccountLinked() {
      return this.$store.state.auth.user.team.xero_account;
    },
    isLinkedToAccountsPackage() {
      return this.$store.getters['integrations/isLinkedToAccountsPackage'];
    },
    linkedIntegrations() {
      return this.$store.state.integrations.linkedIntegrations;
    },
    groupedXeroAccountCodes() {
      let groupedAccounts = [];

      const xeroConfig = this.linkedIntegrations.find(el => el.name === 'Xero');
      xeroConfig.accountCodes.forEach((el) => {

        let name = el.Type;
        if (['DIRECTCOSTS', 'OVERHEADS'].includes(el.Type)) {
          name = 'Expenses';
        } else if (['CURRENT', 'FIXED'].includes(el.Type)) {
          name = 'Assets';
        } else if (['REVENUE'].includes(el.Type)) {
          name = 'Revenue';
        } else if (['EQUITY'].includes(el.Type)) {
          name = 'Equity';
        } else if (['CURRLIAB', 'TERMLIAB'].includes(el.Type)) {
          name = 'Liabilities';
        }

        const index = groupedAccounts.findIndex(elem => elem.name === name);
        if (index !== -1) {
          groupedAccounts[index].accounts.push(el);
        } else { 
          groupedAccounts.push({ name, accounts: [el] });
        }
      });

      return groupedAccounts;
    },
    roomId() {
      return this.$route.params.roomId;
    },
    project() {
      return this.$store.state.project.project;
    },
    projectStages() {
      return this.$store.state.system.projectStages;
    },
    currency() {
      return this.$store.state.auth.user.team.team_currency.symbol;
    },
    hasProductSpecificQuestions() {
      if (!this.editProductData.products.length > 0 || !this.editProductData.products[0].product.system_design || !this.editProductData.products[0].product.system_design.cables ) {
        return false;
      }

      const usCables = this.editProductData.products[0].product.system_design.cables.filter((cab) => cab.purpose === 'User selected');

      return usCables;
    },
    isRakoWiringModule() {
      if (!this.editProductData.products.length > 0 || !this.editProductData.products[0].product.system_design || !this.editProductData.products[0].product.system_design.rakoProductType) {
        return false;
      }
      return this.editProductData.products[0].product.system_design.rakoProductType === 'Wiring Module';
    },
    isKeypad() {
      if (!this.editProductData.products.length > 0 || !this.editProductData.products[0].product.system_design || !this.editProductData.products[0].product.system_design.rakoProductType) {
        return false;
      }
      return this.editProductData.products[0].product.system_design.rakoProductType === 'Keypad';
    },
    numberOfChannels() {
      if (!this.editProductData.products.length > 0 || !this.editProductData.products[0].product.system_design || !this.editProductData.products[0].product.system_design.numberOfChannels) {
        return 0;
      }

      return parseInt(this.editProductData.products[0].product.system_design.numberOfChannels);
    },
    roomExist() {
      return this.$route.params.roomId ? true : false;
    },
    teamPositions() {
      return this.$store.state.system.teamPositions;
    },
    cables() {
      return this.$store.state.system.productSettings ? this.$store.state.system.productSettings.cables : [];
    },
    cablePurposes() {
      return this.$store.state.system.productSettings ? this.$store.state.system.productSettings.cablePurposes : [];
    },
    networkRanges(){
       return this.$store.state.system.productSettings ? this.$store.state.system.productSettings.networkRanges : [];
    },
    projectNetworkRanges() {
      return [this.$store.state.project.project.network_range_1, this.$store.state.project.project.network_range_2, this.$store.state.project.project.network_range_3];
    }
  },
  methods: {
    updateProducts() {
      this.activeAccordion = null;
      this.axios
        .put(`/api/projects/${this.project.id}/room/${this.editProductData.products[0].room_id}/product`, { editProductData: this.editProductData })
        .then((res) => {
          this.$emit('updateProductDetails', res.data);
          this.$notify({
            type: "success",
            title: `${this.editProductData.products[0].product.name} has been successfully updated.`
          });
          this.editProductData = { products: [], installTimeDetails: [] };
          this.$emit("close");
          this.$forceUpdate();
        });
    },
    deleteProduct() {
      this.showDeleteProductModal = true;
    },
    closeDeleteProductModal(closeEditModal = false) {
      this.showDeleteProductModal = false;
      this.$emit("refetchRoom");

      // Close the edit modal if the product deleted was the last product or all products were deleted.
      if (closeEditModal) {
        this.$emit("close");
      } else {
        // Remove the product from the edit products to update the edit modal
        this.editProductData.products.splice(1, 1);
      }
    },
    closeModal() {
      this.activeAccordion = null;
      this.$emit("close");
    },
    hasOption(index) {
      return !!this.editProductData.products[0].upgrade_products[index - 1];
    },
    getDifferenceInPrice(newProduct, currentProduct) {
      const diff = newProduct.customer_price - currentProduct[0].customer_price;
      const posNeg = diff > 0 ? "+" : "-";
      const totalDiff = parseFloat(
        Math.abs(diff * currentProduct.length)
      ).toFixed(2);
      const formattedWithCurrency = this.$options.filters.currency(totalDiff);
      return `${posNeg} ${formattedWithCurrency}`;
    },
    showUpgradeProductModal(currentProduct) {
      this.$emit("showUpgradeProductModal", currentProduct);
    },
    removeUpgradeOption(upgradeProduct) {
      this.axios
        .delete(`/api/projects/remove-upgrade-product/${upgradeProduct.id}`)
        .then(() => {
          this.$emit("upgradeProductRemoved", upgradeProduct.id);
        });
    },
    upgradeToProduct(upgradeProduct) {
      this.upgradingProduct = true;
      this.axios
        .post(
          `/api/projects/${this.project.id}/room/${this.editProductData.products[0].room_id ? this.editProductData.products[0].room_id : this.editProductData.room}/upgrade-product/${upgradeProduct.id}`
        )
        .then(res => {
          this.$emit("productUpgraded", upgradeProduct, res.data);
        })
        .finally(() => {
          this.upgradingProduct = false;
        });
    },
    selectAccordion(accordion) {
      if (this.activeAccordion === accordion) {
        this.activeAccordion = null;
      } else {
        this.activeAccordion = accordion;
      }
    },
    productInstallTime() {
      let installTime = 0;
      this.editProductData.installTimeDetails.forEach(el => {
        installTime += parseInt(el.install_time_mins);
      });
      return installTime;
    },
    productTotalInstallTime() {
      const totalInstallTime =
        this.productInstallTime() * this.editProductData.products.length;
      return totalInstallTime;
    },
    getTotalInstallTypeTime (positionId) {
      const posInstallTimeDetails = this.editProductData.installTimeDetails.find(el => el.id === positionId);
      return (posInstallTimeDetails.install_time_mins * this.editProductData.products.length);
    },
    removeProductFromRoom(product) {
      if (this.roomId) {
         this.$emit("removeProductFromRoom", product);
      }
    },
    removeAllProductFromRoom(product, supplier) {
      if (this.roomId) {
         this.$emit("removeAllProduct", product, supplier);
      }
    },
    closeAddCableToProductModal() {
      this.showAddCableToProductModal = false;
    },
    addCableToProduct(cable, purpose) {
      this.editProductData.cables.push({cable, purpose});
    },
    deleteCable(index) {
      this.editProductData.cables.splice(index, 1);
    }
  }
};
</script>