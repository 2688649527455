<template>
  <div
    v-if="showModal"
    class="modal-window fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center"
    :class="showModal ? 'show' : 'hide'"
  >
    <div
      class="fixed inset-0 transition-opacity"
      :class="showModal ? 'ease-in duration-200 opacity-100' : 'ease-out duration-200 opacity-0'"
    >
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>

    <div
      class="w-1/2 bg-white rounded-lg overflow-hidden shadow-xl transform transition-all"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
      :class="showModal ? 'ease-in duration-200 opacity-100 translate-y-0 sm:scale-100' : 'ease-out duration-300 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'"
    >
      <div class="modal-window-split--content relative">
        <div class="bg-white px-4 pt-5 pb-8 sm:p-8 max-h-full overflow-x-scroll">
          <div
            class="mb-4 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-lime sm:mx-0 sm:h-10 sm:w-10"
          >
            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="pencil w-6 h-6">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
          </div>
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center w-full sm:mt-0 sm:text-left">
              <div class="mt-2">
                <form id="addPlaceholderProduct" @submit.prevent="addPlaceholderProduct">
                  <div class="mb-4">
                    <h4 class="font-bold text-xl">
                      Add a Placeholder Product
                    </h4>
                    <p
                      class="text-sm text-gray-500"
                    >
                      Adding a placeholder product will allow you to add this product to your project now. We will then gather all of the relevant Product information from your chosen Supplier and get it added as a product.
                    </p>
                    <div
                      class="bg-gray-50 px-4 py-3 text-gray-700 rounded-lg shadow-sm mt-4 mb-6 border border-solid text-xs font-bold flex items-center"
                    >
                      <svg
                        class="w-4 h-4 mr-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      Please Note - The information you provide may change based on the information we are given from your chosen Supplier.
                    </div>
                  </div>

                  <div class="mb-4">
                    <label class="form-label mb-2">Quantity</label>
                    <div class="flex">
                      <span class="relative z-0 inline-flex shadow-sm rounded-md">
                        <button
                          type="button"
                          class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-blue-light bg-blue-light text-sm font-medium text-white hover:bg-blue-dark focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                          @click="decreasePlaceholderProdQty"
                        >-</button>
                        <span
                          class="w-16 relative justify-center inline-flex items-center py-2 border border-gray-300 bg-gray-50 text-sm font-medium text-gray-700"
                        >{{ placeholderProduct.qty }}</span>
                        <button
                          type="button"
                          class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-blue-light bg-blue-light text-sm font-medium text-white hover:bg-blue-dark focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                          @click="increasePlaceholderProdQty"
                        >+</button>
                      </span>
                    </div>
                  </div>

                  <div class="grid grid-cols-3 gap-4">
                    <div>
                      <label class="form-label">Supplier</label>
                      <select
                        id="placeholderSupplier"
                        v-model="placeholderProduct.supplier"
                        name="placeholderSupplier"
                        class="select-input"
                        required
                      >
                        <option :value="null" selected>
                          -- Choose Supplier --
                        </option>
                        <option
                          v-for="(supplier, index) in teamsAvailableSuppliers"
                          :key="index"
                        >
                          {{ supplier.name }}
                        </option>
                        <option value="Other">
                          Not yet on Specifi
                        </option>
                      </select>
                    </div>
                    <div v-if="placeholderProduct.supplier === 'Other'">
                      <label class="form-label">Supplier Name</label>
                      <input
                        v-model="placeholderProduct.otherSupplierName"
                        type="text"
                        placeholder="Supplier Name"
                        class="form-input"
                      >
                    </div>
                    <div>
                      <label class="form-label">Product Category</label>
                      <select
                        id="placeholderCategory"
                        v-model="placeholderProduct.category"
                        name="placeholderCategory"
                        class="select-input"
                      >
                        <option :value="null" selected>
                          -- Choose Category --
                        </option>
                        <option
                          v-for="(category, index) in categories"
                          :key="index"
                        >
                          {{ category.name }}
                        </option>
                        <option value="Other">
                          Other
                        </option>
                      </select>
                    </div>
                    <div v-if="placeholderProduct.category === 'Other'">
                      <label class="form-label">Category Name</label>
                      <input
                        v-model="placeholderProduct.otherCategoryName"
                        type="text"
                        placeholder="Category Name"
                        class="form-input"
                      >
                    </div>

                    <div>
                      <label class="form-label">Product Name</label>
                      <input
                        v-model="placeholderProduct.name"
                        type="text"
                        placeholder="Enter Product Name"
                        class="form-input"
                      >
                    </div>
                    <div>
                      <label class="form-label">Brand</label>
                      <input
                        v-model="placeholderProduct.brand"
                        type="text"
                        placeholder="Enter Brand"
                        class="form-input"
                      >
                    </div>
                    <div>
                      <label class="form-label">Retail Price</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <div
                          class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                        >
                          <span class="text-gray-500 sm:text-sm">{{ currency }}</span>
                        </div>
                        <input
                          v-model="placeholderProduct.retailPrice"
                          type="number"
                          step="0.01"
                          name="placeholderRetailPrice"
                          class="form-input pl-7"
                        >
                      </div>
                    </div>
                  </div>

                  <div class="flex justify-end mt-6">
                    <button
                      type="button"
                      class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      @click="closeModal"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      class="ml-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-blue-light hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Add Placeholder Product
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    showModal: Boolean
  },
  data() {
    return {
      placeholderProduct: {
        supplier: null,
        otherSupplierName: null,
        category: null,
        otherCategoryName: null,
        name: null,
        brand: null,
        qty: 1,
        retailPrice: 0,
        tradePrice: 0
      }
    };
  },
  computed: {
    project() {
      return this.$store.state.project.project;
    },
    projectStages() {
      return this.$store.state.system.projectStages;
    },
    currency() {
      return this.$store.state.auth.user.team.team_currency.symbol;
    },
    categories() {
      return this.$store.state.system.categories;
    },
    teamsAvailableSuppliers() {
      const suppliers = this.$store.state.auth.user.team.suppliers;
      const availableSuppliers = [];
      suppliers.map(el => availableSuppliers.push(el.supplier));
      return availableSuppliers;
    },
    roomId() {
      return this.$route.params.roomId;
    }
  },
  methods: {
    addPlaceholderProduct() {
      this.axios
        .post(`/api/projects/${this.project.id}/room/${this.roomId}/add-placeholder-product`, this.placeholderProduct).then(() => {
          this.closeModal();
        });
    },
    increasePlaceholderProdQty() {
      this.placeholderProduct.qty++;
    },
    decreasePlaceholderProdQty() {
      if (this.placeholderProduct.qty > 1) {
        this.placeholderProduct.qty--;
      }
    },
    cancelAddPlaceholderProduct() {
      this.showAddPlaceholderProduct = false;
      this.placeholderProduct = {
        supplier: null,
        otherSupplierName: null,
        category: null,
        otherCategoryName: null,
        name: null,
        brand: null,
        qty: 1,
        retailPrice: 0,
        tradePrice: 0
      };
    },
    closeModal() {
      this.$emit("close");
    }
  }
};
</script>