<template>
  <div v-show="show" class="fixed z-50 inset-0 overflow-y-auto w-screen h-screen">
    <div v-show="show" class="flex items-end justify-center min-h-screen text-center p-0 sm:block">
      <transition
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div v-show="show" class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
      </transition>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <transition
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enter-to-class="opacity-100 translate-y-0 sm:scale-100"
        leave-active-class="ease-in duration-200"
        leave-class="opacity-100 translate-y-0 sm:scale-100"
        leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div v-show="show" 
             class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full"
             :class="{'sm:max-w-lg': size === 'normal','sm:max-w-4xl': size === 'large'}"
             role="dialog" aria-modal="true" aria-labelledby="modal-headline"
        >
          <div v-if="loading" class="absolute bg-white bg-opacity-75 w-full h-full flex items-center justify-center">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
            {{ loadingMsg }}
          </div>
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 w-full sm:mt-0">
                <div class="flex justify-between items-center">
                  <h3 id="modal-headline" class="text-xl leading-6 font-bold text-gray-900">
                    {{ title }}
                  </h3>
                  <button v-if="close" class="focus:outline-none hover:bg-gray-50 p-2 rounded-full" @click="close">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div class="my-6">
                  <slot name="content" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="this.$slots.buttons" class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <slot name="buttons" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
     show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 'normal'
    },
    close: {
      type: Function,
      default: () => 1,
      required: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    loadingMsg: {
      type: String,
      default: '',
      required: false
    }
  }
};
</script>