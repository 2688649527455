<template>
  <div
    v-if="loaded"
    class="modal-window fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center"
    :class="showModal ? 'show' : 'hide'"
  >
    <div
      class="fixed inset-0 transition-opacity"
      :class="showModal ? 'ease-in duration-200 opacity-100' : 'ease-out duration-200 opacity-0'"
    >
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>

    <div
      class="modal-window-split bg-white rounded-lg overflow-hidden shadow-xl transform transition-all"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
      :class="showModal ? 'ease-in duration-200 opacity-100 translate-y-0 sm:scale-100' : 'ease-out duration-300 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'"
    >
      <div class="modal-window-split--product-img bg-gradient">
        <img v-if="product.images" :src="product.images[0].url">
        <img v-if="product.first_image" :src="product.first_image.url">
      </div>

      <div class="modal-window-split--content relative">
        <div class="bg-white px-4 pt-5 pb-8 sm:p-8 max-h-full overflow-x-scroll">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center w-full sm:mt-0 sm:text-left">
              <div class="mt-2">
                <h2 v-if="product && product.has_variants" class="font-medium text-sm xl:text-sm py-2">
                  Please select a Variant:
                </h2>
                <div v-if="product && product.has_variants">
                  <div class="mt-4 border-2 rounded-md overflow-hidden">
                    <div v-for="(productSelect, index) in product.variants" :key="index" class="cursor-pointer bg-blue-dark w-full p-4 text-sm" :class="'border-b-2 border-gray-200'" @click="selectProduct(productSelect)">
                      <div class="flex flex-col sm:flex-row items-center justify-between">
                        <div class="w-full sm:w-3/4 flex items-center mb-4 sm:mb-0">
                          <div v-if="productSelect.first_image" class="flex justify-center items-center w-16 h-16 mr-4 border bg-gray-50 rounded-md">
                            <img class="h-12 w-12 object-contain" :src="productSelect.first_image.url">
                          </div>
                          <div class="w-3/4 text-white">
                            <h4 class="font-medium text-lg text-left">
                              {{ productSelect.name }}
                            </h4>
                            <p class="text-gray-400 text-xs text-left">
                              {{ productSelect.brand }}
                            </p>
                          </div>
                        </div>
                        <span v-if="productSelect.pricing.numAvailableSuppliers" class="w-auto sm:w-1/2 relative z-0 inline-flex justify-end">
                          <div class="relative flex flex-col items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700">
                            <h4
                              class="text-sm text-gray-400"
                            >Retail Price</h4>
                            <h3
                              class="font-bold text-lg"
                            >{{ productSelect.pricing.highestRetailPrice | currency }}</h3>
                          </div>
                        </span>
                        <span v-else class="w-auto sm:w-1/2 relative z-0 inline-flex justify-end">
                          <span
                            class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800"
                          >Available from {{ productSelect.pricing.totalNumSuppliers }} unlinked {{ 'supplier' | pluralize(productSelect.pricing.totalNumSuppliers) }}.</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-else-if="!selectedSupplier">
                  <div class="my-4">
                    <div class="w-full sm:w-3/4 flex items-center mb-4 sm:mb-0">
                      <div v-if="product.first_image || product.images" class="flex justify-center items-center w-16 h-16 mr-4 border bg-gray-50 rounded-md">
                        <img v-if="product.first_image" class="h-12 w-12 object-contain" :src="product.first_image.url">
                      </div>
                      <div class="w-3/4">
                        <h4 class="font-medium text-lg text-left">
                          {{ product.name }}
                        </h4>
                        <p class="text-gray-400 text-xs text-left">
                          {{ product.brand }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <h2 class="font-medium text-sm xl:text-sm py-2">
                    Please select a Supplier:
                  </h2>

                  <div v-if="product.pricing" class="border-2 rounded-md overflow-hidden">
                    <button v-for="(supplier, index) in product.pricing.pricing.linked_suppliers" :key="index" :disabled="!supplier.trade_price" class="p-4 w-full text-sm bg-blue-dark border-2 border-blue-dark hover:border-blue-light cursor-pointer disabled:cursor-not-allowed" :class="index !== product.pricing.pricing.length - 1 && 'border-b-2 border-gray-200'" @click="selectProductSupplier(supplier.supplier)">
                      <div class="flex flex-row items-center justify-between">
                        <div class="w-full sm:w-3/4 flex items-center mb-4 sm:mb-0">
                          <div v-if="supplier.supplier.logo_url" class="flex items-center justify-center w-16 h-16 mr-4 rounded-md overflow-hidden border">
                            <img class="w-12 h-12 object-contain" :src="supplier.supplier.logo_url">
                          </div>
                          <div class="w-3/4 text-left">
                            <h4 class="font-medium text-white text-sm xl:text-lg mb-1">
                              {{ supplier.supplier.name }}
                            </h4>

                            <div v-if="supplier.trade_price" class="flex items-center">
                              <span
                                v-if="supplier.in_stock"
                                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
                              >In Stock</span>
                              <span
                                v-else-if="supplier.special_order === 'Special Order'"
                                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                              >Special Order</span>
                              <span
                                v-else-if="supplier.special_order === 'Pre-Order'"
                                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800"
                              >Pre-Order</span>
                              <span
                                v-else-if="supplier.special_order === 'Back Order'"
                                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-pink-100 text-pink-800"
                              >Back Order</span>
                              <span
                                v-else-if="!supplier.in_stock && supplier.in_stock !== null"
                                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
                              >Out of Stock</span>
                              <span
                                v-else
                                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                              >Stock Levels Not Linked</span>
                            </div>

                            <h5 v-else class="font-medium text-xs text-gray-500">
                              Link your account with
                              <span class="font-bold">{{ supplier.supplier.name }}</span> to select this supplier.
                            </h5>
                          </div>
                        </div>

                        <span class="w-auto sm:w-1/2 relative z-0 inline-flex justify-end">
                          <div class="-ml-px relative flex flex-col items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium rounded-md text-gray-700">
                            <h4 class="text-xs text-gray-400">Retail Price</h4>
                            <h3
                              v-if="supplier.retail_price"
                              class="font-bold text-sm xl:text-lg"
                            >{{ supplier.retail_price | currency }}</h3>
                            <h3 v-else class="hiddenText font-bold text-lg">£123.45</h3>
                          </div>
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
                <div v-else-if="selectedSupplier">
                  <nav aria-label="Back" class="flex justify-start">
                    <button class="btn-primary-md" @click="returnButton()">
                      <svg
                        class="flex-shrink-0 -ml-1 mr-1 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Back
                    </button>
                  </nav>

                  <div class="my-4">
                    <div class="w-full flex justify-between items-center">
                      <div class="w-full flex items-center mb-4 sm:mb-0">
                        <div
                          v-if="product.first_image"
                          class="flex justify-center items-center w-16 h-16 mr-4 border bg-gray-50 rounded-md"
                        >
                          <img class="h-12 w-12 object-contain" :src="product.first_image.url">
                        </div>

                        <div class="w-3/4">
                          <h4 class="font-medium text-lg text-left">
                            {{ product.name }}
                          </h4>
                          <p class="text-gray-400 text-xs text-left">
                            {{ product.brand }}
                          </p>
                        </div>
                      </div>

                      <div v-if="projectExist || selectedRoomId" class="flex justify-center">
                        <span class="relative z-0 inline-flex shadow-sm rounded-md">
                          <button
                            type="button"
                            class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-blue-light bg-blue-light text-sm font-medium text-white hover:bg-blue-dark focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                            @click="decreaseSelProdQty"
                          >-</button>
                          <span
                            class="w-16 relative justify-center inline-flex items-center py-2 border border-gray-300 bg-gray-50 text-sm font-medium text-gray-700"
                          >{{ selectedProductData.qty }}</span>
                          <button
                            type="button"
                            class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-blue-light bg-blue-light text-sm font-medium text-white hover:bg-blue-dark focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                            @click="increaseSelProdQty"
                          >+</button>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div v-if="!projectExist" class="bg-gray-50 p-4 rounded-md shadow border mb-4">
                    <div class="grid grid-cols-6 gap-4">
                      <div class="col-span-6">
                        <label class="block text-xs xl:text-sm font-medium text-gray-700">Select Project</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                          <select id="priority" v-model="selectedProjectId" name="priority" required class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" @change="getProject">
                            <option :value="null" selected disabled>
                              -- Choose Project --
                            </option>
                            <option v-for="(projectName, index) in allProjects" :key="index" :value="projectName.id">
                              {{ projectName.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div v-if="loading" class="col-span-6">
                        <svg id="L4" height="60px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                             viewBox="0 -25 100 100" enable-background="new 0 0 0 0" xml:space="preserve"
                        >
                          <circle fill="#77d183" stroke="none" cx="6" cy="50" r="6">
                            <animate
                              attributeName="opacity"
                              dur="1s"
                              values="0;1;0"
                              repeatCount="indefinite"
                              begin="0.1"
                            />
                          </circle>
                          <circle fill="#85b177" stroke="none" cx="26" cy="50" r="6">
                            <animate
                              attributeName="opacity"
                              dur="1s"
                              values="0;1;0"
                              repeatCount="indefinite"
                              begin="0.2"
                            />
                          </circle>
                          <circle fill="#0e2d29" stroke="none" cx="46" cy="50" r="6">
                            <animate
                              attributeName="opacity"
                              dur="1s"
                              values="0;1;0"
                              repeatCount="indefinite"
                              begin="0.3"
                            />
                          </circle>
                        </svg>
                      </div>
                      <div v-if="selectedProject" class="col-span-6">
                        <label class="block text-xs xl:text-sm font-medium text-gray-700">Select Floor</label>

                        <div class="mt-1 relative rounded-md shadow-sm">
                          <select
                            id="priority"
                            v-model="selectedFloor"
                            name="priority"
                            required
                            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            @change="selectedRoomId = null, activeAccordion = null"
                          >
                            <option :value="null" selected disabled>
                              -- Choose Floor --
                            </option>
                            <option
                              v-for="(optionFloor, index) in selectedProject.floors"
                              :key="index"
                              :value="optionFloor"
                            >
                              {{ optionFloor.name }}
                            </option>
                          </select>
                        </div>
                      </div>


                      <div v-if="selectedFloor" class="col-span-6">
                        <label
                          for="installTime"
                          class="block text-xs xl:text-sm font-medium text-gray-700"
                        >Select Room</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                          <select
                            id="priority"
                            v-model="selectedRoomId"
                            name="priority"
                            required
                            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            @change="activeAccordion = null"
                          >
                            <option :value="null" selected disabled>
                              -- Choose Floor --
                            </option>
                            <option
                              v-for="(room, index) in selectedFloor.rooms"
                              :key="index"
                              :value="room.id"
                            >
                              {{ room.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="projectExist || selectedRoomId" class="border divide-y divide-gray-700 mt-10">
                    <div class="rounded-t-sm bg-white overflow-hidden">
                      <div class="flex relative justify-between items-center p-4 text-white bg-black cursor-pointer" @click="selectAccordion('global-properties')">
                        <div class="absolute inset-0 bg-gradient-to-br from-blue-dark via-green-dark to-blue-dark opacity-75" />

                        <div class="flex relative z-20 items-center">
                          <div class="flex-shrink-0 p-3 bg-blue-light bg-opacity-25 rounded-sm">
                            <HeroIcon icon="Globe" class="w-6 h-6 text-blue-light" />
                          </div>

                          <div class="ml-4">
                            <h3 class="text-lg font-medium">
                              Global Properties
                            </h3>
                            <p class="text-xs">
                              Anything set here will affect all Products in this Project. Some settings like 'Product Description' will impact all Projects.
                            </p>
                          </div>
                        </div>

                        <div class="flex relative z-20 justify-center items-center w-6 h-6 bg-blue-light rounded-sm">
                          <svg v-if="activeAccordion === 'global-properties'" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                          </svg>
                          <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                          </svg>
                        </div>
                      </div>
                      <div v-show="activeAccordion === 'global-properties'" class="bg-gray-50 px-4 py-6 grid grid-cols-6 gap-4">
                        <div class="col-span-6">
                          <div class="flex justify-between">
                            <label for="product_description" class="block text-xs xl:text-sm font-medium text-gray-700">Product Description</label>
                            <span class="text-xs text-gray-500">Optional</span>
                          </div>
                          <div class="mt-1">
                            <input v-model="selectedProductData.description" type="text" name="product_description" class="form-input" placeholder="e.g. In-Ceiling Speaker" aria-describedby="product-name-override">
                          </div>
                          <p class="mt-2 text-xs text-gray-500">
                            A brief description of what the product is, shown on the Customers Sales Quote.
                          </p>
                        </div>

                        <div class="col-span-6">
                          <div class="flex justify-between">
                            <label for="name_override" class="block text-xs xl:text-sm font-medium text-gray-700">Product Name Override</label>
                            <span class="text-xs text-gray-500">Optional</span>
                          </div>
                          <div class="mt-1">
                            <input v-model="product.name_override" type="text" name="name_override" class="form-input" placeholder="e.g. In-Ceiling Speaker" aria-describedby="product-name-override">
                          </div>
                          <p class="mt-2 text-xs text-gray-500">
                            The Product Name shown on the Customers Sales Quote.
                          </p>
                        </div>

                        <div class="col-span-3">
                          <label for="customer_price" class="block text-xs xl:text-sm font-medium text-gray-700">Customer Price</label>
                          <div class="mt-1 relative rounded-md shadow-sm">
                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <span class="text-gray-500 sm:text-sm">
                                {{ currency }}
                              </span>
                            </div>
                            <input id="customer_price" v-model="selectedProductData.customerPrice" type="number" step="0.01" name="customer_price" class="form-input pl-7">
                          </div>
                          <p class="mt-2 text-xs text-gray-500">
                            This will be the price regardless of Supplier.
                          </p>
                        </div>

                        <div v-if="projectStages.length > 0" class="col-span-3">
                          <label for="project_stage" class="block text-xs xl:text-sm font-medium text-gray-700">Project Install Stage</label>
                          <select id="project_stage" v-model="selectedProductData.installStage" name="project_stage" required class="select-input">
                            <option :value="null" selected disabled>
                              -- Choose Project Stage --
                            </option>
                            <option v-for="(projectStage, index) in projectStages" :key="index" :value="projectStage.id">
                              {{ projectStage.name }}
                            </option>
                          </select>
                          <p class="mt-2 text-xs text-gray-500">
                            The stage at which this Product will be installed.
                          </p>
                        </div>

                        <div class="flex col-span-3 justify-between items-center py-4 cursor-pointer">
                          <div class="flex flex-col cursor-pointer" @click="selectedProductData.show_on_sales_quote = !selectedProductData.show_on_sales_quote">
                            <p class="form-label">
                              Show as Line Item on Sales Quote
                            </p>
                            <p class="mt-2 text-xs text-gray-500">
                              If hidden, item will be shown in Additional Costs section.
                            </p>
                          </div>
                          <button
                            type="button"
                            :class="selectedProductData.show_on_sales_quote ? 'bg-blue-light' : 'bg-gray-200'"
                            class="inline-flex relative flex-shrink-0 ml-4 w-11 h-6 rounded-full border-2 border-transparent focus:ring-2 focus:ring-blue-light focus:ring-offset-2 transition-colors duration-200 ease-in-out cursor-pointer focus:outline-none"
                            @click="selectedProductData.show_on_sales_quote = !selectedProductData.show_on_sales_quote"
                          >
                            <span :class="selectedProductData.show_on_sales_quote ? 'translate-x-5' : 'translate-x-0'" class="inline-block w-5 h-5 bg-white rounded-full ring-0 shadow transition duration-200 ease-in-out transform" />
                          </button>
                        </div>

                        <div v-if="networkRanges && networkRanges.length > 0" class="col-span-3">
                          <label for="network_category" class="form-label">Network IP</label>
                          <select id="network_category" v-model="selectedProductData.networkRange" name="network_category" class="select-input">
                            <option :value="null" selected>
                              -- Choose Network Range --
                            </option>
                            <option v-for="(avNetworkRange, index) in networkRanges" :key="index" :value="avNetworkRange">
                              {{ avNetworkRange.category }} ({{ projectNetworkRanges[0] }}.{{ projectNetworkRanges[1] }}.{{ projectNetworkRanges[2] }}[{{ avNetworkRange.start }} - {{ avNetworkRange.end }}])
                            </option>
                          </select>
                          <div class="inline-flex items-center my-3 text-xs text-gray-500">
                            <input id="saveAsDefaultNetworkRange" v-model="saveNetworkRangeAsDefault" name="saveAsDefaultNetworkRange" :value="false" type="checkbox" class="w-3 h-3 text-blue-light focus:ring-blue-light">
                            <label for="saveAsDefaultNetworkRange" class="ml-2 text-xs text-gray-700">
                              Make this the default Network Range for <span class="font-bold">{{ selectedProductData.name }}</span>.
                            </label>
                          </div>
                          <span class="inline-flex items-center py-1 px-2 text-xs text-white bg-blue-dark rounded-sm">
                            <HeroIcon icon="InformationCircle" class="mr-2 w-4 h-4 text-blue-light" /> Note: Previously specified {{ selectedProductData.name }} in existing Projects will not be affected.
                          </span>
                        </div>

                        <div class="block col-span-6" aria-hidden="true">
                          <div class="py-5">
                            <div class="border-t border-gray-200" />
                          </div>
                        </div>

                        <div class="col-span-6">
                          <div class="flex justify-between items-center mb-2">
                            <div>
                              <h3 class="text-sm font-medium text-gray-700">
                                Cables
                              </h3>
                              <p class="mt-1 text-xs text-gray-500">
                                Cables specified here will appear on your cable schedule.
                              </p>
                            </div>

                            <button type="button" class="btn-primary-sm" @click="showAddCableToProductModal = true">
                              <HeroIcon icon="Plus" class="mr-1 -ml-1 w-4 h-4" />
                              Add Cable
                            </button>
                          </div>

                          <div class="flex items-start p-4 my-4 bg-blue-dark rounded-md border">
                            <div class="flex items-center h-5">
                              <input id="saveAsDefaultCables" v-model="saveCablesAsDefault" name="saveAsDefaultCables" :value="true" type="checkbox" class="w-4 h-4 text-blue-light rounded border-blue-dark focus:ring-blue-light">
                            </div>
                            <div class="ml-3 text-sm">
                              <label for="saveAsDefaultCables" class="font-bold text-white">Save as Product Default</label>
                              <p class="text-xs text-gray-50">
                                These cables will become the default for <span class="font-bold">{{ selectedProductData.name }}</span>.
                              </p>
                              <span class="inline-flex items-center py-1 px-2 mt-2 text-xs text-white bg-blue-light bg-opacity-25 rounded-md">
                                <HeroIcon icon="InformationCircle" class="mr-2 w-4 h-4 text-lime" /> Note: Previously specified {{ selectedProductData.name }} in existing Projects will not be affected.
                              </span>
                            </div>
                          </div>

                          <div class="flex flex-col">
                            <div class="overflow-x-auto -my-2 sm:-mx-6 lg:-mx-8">
                              <div class="inline-block py-2 sm:px-6 lg:px-8 min-w-full align-middle">
                                <div class="overflow-hidden sm:rounded-lg border border-gray-200">
                                  <table class="min-w-full divide-y divide-gray-200">
                                    <thead class="table-head">
                                      <tr>
                                        <th scope="col" class="table-heading">
                                          Cable
                                        </th>
                                        <th scope="col" class="table-heading">
                                          Purpose
                                        </th>
                                        <th scope="col" class="relative py-3 px-6">
                                          <span class="sr-only">Edit</span>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody v-if="selectedProductData.cables && selectedProductData.cables.length > 0">
                                      <tr v-for="(cable, cableIdx) in selectedProductData.cables" :key="cableIdx" class="bg-white" :class="(cableIdx % 2) === 0 ? 'bg-white' : 'bg-gray-50'">
                                        <td class="py-3 px-6 text-xs font-medium text-gray-900 whitespace-nowrap">
                                          {{ cable.cable }}
                                        </td>
                                        <td class="py-3 px-6 text-xs text-gray-500 whitespace-nowrap">
                                          {{ cable.purpose }}
                                        </td>
                                        <td class="py-3 px-6 text-sm font-medium text-right whitespace-nowrap">
                                          <button type="button" class="p-2 rounded-full" @click="deleteCable(cableIdx)">
                                            <svg class="w-5 h-5 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                            </svg>
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tbody v-else>
                                      <tr class="bg-white">
                                        <td colspan="3" class="py-4 px-6 text-xs font-medium text-center text-gray-500 whitespace-nowrap">
                                          No Cables
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="rounded-b-sm bg-white overflow-hidden">
                      <div class="flex relative justify-between items-center p-4 text-white bg-black cursor-pointer" @click="selectAccordion('installation-time')">
                        <div class="absolute inset-0 bg-gradient-to-br from-blue-dark via-green-dark to-blue-dark opacity-75" />

                        <div class="flex relative z-20 items-center">
                          <div class="flex-shrink-0 p-3 bg-blue-light bg-opacity-25 rounded-sm">
                            <HeroIcon icon="Clock" class="w-6 h-6 text-blue-light" />
                          </div>

                          <div class="ml-4">
                            <h3 class="text-lg font-medium">
                              Installation Time
                            </h3>
                            <p class="text-xs">
                              Setting an installation time will allow you to better quote for your labour.
                            </p>
                          </div>
                        </div>

                        <div class="flex relative z-20 justify-center items-center w-6 h-6 bg-blue-light rounded-sm">
                          <svg v-if="activeAccordion === 'installation-time'" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                          </svg>
                          <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                          </svg>
                        </div>
                      </div>

                      <div v-show="activeAccordion === 'installation-time'" class="bg-gray-50 px-4 py-6">
                        <p class="text-xs xl:text-sm text-gray-600">
                          This will be the installation times for all occurances of a <span class="text-xs xl:text-base text-blue-light font-bold">{{ product.name }}</span> in this room.
                        </p>
                        <div v-if="teamPositions && teamPositions.length > 0">
                          <div class="flex items-center p-4 my-6 bg-gradient-to-br from-black via-green-dark to-black rounded-sm">
                            <input id="saveAsDefaultInstallTime" v-model="updateDefaultInstallInfo" type="checkbox" :value="true" class="focus:ring-indigo-500 text-blue-dark border-gray-300 rounded mr-4">
                            <div class="flex flex-col">
                              <label for="saveAsDefaultInstallTime" class="text-xs xl:text-base text-white font-bold">Save as Install Time Defaults</label>
                              <p class="text-xxs xl:text-xs text-gray-200">
                                This will update your default install time(s) for <span class="text-xs xl:text-base text-blue-light font-bold">{{ product.name }}</span>.
                              </p>
                              <p class="text-xxs xl:text-xs text-gray-200">
                                <span class="text-xs xl:text-base font-bold">Note:</span> Previously added install times in other Projects will remain unaffected.
                              </p>
                            </div>
                          </div>

                          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="w-full divide-y divide-gray-200">
                              <thead class="bg-gray-50">
                                <tr>
                                  <th scope="col" class="px-2 xl:px-6 py-3 text-left text-xxs xl:text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Installation Type
                                  </th>
                                  <th scope="col" class="px-2 xl:px-6 py-3 text-left text-xxs xl:text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Install Time
                                  </th>
                                  <th scope="col" class="px-2 xl:px-6 py-3 text-left text-xxs xl:text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Total Install Time
                                  </th>
                                </tr>
                              </thead>
                              <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(installTime, posIdx) in selectedProductData.installTimeDetails" :key="posIdx">
                                  <td class="px-2 xl:px-6 py-4 whitespace-nowrap text-xxs xl:text-sm font-medium text-gray-900">
                                    {{ installTime.position }}
                                  </td>
                                  <td class="px-2 xl:px-6 py-4 whitespace-nowrap text-right text-xxs xl:text-sm font-medium text-gray-900">
                                    <div class="flex rounded-md shadow-sm">
                                      <input v-model="installTime.install_time_mins" type="number" class="text-right form-input mt-0 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-l-md text-xxs xl:text-sm border-gray-300">
                                      <span class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-xxs xl:text-sm">
                                        {{ 'minute' | pluralize(installTime.install_time_mins) }}
                                      </span>
                                    </div>
                                  </td>
                                  <td class="px-2 xl:px-6 py-4 whitespace-nowrap text-right text-xxs xl:text-sm font-medium text-gray-900">
                                    {{ 'minute' | pluralize(getTotalInstallTypeTime(installTime.id), true) }}
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot class="bg-gray-50">
                                <tr>
                                  <td scope="col" class="px-2 xl:px-6 py-3 text-left text-xxs xl:text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Total Install Time:
                                  </td>
                                  <td scope="col" class="px-2 xl:px-6 py-3 text-right text-xxs xl:text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    {{ 'minute' | pluralize(productInstallTime(), true) }}
                                  </td>
                                  <td scope="col" class="px-2 xl:px-6 py-3 text-right text-xxs xl:text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    {{ 'minute' | pluralize(productTotalInstallTime(), true) }}
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                        <div v-else>
                          You haven't added any team positions. To associate install time with products, add at least one Team Position.
                        </div>
                      </div>
                    </div>
                    <div v-if="isLinkedToAccountsPackage" class="overflow-hidden bg-white rounded-b-sm">
                      <div class="flex relative justify-between items-center p-4 text-white bg-black cursor-pointer" @click="selectAccordion('xero-settings')">
                        <div class="absolute inset-0 bg-gradient-to-br from-blue-dark via-green-dark to-blue-dark opacity-75" />

                        <div class="flex relative z-20 items-center">
                          <div class="flex-shrink-0 p-3 bg-blue-light bg-opacity-25 rounded-sm">
                            <svg class="w-6 h-6" viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
                              <path fill="#13B5EA" d="M22.457 45.49c12.402 0 22.456-10.072 22.456-22.495C44.913 10.57 34.86.5 22.457.5 10.054.5 0 10.57 0 22.995 0 35.418 10.054 45.49 22.457 45.49" />
                              <path fill="#fff" d="M10.75 22.935l3.832-3.85a.688.688 0 0 0-.977-.965l-3.83 3.833-3.845-3.84a.687.687 0 0 0-.966.979l3.832 3.837-3.83 3.84a.688.688 0 1 0 .964.981l3.84-3.842 3.825 3.827a.685.685 0 0 0 1.184-.473.68.68 0 0 0-.2-.485l-3.83-3.846m22.782.003c0 .69.56 1.25 1.25 1.25a1.25 1.25 0 0 0-.001-2.5c-.687 0-1.246.56-1.246 1.25m-2.368 0c0-1.995 1.62-3.62 3.614-3.62 1.99 0 3.613 1.625 3.613 3.62s-1.622 3.62-3.613 3.62a3.62 3.62 0 0 1-3.614-3.62m-1.422 0c0 2.78 2.26 5.044 5.036 5.044s5.036-2.262 5.036-5.043c0-2.78-2.26-5.044-5.036-5.044a5.046 5.046 0 0 0-5.036 5.044m-.357-4.958h-.21c-.635 0-1.247.2-1.758.595a.696.696 0 0 0-.674-.54.68.68 0 0 0-.68.684l.002 8.495a.687.687 0 0 0 1.372-.002v-5.224c0-1.74.16-2.444 1.648-2.63.14-.017.288-.014.29-.014.406-.015.696-.296.696-.675a.69.69 0 0 0-.69-.688m-13.182 4.127c0-.02.002-.04.003-.058a3.637 3.637 0 0 1 7.065.055H16.2zm8.473-.13c-.296-1.403-1.063-2.556-2.23-3.296a5.064 5.064 0 0 0-5.61.15 5.098 5.098 0 0 0-1.973 5.357 5.08 5.08 0 0 0 4.274 3.767c.608.074 1.2.04 1.81-.12a4.965 4.965 0 0 0 1.506-.644c.487-.313.894-.727 1.29-1.222.006-.01.014-.017.022-.027.274-.34.223-.826-.077-1.056-.254-.195-.68-.274-1.014.156-.072.104-.153.21-.24.315-.267.295-.598.58-.994.802-.506.27-1.08.423-1.69.427-1.998-.023-3.066-1.42-3.447-2.416a3.716 3.716 0 0 1-.153-.58l-.01-.105h7.17c.982-.022 1.51-.717 1.364-1.51z" />
                            </svg>
                          </div>

                          <div class="ml-4">
                            <h3 class="text-lg font-medium">
                              Xero Settings
                            </h3>
                            <p class="text-xs">
                              Link the Products in this Project to a Xero Account and Tax Rate.
                            </p>
                          </div>
                        </div>

                        <div class="flex relative z-20 justify-center items-center w-6 h-6 bg-blue-light rounded-sm">
                          <svg v-if="activeAccordion === 'xero-settings'" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                          </svg>
                          <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                          </svg>
                        </div>
                      </div>
                      <div v-show="activeAccordion === 'xero-settings'" class="grid grid-cols-6 gap-4 py-6 px-4">
                        <div class="col-span-6">
                          <h3 class="text-lg font-semibold">
                            Xero Settings
                          </h3>
                        </div>
                        <div class="col-span-3">
                          <label class="form-label">Purchasing Account</label>
                          <select v-model="selectedProductData.accountCode" class="mb-2 select-input" required>
                            <option :value="null" selected disabled>
                              Choose Account
                            </option>
                            <optgroup v-for="(group, groupIndex) in groupedXeroAccountCodes" :key="groupIndex" :label="group.name">
                              <option v-for="(code, codeIdx) in group.accounts" :key="codeIdx" :value="code.Code">
                                {{ code.Code }} - {{ code.Name }}
                              </option>
                            </optgroup>
                          </select>

                          <div class="flex items-center">
                            <input id="save_account_code" v-model="saveAccountCodeAsDefault" name="save_account_code" type="checkbox" class="text-blue-light rounded-sm" :value="true">
                            <label for="save_account_code" class="block ml-2 text-xs text-gray-700">
                              Always use this Account for Purchases
                            </label>
                          </div>
                        </div>
                        <div v-if="linkedIntegrations.find(el => el.name === 'Xero')" class="col-span-3">
                          <label class="form-label">Tax Rate</label>
                          <select v-model="selectedProductData.taxRate" class="mb-2 select-input" required>
                            <option :value="null" selected disabled>
                              Choose Tax Rate
                            </option>
                            <option v-for="(taxRate, taxRateIdx) in linkedIntegrations.find(el => el.name === 'Xero').taxRates" :key="`tax_${taxRateIdx}`" :value="taxRate.TaxType">
                              {{ taxRate.Name }}
                            </option>
                          </select>

                          <div class="flex items-center">
                            <input id="save_tax_rate" v-model="saveTaxRateAsDefault" name="save_tax_rate" class="text-blue-light rounded-sm" type="checkbox" :value="true">
                            <label for="save_tax_rate" class="block ml-2 text-xs text-gray-700">
                              Always use this Tax Rate for Purchases
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="hasProductSpecificQuestions"
                      class="grid grid-cols-2 sm:grid-cols-3 gap-4"
                    >
                      <div v-for="(item, index) in parseInt(selectedProductData.qty || 0)" :key="index">
                        <div v-if="product.system_design.numberOfSpeakers === 'Pair'">
                          <div class="input-group">
                            <label class="block text-xs xl:text-sm font-medium text-gray-700 mt-2">Location 1</label>
                            <input
                              v-model="selectedProductData.details.itemSpecific[index].position"
                              type="text"
                              class="form-input"
                            >
                          </div>
                          <div class="input-group">
                            <label class="block text-xs xl:text-sm font-medium text-gray-700 mt-2">Location 2</label>
                            <input
                              v-model="selectedProductData.details.itemSpecific[index].position2"
                              type="text"
                              class="form-input"
                            >
                          </div>
                        </div>
                        <div v-else-if="product.system_design.numberOfSpeakers">
                          <label
                            class="block text-xs xl:text-sm font-medium text-gray-700 mt-4"
                          >Input {{ item }} Purpose</label>
                          <input v-model="selectedProductData.details.itemSpecific[index].position" type="text" class="form-input">
                        </div>
                      </div>
                    </div>

                    <!-- Module Name -->
                    <div v-if="isRakoWiringModule" class="grid grid-cols-3 gap-4">
                      <div v-for="(item, idx) in parseInt(selectedProductData.qty || 0)" :key="idx">
                        <label>Module {{ idx + 1 }} Name</label>
                        <br>
                        <input
                          v-model="selectedProductData.details.itemSpecific[idx].moduleName"
                          type="text"
                          :placeholder="`Enter Module ${idx + 1} Name`"
                          class="form-input"
                        >
                      </div>
                    </div>

                    <!-- Keypad Name -->
                    <div v-if="isKeypad" class="grid grid-cols-3 gap-4">
                      <div
                        v-for="(item, index) in parseInt(selectedProductData.qty || 0)"
                        :key="item"
                      >
                        <label>Keypad Name {{ index + 1 }}</label>
                        <input
                          v-model="selectedProductData.details.itemSpecific[idx].name"
                          type="text"
                          placeholder="Enter Keypad Name"
                          class="form-input"
                        >
                      </div>
                    </div>

                    <!-- Channels -->
                    <div v-for="(item, index) in parseInt(selectedProductData.qty || 0)" :key="index" class="grid grid-cols-3 gap-4">
                      <div v-for="(idx, t) in numberOfChannels" :key="idx" class="row conts--input">
                        <label>Channel Name {{ idx }}</label>
                        <input v-model="selectedProductData.details.itemSpecific[idx].channels[t]" type="text" placeholder="Enter channel name" class="form-input">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white flex justify-end mt-6 bg-opacity-75">
            <button type="button" class="mt-4 btn-primary-outline-md mr-2" @click="closeModal">
              Cancel
            </button>
            <button v-if="selectedSupplier && (projectExist || selectedRoomId)" type="button" class="mt-4 btn-primary-md" @click="addProductToProject">
              <svg class="flex-shrink-0 -ml-1 mr-2 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z" />
              </svg>
              Add to Project
            </button>
          </div>
        </div>
      </div>
    </div>

    <AddCableToProductModal
      form-name="addCableToProduct"
      :show="showAddCableToProductModal"
      :close="closeAddCableToProductModal"
      :cables="cables"
      :cable-purposes="cablePurposes"
      @addCableToProduct="addCableToProduct"
    />
  </div>
</template>

<script>
import AddCableToProductModal from './AddCableToProductModal.vue';
export default {
  components: {
    AddCableToProductModal
  },
  props: {
    showModal: Boolean,
    quickAdd: {
      type: Boolean,
      required: false,
      default: false
    },
    product: {
      type: Object,
      default: () => {},
      required: true
    },
    roomId:  {
      type: Number,
      default: 0,
      required: false
    },
    selectedProductData:  {
      type: Object,
      default: () => {},
      required: false
    },
    floor:  {
      type: Number,
      default: 0,
      required: false
    },
    isCustomProduct: Boolean
  },
  data() {
    return {
      activeAccordion: null,
      selectedSupplier: null,
      loaded: true,
      showInfoModal: false,
      infoModalTitle: "",
      infoModalText: "",
      selectedProjectId: null,
      selectedRoomId: null,
      selectedProject: null,
      selectedFloor: null,
      allProjects: null,
      loading: false,
      updateDefaultInstallInfo: false,
      showAddCableToProductModal: false,
      saveCablesAsDefault: true,
      saveNetworkRangeAsDefault: false,
      saveAccountCodeAsDefault: false,
      saveTaxRateAsDefault: false
    };
  },
  computed: {
    xeroAccountLinked() {
      return this.$store.state.auth.user.team.xero_account;
    },
    isLinkedToAccountsPackage() {
      return this.$store.getters['integrations/isLinkedToAccountsPackage'];
    },
    linkedIntegrations() {
      return this.$store.state.integrations.linkedIntegrations;
    },
    groupedXeroAccountCodes() {
      let groupedAccounts = [];

      const xeroConfig = this.linkedIntegrations.find(el => el.name === 'Xero');
      if(xeroConfig){
        xeroConfig.accountCodes.forEach((el) => {

          let name = el.Type;
          if (['DIRECTCOSTS', 'OVERHEADS'].includes(el.Type)) {
            name = 'Expenses';
          } else if (['CURRENT', 'FIXED'].includes(el.Type)) {
            name = 'Assets';
          } else if (['REVENUE'].includes(el.Type)) {
            name = 'Revenue';
          } else if (['EQUITY'].includes(el.Type)) {
            name = 'Equity';
          } else if (['CURRLIAB', 'TERMLIAB'].includes(el.Type)) {
            name = 'Liabilities';
          }

          const index = groupedAccounts.findIndex(elem => elem.name === name);
          if (index !== -1) {
            groupedAccounts[index].accounts.push(el);
          } else {
            groupedAccounts.push({ name, accounts: [el] });
          }
        });
      }
      return groupedAccounts;
    },
    cables() {
      return this.$store.state.system.productSettings ? this.$store.state.system.productSettings.cables : [];
    },
    cablePurposes() {
      return this.$store.state.system.productSettings ? this.$store.state.system.productSettings.cablePurposes : [];
    },
    networkRanges(){
       return this.$store.state.system.productSettings ? this.$store.state.system.productSettings.networkRanges : [];
    },
    projectNetworkRanges() {
      return [this.$store.state.project.project.network_range_1, this.$store.state.project.project.network_range_2, this.$store.state.project.project.network_range_3];
    },
    teamPositions () {
      return this.$store.state.system.teamPositions;
    },
    projectExist(){
      return this.$route.params.projectId ? true : false;
    },
    projectStages() {
      return this.$store.state.system.projectStages;
    },
    currency() {
      return this.$store.state.auth.user.team.team_currency.symbol;
    },
    project() {
      return this.$store.state.project.project;
    },
    favouriteProducts() {
      return this.$store.state.system.favouriteProducts;
    },
    customProducts() {
      return this.$store.state.system.customProducts;
    },
    hasProductSpecificQuestions() {
      if (
        !this.product ||
        !this.product.system_design ||
        !this.product.system_design.cables
      ) {
        return false;
      }

      const usCables = this.product.system_design.cables.filter(
        cable => cable.purpose === "User Selected"
      );

      for (let index = 0; index < this.selectedProductData.qty; index++) {
        if (!this.selectedProductData.details.itemSpecific[index]) {
          const additionalInfo =
            this.product.system_design.numberOfSpeakers === "Pair"
              ? { position: null, position2: null }
              : { position: null };
          this.$set(
            this.selectedProductData.details.itemSpecific,
            index,
            additionalInfo
          );
        }
      }

      return usCables;
    },
    isRakoWiringModule() {
      if (
        !this.product ||
        !this.product.inputs_outputs ||
        !this.product.inputs_outputs.inputs
      ) {
        return false;
      }

      const inputs =
        this.product.inputs_outputs.inputs.filter(input =>
          ["RAK8 modules", "RxLink input"].includes(input.type)
        ).length > 0;
      if (inputs) {
        for (let index = 0; index < this.selectedProductData.qty; index++) {
          this.$set(
            this.selectedProductData.details.itemSpecific,
            index,
            {}
          );
        }
      }
      return inputs;
    },
    isKeypad() {
      if (
        !this.product ||
        !this.product.system_design ||
        !this.product.system_design.rakoProductType ||
        this.product.system_design.rakoProductType !== "Keypad"
      ) {
        return false;
      }

      for (let index = 0; index < this.selectedProductData.qty; index++) {
        this.$set(this.selectedProductData.details.itemSpecific, index, {});
      }

      return true;
    },
    numberOfChannels() {
      if (
        !this.product ||
        !this.product.system_design ||
        !this.product.system_design.numberOfChannels
      ) {
        return 0;
      }

      for (let index = 0; index < this.selectedProductData.qty; index++) {
        this.$set(this.selectedProductData.details.itemSpecific, index, {
          channels: {}
        });
      }

      return parseInt(this.product.system_design.numberOfChannels);
    }
  },
  mounted() {
    this.getProjects();
  },
  methods: {
     getProject() {
      this.activeAccordion = null;
      this.selectedProject = null;
      this.selectedFloor = null;
      this.selectedRoomId = null;
      this.loading = true;
      this.axios.get(`/api/projects/${this.selectedProjectId}/design`).then((response) => {
        this.selectedProject = {
          id: response.data.id,
          name: response.data.name,
          floors: response.data.floors,
          network_range_1: response.data.network_range_1,
          network_range_2: response.data.network_range_2,
          network_range_3: response.data.network_range_3,
          stage: response.data.stage
        };
      }).finally(() => {
        this.loading = false;
      });
    },
    getProjects() {
      this.axios.get(`/api/projects/all-projects`).then((projects) => {
          this.allProjects = projects.data;
      });
    },
    calculateMargin(tradePrice, retailPrice) {
      const taxRate = this.$store.state.auth.taxRate.product + 1;
      return Math.abs(retailPrice / taxRate - tradePrice);
    },
    selectProduct(product) {
      this.product = product;
      this.selectedProductData.installTimeMins = product.install_time || 0;
      this.selectedProductData.installStage = product.install_stage || null;
      this.selectedProductData.description = product.product_description || null;

       // Setup Install Times
       this.teamPositions.forEach((el) => {
          const details = { ...el };
          details.install_time_mins = 0;
          const defaultInstallTime = product.team_install_info_default.find(s => s.team_position_id === details.id);
          details.install_time_mins = defaultInstallTime ? defaultInstallTime.install_time_mins : 0;
          this.selectedProductData.installTimeDetails.push(details);
       });
    },
    selectProductSupplier(supplier) {
      const supplierPricing = this.product.pricing.pricing.linked_suppliers.find(el => el.supplier_id === supplier.id);
      this.selectedProductData.customerPrice = parseFloat(supplierPricing.retail_price).toFixed(2);
      this.selectedProductData.tradePrice = parseFloat(supplierPricing.trade_price).toFixed(2);
      this.selectedSupplier = supplier;
    },
    decreaseSelProdQty() {
      if (this.selectedProductData.qty > 1) {
        this.selectedProductData.qty--;
      }
    },
    increaseSelProdQty() {
      this.selectedProductData.qty++;
    },
    addProductToProject() {
      this.productDetails = {
        qty: this.selectedProductData.qty,
        id: this.product.id,
        supplier: {
          supplier_id: this.selectedSupplier.id,
          supplier_type: this.selectedSupplier.supplier_type
        },
        details: this.selectedProductData.details,
        customer_price: this.selectedProductData.customerPrice,
        trade_price: this.selectedProductData.tradePrice,
        name_override: this.product.name_override,
        updateDefaultInstallInfo: this.updateDefaultInstallInfo,
        install_info: this.selectedProductData.installTimeDetails,
        install_stage: this.selectedProductData.installStage,
        description: this.selectedProductData.description,
        show_on_sales_quote: this.selectedProductData.show_on_sales_quote,
        accountCode: this.selectedProductData.accountCode,
        taxRate: this.selectedProductData.taxRate,
        saveAccountCodeAsDefault: this.saveAccountCodeAsDefault,
        saveTaxRateAsDefault: this.saveTaxRateAsDefault
      };

      this.productDetails.saveCablesAsDefault = this.saveCablesAsDefault;
      this.productDetails.cables = this.selectedProductData.cables;

      this.productDetails.saveNetworkRangeAsDefault = this.saveNetworkRangeAsDefault;
      this.productDetails.networkRange = this.selectedProductData.networkRange;

      let projectId, roomId;
      projectId = this.projectExist ? this.project.id : this.selectedProjectId;
      roomId = this.projectExist ? this.roomId : this.selectedRoomId;

      this.axios.post(`/api/projects/${projectId}/room/${roomId}/add-product`, this.productDetails).then(() => {
        this.$notify({
          type: "success",
          title: "Product Added",
          text: `${this.productDetails.qty} x ${this.product.name} successfully added`
        });

        this.$forceUpdate();
        this.closeModal();
        this.$router.push({ name: "projectRoomProducts", params: { projectId, roomId } });
      }).catch((error) => {
        this.$notify({
          type: "error",
          title: "Something went wrong",
          text: 'Please check the data and try again'
        });
      });
    },
    returnButton() {
      this.activeAccordion = null;
      this.selectedSupplier = null;
      this.selectedProjectId = null;
      this.selectedProject = null;
      this.selectedFloor = null;
      this.selectedRoomId = null;
    },
    closeModal() {
      this.activeAccordion = null;
      this.selectedSupplier = null;
      this.selectedProjectId = null;
      this.selectedProject = null;
      this.selectedFloor = null;
      this.selectedRoomId = null;
      this.$emit("closeModal");
    },
     selectAccordion (accordion) {
      if (this.activeAccordion === accordion) {
        this.activeAccordion = null;
      } else {
        this.activeAccordion = accordion;
      }
    },
    productInstallTime () {
      let installTime = 0;
      this.selectedProductData.installTimeDetails.forEach((el) => {
        installTime += parseInt(el.install_time_mins);
      });
      return installTime;
    },
    productTotalInstallTime () {
      const totalInstallTime = this.productInstallTime() * this.selectedProductData.qty;
      return totalInstallTime;
    },
    getTotalInstallTypeTime (positionId) {
      const posInstallTimeDetails = this.selectedProductData.installTimeDetails.find(el => el.id === positionId);
      return (posInstallTimeDetails.install_time_mins * this.selectedProductData.qty);
    },
    deleteCable(index) {
      this.selectedProductData.cables.splice(index, 1);
    },
    closeAddCableToProductModal() {
      this.showAddCableToProductModal = false;
    },
    addCableToProduct(cable, purpose) {
      this.selectedProductData.cables.push({cable, purpose});
    }
  }
};
</script>