<template>
  <div>
    <swiper 
      v-if="products.length || customProducts.length || bundleProducts.length" 
      ref="productsSwiper"
      class="swiper product-selector f-3"
      :options="swiperOptions"
      :auto-update="true"
      :auto-destroy="false"
      :delete-instance-on-destroy="false"
      :cleanup-styles-on-destroy="true"
    >
      <swiper-slide v-for="product in products" :key="product.index">
        <div class="slide--image">
          <img v-if="product.img" :src="product.img" alt="Specifi">        
          <!-- Upgrade Options -->
          <div v-if="product.upgrades.length > 0" class="absolute top-0 right-0 p-2">
            <div class="flex -space-x-2">
              <div v-for="(productUpgrade, upIdx) in product.upgrades" :key="upIdx" class="w-10 h-10 flex items-center justify-center rounded-full ring-2 ring-gray-200 bg-gradient-to-br from-green-light to-green-dark shadow-lg p-1">
                <img v-if="productUpgrade.upgrade_product && productUpgrade.upgrade_product.first_image" :src="productUpgrade.upgrade_product.first_image.url" class="object-contain" alt="">
                <img v-else-if="productUpgrade.upgrade_product && productUpgrade.upgrade_product.image_url" :src="productUpgrade.upgrade_product.image_url" class="object-contain" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="slide--padded p-4 text-center">
          <h3 class="slide--title sans-serif text-lg xl:text-2xl p-1">
            {{ product.name }}
          </h3>
          <p class="slide--subtitle text-l mb-1">
            {{ product.supplier.name }}
          </p>
          <p class="slide--subtitle text-xs mb-2">
            {{ product.brand }}
          </p>
          <div v-if="product.customer_price" class="slide--subtitle">
            <span class="text-white opacity-80 font-medium">{{ product.customer_price | currency }}</span>
          </div>
          <div v-else class="slide--subtitle">
            <span>N/A</span>
          </div>

          <div class="w-full px-3 mx-auto my-6">
            <span class="relative z-0 inline-flex rounded-md w-full justify-center items-center">
              <button type="button" class="bg-transparent text-lime focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150" @click="decreaseRoomProduct(product)">
                <svg class="w-6 h-6 xl:w-8 xl:h-8" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </button>
              <span class="font-bold text-lg appearance-none w-1/2 text-center bg-transparent py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                {{ product.qty }}
              </span>
              <button type="button" class="bg-transparent text-lime focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150" @click="increaseRoomProduct(product)">
                <svg class="w-6 h-6 xl:w-8 xl:h-8" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </button>
            </span>
          </div>
      
          <button class="bg-gradient-green rounded-md py-2 px-4 mb-12 font-medium text-xs text-white mr-2" @click="viewProduct(product)">
            View
          </button>
          <button class="bg-gradient-green rounded-md py-2 px-4 mb-12 font-medium text-xs text-white ml-2" @click="editProduct(product, product.products[0].room_id)">
            Edit
          </button>

          <div v-if="product.added" class="slide--selected">
            <img src="@/assets/img/icons/check.svg" alt="Close"> Added to project
          </div>
        </div>
      </swiper-slide>

      <swiper-slide v-for="product in customProducts" :key="product.index">
        <span class="text-base font-bold -top-3 right-6 absolute bg-blue-dark text-white px-3 py-1 font-light rounded-full shadow z-10">Custom</span>
        <div class="slide--image">
          <img v-if="product.img" :src="product.img" alt="Specifi">
          <div v-if="product.upgrades.length > 0" class="absolute top-0 right-0 p-2">
            <div class="flex -space-x-2">
              <div v-for="(productUpgrade, upIdx) in product.upgrades" :key="upIdx" class="w-10 h-10 flex items-center justify-center rounded-full ring-2 ring-gray-200 bg-gradient-to-br from-green-light to-green-dark shadow-lg p-1">
                <img v-if="productUpgrade.upgrade_product && productUpgrade.upgrade_product.first_image" :src="productUpgrade.upgrade_product.first_image.url" class="object-contain" alt="">
                <img v-else-if="productUpgrade.upgrade_product && productUpgrade.upgrade_product.image_url" :src="productUpgrade.upgrade_product.image_url" class="object-contain" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="slide--padded p-4 text-center">
          <h3 class="slide--title sans-serif text-lg xl:text-2xl p-1">
            {{ product.name }}
          </h3>
          <p class="slide--subtitle text-xs mb-1">
            {{ product.brand }}
          </p>
          <div v-if="product.customer_price" class="slide--subtitle">
            <span class="text-white opacity-80 font-medium">{{ product.customer_price | currency }}</span>
          </div>
          <div v-else class="slide--subtitle">
            <span>N/A</span>
          </div>

          <div class="w-full px-3 mx-auto my-6">
            <span class="relative z-0 inline-flex rounded-md w-full justify-center items-center">
              <button type="button" class="bg-transparent text-lime focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150" @click="decreaseCustomProductQty(product)">
                <svg class="w-6 h-6 xl:w-8 xl:h-8" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </button>
              <span class="font-bold text-lg appearance-none w-1/2 text-center bg-transparent py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                {{ product.qty }}
              </span>
              <button type="button" class="bg-transparent text-lime focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150" @click="incrementCustomProductQty(product)">
                <svg class="w-6 h-6 xl:w-8 xl:h-8" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </button>
            </span>
          </div>
          <button class="bg-gradient-green rounded-md py-2 px-4 mb-12 font-medium text-xs text-white ml-2" @click="editCustomProduct(product)">
            Edit
          </button>

          <div v-if="product.added" class="slide--selected">
            <img src="@/assets/img/icons/check.svg" alt="Close"> Added to project
          </div>
        </div>
      </swiper-slide>

      <swiper-slide v-for="product in bundleProducts" :key="product.index">
        <span class="text-base font-bold -top-3 right-6 absolute bg-blue-light text-white px-3 py-1 font-light rounded-full shadow z-10">Total: {{ (product.grouped_products.length + product.grouped_custom_products.length) * product.qty }}</span>
        <div class="slide--image">
          <img v-if="product.grouped_products.length > 0" :src="product.grouped_products[0].product.first_image.url" alt="Specifi">
          <img v-else-if="product.grouped_custom_products.length > 0" :src="product.grouped_custom_products[0].product.image_url" alt="Specifi">
          <div v-if="product.grouped_products.length > 0" class="absolute left-0 top-0 p-2 z-50">
            <div class="flex -space-x-2">
              <div v-for="(productImage, upIdx) in product.grouped_products.slice(0,3)" :key="upIdx" class="w-10 h-10 flex items-center justify-center rounded-full ring-2 ring-gray-200 bg-gradient-to-br from-blue-light to-blue-dark shadow-lg p-1">
                <img v-if="productImage.product.first_image" :src="productImage.product.first_image.url" class="object-contain" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="slide--padded p-4 text-center">
          <h3 class="slide--title sans-serif text-lg xl:text-2xl p-1">
            {{ product.name }}
          </h3>
          <p class="slide--subtitle text-xs mb-1">
            {{ product.brands }}
          </p>
          <div v-if="product.customer_price" class="slide--subtitle">
            <span class="text-white opacity-80 font-medium">{{ product.customer_price | currency }}</span>
          </div>
          <div v-else class="slide--subtitle">
            <span>N/A</span>
          </div>

          <div class="w-full px-3 mx-auto my-6">
            <span class="relative z-0 inline-flex rounded-md w-full justify-center items-center">
              <button type="button" class="bg-transparent text-lime focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150" @click="decreaseProductBundleQty(product)">
                <svg class="w-6 h-6 xl:w-8 xl:h-8" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </button>
              <span class="font-bold text-lg appearance-none w-1/2 text-center bg-transparent py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                {{ product.qty }}
              </span>
              <button type="button" class="bg-transparent text-lime focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150" @click="incrementProductBundleQty(product)">
                <svg class="w-6 h-6 xl:w-8 xl:h-8" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </button>
            </span>
          </div>
          <button class="bg-gradient-green rounded-md py-2 px-4 mb-12 font-medium text-xs text-white ml-2" @click="editProductBundle(product)">
            Edit
          </button>

          <div v-if="product.added" class="slide--selected">
            <img src="@/assets/img/icons/check.svg" alt="Close"> Added to project
          </div>
        </div>
      </swiper-slide>

      <!-- <swiper-slide v-for="product in placeholderProducts" :key="product.index">
        <span class="text-l -top-3 right-12 absolute bg-blue-light text-white px-3 py-1 font-light rounded-full shadow z-10">Placeholder</span>
        <div class="slide--image">
          <img v-if="product.img" :src="product.img" alt="Specifi">
        </div>
        <div class="slide--padded p-4 text-center">
          <h3 class="slide--title sans-serif text-lg xl:text-2xl">
            {{ product.placeholder_product.name }}
          </h3>
          <p class="slide--subtitle text-xs mb-1">
            {{ product.placeholder_product.brand }}
          </p>
          <div v-if="product.placeholder_product.retail_price" class="slide--subtitle">
            <span class="text-base xl:text-lg">{{ product.placeholder_product.retail_price | currency }}</span>
          </div>
          <div v-else class="slide--subtitle">
            <span>N/A</span>
          </div>

          <div class="w-full px-3 mx-auto my-6">
            <span class="relative z-0 inline-flex rounded-md w-full justify-center items-center">
              <button type="button" class="bg-transparent text-lime focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150" @click="removePlaceholderProductFromRoom(product)">
                <svg class="w-6 h-6 xl:w-8 xl:h-8" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </button>
              <input
                v-model="product.qty"
                class="font-bold text-base xl:text-lg appearance-none w-1/2 text-center bg-transparent py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
              >
              <button type="button" class="bg-transparent text-lime focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150" @click="incrementPlaceholderProductQty(product)">
                <svg class="w-6 h-6 xl:w-8 xl:h-8" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </button>
            </span>
          </div>
          <button class="bg-gradient-green rounded-md py-2 px-4 mb-12 font-medium text-xs text-white ml-2" @click="editPlacholderProduct(product)">
            Edit
          </button>

          <div v-if="product.added" class="slide--selected">
            <img src="@/assets/img/icons/check.svg" alt="Close"> Added to project
          </div>
        </div>
      </swiper-slide> -->

      <div slot="button-prev" :class="$isTouchDevice && 'hide'" class="swiper-btn swiper-btn-prev">
        <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-12 h-12">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
        </svg>
      </div>
      <div slot="button-next" :class="$isTouchDevice && 'hide'" class="swiper-btn swiper-btn-next">
        <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-12 h-12">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
        </svg>
      </div>
    </swiper>
    <div v-else class="flex flex-col items-center">
      <h1 class="sans-serif text-2xl text-center font-semibold">
        You haven't added any products yet.
      </h1>
      <button class="bg-gradient rounded-full mt-8 mb-8 py-3 px-8 font-bold text-base text-white button-primary" @click="showAddProduct">
        + Add product
      </button>
    </div>
  
    <edit-product-modal 
      :show-modal="showEditModal"
      :edit-product="editProductData" 
      :floor="floor"
      @updateProductDetails="updateProductDetails"
      @close="closeEditProductModal" 
      @upgradeProductRemoved="upgradeProductRemoved"
      @productUpgraded="productUpgraded"
      @showUpgradeProductModal="showUpgradeProductModal"
      @removeProductFromRoom="removeProductFromRoom"
      @removeAllProduct="removeAllProduct"
    />

    <EditCustomProductModal
      :show="showEditCustomProductModal"
      :edit-product-data="editCustomProductData"
      @showUpgradeProductModal="showUpgradeProductModal"
      @upgradeCustomProductRemoved="upgradeCustomProductRemoved"
      @productUpgraded="productUpgraded"
      @updated="customProductUpdated"
      @close="cancelEditCustomProduct"
    />

    <AddUpgradeProductModal
      :show="showAddProductUpgradeModal"
      :product-to-upgrade="productToUpgrade"
      :room-id="roomId ? roomId : editProductData ? editProductData.room.id : editCustomProductData.room.id"
      @upgradeProductAdded="upgradeProductAddedToProduct"
      @close="closeAddUpgradeProductModal"
    />

    <EditPlaceholderProductModal
      :show="showEditPlaceholderProductModal"
      :edit-product-data="editPlaceholderProductData"
      @close="cancelEditPlaceholderProduct"
    />


    <EditProductBundleModal
      :loading="loadingProductBundleToEdit"
      :show="showEditProductBundleModal"
      :edit-product-data="editProductBundleData"
      :save="saveEditProductBundle"
      @close="cancelEditProductBundle"
    />
  </div>
</template>

<script>
import EditProductModal from '../modals/EditProductModal.vue';
import EditPlaceholderProductModal from '../modals/EditPlaceholderProductModal.vue';
import EditCustomProductModal from '../modals/EditCustomProductModal.vue';
import AddUpgradeProductModal from '../modals/AddUpgradeProductModal.vue';
import EditProductBundleModal from '../modals/EditBundleProductModal.vue';

export default {
  components: {
    EditProductModal,
    EditPlaceholderProductModal,
    EditCustomProductModal,
    AddUpgradeProductModal,
    EditProductBundleModal
  },
  props: {
    products: {
      type: Array,
      required: true
    },
    customProducts: {
      type: Array,
      required: true
    },
    bundleProducts: {
      type: Array,
      required: true
    },
    placeholderProducts: {
      type: Array,
      required: true
    },
    floor: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-btn-next',
          prevEl: '.swiper-btn-prev'
        },
        loop: false,
        loopedSlides: 5,
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 1,
        slideToClickedSlide: true,
        freeMode: true,
        freeModeSticky: true,
        freeModeMomentumRatio: .2,
        keyboard: {
          enabled: true
        }
      },
      showEditModal: false,
      editProductData: {
        description: null,
        product: null,
        products: [],
        installTimeDetails: [],
        show_on_sales_quote: true,
        cables: []
      },
      showEditPlaceholderProductModal: false,
      editPlaceholderProductData: {},
      showEditCustomProductModal: false,
      editCustomProductData: {
        productData: {
         products: []
        },
        installTimeDetails: [] 
      },
      showAddProductUpgradeModal: false,
      productToUpgrade: null,
      showEditProductBundleModal: false,
      loadingProductBundleToEdit: false,
      editProductBundleData: {
        product: null,
        groupedProducts: [],
        groupedCustomProducts: []
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.productsSwiper.$swiper;
    },
    roomId() {
      return parseInt(this.$route.params.roomId);
    },
    project() {
      return this.$store.state.project.project;
    },
    projectId(){
      return this.$route.params.projectId;
    },
    teamPositions () {
      return this.$store.state.system.teamPositions;
    }
  },
  methods: {
    viewProduct(product) {
      if (product.type === 'custom') {
        this.$router.push({ name: 'projectRoomCustomProduct', params: { productId: product.id, roomId: this.$route.params.roomId } });
      } else {
        this.$router.push({ name: 'projectRoomProduct', params: { productId: product.id, roomId: this.$route.params.roomId } });
      }
    },
    showAddProduct() {
      this.$emit('showAddProduct');
    },
    increaseRoomProduct(product) {
      this.axios.post(`/api/products/${this.$route.params.projectId}/${this.$route.params.roomId}/${product.id}/${product.supplier.id}/increment`).then((res) => {
          const idx = this.products.findIndex(productIdx => (productIdx.id === res.data.incrementedProduct.product_id) && (productIdx.supplier.id === res.data.incrementedProduct.supplier_id) );
          this.products[idx].qty += 1;
          this.$store.dispatch("project/addProduct", {
            floorId: this.floor,
            room: parseInt(this.$route.params.roomId),
            product: { productsAdded: [res.data.incrementedProduct], additionalProductsAdded: res.data.additionalProductsAdded }
          });
          this.addProductFromRoom({ productsAdded: [res.data.incrementedProduct], additionalProductsAdded: res.data.additionalProductsAdded });
          this.$forceUpdate();
          this.$notify({ 
            type: 'success', 
            title: `1 x ${res.data.incrementedProduct.product.name} has been successfully added.`
          });
      });
    },
    decreaseRoomProduct(product) {
     const productId = product.id;
      const roomId = this.$route.params.roomId;
      const projectId = this.$route.params.projectId;
        this.axios.delete(`/api/projects/${projectId}/room/${roomId}/delete-product/${productId}/${product.supplier.id}`).then(() => {
          const prodIdx = this.products.findIndex(el => el.id === productId);
          if ( this.products[prodIdx].qty === 1) {
           this.products.splice(prodIdx, 1);
          } else {
            this.products[prodIdx].qty -= 1;
          }
          this.$store.dispatch("project/removeProduct", {
            floorId: this.floor,
            room: parseInt(this.$route.params.roomId),
            product: productId,
            supplier: product.supplier.id
          });
          this.$forceUpdate();
          this.$notify({ 
            type: 'success', 
            title: `Product has been successfully removed.`
          }); 
        });
    },
    editProduct(product, room) {
      this.editProductData.product = product;
      this.editProductData.products = product.products;
      this.editProductData.install_info = product.products[0].install_times ? product.products[0].install_times : [];
      this.editProductData.customer_price = product.products[0].customer_price.toFixed(2);
      this.editProductData.install_stage = product.products[0].install_stage;
      this.editProductData.description = product.products[0].product_description ? product.products[0].product_description.description : "";
      this.editProductData.updateDefaultInstallInfo = false;
      this.editProductData.installTimeDetails = [];
      this.editProductData.room = room;

      // Setup Install Times
      this.teamPositions.forEach((el) => {
        const details = { ...el };
        const setInstallTime = this.editProductData.install_info.find(installInfo => installInfo.team_position_id === el.id);
        details.install_time_mins = setInstallTime ? setInstallTime.install_time_mins : 0;
        this.editProductData.installTimeDetails.push(details);
      });
      this.showEditModal = true;
    },
    closeEditProductModal() {
      this.showEditModal = false;
      this.editProductData.products = [],
      this.editProductData.installTimeDetails = [];
    },
    removePlaceholderProductFromRoom(product) {
      this.axios.post(`/api/products/${this.project.id}/${product.room_id}/${product.id}/decrease-placeholder`).then(() => {
        const prodIdx = this.placeholderProducts.findIndex(el => el.id === product.id);
        if ( this.placeholderProducts[prodIdx].qty === 1) {
          this.placeholderProducts.splice(prodIdx, 1);
        } else {
          this.placeholderProducts[prodIdx].qty -= 1;
        }
        this.$store.dispatch("project/removePlaceholderProduct", {
          floorId: this.floor,
          room: parseInt(this.$route.params.roomId),
          product: product.id
        });
        this.$notify({ 
            type: 'success', 
            title: `1 x ${product.placeholder_product.name} has been successfully removed.`
          });
        this.$forceUpdate();
      });
    },
    incrementPlaceholderProductQty(product) {
      this.axios.post(`/api/products/${this.project.id}/${product.room_id}/${product.id}/increment-placeholder`).then(() => {
        const idx = this.placeholderProducts.findIndex(productIdx => productIdx.id === product.id);
        this.placeholderProducts[idx].qty += 1;
        this.$store.dispatch("project/addPlaceholderProduct", {
          floorId: this.floor,
          room: parseInt(this.$route.params.roomId),
          product: product.id
        });
        this.$notify({ 
            type: 'success', 
            title: `1 x ${product.placeholder_product.name} has been successfully added.`
          });
         this.$forceUpdate();
      });
    },
    incrementCustomProductQty(product) {
      this.axios.post(`/api/products/${this.project.id}/${this.roomId}/${product.id}/increment-custom`).then((res) => {
          const idx = this.customProducts.findIndex(productIdx => productIdx.id === res.data.product_id);
          this.customProducts[idx].qty += 1;
          this.$store.dispatch("project/addCustomProduct", {
            floorId: this.floor,
            room: this.roomId,
            product: res.data
          });
          this.$forceUpdate();
          this.$notify({ 
            type: 'success', 
            title: `1 x ${product.name} has been successfully added.`
          });
         this.$forceUpdate();
      });
    },
    decreaseCustomProductQty(product) {
      this.axios.delete(`/api/products/${this.project.id}/${this.roomId}/${product.id}/decrease-custom`).then((res) => {
         const prodIdx = this.customProducts.findIndex(el => el.id === res.data.product_id);
         if ( this.customProducts[prodIdx].qty === 1) {
           this.customProducts.splice(prodIdx, 1);
          } else {
            this.customProducts[prodIdx].qty -= 1;
          }
          this.$store.dispatch("project/removeCustomProduct", {
            floorId: this.floor,
            room: this.roomId,
            product: res.data.product_id
          });
          this.$forceUpdate();
          this.$notify({ 
            type: 'success', 
            title: `Custom product removed`
          });
         this.$forceUpdate();
      });
    },
    customProductUpdated (productData) {
      this.$emit('upgradeCustomData', productData);
    },
    updateProductDetails (productData) {
       this.$emit('updateProductDetailsRoom', productData);
    },
    editPlacholderProduct(product) {
      this.editPlaceholderProductData = product;
      this.showEditPlaceholderProductModal = true;
    },
    cancelEditPlaceholderProduct() {
      this.showEditPlaceholderProductModal = false;
      this.editPlaceholderProductData = {};
    },
    editCustomProduct(product) {
      this.editCustomProductData.productData = product;
      this.editCustomProductData.products = product.products;
      this.editCustomProductData.install_info = product.products[0].install_times ? product.products[0].install_times : [];
      this.editCustomProductData.installTimeDetails = [];
      this.editCustomProductData.updateDefaultInstallInfo = false;

      // Setup Install Times
      this.teamPositions.forEach((el) => {
        const details = { ...el };
        const setInstallTime = this.editCustomProductData.install_info.find(installInfo => installInfo.team_position_id === el.id);
        details.install_time_mins = setInstallTime ? setInstallTime.install_time_mins : 0;
        this.editCustomProductData.installTimeDetails.push(details);
      });
      this.showEditCustomProductModal = true;
    },
    cancelEditCustomProduct() {
      this.showEditCustomProductModal = false;
       this.editCustomProductData = {
        productData: {
          products: []
        },
        installTimeDetails: []
      };
    },
    showUpgradeProductModal(currentProduct) {
      this.showAddProductUpgradeModal = true;
      this.productToUpgrade = currentProduct;
    },
    upgradeProductAddedToProduct(product, productDetails) {
      this.$emit('upgradeProductAddedToRoom', product, productDetails);
      this.$notify({ 
        type: 'success', 
        title: `Product Option Added`
      });
    },
    closeAddUpgradeProductModal() {
      this.showAddProductUpgradeModal = false;
      this.productToUpgrade = null;
    },
    upgradeProductRemoved(productId) {
      const idx = this.editProductData.product.upgrades.findIndex(el => el.id === productId);
      const upProdUpd = {...this.editProductData};
      upProdUpd.product.upgrades.splice(idx, 1);
      this.editProductData = upProdUpd;
      this.$notify({
        type: 'success',
        title: `Product upgrade option removed`
      });
    },
    productUpgraded(upgradeProduct, newProducts) {
      this.$emit('productUpgradedToRoom', upgradeProduct, newProducts);
      this.$notify({ 
          type: 'success', 
          title: `Product upgraded`
        });
      this.closeEditProductModal();
    },
    removeProductFromRoom(product) {
      this.$emit('removeProduct', product);
    },
    addProductFromRoom(product) {
      this.$emit('addProduct', product);
    },
    removeAllProduct(product, supplier) {
      this.$emit('removeAllProduct', product, supplier);
    },
    incrementProductBundleQty(productBundle) {
      this.axios.post(`/api/products/${this.project.id}/${this.roomId}/${productBundle.id}/increment-product-bundle`).then((res) => {
        const idx = this.bundleProducts.findIndex(productIdx => productIdx.id === productBundle.id);
        this.bundleProducts[idx].qty += 1;
        // this.$store.dispatch("project/incrementProductBundleInRoom", { floor_id: this.floor, room_id: res.data.project_room_id, productDetails: res.data });
        this.$notify({type: 'success', title: `1 x ${res.data.team_product_bundle.name} added`});
        this.$forceUpdate();
      }).catch(() => {
        this.$notify({type: 'error', title: 'Something went wrong, Please try again'});
      });
    },
    decreaseProductBundleQty(productBundle) {
      this.axios.delete(`/api/products/${this.project.id}/${this.roomId}/${productBundle.id}/remove-product-bundle`).then((res) => {
        // this.$store.dispatch('project/removeProductBundleFromRoom', { floor_id: this.floor, room_id: res.data.project_room_id, productDetails: productBundle });
        const prodIdx = this.bundleProducts.findIndex(el => el.id === productBundle.id);
         if ( this.bundleProducts[prodIdx].qty === 1) {
           this.bundleProducts.splice(prodIdx, 1);
          } else {
            this.bundleProducts[prodIdx].qty -= 1;
          }
        this.$notify({type: 'success', title: `1 x ${productBundle.name} removed`});
        this.$forceUpdate();
      }).catch(() => {
        this.$notify({type: 'error', title: 'Something went wrong, Please try again'});
      });
    },
    groupedProductBundles(room) {
      if (!room || !room.room_product_bundles) return [];

      const ggs = this.groupByBundle(room.room_product_bundles);
      return Object.keys(ggs).map((ind) => {
        const prodG = ggs[ind];
        const installTimeToShow = [];

        // Get the pricing that the bundle was added with:
        // i.e. not the update product bundle price if it has been changed.
        let customerPrice = 0;
        let tradePrice = 0;
        prodG[0].grouped_products.forEach((el) => {
          customerPrice += el.customer_price * el.qty;
          tradePrice += el.trade_price * el.qty;
        });

        prodG[0].grouped_custom_products.forEach((el) => {
          customerPrice += el.customer_price * el.qty;
          tradePrice += el.trade_price * el.qty;
        });

        return {
          id: prodG[0].team_product_bundle.id,
          name: prodG[0].team_product_bundle.name,
          brands: prodG[0].team_product_bundle.product_brands,
          suppliers: prodG[0].team_product_bundle.product_suppliers,
          qty: prodG.length,
          customer_price: customerPrice,
          trade_price: tradePrice,
          description: prodG[0].team_product_bundle.description,
          deleted_at: prodG[0].team_product_bundle.deleted_at,
          grouped_products: prodG[0].grouped_products,
          grouped_custom_products: prodG[0].grouped_custom_products,
          installTimeToShow: installTimeToShow
        };
      });
    },
    groupProductBundles(productBundles) {
      const products = [];
      const customProducts = [];

      productBundles.forEach((el) => {
          el.bundle_products.forEach((elem) => {
            products.push(elem);
          });

          el.bundle_custom_products.forEach((elem) => {
            customProducts.push(elem);
          });


        // Get the pricing that the bundle was added with:
        // i.e. not the update product bundle price if it has been changed.
        // let customerPrice = 0;
        // let tradePrice = 0;
        // prodG[0].bundle_products.forEach((el) => {
        //   customerPrice += el.customer_price;
        //   tradePrice += el.trade_price;
        // });

        // prodG[0].bundle_custom_products.forEach((el) => {
        //   customerPrice += el.customer_price;
        //   tradePrice += el.trade_price;
        // });
      });

      return {
        id: productBundles[0].team_product_bundle.id,
        name: productBundles[0].team_product_bundle.name,
        brands: productBundles[0].team_product_bundle.product_brands,
        suppliers: productBundles[0].team_product_bundle.product_suppliers,
        qty: productBundles.length,
        customer_price: 0,
        trade_price: 0,
        description: productBundles[0].team_product_bundle.description,
        deleted_at: productBundles[0].team_product_bundle.deleted_at,
        products: products,
        customProducts: customProducts
      };
    },
    saveEditProductBundle() {
      this.axios.put(`/api/projects/${this.project.id}/room/${this.roomId}/product-bundle`, { editProductData: this.editProductBundleData }).then((res) => {
        this.cancelEditProductBundle();
        this.$notify({type: 'success', title: 'Product updated'});
      }).catch(() => {
        this.$notify({type: 'error', title: 'Something went wrong, Please try again'});
      });
    },
    cancelEditProductBundle() {
      this.showEditProductBundleModal = false;
      this.editProductBundleData = { product: null, groupedProducts: [], groupedCustomProducts: [] };
    },
    editProductBundle(product) {
      this.loadingProductBundleToEdit = true;
      this.showEditProductBundleModal = true;
      this.axios.get(`/api/products/${this.projectId}/${this.roomId}/${product.id}`).then((res) => {
        const groupedProductBundles = this.groupProductBundles(res.data);
        this.editProductBundleData.product = groupedProductBundles;
        const productsCopy = groupedProductBundles.products;
        const customProductsCopy = groupedProductBundles.customProducts;

        productsCopy.forEach((el) => {
          const elCopy = {...el};

          // Has this Product already been added?
          const productIndex = this.editProductBundleData.groupedProducts.findIndex(elem => elem.product_id === el.product_id && elem.supplier_id === el.supplier_id);

          if (productIndex === -1) {

            elCopy.products = [];
            elCopy.customer_price = el.customer_price;
            elCopy.products.push({...el});
            elCopy.install_info = el.install_times ? el.install_times : [];
            elCopy.updateDefaultInstallInfo = false;
            elCopy.install_stage = el.install_stage;
            elCopy.installTimeDetails = [];
            elCopy.details = el.details.hasOwnProperty('details') ? el.details.details : {};

            // Get any default or configured cables
            const overWrittenCables = el.details.hasOwnProperty('cables') ? el.details.cables : false;
            elCopy.cables = overWrittenCables ? overWrittenCables : [];

            // Get any default or configured networks
            const overWrittenNetworks = el.details.hasOwnProperty('networkRange') ? el.details.networkRange : false;
            elCopy.networkRange = overWrittenNetworks ? overWrittenNetworks : {};

            // Setup Install Times
            this.teamPositions.forEach((el) => {
              const details = { ...el };
              const setInstallTime = elCopy.install_info.find((installInfo) => installInfo.team_position_id === el.id);
              details.install_time_mins = setInstallTime ? setInstallTime.install_time_mins : 0;
              elCopy.installTimeDetails.push(details);
            });

            this.editProductBundleData.groupedProducts.push({...elCopy});
          } else {
            this.editProductBundleData.groupedProducts[productIndex].products.push({...elCopy});
          }
        });

        customProductsCopy.forEach((el) => {
          const elCopy = {...el};

          // Has this Product already been added?
          const productIndex = this.editProductBundleData.groupedCustomProducts.findIndex(elem => elem.team_custom_product_id === el.team_custom_product_id && elem.supplier_id === el.supplier_id);

          if (productIndex === -1) {
            elCopy.products = [];
            elCopy.customer_price = el.customer_price;
            elCopy.products.push({...el});
            elCopy.install_info = el.install_times ? el.install_times : [];
            elCopy.updateDefaultInstallInfo = false;
            elCopy.installTimeDetails = [];

            // Setup Install Times
            this.teamPositions.forEach((el) => {
              const details = { ...el };
              details.install_time_mins = 0;
              const defaultTime = elCopy.install_info.find((installTime) => installTime.team_position_id === el.id);
              details.install_time_mins = defaultTime ? defaultTime.install_time_mins : 0;
              elCopy.installTimeDetails.push(details);
            });

            this.editProductBundleData.groupedCustomProducts.push({...elCopy});
          } else {
            this.editProductBundleData.groupedCustomProducts[productIndex].products.push({...elCopy});
          }
        });
      }).finally(() => {
        this.loadingProductBundleToEdit = false;
      });
    },
    upgradeCustomProductRemoved(productId){
      const idx = this.editCustomProductData.products[0].upgrade_products.findIndex((el) => el.id === productId);
      const upProdUpd = { ...this.editCustomProductData };
      upProdUpd.products[0].upgrade_products.splice(idx, 1);
      this.editCustomProductData = upProdUpd;
      this.$notify({type: "success", title: `Product upgrade option removed'`});
    }
  }
};
</script>
