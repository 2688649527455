<template>
  <div
    class="modal-window fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center" :class="showModal ? 'show' : 'hide'"
  >
    <div
      class="fixed inset-0 transition-opacity"
      :class="showModal ? 'ease-in duration-200 opacity-100' : 'ease-out duration-200 opacity-0'"
    >
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>
    <div
      class="modal-window bg-white rounded-lg overflow-hidden shadow-xl transform transition-all p-6"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
      :class="showModal ? 'ease-in duration-200 opacity-100 translate-y-0 sm:scale-100' : 'ease-out duration-300 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'"
    >
      <div class="w-full">
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
          >
            <svg
              class="h-6 w-6 text-red-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 id="modal-headline" class="text-lg leading-6 font-medium text-gray-900">
              Delete
            </h3>
            <div class="mt-2">
              <p class="text-sm leading-5 text-gray-500">
                Please choose an option.
              </p>
            </div>
          </div>
        </div>

        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              @click="deleteSingleProduct"
            >Delete Single Product</button>
          </span>
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto sm:ml-3">
            <button
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              @click="deleteAllProducts"
            >Delete All Products</button>
          </span>
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto sm:ml-3">
            <button
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              @click="closeModal"
            >Cancel</button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: Boolean,
    roomId: {
      type: Number,
      required: true
    },
    productId: {
      type: Number,
      required: true
    },
    projectId: {
      type: Number,
      required: true
    },
    supplierId: {
      type: Number,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    floor: {
      type: Number,
      required: true
    }
  },
  computed: {
    roomIdExist() {
      return this.$route.params.roomId;
    }
  },
  methods: {
    deleteSingleProduct() {
      this.axios
        .delete(
          `/api/projects/${this.projectId}/room/${this.roomId}/delete-product/${this.productId}/${this.supplierId}`
        )
        .then((res) => {
          this.$store.dispatch("project/removeProduct", {
            floorId: this.floor ? this.floor : this.product.room.floor_id,
            room: this.roomId,
            product: this.productId,
            supplier: this.supplierId
          });
          if(this.roomIdExist){
            this.$emit("removeProductFromSwiper", res.data);
          }
        })
        .finally(() => {
          this.$emit("closeDeleteProductModal");
          this.$notify({
            type: "success",
            title: "Product Deleted",
            text: `1 x ${this.product.product.name} successfully removed`
          });
        });
    },
    deleteAllProducts() {
      this.axios
        .delete(
          `/api/projects/${this.projectId}/room/${this.roomId}/delete-products/${this.productId}/${this.supplierId}`
        )
        .then(() => {
          this.$store.dispatch("project/removeAllProduct", {
            floorId: this.floor ? this.floor : this.product.room.floor_id,
            room: this.roomId,
            product: this.productId,
            supplier: this.supplierId
          });
          if(this.roomIdExist){
            this.$emit("removeAllProductFromSwiper", this.productId, this.supplierId);
          }
        })
        .finally(() => {
          this.$emit("closeDeleteProductModal", true);
          this.$notify({
            type: "success",
            title: "Products Deleted",
            text: `${this.product.product.qty} x ${this.product.product.name} successfully removed`
          });
        });
    },
    closeModal() {
      this.$emit("closeDeleteProductModal");
    }
  }
};
</script>