import axios from 'axios';

export default {
  namespaced: true,
  state: {
    clientMode: false,
    categories: [],
    projectStages: [],
    favouriteProducts: [],
    showPricing: true,
    customProducts: [],
    teamPositions: [],
    brands: [],
    productBundles: [],
    productSettings: null
  },
  mutations: {
    SET_MODE(state, value) {
      state.clientMode = value;
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    },
    SET_PROJECT_STAGES(state, value) {
      state.projectStages = value;
    },
    SET_PRODUCT_FAVOURITES(state, value) {
      state.favouriteProducts = value;
    },
    SET_PRICING(state) {
      state.showPricing = !state.showPricing;
    },
    SET_CURRENT_CATEGORY(state, categoryId) {
      state.currentCategory = categoryId;
    },
    SET_CUSTOM_PRODUCTS(state, value) {
      state.customProducts = value;
    },
    SET_TEAM_POSITIONS(state, value) {
      state.teamPositions = value;
    },
    SET_BRANDS(state, brands) {
      state.brands = brands;
    },
    SET_PRODUCT_BUNDLES(state, value) {
      state.productBundles = value;
    },
    SET_PRODUCT_SETTINGS(state, value) {
      state.productSettings = value;
    }
  },
  actions: {
    getCategories({ commit }) {
      axios.get('/api/categories').then((response) => {
        commit('SET_CATEGORIES', response.data);
      });
    },
    getProjectInstallDefaults({ commit }) {
      axios.get('/api/project-install-defaults').then((res) => {
        commit('SET_PROJECT_STAGES', res.data.projectStages);
        commit('SET_TEAM_POSITIONS', res.data.teamPositions);
      });
    },
    // getProductFavourites({ commit }) {
    //   axios.get('/api/products/favourites/all').then((res) => {
    //     commit('SET_PRODUCT_FAVOURITES', res.data);
    //   });
    // },
    // getProductBundles({ commit }) {
    //   axios.get('/api/product-bundles').then((res) => {
    //     commit('SET_PRODUCT_BUNDLES', res.data);
    //   });
    // },
    getProductSettings({ commit }) {
      axios.get('/api/product-settings').then((res) => {
        commit('SET_PRODUCT_SETTINGS', res.data);
      });
    },
    togglePricing({ commit }) {
      commit('SET_PRICING');
    },
    getCurrentCategory({ commit }, categoryId) {
      commit('SET_CURRENT_CATEGORY', categoryId);
    },
    getCustomProducts({ commit }) {
      axios.get('/api/custom-products/all').then((res) => {
        commit('SET_CUSTOM_PRODUCTS', res.data);
      });
    },
    getBrands({ commit }) {
      axios.get('/api/available-brands').then((response) => {
        commit('SET_BRANDS', response.data);
      });
    },
    getTeamSpecificProducts({ commit }, project = null) {
      axios.get('/api/products/team-specific/all', { params: { project } }).then((res) => {
        commit('SET_CUSTOM_PRODUCTS', res.data.custom);
        commit('SET_PRODUCT_BUNDLES', res.data.productBundles);
        commit('SET_PRODUCT_FAVOURITES', res.data.favourites);
      });
    }
  }
};