<template>
  <div v-if="currentCategory || isCustomProduct || isFavouriteProduct || isBundleProduct" class="shell-container">
    <header class="router-header flex flex-wrap content-center items-center transition ease-in-out container px-8">
      <div class="flex-1">
        <button class="bg-gradient text-white font-bold p-1 xl:p-2 rounded-full" @click="routerBack()">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 xl:h-6 xl:w-6" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
          </svg>
        </button>
      </div>
      <h3 v-if="isCustomProduct" class="flex-auto sans-serif text-2xl xl:text-4xl text-center font-semibold">
        Custom Product
      </h3>
      <h3 v-else-if="isFavouriteProduct" class="flex-auto sans-serif text-2xl xl:text-4xl text-center font-semibold">
        Favourite Products
      </h3>
      <h3 v-else-if="isBundleProduct" class="flex-auto sans-serif text-2xl xl:text-4xl text-center font-semibold">
        Product Bundles
      </h3>
      <h3 v-else class="flex-auto sans-serif text-2xl xl:text-4xl text-center font-semibold">
        {{ currentCategory.name }}
      </h3>
      <ul class="flex flex-1 text-right flex-col fabs">
        <li @click="showChangeCategoryModal = true">
          <span class="invisible sm:visible">Change category</span>
          <button class="bg-gradient text-white font-bold py-2 px-2 rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
            </svg>
          </button>
        </li>
      </ul>
    </header>

    <div
      v-if="selectedCategory && showProducts.length === 0 && !isCustomProduct && !isBundleProduct"
      class="flex justify-center items-center flex-col"
    >
      <div class="category-overlay bg-gradient w-full z-10 absolute bottom-0" />
      <div v-for="(question, index) in selectedCategory.questions.questions" :key="index" class="w-full my-16 absolute z-20 bottom-0 text-white">
        <div v-if="currentQuestion === index">
          <h3 class="font-bold text-center my-6 text-xl">
            {{ question.question }}
          </h3>
          <div class="w-full flex justify-center">
            <div class="bg-black p-2 inline-flex rounded-full mt-4 text-center shadow-md">
              <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="chevron-down w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>

          <div class="my-6 text-center flex justify-center w-full mx-auto">
            <div v-for="(questionCatergory, idx) in categoryAnswers" :key="idx" class="flex items-center" @click="goBackToQuestion(index)">
              <div v-if="index !== 0" class="inline-flex rounded-full text-center shadow-md ml-8 mr-8">
                <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="chevron-right w-4 h-4"> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" /></svg>
              </div>
              <div>
                <span class="block uppercase tracking-wide text-md font-bold">{{ questionCatergory.answer }}</span>
              </div>
            </div>
          </div>

          <div class="w-3/4 mx-auto">
            <div class="flex flex-wrap justify-between w-full">
              <div v-for="(answer, answerIndex) in question.answers" :key="answerIndex" class="border border-white flex flex-col text-white font-bold px-4 py-8 rounded-md w-40 text-center items-center justify-center mb-4 hover:bg-gray-50 hover:text-gray-800 focus:outline-none shadow" @click="selectAnswer(question.dbField, answerIndex)">
                <h3 class="text-xl font-semibold">
                  {{ answerIndex }}
                </h3>
                <h4 class="mt-1 text-xs font-bold text-center sm:text-left text-gray-400 group-hover:text-white">
                  {{ 'Product' | pluralize(answer, true) }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="filterProducts && filterProducts.length === 0" class="w-full justify-center inline-flex items-center my-20 absolute z-20 bottom-20 text-white">
        <button class="bg-lime text-white font-bold p-1 xl:p-2 rounded-full" @click="routerBack()">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 xl:h-6 xl:w-6" viewBox="0 0 20 20" fill="#122236">
            <path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
          </svg>
        </button>
        <h3 class="font-bold text-center my-6 ml-4 text-3xl">
          No Products Available
        </h3>
      </div>
    </div>

    <category-swiper
      v-else-if="showProducts.length > 0"
      :products="showProducts"
      :is-custom-product="isCustomProduct"
      :is-bundle-product="isBundleProduct"
      :is-favourite-product="isFavouriteProduct"
      @addProductToProject="addProductToProject"
    />

    <category-swiper
      v-else-if="isCustomProduct"
      :products="customProducts"
      :is-custom-product="isCustomProduct"
      :is-bundle-product="isBundleProduct"
      :is-favourite-product="isFavouriteProduct"
      @addProductToProject="addProductToProject"
    />

    <category-swiper
      v-else-if="isFavouriteProduct"
      :products="favouriteProducts"
      :is-custom-product="isCustomProduct"
      :is-bundle-product="isBundleProduct"
      :is-favourite-product="isFavouriteProduct"
      @addProductToProject="addProductToProject"
    />

    <category-swiper
      v-else-if="isBundleProduct"
      :products="productBundles"
      :is-custom-product="isCustomProduct"
      :is-bundle-product="isBundleProduct"
      :is-favourite-product="isFavouriteProduct"
      @addProductToProject="addProductToProject"
    />

    <change-category-modal
      :show-modal="showChangeCategoryModal"
      @closeModal="showChangeCategoryModal = false"
    />

    <add-product-to-project
      v-if="showAddProductToProjectModal"
      :quick-add="true"
      :show-modal="showAddProductToProjectModal"
      :product="productToAdd"
      :is-custom-product="isCustomProduct"
      :is-bundle-product="isBundleProduct"
      :is-favourite-product="isFavouriteProduct"
      @success="successfullyAdded"
      @closeModal="showAddProductToProjectModal = false"
    />
  </div>
</template>

<script>
import CategorySwiper from "../../../components/swipers/CategorySwiper.vue";
import ChangeCategoryModal from "../../../components/modals/ChangeCategoryModal.vue";
import AddProductToProject from "../../../components/modals/AddProductToProjectModal.vue";

export default {
  components: {
    CategorySwiper,
    ChangeCategoryModal,
    AddProductToProject
  },
  data() {
    return {
      showAddProductToProjectModal: false,
      productToAdd: {},
      showProducts: [],
      selectedCategory: null,
      showChangeCategoryModal: false,
      productSearch: null,
      previousQuestionIds: [],
      selectedQuestionId: 1,
      answers: [],
      categoryAnswers: [],
      currentQuestion: 0,
      filterProducts: null,
      supplierType: 'linked_suppliers'
    };
  },
  computed: {
    isCustomProduct() {
      return this.$route.params.categorySlug === "custom-product" ? true : false;
    },
    isFavouriteProduct() {
      return this.$route.params.categorySlug === "favourite-product" ? true : false;
    },
    isBundleProduct() {
      return this.$route.params.categorySlug === "bundle-product" ? true : false;
    },
    projectId() {
      return this.$route.params.projectId ? this.$route.params.projectId : null;
    },
    customProducts() {
      return this.$store.state.system.customProducts;
    },
    productBundles() {
      return this.$store.state.system.productBundles;
    },
    currentCategory() {
      let category = this.$store.state.system.categories.find(
        element =>
          element.name.replace(/\s+/g, "-").toLowerCase() ===
          this.$route.params.categorySlug.toLowerCase()
      );

      if (category) {
        this.selectCategory(category);
      }

      return category;
    },
    favouriteProducts() {
      return this.$store.state.system.favouriteProducts;
    },
    previousQuestions() {
      const previousQuestions = [];
      this.previousQuestionIds.forEach(id => {
        previousQuestions.push(this.selectedCategory.questions.questions[id]);
      });

      return previousQuestions;
    }
  },
  methods: {
    selectCategory(category) {
      this.loading = true;
      this.filteredByWalkthrough = true;

      if (category.type === "Questionnaire") {
        const questionOne = category.questions.questions[0];
        this.axios.post(`/api/products/filter-answers/${category.id}`, {
          filter: questionOne,
          supplierType: 'linked_suppliers'
        }).then(res => {
          category.questions.questions[0].answers = res.data;
          this.selectedCategory = category;
        }).finally(() => {
          this.loading = false;
        });
      } else {
        this.axios.get(`/api/products/get-by-category/${category.id}`, { params: { supplierType: 'linked_suppliers', isDesign: true } }).then(res => {
          this.filterProducts = res.data.filter(el => el.pricing.lowestTradePrice !== 0);
          this.showProducts = this.filterProducts;
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    routerBack() {
      if (this.previousQuestionIds.length > 0) {
        this.backQuestion();
      } else {
        this.$router.go(-1);
      }
    },
    selectAnswer(dbField, answer) {
      this.categoryAnswers.push({ dbField: dbField, answer: answer });
      this.currentQuestion++;
      let questionNumber = this.currentQuestion;
      let question = this.selectedCategory.questions.questions[questionNumber];

      if (question && question.if) {
        question = this.doAnswersFulfillCriteria(question, questionNumber);
      }

      if (!question || this.categoryAnswers.length === this.selectedCategory.questions.questions.length) {
        this.axios.post(`/api/products/filter-by-walkthrough`, {
          category: this.selectedCategory.id,
          supplierType: 'linked_suppliers',
          categoryAnswers: this.categoryAnswers,
          isDesign: true
        }).then((res) => {
          this.filterProducts = res.data.filter(el => el.pricing.lowestTradePrice !== 0);
          this.showProducts = this.filterProducts;
        });
      } else {
        this.axios.post(`/api/products/filter-answers/${this.selectedCategory.id}`, {
          filter: question,
          supplierType: 'linked_suppliers',
          prevAnswers: this.categoryAnswers
        }).then((res) => {
          this.selectedCategory.questions.questions[this.currentQuestion].answers = res.data;
        });
      }
    },
    doAnswersFulfillCriteria(question, questionNumber) {
      let checkingCriteria = true;

      while (checkingCriteria === true) {
        const b = this.categoryAnswers.find(el => {
          return (
            el.dbField === question.if[0].dbField &&
            el.answer === question.if[0].answer
          );
        });

        if (!b) {
          this.currentQuestion = ++questionNumber;
          question = this.selectedCategory.questions.questions[
            this.currentQuestion
          ];

          if (!question || !question.if) {
            checkingCriteria = false;
          }
        } else {
          checkingCriteria = false;
        }
      }

      return question;
    },
    goBackToQuestion(index) {
      this.selectedQuestionId = this.previousQuestionIds[index];
      this.previousQuestionIds.length = index;
      this.answers.length = index;
      this.previousQuestions.length = index;
    },
    backQuestion() {
      this.hideQuestions = false;
      this.showProducts = [];
      this.filterProducts = [];
      if (this.previousQuestionIds.length) {
        this.selectedQuestionId = this.previousQuestionIds.slice(-1)[0];
        this.previousQuestionIds.pop();
      }
    },
    successfullyAdded(qtyAdded) {
      this.qtyAdded = qtyAdded;
      this.showAddProductToProjectModal = false;
      this.$notify({
        type: "success",
        title: "Product Added",
        text: `${qtyAdded} x products added to project.`
      });

      const { projectId, roomId } = this.$route.params;
      this.$router.push({
        name: "projectRoomProducts",
        params: { projectId, roomId }
      });
    },
    addProductToProject(product) {
      this.productToAdd = product;
      this.showAddProductToProjectModal = true;
    }
  }
};
</script>