<template>
  <div class="mobile-nav block sm:hidden" :class="{ open: active }">
    <nav class="mobile-nav--nav">
      <button @click="closeWindow('/builder')">
        Builder  
      </button>
      <button @click="closeWindow('/browse')">
        Browse
      </button>
      <button @click="closeWindow('/search')">
        Search
      </button>
    </nav>

    <span class="bg-gradient-green p-3 text-sm font-semibold text-white hover:opacity-50" @click="goToInstallerMode">Switch to Installer Mode</span>
  </div>
</template>

<script>
export default {
  name: "LoadingScreen",
  props: {active: Boolean},

  methods: {
    closeWindow(link) {
       this.$router.push({
        path: link
      });
       this.$emit('activeFalse');
    },
    goToInstallerMode() {
      if (this.$route.params.projectId) {
        window.location = `${process.env.VUE_APP_CLIENT_URL}/projects/${this.$route.params.projectId}`;
      } else {
        window.location = `${process.env.VUE_APP_CLIENT_URL}/projects`;
      }
    }
  }
};
</script>
