<template>
  <div v-if="showModal" class="modal-window overflow-y-auto fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center" :class="showModal ? 'show' : 'hide'">
    <div class="fixed inset-0 transition-opacity" :class="showModal ? 'ease-in duration-200 opacity-100' : 'ease-out duration-200 opacity-0'">
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>
    <div class="w-full sm:w-2/3 bg-white rounded-lg overflow-auto shadow-xl transform transition-all" role="dialog" aria-modal="true" aria-labelledby="modal-headline" :class="showModal ? 'ease-in duration-200 opacity-100 translate-y-0 sm:scale-100' : 'ease-out duration-300 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'">
      <div class="modal-window-split--content relative">
        <div v-if="product !== null" class="bg-white p-5 xl:p-8 max-h-full">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center w-full sm:mt-0 sm:text-left">
              <div class="mt-2">
                <div class="my-4">
                  <div class="w-full flex justify-between items-center">
                    <div class="w-full flex items-center mb-4 sm:mb-0">
                      <div v-if="product.image_url" class="flex justify-center items-center w-16 h-16 mr-4 border bg-gray-50 rounded-md">
                        <img class="h-12 w-12 object-contain" :src="product.image_url">
                      </div>
                      <div class="w-3/4">
                        <h4 class="font-medium text-lg text-left">
                          {{ product.name }}
                        </h4>
                        <p class="text-gray-400 text-xs text-left">
                          {{ product.brand }}
                        </p>
                      </div>
                    </div>
                    <div v-if="projectExist || selectedRoomId" class="flex justify-center">
                      <span class="relative z-0 inline-flex shadow-sm rounded-md">
                        <button
                          type="button"
                          class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-blue-light bg-blue-light text-sm font-medium text-white hover:bg-blue-dark focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                          @click="decreaseSelCustProdQty"
                        >-</button>
                        <span class="w-16 relative justify-center inline-flex items-center py-2 border border-gray-300 bg-gray-50 text-sm font-medium text-gray-700">{{ productData.qty }}</span>
                        <button type="button" class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-blue-light bg-blue-light text-sm font-medium text-white hover:bg-blue-dark focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" @click="increaseSelCustProdQty">+</button>
                      </span>
                    </div>
                  </div>
                </div>

                <div v-if="!projectExist" class="bg-gray-50 p-4 rounded-md shadow border mb-4">
                  <div class="grid grid-cols-6 gap-4">
                    <div class="col-span-3 sm:col-span-2">
                      <label for="installTime" class="block text-xs xl:text-sm font-medium text-gray-700">
                        Select Project</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <select id="priority" v-model="selectedProjectId" name="priority" required class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-xs xl:text-sm" @change="getProject">
                          <option :value="null" selected disabled>
                            -- Choose Project --
                          </option>
                          <option v-for="(projectName, index) in projects" :key="index" :value="projectName.id">
                            {{ projectName.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div v-if="loading" class="col-span-3 sm:col-span-2">
                      <svg id="L4" height="60px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 -25 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                        <circle fill="#77d183" stroke="none" cx="6" cy="50" r="6">
                          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1" />    
                        </circle>
                        <circle fill="#85b177" stroke="none" cx="26" cy="50" r="6">
                          <animate
                            attributeName="opacity"
                            dur="1s"
                            values="0;1;0"
                            repeatCount="indefinite" 
                            begin="0.2"
                          />       
                        </circle>
                        <circle fill="#0e2d29" stroke="none" cx="46" cy="50" r="6">
                          <animate
                            attributeName="opacity"
                            dur="1s"
                            values="0;1;0"
                            repeatCount="indefinite" 
                            begin="0.3"
                          />     
                        </circle>
                      </svg>
                    </div>
                    <div v-if="selectedProject" class="col-span-3 sm:col-span-2">
                      <label
                        for="quantity"
                        class="block text-xs xl:text-sm font-medium text-gray-700"
                      >Select Floor</label>
          
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <select
                          id="priority"
                          v-model="selectedFloor"
                          name="priority"
                          required
                          class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-xs xl:text-sm"
                          @change="selectedRoomId = null"
                        >
                          <option :value="null" selected disabled>
                            -- Choose Floor --
                          </option>
                          <option
                            v-for="(floor, index) in selectedProject.floors"
                            :key="index"
                            :value="floor"
                          >
                            {{ floor.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                        
                    <div v-if="selectedFloor" class="col-span-3 sm:col-span-2">
                      <label
                        for="installTime"
                        class="block text-xs xl:text-sm font-medium text-gray-700"
                      >
                        Select Room
                      </label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <select
                          id="priority"
                          v-model="selectedRoomId"
                          name="priority"
                          required
                          class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-xs xl:text-sm"
                        >
                          <option :value="null" selected disabled>
                            -- Choose Floor --
                          </option>
                          <option
                            v-for="(room, index) in selectedFloor.rooms"
                            :key="index"
                            :value="room.id"
                          >
                            {{ room.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="projectExist || selectedRoomId" class="border divide-y divide-gray-700 mt-10">
                  <div class="rounded-t-sm bg-white overflow-hidden">
                    <div class="flex relative justify-between items-center p-4 text-white bg-black cursor-pointer" @click="selectAccordion('global-properties')">
                      <div class="absolute inset-0 bg-gradient-to-br from-blue-dark via-green-dark to-blue-dark opacity-75" />

                      <div class="flex relative z-20 items-center">
                        <div class="flex-shrink-0 p-3 bg-blue-light bg-opacity-25 rounded-sm">
                          <HeroIcon icon="Globe" class="w-6 h-6 text-blue-light" />
                        </div>

                        <div class="ml-4">
                          <h3 class="text-lg font-medium">
                            Global Properties
                          </h3>
                          <p class="text-xs">
                            Anything set here will affect all Products in this Project. Some settings like 'Product Description' will impact all Projects.
                          </p>
                        </div>
                      </div>

                      <div class="flex relative z-20 justify-center items-center w-6 h-6 bg-blue-light rounded-sm">
                        <svg v-if="activeAccordion === 'global-properties'" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                        </svg>
                        <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                      </div>
                    </div>
                    <div v-show="activeAccordion === 'global-properties'" class="bg-gray-50 px-4 py-6 grid grid-cols-6 gap-4">
                      <div class="col-span-6">
                        <div class="flex justify-between">
                          <label for="product_description" class="block text-sm font-medium text-gray-700">Product Description</label>
                          <span class="text-xs text-gray-500">Optional</span>
                        </div>
                        <div class="mt-1">
                          <input v-model="productData.product.description" type="text" name="product_description" class="form-input" placeholder="e.g. In-Ceiling Speaker" aria-describedby="product-name-override">
                        </div>
                        <p class="mt-2 text-xs text-gray-500">
                          A brief description of what the product is, shown on the Customers Sales Quote.
                        </p>
                      </div>

                      <div class="col-span-3">
                        <label for="customer_price" class="block text-sm font-medium text-gray-700">Customer Price</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-sm">
                              {{ currency }}
                            </span>
                          </div>
                          <input id="customer_price" v-model="productData.product.customer_price" type="number" step="0.01" name="customer_price" class="form-input pl-7">
                        </div>
                        <p class="mt-2 text-xs text-gray-500">
                          This will be the price regardless of Supplier.
                        </p>
                      </div>

                      <div v-if="projectStages.length > 0" class="col-span-3">
                        <label for="project_stage" class="block text-sm font-medium text-gray-700">Project Install Stage</label>
                        <select id="project_stage" v-model="productData.product.stage_id" name="project_stage" required class="select-input">
                          <option :value="null" selected disabled>
                            -- Choose Project Stage --
                          </option>
                          <option v-for="(projectStage, index) in projectStages" :key="index" :value="projectStage.id">
                            {{ projectStage.name }}
                          </option>
                        </select>
                        <p class="mt-2 text-xs text-gray-500">
                          The stage at which this Product will be installed.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="rounded-b-sm bg-white overflow-hidden">
                    <div class="flex relative justify-between items-center p-4 text-white bg-black cursor-pointer" @click="selectAccordion('installation-time')">
                      <div class="absolute inset-0 bg-gradient-to-br from-blue-dark via-green-dark to-blue-dark opacity-75" />

                      <div class="flex relative z-20 items-center">
                        <div class="flex-shrink-0 p-3 bg-blue-light bg-opacity-25 rounded-sm">
                          <HeroIcon icon="Clock" class="w-6 h-6 text-blue-light" />
                        </div>

                        <div class="ml-4">
                          <h3 class="text-lg font-medium">
                            Installation Time
                          </h3>
                          <p class="text-xs">
                            Setting an installation time will allow you to better quote for your labour.
                          </p>
                        </div>
                      </div>

                      <div class="flex relative z-20 justify-center items-center w-6 h-6 bg-blue-light rounded-sm">
                        <svg v-if="activeAccordion === 'installation-time'" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                        </svg>
                        <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                      </div>
                    </div>

                    <div v-show="activeAccordion === 'installation-time'" class="bg-gray-50 px-4 py-6">
                      <p v-if="productData.product" class="text-sm text-gray-600">
                        This will be the installation times for all occurances of a <span class="text-blue-light font-bold">{{ product.name }}</span> in this room.
                      </p>

                      <div v-if="teamPositions.length > 0">
                        <div class="flex items-center p-4 my-6 bg-gradient-to-br from-black via-green-dark to-black rounded-sm">
                          <input id="saveAsDefaultInstallTime" v-model="productData.updateDefaultInstallInfo" type="checkbox" :value="true" class="focus:ring-indigo-500 text-blue-dark border-gray-300 rounded mr-4">
                          <div class="flex flex-col">
                            <label for="saveAsDefaultInstallTime" class="font-bold text-white">Save as Install Time Defaults</label>
                            <p v-if="productData.product" class="text-xs text-gray-200">
                              This will update your default install time(s) for <span class="text-blue-light font-bold">{{ productData.product.name }}</span>.
                            </p>
                            <p class="text-xs text-gray-200">
                              <span class="font-bold">Note:</span> Previously added install times in other Projects will remain unaffected.
                            </p>
                          </div>
                        </div>

                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table class="w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                              <tr>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Installation Type
                                </th>
                                <th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Install Time
                                </th>
                                <th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Total Install Time
                                </th>
                              </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                              <tr v-for="(installTime, posIdx) in productData.installTimeDetails" :key="posIdx">
                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {{ installTime.position }}
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-900">
                                  <div class="flex rounded-md shadow-sm">
                                    <input v-model="installTime.install_time_mins" type="number" min="0" class="text-right form-input mt-0 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300">
                                    <span class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                                      {{ 'minute' | pluralize(installTime.install_time_mins) }}
                                    </span>
                                  </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-900">
                                  {{ 'minute' | pluralize(getTotalInstallTypeTime(installTime.id), true) }}
                                </td>
                              </tr>
                            </tbody>
                            <tfoot class="bg-gray-50">
                              <tr>
                                <td scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Total Install Time: 
                                </td>
                                <td scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  {{ 'minute' | pluralize(productInstallTime(), true) }}
                                </td>
                                <td scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  {{ 'minute' | pluralize(productTotalInstallTime(), true) }}
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                      <div v-else>
                        You haven't added any team positions. To associate install time with products, add at least one Team Position.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bg-white flex justify-end mt-6 bg-opacity-75">
                  <button type="button" class="mt-4 btn-primary-outline-md" @click="closeModal">
                    Cancel
                  </button>
                  <button
                    v-if="projectExist || selectedRoomId"
                    type="button"
                    class="mt-4 btn-primary-md ml-2"
                    @click="addCustomProductToProject"
                  >
                    + Add to Project
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: Boolean,
    product: {
      type: Object,
      default: () => {},
      required: false
    },
    projects: {
      type: Array,
      default: () => [],
      required: false
    },
    projectExist: Boolean
  },
  data() {
    return {
      selectedProjectId: null,
      selectedRoomId: null,
      selectedProject: null,
      selectedFloor: null,
      loading: false,
      activeAccordion: null,
      productData: {
        product: null,
        qty: 1,
        installTimeDetails: [],
        updateDefaultInstallInfo: false,
        accountCode: null,
        taxRate: null
      }
    };
  },
  computed: {
    project() {
      return this.$store.state.project.project;
    },
    projectStages() {
      return this.$store.state.system.projectStages;
    },
    currency() {
      return this.$store.state.auth.user.team.team_currency.symbol;
    },
    categories() {
      return this.$store.state.system.categories;
    },
    teamsAvailableSuppliers() {
      const suppliers = this.$store.state.auth.user.team.suppliers;
      const availableSuppliers = [];
      suppliers.map(el => availableSuppliers.push(el.supplier));
      return availableSuppliers;
    },
    roomId() {
      return this.$route.params.roomId;
    },
    teamPositions () {
      return this.$store.state.system.teamPositions;
    }
  },
  watch: {
    product: [{
      handler: 'getCustomProduct'
    }]
  },
  mounted() {
    this.getCustomProduct(this.product);
  },
  methods: {
    getCustomProduct(product) {
      if (product) {
        this.productData.product = product;
        // Setup Install Times
        this.teamPositions.forEach((el) => {
          const details = { ...el };
          details.install_time_mins = 0;
          const defaultTime = product.team_install_info_default.find(installTime => installTime.team_position_id === el.id);
          details.install_time_mins = defaultTime ? defaultTime.install_time_mins : 0;
          this.productData.installTimeDetails.push(details);
        });
      }
    },
    increaseSelCustProdQty() {
      this.productData.qty = this.productData.qty += 1;
    },
    decreaseSelCustProdQty() {
      if (this.productData.qty > 1) {
        this.productData.qty--;
      }
    },
    getProject() {
      this.selectedProject = null;
      this.selectedFloor = null;
      this.selectedRoomId = null;
      this.loading = true;
      this.axios.get(`/api/projects/${this.selectedProjectId}`).then((response) => {
        this.selectedProject = {
          id: response.data.id,
          name: response.data.name,
          floors: response.data.floors,
          network_range_1: response.data.network_range_1,
          network_range_2: response.data.network_range_2,
          network_range_3: response.data.network_range_3,
          stage: response.data.stage
        };
      }).finally(() => {
        this.loading = false;
      });
    },
    addCustomProductToProject() {
      let projectId, roomId;
      projectId = this.projectExist ? this.project.id : this.selectedProjectId;
      roomId = this.projectExist ? this.roomId : this.selectedRoomId;

      this.axios.post(`/api/projects/${projectId}/room/${roomId}/add-custom-product`, { customProduct: this.productData }).then(() => {
          this.$notify({
            type: "success",
            title: `${this.productData.qty}x ${this.productData.product.name} Custom Product Added`
          });
        })
        .finally(() => {
          this.$forceUpdate();
          this.$router.push({
            name: "projectRoomProducts",
            params: { projectId, roomId }
            });
          this.closeModal();
        });
    },
    closeModal() {
      this.selectedProjectId = null;
      this.selectedProject = null;
      this.selectedFloor = null;
      this.selectedRoomId = null;
      this.activeAccordion = null;
      this.$emit("close");
    },
    getTotalInstallTypeTime (positionId) {
      const posInstallTimeDetails = this.productData.installTimeDetails.find(el => el.id === positionId);
      return (posInstallTimeDetails.install_time_mins * this.productData.qty);
    },
     productInstallTime () {
      let installTime = 0;
      this.productData.installTimeDetails.forEach((el) => {
        installTime += parseInt(el.install_time_mins);
      });
      return installTime;
    },
    productTotalInstallTime () {
      const totalInstallTime = this.productInstallTime() * this.productData.qty;
      return totalInstallTime;
    },
    selectAccordion (accordion) {
      if (this.activeAccordion === accordion) {
        this.activeAccordion = null;
      } else {
        this.activeAccordion = accordion;
      }
    }
  }
};
</script>