<template>
  <div v-if="authenticated">
    <main class="shell">
      <mobile-nav :active="mobNavOpen" @activeFalse="setMobileNavOpen" />
      <header class="animate-first header flex items-center flex-wrap transition ease-in-out px-6 md:px-8">
        <div v-if="user" class="user flex flex-1 items-center" @click="goToHomepage">
          <div v-if="user.team.image_url" class="rounded-full flex h-10 w-10 xl:h-12 xl:w-12 mr-4 items-center justify-center">
            <img :src="user.team.image_url" alt>
          </div>
          <div class="user-name flex-1">
            <p class="font-medium xl:font-bold text-xs xl:text-lg">
              {{ user.team.name }}
            </p>
            <p class="text-xxs text-gray-600 xl:text-sm">
              {{ user.fullname }}
            </p>
            <button type="button" class="text-xxs xl:text-sm mt-2 flex items-end bg-lime font-semibold xl:font-medium text-gray-900 text-center rounded hover:text-gray-200 p-1 px-2 shadow-lg" @click="logout">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
              <span class="text-xxs xl:text-sm"> Logout</span>
            </button>
          </div>
        </div>

        <div class="con" :class="{active : mobNavOpen}" @click="mobNavOpen = !mobNavOpen">
          <div class="bar arrow-top-r" />
          <div class="bar arrow-middle-r" />
          <div class="bar arrow-bottom-r" />
        </div>

        <nav class="text-center hidden sm:block h-1/2 xl:h-max">
          <router-link class="font-semibold mr-0 text-base xl:text-2xl xl:mr-2 p-6 xl:p-10" to="/builder">
            Builder
          </router-link>
          <router-link class="font-semibold mr-0 text-base xl:text-2xl xl:mr-2 p-6 xl:p-10" to="/browse">
            Browse
          </router-link>
          <router-link class="font-semibold mr-0 text-base xl:text-2xl xl:mr-2 p-6 xl:p-10" to="/search">
            Search
          </router-link>
        </nav>

        <div class="flex-1 text-right hidden sm:block">
          <div class="flex flex-col justify-end items-end">
            <button class="bg-gradient-green p-2 xl:p-3 text-xxs xl:text-sm font-semibold text-white rounded hover:opacity-50 shadow-lg" @click="goToInstallerMode">
              Switch to Installer Mode
            </button>
          </div>
        </div>
      </header>

      <transition-page>
        <router-view />
      </transition-page>

      <div class="flex justify-center">
        <div class="specifi-branding bg-gray-900 w-1/2 rounded-t-full">
          <img src="@/assets/img/specifi_logo_white.svg" alt="Specifi" class="h-6 xl:h-8">
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import MobileNav from '../components/MobileNav.vue';
import TransitionPage from '../components/transitions/TransitionPage.vue';

export default {
  components: {
    MobileNav,
    TransitionPage
  },
  data() {
    return {
      showLoader: true,
      isLoading: true,
      mobNavOpen: false,
      loadingApplication: true
    };
  },
  computed: {
    authenticated() {
      return this.$store.state.auth.authenticated;
    },
    user() {
      return this.$store.state.auth.user;
    },
    showPricing() {
      return this.$store.state.system.showPricing;
    }
  },
  mounted() {
    this.getTeamSpecificProducts();
    Promise.all([
      this.$store.dispatch('system/getCategories'),
      this.$store.dispatch('system/getProjectInstallDefaults'),
      this.$store.dispatch('system/getBrands'),
      this.$store.dispatch('integrations/getLinkedAccountsPackage'),
      this.$store.dispatch('system/getProductSettings')
    ]).finally(() => {
      this.loadingApplication = false;
    });
  },
  methods: {
    ...mapActions({
      logoutAction: 'auth/logout',
      getTeamSpecificProducts: 'system/getTeamSpecificProducts'

    }),
    async logout() {
      await this.logoutAction();
      this.$router.replace({ name: 'Login' });
    },
    goToHomepage() {
      if (this.$router.currentRoute.name !== 'projects') {
        this.$router.push({ name: 'projects' });
      }
    },
    goToInstallerMode() {
      if (this.$route.params.projectId) {
        window.location = `${process.env.VUE_APP_CLIENT_URL}/projects/${this.$route.params.projectId}`;
      } else {
        window.location = `${process.env.VUE_APP_CLIENT_URL}/projects`;
      }
    },
    setMobileNavOpen(){
      this.mobNavOpen = false;
    }
  }
};
</script>