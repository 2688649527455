<template>
  <router-view :key="$route.fullPath" />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  mounted() {
    if (this.$route.params.projectId) {
      this.getProject(this.$route.params.projectId);
    }
    this.getProjectInstallDefaults();
  },
  methods: {
    ...mapActions({
      getProject: 'project/getProject',
      getProjectInstallDefaults: 'system/getProjectInstallDefaults'
    })
  }
};
</script>
