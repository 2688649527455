<template>
  <div :class="{ loader: true, fadeout: !isLoading }">
    <div class="flex flex-wrap">
      <img src="@/assets/img/specifi_logo_blue.svg" width="350" height="175" alt="specifi">
      <svg id="L4" version="1.1" height="100px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           viewBox="0 -25 60 100" enable-background="new 0 0 0 0" xml:space="preserve"
      >
        <circle fill="#77d183" stroke="none" cx="6" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"
          />    
        </circle>
        <circle fill="#85b177" stroke="none" cx="26" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite" 
            begin="0.2"
          />       
        </circle>
        <circle fill="#0e2d29" stroke="none" cx="46" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite" 
            begin="0.3"
          />     
        </circle>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingScreen",
  props: {isLoading: {type: Boolean}}
};
</script>

<style>
.fadeout {
  animation: fadeout 1s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>