<template>
  <div>
    <div v-if="project" class="shell-container animate-second">
      <header class="router-header flex flex-wrap content-center transition ease-in-out container px-4 md:px-8">
        <div class="flex-1">
          <button class="bg-gradient text-white font-bold p-1 xl:p-2 rounded-full" @click="routerBack()">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 xl:h-6 xl:w-6" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>

        <h2 class="flex-10 sans-serif text-2xl xl:text-3xl text-center font-semibold my-0 mx-auto">
          Overview -
          <span class="text-gradient bg-gradient-to-br from-blue-dark to-blue">{{ project.name }}</span>
        </h2>
        <div class="flex-1" />
      </header>
      <h2 class="text-center text-lg xl:text-2xl font-bold mb-8">
        Floors
      </h2>

      <div v-for="(floor, floorIndex) in project.floors" :key="floorIndex" class="floor-overview bg-gradient-to-br from-blue-dark to-blue rounded-lg p-6 shadow-md mb-4">
        <h5 class="text-base xl:text-lg font-bold pl-1">
          {{ floor.name }}
        </h5>
        <div class="flex flex-col sm:flex-row sm:flex-wrap mt-1 sm:mt-0 sm:space-x-6 mb-4 pl-1">
          <div class="flex items-center mt-2 text-xs text-white font-semibold">
            <svg class="flex-shrink-0 mr-1.5 w-4 h-4 text-lime" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
            </svg>
            {{ 'Product' | pluralize(totalFloorProductsCount(floor.rooms), true) }}
          </div>
          <div class="flex items-center mt-2 text-xs text-white font-semibold">
            <svg class="flex-shrink-0 mr-1.5 w-4 h-4 text-lime" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
              <path fill-rule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clip-rule="evenodd" />
            </svg>
            {{ totalFloorPrice(floor.rooms) | currency }}
          </div>
        </div>
        <ul>
          <li v-for="(room, roomIndex) in floor.rooms" :key="roomIndex" class="bg-white rounded-md shadow-md mb-4 text-black flex items-center p-4 flex-col">
            <div class="flex flex-row w-full justify-between items-center" @click="showRoomProducts(floorIndex, roomIndex)">
              <div class="flex items-center">
                <img
                  v-if="room.image_url"
                  :src="room.image_url"
                  class="uploadedImage w-12 h-12 object-cover object-center rounded-md shadow-md mr-4"
                >
                <img v-else class="noImage" src="@/assets/img/icons/rooms.svg" alt="Room">

                <span>
                  <h4 class="font-semibold xl:font-bold text-base xl:text-lg">{{ room.name }}</h4>
                  <div class="flex flex-col sm:flex-row sm:flex-wrap mt-1 sm:mt-0 sm:space-x-6">
                    <div class="flex items-center mt-2 text-xs text-gray-600 font-semibold">
                      <svg class="flex-shrink-0 mr-1.5 w-4 h-4 text-blue" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
                      </svg>
                      {{ 'Product' | pluralize(totalRoomProductsCount(room.room_products.length, room.room_custom_products.length, room.room_placeholder_products, room.room_product_bundles), true) }}
                    </div>
                    <div class="flex items-center mt-2 text-xs text-gray-600 font-semibold">
                      <svg class="flex-shrink-0 mr-1.5 w-4 h-4 text-blue" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                        <path fill-rule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clip-rule="evenodd" />
                      </svg>
                      {{ getRoomCost(room) | currency }}
                    </div>
                  </div>
                </span>
              </div>

              <svg v-if="showRoom == `${floorIndex}${roomIndex}`" fill="none" viewBox="0 0 24 24" stroke="#fff" class="chevron-up bg-gradient rounded-sm w-5 h-5 xl:w-6 xl:h-6 flex items-center justify-center">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
              </svg>
              <svg v-else fill="none" viewBox="0 0 24 24" stroke="#fff" class="chevron-down bg-gradient rounded-sm w-5 h-5 xl:w-6 xl:h-6 flex items-center justify-center">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>

            <div v-show="showRoom == `${floorIndex}${roomIndex}`" class="border-dashed border-2 border-gray-400 w-full p-6 mt-4 rounded-md shadow-md">
              <h4 class="text-base xl:text-lg font-bold mb-6">
                Products
              </h4>

              <div v-if="room.room_products.length > 0" class="grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4">
                <div v-for="(product, index) in groupedProducts(room)" :key="index" class="productsOverview relative">
                  <div class="editBtn p-2 top-0 right-0 xl:p-3 bg-lime shadow-md" @click="editProduct(product, room)">
                    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="pencil w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>
                  </div>

                  <div class="slide--image">
                    <!-- Upgrade Options -->
                    <div v-if="product.upgrades.length > 0" class="absolute -top-5 left-0 p-2">
                      <div class="flex -space-x-2">
                        <div v-for="(productUpgrade, upIdx) in product.upgrades" :key="upIdx" class="w-10 h-10 flex items-center justify-center rounded-full ring-2 ring-gray-200 bg-gradient-to-br from-green-light to-green-dark shadow-lg p-1">
                          <img v-if="productUpgrade.upgrade_product && productUpgrade.upgrade_product.first_image" :src="productUpgrade.upgrade_product.first_image.url" class="object-contain" alt="">
                          <img v-else-if="productUpgrade.upgrade_product && productUpgrade.upgrade_product.image_url" :src="productUpgrade.upgrade_product.image_url" class="object-contain" alt="">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <figure class="imgWrapper pt-4">
                      <img v-if="product.img" :src="product.img">
                    </figure>

                    <div class="my-4 text-center">
                      <p v-if="product.brand" class="text-xs text-gray-500">
                        {{ product.brand }}
                      </p>
                      <p v-if="product.supplier" class="text-xs font-semibold xl:font-bold text-supplier">
                        {{ product.supplier.name }}
                      </p>
                      <p class="text-sm font-semibold xl:font-bold text-gray-700">
                        {{ product.name }}
                      </p>
                      <p class="text-xs font-semibold xl:font-bold text-gray-500 mt-2">
                        {{ product.customer_price | currency }}
                      </p>
                      <div class="productActions flex justify-center items-center mt-4 px-2">
                        <button type="button" class="bg-gradient rounded-full text-white h-5 w-5 xl:h-6 xl:w-6" @click="decreaseRoomProduct(product, room)">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 xl:h-6 xl:w-6" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z" clip-rule="evenodd" />
                          </svg>
                        </button>
                        <span class="bg-white text-xs xl:text-base font-bold appearance-none w-1/2 rounded-lg text-center px-6 py-2 mx-6 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">{{ product.qty }}</span>
                        <button type="button" class="bg-gradient rounded-full text-white h-5 w-5 xl:h-6 xl:w-6" @click="increaseRoomProduct(product, room)">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 xl:h-6 xl:w-6" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <p class="text-gray-600 text-center my-6">
                  No products added to {{ room.name }}.
                </p>
              </div>

              <h4 v-if="room.room_product_bundles && room.room_product_bundles.length > 0" class="text-base xl:text-lg font-bold my-6">
                Bundle Products
              </h4>

              <div v-if="room.room_product_bundles && room.room_product_bundles.length > 0" class="grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4">
                <div v-for="(product, index) in groupedProductBundles(room)" :key="index" class="productsOverview relative">
                  <div class="editBtn p-2 top-0 right-0 xl:p-3 bg-lime shadow-md" @click="editProductBundle(product, room)">
                    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="pencil w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>
                  </div>
                  <div class="slide--image">
                    <div v-if="product.grouped_products.length > 0" class="absolute left-0 top-0 p-2 z-50">
                      <div class="flex -space-x-2">
                        <div v-for="(productImage, upIdx) in product.grouped_products.slice(0,3)" :key="upIdx" class="w-10 h-10 flex items-center justify-center rounded-full ring-2 ring-gray-200 bg-gradient-to-br from-blue-light to-blue-dark shadow-lg p-1">
                          <img v-if="productImage.product.first_image" :src="productImage.product.first_image.url" class="object-contain" alt="">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <figure class="imgWrapper pt-4">
                      <img v-if="product.grouped_products.length > 0" :src="product.grouped_products[0].product.first_image.url" alt="Specifi">
                      <img v-else-if="product.grouped_custom_products.length > 0" :src="product.grouped_custom_products[0].product.image_url" alt="Specifi">
                    </figure>

                    <div class="my-4 text-center">
                      <p v-if="product.brands" class="text-xs text-gray-500">
                        {{ product.brands }}
                      </p>
                      <p v-if="product.supplier" class="text-xs font-semibold xl:font-bold text-supplier">
                        {{ product.supplier.name }}
                      </p>
                      <p class="text-sm font-semibold xl:font-bold text-gray-700">
                        {{ product.name }}
                      </p>
                      <p class="text-xs font-semibold xl:font-bold text-gray-500 mt-2">
                        {{ product.customer_price | currency }}
                      </p>
                      <div class="productActions flex justify-center items-center mt-4 px-2">
                        <button type="button" class="bg-gradient rounded-full text-white h-5 w-5 xl:h-6 xl:w-6" @click="decreaseProductBundleQty(product, room)">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 xl:h-6 xl:w-6" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z" clip-rule="evenodd" />
                          </svg>
                        </button>
                        <span class="bg-white text-xs xl:text-base font-bold appearance-none w-1/2 rounded-lg text-center px-6 py-2 mx-6 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">{{ product.qty }}</span>
                        <button type="button" class="bg-gradient rounded-full text-white h-5 w-5 xl:h-6 xl:w-6" @click="incrementProductBundleQty(product, room)">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 xl:h-6 xl:w-6" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h4 v-if="room.room_custom_products.length > 0" class="text-base xl:text-lg font-bold my-6">
                Custom Products
              </h4>

              <div v-if="room.room_custom_products.length > 0" class="grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4">
                <div v-for="(product, index) in groupedCustomProducts(room)" :key="index" class="productsOverview relative">
                  <span class="text-xs -top-1 right-22 xl:top-2 xl:right-12 absolute bg-blue-dark text-white px-3 py-1 font-light rounded-full shadow z-10">
                    Custom
                  </span>
                  <div class="editBtn p-2 top-0 right-0 xl:p-3 bg-lime shadow-md" @click="editCustomProduct(product, room)">
                    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="pencil w-4 h-4">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                      />
                    </svg>
                  </div>
                  <div class="slide--image">
                    <!-- Upgrade Options -->
                    <div v-if="product.upgrades.length > 0" class="absolute -top-5 left-0 p-2">
                      <div class="flex -space-x-2">
                        <div v-for="(productUpgrade, upIdx) in product.upgrades" :key="upIdx" class="w-10 h-10 flex items-center justify-center rounded-full ring-2 ring-gray-200 bg-gradient-to-br from-green-light to-green-dark shadow-lg p-1">
                          <img v-if="productUpgrade.upgrade_product && productUpgrade.upgrade_product.first_image" :src="productUpgrade.upgrade_product.first_image.url" class="object-contain" alt="">
                          <img v-else-if="productUpgrade.upgrade_product && productUpgrade.upgrade_product.image_url" :src="productUpgrade.upgrade_product.image_url" class="object-contain" alt="">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <figure class="imgWrapper pt-4">
                      <img v-if="product.img" :src="product.img">
                      <img v-else class="w-1/3" src="@/assets/img/speaker.svg">
                    </figure>

                    <div class="my-4 text-center">
                      <p v-if="product.brand" class="text-xs text-gray-500">
                        {{ product.brand }}
                      </p>
                      <p class="text-sm font-semibold xl:font-bold text-gray-700">
                        {{ product.name }}
                      </p>
                      <p class="text-xs font-semibold xl:font-bold text-gray-500 mt-2">
                        {{ product.customer_price | currency }}
                      </p>
                      <div class="productActions flex justify-center items-center mt-4 px-2">
                        <button type="button" class="bg-gradient rounded-full text-white h-5 w-5 xl:h-6 xl:w-6" @click="decreaseCustomProductQty(product, room)">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 xl:h-6 xl:w-6" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z" clip-rule="evenodd" />
                          </svg>
                        </button>
                        <span class="bg-white text-xs xl:text-base font-bold appearance-none w-1/2 rounded-lg text-center px-6 py-2 mx-6 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">{{ product.qty }}</span>
                        <button type="button" class="bg-gradient rounded-full text-white h-5 w-5 xl:h-6 xl:w-6" @click="incrementCustomProductQty(product, room)">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 xl:h-6 xl:w-6" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h4 v-if="room.room_placeholder_products && room.room_placeholder_products.length > 0" class="text-base xl:text-lg font-bold my-6">
                Placeholder Products
              </h4>

              <div v-if="room.room_placeholder_products && room.room_placeholder_products.length > 0" class="grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4">
                <div v-for="(product, index) in room.room_placeholder_products" :key="index" class="productsOverview relative">
                  <span class="text-xs -top-1 right-22 xl:top-2 xl:right-12 absolute bg-blue-light text-white px-1 py-0.5 xl:px-3 xl:py-1 font-light rounded-full shadow z-10">
                    Placeholder
                  </span>
                  <div class="editBtn p-2 top-0 right-0 xl:p-3 bg-lime shadow-md" @click="editPlacholderProduct(product)">
                    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="pencil w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>
                  </div>
                  <div>
                    <figure class="imgWrapper pt-4">
                      <img v-if="product.img" :src="product.img">
                    </figure>
                    <div class="my-4 text-center">
                      <p v-if="product.placeholder_product.brand" class="text-xs text-gray-500">
                        {{ product.placeholder_product.brand }}
                      </p>
                      <p v-if="product.placeholder_product.supplier" class="text-xs font-medium xl:font-bold text-supplier">
                        {{ product.placeholder_product.supplier }}
                      </p>
                      <p class="text-sm font-semibold xl:font-bold text-gray-700">
                        {{ product.placeholder_product.name }}
                      </p>
                      <p class="text-xs font-semibold xl:font-bold text-gray-500 mt-2">
                        {{ product.placeholder_product.retail_price | currency }}
                      </p>
                      <div class="productActions flex justify-center items-center mt-4 px-2">
                        <button type="button" class="bg-gradient rounded-full text-white h-5 w-5 xl:h-6 xl:w-6" @click="removePlaceholderProductFromRoom(product, room.floor_id)">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 xl:h-6 xl:w-6" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z" clip-rule="evenodd" />
                          </svg>
                        </button>
                        <input v-model="product.qty" class="bg-white text-xs xl:text-base font-bold appearance-none w-1/2 rounded-lg text-center px-6 py-2 mx-6 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text">
                        <button type="button" class="bg-gradient rounded-full text-white h-5 w-5 xl:h-6 xl:w-6" @click="incrementPlaceholderProductQty(product, room.floor_id)">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 xl:h-6 xl:w-6" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else class="flex justify-center py-20 my-20">
      <img src="@/assets/img/specifi_logo_blue.svg" width="350" height="175" alt="specifi">
      <svg id="L4" height="100px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 -25 60 100" enable-background="new 0 0 0 0" xml:space="preserve">
        <circle fill="#77d183" stroke="none" cx="6" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"
          />    
        </circle>
        <circle fill="#85b177" stroke="none" cx="26" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite" 
            begin="0.2"
          />       
        </circle>
        <circle fill="#0e2d29" stroke="none" cx="46" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite" 
            begin="0.3"
          />     
        </circle>
      </svg>
    </div>
    
    <edit-product-modal
      :show-modal="showEditModal"
      :edit-product="editProductData"
      @close="closeEditProductModal"
      @upgradeProductRemoved="upgradeProductRemoved"
      @productUpgraded="productUpgraded"
      @showUpgradeProductModal="showUpgradeProductModal"
      @updateProductDetails="updateProductInfo"
    />

    <EditCustomProductModal
      :show="showEditCustomProductModal"
      :edit-product-data="editCustomProductData"
      @showUpgradeProductModal="showUpgradeProductModal"
      @upgradeCustomProductRemoved="upgradeCustomProductRemoved"
      @productUpgraded="productUpgraded"
      @updated="customProductUpdated"
      @close="cancelEditCustomProduct"
    />

    <AddUpgradeProductModal
      :show="showAddProductUpgradeModal"
      :product-to-upgrade="productToUpgrade"
      :room-id="editProductData && editProductData.room ? editProductData.room.id : (editCustomProductData && editCustomProductData.room ? editCustomProductData.room.id : 0)"
      @upgradeProductAdded="upgradeProductAddedToProduct"
      @close="closeAddUpgradeProductModal"
    />

    <EditPlaceholderProductModal
      :show="showEditPlaceholderProductModal"
      :edit-product-data="editPlaceholderProductData"
      @close="cancelEditPlaceholderProduct"
    />

    <EditProductBundleModal
      :loading="loadingProductBundleToEdit"
      :show="showEditProductBundleModal"
      :edit-product-data="editProductBundleData"
      :save="saveEditProductBundle"
      @close="cancelEditProductBundle"
    />
  </div>
</template>

<script>
import EditProductModal from "../../../components/modals/EditProductModal.vue";
import EditPlaceholderProductModal from "../../../components/modals/EditPlaceholderProductModal.vue";
import EditCustomProductModal from "../../../components/modals/EditCustomProductModal.vue";
import AddUpgradeProductModal from '../../../components/modals/AddUpgradeProductModal.vue';
import EditProductBundleModal from '../../../components/modals/EditBundleProductModal.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    EditProductModal,
    EditPlaceholderProductModal,
    EditCustomProductModal,
    AddUpgradeProductModal,
    EditProductBundleModal
  },
  data() {
    return {
      projectrooms: [],
      showRoom: -1,
      showEditModal: false,
      showEditPlaceholderProductModal: false,
      editPlaceholderProductData: {},
      showEditCustomProductModal: false,
      editCustomProductData: {
        productData: {
         products: []
        },
        installTimeDetails: [],
        room: null
      },
      editProductData: {
        products: [],
        installTimeDetails: [],
        show_on_sales_quote: true,
        cables: []
      },
      showAddProductUpgradeModal: false,
      productToUpgrade: null,
      showEditProductBundleModal: false,
      loadingProductBundleToEdit: false,
      editProductBundleData: {
        product: null,
        groupedProducts: [],
        groupedCustomProducts: []
      },
      room_id: null
    };
  },
  computed: {
    project() {
      return this.$store.state.project.project;
    },
    teamPositions() {
      return this.$store.state.system.teamPositions;
    },
    projectId() {
      return this.$route.params.projectId;
    }
  },
  methods: {
     ...mapActions({
      getProject: 'project/getProject'
    }),
    checkIfInstallTimeExist(product){
      return Object.keys(product).filter(key => product[key].install_time_mins > 0);
    },
    groupedProducts(room) {
      const ggs = this.groupBy(room.room_products);

      return Object.keys(ggs).map(ind => {
        const prodG = ggs[ind];

        return {
          id: prodG[0].product ? prodG[0].product.id : '',
          name: prodG[0].product.name,
          brand: prodG[0].product.brand,
          qty: prodG.length,
          img: prodG[0].product.first_image ? prodG[0].product.first_image.url : "",
          customer_price: prodG[0].customer_price,
          nameOverride: prodG[0].name_override,
          upgrades: prodG[0].upgrade_products,
          supplier: {
            id: prodG[0].supplier.id,
            name: prodG[0].supplier.name
          },
          products: prodG,
          installTimeToShow: this.checkIfInstallTimeExist(prodG[0].install_times),
          xeroConfig: prodG[0].xero_config,
          quickBooksConfig: prodG[0].quick_books_config
        };
      });
    },
    groupedCustomProducts(room) {
      const ggs = this.groupByCustom(room.room_custom_products);

      return Object.keys(ggs).map(ind => {
        const prodG = ggs[ind];
        return {
          id: prodG[0].product.id,
          name: prodG[0].product.name,
          brand: prodG[0].product.brand,
          qty: prodG.length,
          install_time: prodG[0].product.install_time,
          install_stage: prodG[0].product.stage_id,
          customer_price: prodG[0].customer_price,
          description: prodG[0].product.description,
          img: prodG[0].product.image_url ? prodG[0].product.image_url : "",
          install_times: prodG[0].install_times,
          installTimeToShow: this.checkIfInstallTimeExist(prodG[0].install_times),
          deleted_at: prodG[0].product.deleted_at,
          products: prodG,
          upgrades: prodG[0].upgrade_products,
          xeroConfig: prodG[0].xero_config,
          quickBooksConfig: prodG[0].quick_books_config
        };
      });
    },
    groupedProductBundles(room) {
      if (!room || !room.room_product_bundles) return [];

      const ggs = this.groupByBundle(room.room_product_bundles);
      return Object.keys(ggs).map((ind) => {
        const prodG = ggs[ind];
        const installTimeToShow = [];

        // Get the pricing that the bundle was added with:
        // i.e. not the update product bundle price if it has been changed.
        let customerPrice = 0;
        let tradePrice = 0;
        prodG[0].grouped_products.forEach((el) => {
          customerPrice += el.customer_price * el.qty;
          tradePrice += el.trade_price * el.qty;
        });

        prodG[0].grouped_custom_products.forEach((el) => {
          customerPrice += el.customer_price * el.qty;
          tradePrice += el.trade_price * el.qty;
        });

        return {
          id: prodG[0].team_product_bundle.id,
          name: prodG[0].team_product_bundle.name,
          brands: prodG[0].team_product_bundle.product_brands,
          suppliers: prodG[0].team_product_bundle.product_suppliers,
          qty: prodG.length,
          customer_price: customerPrice,
          trade_price: tradePrice,
          description: prodG[0].team_product_bundle.description,
          deleted_at: prodG[0].team_product_bundle.deleted_at,
          grouped_products: prodG[0].grouped_products,
          grouped_custom_products: prodG[0].grouped_custom_products,
          installTimeToShow: installTimeToShow
        };
      });
    },
    groupProductBundles(productBundles) {
      const products = [];
      const customProducts = [];

      productBundles.forEach((el) => {
          el.bundle_products.forEach((elem) => {
            products.push(elem);
          });

          el.bundle_custom_products.forEach((elem) => {
            customProducts.push(elem);
          });


        // Get the pricing that the bundle was added with:
        // i.e. not the update product bundle price if it has been changed.
        // let customerPrice = 0;
        // let tradePrice = 0;
        // prodG[0].bundle_products.forEach((el) => {
        //   customerPrice += el.customer_price;
        //   tradePrice += el.trade_price;
        // });

        // prodG[0].bundle_custom_products.forEach((el) => {
        //   customerPrice += el.customer_price;
        //   tradePrice += el.trade_price;
        // });
      });

      return {
        id: productBundles[0].team_product_bundle.id,
        name: productBundles[0].team_product_bundle.name,
        brands: productBundles[0].team_product_bundle.product_brands,
        suppliers: productBundles[0].team_product_bundle.product_suppliers,
        qty: productBundles.length,
        customer_price: 0,
        trade_price: 0,
        description: productBundles[0].team_product_bundle.description,
        deleted_at: productBundles[0].team_product_bundle.deleted_at,
        products: products,
        customProducts: customProducts
      };
    },
    groupByBundle(arr) {
      return arr.reduce((obj, item) => {
        const key = `bundle_${item.team_product_bundle_id}`;

        // If the key doesn't exist yet, create it
        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
          obj[key] = [];
        }

        // Push the value to the object
        obj[key].push(item);

        // Return the object to the next item in the loop
        return obj;
      }, {});
    },
    showRoomProducts(floorIndex, roomIndex) {
      this.showRoom =`${floorIndex}${roomIndex}` == this.showRoom ? -1 : `${floorIndex}${roomIndex}`;
    },
    routerBack() {
      this.$router.go(-1);
    },
    groupBy(arr) {
      return arr.reduce(function(obj, item) {
        const key = `${item.product_id}_${item.supplier ? item.supplier.id : ''}`;
        // If the key doesn't exist yet, create it
        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
          obj[key] = [];
        }

        // Push the value to the object
        obj[key].push(item);

        // Return the object to the next item in the loop
        return obj;
      }, {});
    },
    groupByCustom(arr) {
      return arr.reduce(function(obj, item) {
        const key = `custom_${item.product_id}`;

        // If the key doesn't exist yet, create it
        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
          obj[key] = [];
        }

        // Push the value to the object
        obj[key].push(item);

        // Return the object to the next item in the loop
        return obj;
      }, {});
    },
    increaseRoomProduct(product, room) {
      this.axios
        .post(
          `/api/products/${this.$route.params.projectId}/${room.id}/${product.id}/${product.supplier.id}/increment`
        )
        .then(res => {
          this.$store.dispatch("project/addProduct", {
            floorId: room.floor_id,
            room: room.id,
            product: { productsAdded: [res.data.incrementedProduct], additionalProductsAdded: res.data.additionalProductsAdded }
          });
          this.$forceUpdate();
          this.$notify({
            type: "success",
            title: `1 x ${res.data.incrementedProduct.product.name} has been successfully added.`
          });
        });
    },
    decreaseRoomProduct(product, room) {
      const productId = product.id;
      const projectId = this.$route.params.projectId;
      this.axios
        .delete(
          `/api/projects/${projectId}/room/${room.id}/delete-product/${productId}/${product.supplier.id}`
        )
        .then(() => {
          this.$store.dispatch("project/removeProduct", {
            floorId: room.floor_id,
            room: room.id,
            product: productId,
            supplier: product.supplier.id
          });
          this.$forceUpdate();
          this.$notify({
            type: "success",
            title: `1 x ${product.name} has been successfully removed.`
          });
        });
    },
    editProduct(product, room) {
      this.editProductData.product = product;
      this.editProductData.products = product.products;
      this.editProductData.install_info = product.products[0].install_times ? product.products[0].install_times : [];
      this.editProductData.customer_price = product.products[0].customer_price.toFixed(2);
      this.editProductData.install_stage = product.products[0].install_stage;
      this.editProductData.description = product.products[0].product_description ? product.products[0].product_description.description : "";
      this.editProductData.updateDefaultInstallInfo = false;
      this.editProductData.installTimeDetails = [];
      this.editProductData.room = room;
      this.editProductData.show_on_sales_quote = !product.products[0].hidden_line_item;

      // Get any default or configured cables
      const overWrittenCables = product.details && product.details.hasOwnProperty('cables') ? product.details.cables : false;
      this.editProductData.cables = overWrittenCables ? overWrittenCables : [];

       // Get any default or configured networks
      const overWrittenNetworks = product.details && product.details.hasOwnProperty('networkRange') ? product.details.networkRange : false;
      this.editProductData.networkRange = overWrittenNetworks ? overWrittenNetworks : {};

      // Setup Install Times
      this.teamPositions.forEach((el) => {
        const details = { ...el };
        const setInstallTime = this.editProductData.install_info.find(installInfo => installInfo.team_position_id === el.id);
        details.install_time_mins = setInstallTime ? setInstallTime.install_time_mins : 0;
        this.editProductData.installTimeDetails.push(details);
      });
      this.showEditModal = true;
    },
    closeEditProductModal() {
      this.editProductData = {};
      this.showEditModal = false; 
    },
    updateProductInfo(product){
      this.$store.dispatch("project/updateProductInfo", {
        floorId: this.editProductData.room.floor_id,
        room: this.editProductData.room,
        product: product
      });
      this.closeEditProductModal;
    },
    removePlaceholderProductFromRoom(product, floorId) {
      this.axios.post(`/api/products/${this.project.id}/${product.room_id}/${product.id}/decrease-placeholder`).then(() => {
          this.$store.dispatch("project/removePlaceholderProduct", {
            floorId: floorId,
            room: product.room_id,
            product: product.id
          });
          this.$notify({
            type: "success",
            title: `1 x ${product.placeholder_product.name} has been successfully removed.`
          });
          this.$forceUpdate();
        });
    },
    incrementPlaceholderProductQty(product, floorId) {
      this.axios.post(`/api/products/${this.project.id}/${product.room_id}/${product.id}/increment-placeholder`).then(() => {
          this.$store.dispatch("project/addPlaceholderProduct", {
            floorId: floorId,
            room: product.room_id,
            product: product.id
          });
          this.$notify({
            type: "success",
            title: `1 x ${product.placeholder_product.name} has been successfully added.`
          });
          this.$forceUpdate();
        });
    },
    incrementCustomProductQty(product, room) {
      this.axios.post(`/api/products/${this.project.id}/${room.id}/${product.id}/increment-custom`).then(res => {
          this.$store.dispatch("project/addCustomProduct", {
            floorId: room.floor_id,
            room: room.id,
            product: res.data
          });
          this.$forceUpdate();
          this.$notify({
            type: "success",
            title: `1 x ${product.name} has been successfully added.`
          });
          this.$forceUpdate();
        });
    },
    decreaseCustomProductQty(product, room) {
      this.axios.delete(`/api/products/${this.project.id}/${room.id}/${product.id}/decrease-custom`).then(res => {
          this.$store.dispatch("project/removeCustomProduct", {
            floorId: room.floor_id,
            room: room.id,
            product: res.data.product_id
          });
          this.$forceUpdate();
          this.$notify({
            type: "success",
            title: `1 x ${product.name} removed`
          });
          this.$forceUpdate();
        });
    },
    editPlacholderProduct(product) {
      this.editPlaceholderProductData = product;
      this.showEditPlaceholderProductModal = true;
    },
    cancelEditPlaceholderProduct() {
      this.showEditPlaceholderProductModal = false;
      this.editPlaceholderProductData = {};
    },
    editCustomProduct(product, room) {
      this.editCustomProductData.room = room;
      this.editCustomProductData.productData = product;
      this.editCustomProductData.products = product.products;
      this.editCustomProductData.install_info = product.products[0].install_times ? product.products[0].install_times : [];
      this.editCustomProductData.installTimeDetails = [];
      this.editCustomProductData.updateDefaultInstallInfo = false;

      // Setup Install Times
      this.teamPositions.forEach((el) => {
        const details = { ...el };
        const setInstallTime = this.editCustomProductData.install_info.find(installInfo => installInfo.team_position_id === el.id);
        details.install_time_mins = setInstallTime ? setInstallTime.install_time_mins : 0;
        this.editCustomProductData.installTimeDetails.push(details);
      });

      this.showEditCustomProductModal = true;
    },
    cancelEditCustomProduct() {
      this.showEditCustomProductModal = false;
      this.editCustomProductData = { products: [] };
    },
    customProductUpdated(customProd){
      this.$store.dispatch("project/updateCustomProduct", {
        floorId: this.editCustomProductData.room.floor_id,
        room:  this.editCustomProductData.room.id,
        product: customProd
      });
      this.cancelEditCustomProduct();
    },
    showUpgradeProductModal(currentProduct) {
      this.showAddProductUpgradeModal = true;
      this.productToUpgrade = currentProduct;
    },
    upgradeProductAddedToProduct(product, productDetails) {
      this.getProject(this.$route.params.projectId);

      this.$notify({ 
        type: 'success', 
        title: `Product Option Added`
      });
      this.closeEditProductModal();
      this.cancelEditCustomProduct();
    },
    closeAddUpgradeProductModal() {
      this.showAddProductUpgradeModal = false;
      this.productToUpgrade = null;
    },
    upgradeProductRemoved(productId) {
      const idx = this.editProductData.upgrades.findIndex(el => el.id === productId);
      const upProdUpd = {...this.editProductData};
      upProdUpd.upgrades.splice(idx, 1);
      this.editProductData = upProdUpd;
       this.$notify({ 
          type: 'success', 
          title: `Product upgrade option removed`
        });
    },
    productUpgraded(upgradeProduct, newProducts) {
      this.getProject(this.$route.params.projectId);
      this.$notify({ 
          type: 'success', 
          title: `Product upgraded`
        });
      this.closeEditProductModal();
      this.cancelEditCustomProduct();
    },
    totalRoomProductsCount(product, customProduct, placeholderProduct, bundleProduct){
      let total = 0; 
      if(placeholderProduct){
        placeholderProduct.forEach(el => {
          total += el.qty;
        });
      } 
      let totalBundleProduct = 0; 
      if(bundleProduct){
        bundleProduct.forEach((el) => {
          totalBundleProduct += el.bundle_products.length;
          totalBundleProduct += el.bundle_custom_products.length;
        });
      }
      return product +  customProduct + total + totalBundleProduct;
    },
    totalFloorPrice(rooms){
      let total = 0;
        rooms.forEach(room => {
          total = total + this.getRoomCost(room);
        });
      return total;
    },
    totalFloorProductsCount(rooms){
      let total = 0;
        rooms.forEach(room => {
          // let placeholderTotal = 0; 
          // if(room.room_placeholder_products){
          //   room.room_placeholder_products.forEach(el => {
          //     placeholderTotal = placeholderTotal + el.qty;
          //   });
          // } 
          let numProductBundleProducts = 0;
          if(room.room_placeholder_products.length > 0){
            room.room_product_bundles.forEach((el) => {
              numProductBundleProducts += el.bundle_products.length;
              numProductBundleProducts += el.bundle_custom_products.length;
            });
          }
          total = total + room.room_products.length + room.room_custom_products.length  + numProductBundleProducts;
        });
      return total;
    },
    getRoomCost(room) {
      let retailPrice = 0;

      // 'Normal' Products
      room.room_products.forEach((el) => {
        retailPrice += el.customer_price;
      });

      // Custom Products
      room.room_custom_products.forEach((el) => {
        retailPrice += el.customer_price;
      });

      // Placeholder Products
      // room.room_placeholder_products.forEach((el) => {
      //   retailPrice += el.placeholder_product.retail_price * el.qty;
      // });

      room.room_product_bundles.forEach((el) => {
        el.bundle_products.forEach((bundleProduct) => {
          retailPrice += bundleProduct.customer_price;
        });

        el.bundle_custom_products.forEach((bundleProduct) => {
          retailPrice += bundleProduct.customer_price;
        });
      });

      return retailPrice;
    },

    incrementProductBundleQty(productBundle, room) {
      this.axios.post(`/api/products/${this.project.id}/${room.id}/${productBundle.id}/increment-product-bundle`).then((res) => {
        this.$store.dispatch("project/incrementProductBundleInRoom", { floor_id: room.floor_id, room_id: res.data.project_room_id, productDetails: res.data });
        this.$forceUpdate();
        this.$notify({type: 'success', title: `1 x ${res.data.team_product_bundle.name} added`});
      }).catch(() => {
        this.$notify({type: 'error', title: 'Something went wrong, Please try again'});
      });
    },
    decreaseProductBundleQty(productBundle, room) {
      this.axios.delete(`/api/products/${this.project.id}/${room.id}/${productBundle.id}/remove-product-bundle`).then((res) => {
        this.$store.dispatch('project/removeProductBundleFromRoom', { floor_id: room.floor_id, room_id: res.data.project_room_id, productDetails: productBundle });
        this.$notify({type: 'success', title: `1 x ${productBundle.name} removed`});
        this.$forceUpdate();
      }).catch(() => {
        this.$notify({type: 'error', title: 'Something went wrong, Please try again'});
      });
    },
    editProductBundle(product, room) {
      this.room_id = room.id;
      this.loadingProductBundleToEdit = true;
      this.showEditProductBundleModal = true;
      this.axios.get(`/api/products/${this.projectId}/${room.id}/${product.id}`).then((res) => {
        const groupedProductBundles = this.groupProductBundles(res.data);
        this.editProductBundleData.product = groupedProductBundles;
        const productsCopy = groupedProductBundles.products;
        const customProductsCopy = groupedProductBundles.customProducts;

        productsCopy.forEach((el) => {
          const elCopy = {...el};

          // Has this Product already been added?
          const productIndex = this.editProductBundleData.groupedProducts.findIndex(elem => elem.product_id === el.product_id && elem.supplier_id === el.supplier_id);

          if (productIndex === -1) {

            elCopy.products = [];
            elCopy.customer_price = el.customer_price;
            elCopy.products.push({...el});
            elCopy.install_info = el.install_times ? el.install_times : [];
            elCopy.updateDefaultInstallInfo = false;
            elCopy.install_stage = el.install_stage;
            elCopy.installTimeDetails = [];
            elCopy.details = el.details.hasOwnProperty('details') ? el.details.details : {};

            // Get any default or configured cables
            const overWrittenCables = el.details.hasOwnProperty('cables') ? el.details.cables : false;
            elCopy.cables = overWrittenCables ? overWrittenCables : [];

            // Get any default or configured networks
            const overWrittenNetworks = el.details.hasOwnProperty('networkRange') ? el.details.networkRange : false;
            elCopy.networkRange = overWrittenNetworks ? overWrittenNetworks : {};

            // Setup Install Times
            this.teamPositions.forEach((el) => {
              const details = { ...el };
              const setInstallTime = elCopy.install_info.find((installInfo) => installInfo.team_position_id === el.id);
              details.install_time_mins = setInstallTime ? setInstallTime.install_time_mins : 0;
              elCopy.installTimeDetails.push(details);
            });

            this.editProductBundleData.groupedProducts.push({...elCopy});
          } else {
            this.editProductBundleData.groupedProducts[productIndex].products.push({...elCopy});
          }
        });

        customProductsCopy.forEach((el) => {
          const elCopy = {...el};

          // Has this Product already been added?
          const productIndex = this.editProductBundleData.groupedCustomProducts.findIndex(elem => elem.team_custom_product_id === el.team_custom_product_id && elem.supplier_id === el.supplier_id);

          if (productIndex === -1) {
            elCopy.products = [];
            elCopy.customer_price = el.customer_price;
            elCopy.products.push({...el});
            elCopy.install_info = el.install_times ? el.install_times : [];
            elCopy.updateDefaultInstallInfo = false;
            elCopy.installTimeDetails = [];

            // Setup Install Times
            this.teamPositions.forEach((el) => {
              const details = { ...el };
              details.install_time_mins = 0;
              const defaultTime = elCopy.install_info.find((installTime) => installTime.team_position_id === el.id);
              details.install_time_mins = defaultTime ? defaultTime.install_time_mins : 0;
              elCopy.installTimeDetails.push(details);
            });

            this.editProductBundleData.groupedCustomProducts.push({...elCopy});
          } else {
            this.editProductBundleData.groupedCustomProducts[productIndex].products.push({...elCopy});
          }
        });
      }).finally(() => {
        this.loadingProductBundleToEdit = false;
      });
    },
    saveEditProductBundle() {
      this.axios.put(`/api/projects/${this.project.id}/room/${this.room_id}/product-bundle`, { editProductData: this.editProductBundleData }).then((res) => {
        this.cancelEditProductBundle();
        this.$notify({type: 'success', title: 'Product updated'});
      }).catch(() => {
        this.$notify({type: 'error', title: 'Something went wrong, Please try again'});
      });
    },
    cancelEditProductBundle() {
      this.room_id = null;
      this.showEditProductBundleModal = false;
      this.editProductBundleData = { product: null, groupedProducts: [], groupedCustomProducts: [] };
    },
    upgradeCustomProductRemoved(productId) {
      const idx = this.editCustomProductData.products[0].upgrade_products.findIndex((el) => el.id === productId);
      const upProdUpd = { ...this.editCustomProductData };
      upProdUpd.products[0].upgrade_products.splice(idx, 1);
      this.editCustomProductData = upProdUpd;
      this.$notify({type: "success", title: `Product upgrade option removed'`});
    }
  }
};
</script>