<template>
  <component :is="selectedIcon" />
</template>

<script>
import * as HeroIcons from "@vue-hero-icons/outline";
import * as HeroIconsSolid from "@vue-hero-icons/solid";

export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'outline'
    }
  },
  computed: {
    selectedIcon() {
      if (this.type === 'solid') {
        return HeroIconsSolid[`${this.icon}Icon`];
      } else {
        return HeroIcons[`${this.icon}Icon`];
      }
    }
  }
};
</script>