<template>
  <div class="shell-container">
    <header class="router-header short flex flex-wrap transition ease-in-out container px-8">
      <div class="flex-1">
        <button class="bg-gradient text-white font-bold p-1 xl:p-2 rounded-full" @click="routerBack()">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 xl:h-6 xl:w-6" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
          </svg>
        </button>
      </div>
    </header>
    <div class="container">
      <div class="product-single flex px-4">
        <div class="product-single--images">
          <ul v-if="product.images" class="images--thumbs">
            <li
              v-for="(image, i) in product.images"
              :key="i"
              class="rounded-full"
              :class="{imgActive: i === galleryIndex}"
              @click="showImg(i)"
            >
              <img :src="image.url" :alt="product.title">
            </li>
          </ul>

          <div v-if="product.images" class="images--featured" :class="animate" style="z-index: 5;">
            <img :src="product.images[galleryIndex].url" alt="Specifi">
          </div>
        </div>
        <div class="product-single--info p-10">
          <h2 class="product--title text-white sans-serif font-bold text-xl xl:text-4xl">
            {{ product.name }}<span class="product--price text-white sans-serif text-base xl:text-xl mb-2 ml-2">{{ product.brand }}</span>
          </h2>
          <h2 v-if="product.pricing" class="text-lime font-semibold sans-serif text-lg xl:text-2xl my-1">
            {{ product.pricing.highestRetailPrice | currency }}
          </h2>

          <h3 class="product--title text-white sans-serif text-lg xl:text-2xl">
            Features
          </h3>
          <ul class="product--features text-xs xl:text-base">
            {{ product.summary }}
          </ul>

          <button
            class="bg-transparent rounded-lg flex mt-8 mb-8 py-3 px-4 font-bold text-sm xl:text-base text-white border border-lime hover:bg-gray-900"
            @click="addProductToProject"
          >
            + Add to project
          </button>
        </div>
      </div>
    </div>

    <add-product-to-project
      :show-modal="showAddProductToProjectModal"
      :product="product"
      :selected-product-data="selectedProductData"
      :room-id=" parseInt(roomId)"
      @success="successfullyAdded"
      @closeModal="showAddProductToProjectModal = false"
    />
  </div>
</template>

<script>
import AddProductToProject from "../components/modals/AddProductToProjectModal.vue";
//import Compare from '../components/modals/CompareModal.vue';
//import AWS from 'aws-sdk';

export default {
  components: {
    AddProductToProject
    // Compare,
  },
  data() {
    return {
      galleryIndex: 0,
      animate: "",
      view360: false,
      product: {},
      has360: false,
      showAddProductToProjectModal: false,
      showCompareModal: false,
      qtyAdded: 0,
      productsToCompare: [],
      comparisonProducts: [],
      variantId: null,
      optionsSelected: {
        one: null,
        two: null,
        three: null
      },
      selectedProductData: {
        qty: 1,
        installTimeMins: 0,
        install_time_mins: 0,
        customerPrice: 0,
        projectStage: null,
        nameOverride: "",
        description: "",
        details: {
          itemSpecific: {}
        },
        installTimeDetails: [],
        team_install_info_default: []
      }
    };
  },
  computed: {
    teamPositions () {
      return this.$store.state.system.teamPositions;
    },
    roomId() {
      return this.$route.params.roomId;
    },
    projectId() {
     return this.$route.params.projectId ? this.$route.params.projectId : null;
    }
  },
  created() {
    let productURL = `/api/products/${this.$route.params.productId}`;
    this.axios.get(productURL).then(product => {
      this.product = product.data;
    });
  },
  methods: {
    routerBack() {
      this.$router.go(-1);
    },
    showImg(i) {
      this.view360 = false;
      this.galleryIndex = i;
      this.animate = "animate__animated animate__fadeIn";
      setTimeout(() => (this.animate = ""), 1000);
    },
    addProductToProject() {
      this.selectedProductData.installTimeDetails = [];
      this.selectedProductData.installTimeMins = 0;
      this.selectedProductData.installStage = null;
      this.selectedProductData.description = null;

      this.selectedProductData.installTimeMins = this.product.install_time || 0;
      this.selectedProductData.installStage = this.product.install_stage || null;
      this.selectedProductData.description = this.product.product_description || null;

       // Setup Install Times
      this.teamPositions.forEach((el) => {
        const details = { ...el };
        details.install_time_mins = 0;
        const defaultInstallTime = this.product.team_install_info_default.find(s => s.team_position_id === details.id);
        details.install_time_mins = defaultInstallTime ? defaultInstallTime.install_time_mins : 0;
        this.selectedProductData.installTimeDetails.push(details);
      });

      if (this.product.default_cables) {
        this.selectedProductData.cables = this.product.default_cables.cables;
      } else if (this.product.system_design && this.product.system_design.hasOwnProperty('cables')) {
        this.selectedProductData.cables = JSON.parse(JSON.stringify(this.product.system_design.cables));
      }

      if (this.product.default_xero_config) {
        this.selectedProductData.accountCode = this.product.default_xero_config.account_code;
        this.selectedProductData.taxRate = this.product.default_xero_config.tax_rate;
      }

      if (this.product.default_network_range) {
        this.selectedProductData.networkRange = this.product.default_network_range.team_network_range;
      }


      this.showAddProductToProjectModal = true;
    },
    successfullyAdded(qtyAdded) {
      this.qtyAdded = qtyAdded;
      this.showAddProductToProjectModal = false;
      this.$notify({
        type: "success",
        title: "Product Added",
        text: `${qtyAdded} x products added to project.`
      });

      const { projectId, roomId } = this.$route.params;
      this.$router.push({
        name: "projectRoomProducts",
        params: { projectId, roomId }
      });
    },
    addToCompareProducts(productId) {
      if (this.productsToCompare.includes(productId)) {
        const idx = this.productsToCompare.indexOf(productId);
        this.productsToCompare.splice(idx, 1);
      } else {
        this.productsToCompare.push(productId);
      }
    },
    compareProducts() {
      const category = this.$store.state.categories.find(
        element => element.category == this.product.systemDesign.category
      );
      this.comparisonProducts = category.products_available.filter(element =>
        [this.product.id, ...this.productsToCompare].includes(element.id)
      );
      this.showCompareModal = true;
    }
  }
};
</script>

<style type="text/css">
.imgActive {
  border: 2px solid #000 !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>