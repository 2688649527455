<template>
  <div class="modal-window fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center" :class="showModal ? 'show' : 'hide'">
    <div class="fixed inset-0 transition-opacity" :class="showModal ? 'ease-in duration-200 opacity-100' : 'ease-out duration-200 opacity-0'">
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>

    <div
      class="modal-window-split bg-white rounded-lg overflow-hidden shadow-xl transform transition-all"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
      :class="showModal ? 'ease-in duration-200 opacity-100 translate-y-0 sm:scale-100' : 'ease-out duration-300 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'"
    >
      <div v-if="roomData.image_url || preview" class="modal-window-split--img">
        <img v-if="preview" :src="preview" :alt="roomData.name">
        <img v-else :src="roomData.image_url" :alt="roomData.name">
        <div class="modal-window-split--img-change">
          <label
            for="room-image-upload"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-gray-900 text-base leading-6 font-medium text-white shadow-sm hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
            </svg>
            Change image
          </label>
          <input id="room-image-upload" ref="roomImage" type="file" accept="image/*" capture="camera" @change="onChangeFileUpload()">
        </div>
      </div>
      <div v-if="!roomData.image_url && !preview" class="modal-window-split--img bg-gradient">
        <div class="modal-window-split--img-upload flex items-center">
          <label for="room-image-upload">
            <div
              class="bg-white bg-opacity-25 text-white font-bold py-2 px-2 rounded-full focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150"
              @click="editRoomModalShow = true"
            >
              <div class="bg-white bg-opacity-25 text-white font-bold py-1 px-1 rounded-full">
                <div class="bg-white text-white font-bold py-4 px-4 rounded-full">
                  <img src="@/assets/img/icons/camera-upload.svg" alt="Rooms">
                </div>
              </div>
            </div>
            <span class="text-xl text-white">Take or upload a photo</span>
          </label>
          <input id="room-image-upload" ref="roomImage" type="file" accept="image/*" capture="camera" @change="onChangeFileUpload()">
        </div>
      </div>
      <div class="modal-window-split--content relative">
        <div class="bg-white px-4 pt-5 pb-16 xl:p-8 xl:pb-20 max-h-full overflow-x-scroll">
          <div class="mb-4 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-lime sm:mx-0 sm:h-10 sm:w-10">
            <svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
            </svg>
          </div>
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center w-full sm:mt-0 sm:text-left">
              <div class="flex justify-between items-center mb-4">
                <h3 id="modal-headline" class="text-lg xl:text-2xl leading-8 font-bold text-gray-900">
                  Edit -
                  <span class="text-blue-400">{{ roomData.name }}</span>
                </h3>
                <button
                  type="button"
                  class="inline-flex justify-center items-center w-auto rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  @click="duplicateProjectRoom"
                >
                  <svg class="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor"> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" /> </svg>
                  Duplicate Room
                </button>
              </div>
              <div class="mt-2">
                <form id="editRoom" class="w-full" @submit.prevent="updateRoom">
                  <div class="grid grid-cols-6 gap-4 xl:gap-6">
                    <div class="col-span-6 sm:col-span-3">
                      <label for="customer_name" class="block text-xs xl:text-sm font-medium text-gray-700">Room Type
                      </label>
                      <select id="priority" v-model="roomData.is_comms" name="priority" required class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                        <option :value="1">
                          Comms Room
                        </option>
                        <option :value="0">
                          Room
                        </option>
                      </select>
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="name" class="block text-xs xl:text-sm font-medium text-gray-700">Room Name</label>
                      <input id="floorName" v-model="roomData.name" type="text" name="floorName" required placeholder="e.g. Living Room" class="form-input">
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label
                        for="name"
                        class="block text-xs xl:text-sm font-medium text-gray-700"
                      >Distance From Rack (m)</label>
                      <input id="floorName" v-model="roomData.distance_from_rack" type="number" name="floorName" placeholder="e.g. 10" class="form-input">
                    </div>
                    <div class="col-span-6 sm:col-span-3">
                      <label for="name" class="block text-xs xl:text-sm font-medium text-gray-700">
                        Ceiling Depth (mm)
                      </label>
                      <input id="floorName" v-model="roomData.ceiling_depth" type="number" name="floorName" placeholder="e.g. 300" class="form-input">
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="name" class="block text-xs xl:text-sm font-medium text-gray-700">
                        Ethernet Points
                      </label>
                      <input id="floorName" v-model="roomData.ethernet_points" type="text" name="floorName" placeholder="e.g. 4" class="form-input">
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label for="name" class="block text-xs xl:text-sm font-medium text-gray-700">Phone Points</label>
                      <input id="floorName" v-model="roomData.phone_points" type="text" name="floorName" placeholder="e.g. 2" class="form-input">
                    </div>

                    <div class="col-span-6">
                      <label for="customer_name" class="block text-xs xl:text-sm font-medium text-gray-700">Scope</label>
                      <textarea id="about" v-model="roomData.scope" name="about" rows="4" class="form-input" placeholder="e.g. The scope of work for the room." />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse absolute bottom-0 w-full bg-white bg-opacity-75">
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button type="submit" form="editRoom" class="btn-primary-md bg-gradient">Update Room</button>
          </span>
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto sm:ml-3">
            <button type="button"
                    class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    @click="closeModal()"
            >
              Cancel
            </button>
          </span>
          <span class="mt-3 flex w-full sm:mt-0 sm:w-auto">
            <button
              type="button"
              class="inline-flex justify-center w-full px-4 py-2 text-base leading-6 font-medium text-red-600 hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              @click="showDeleteConfirmModal = true"
            >Delete Room</button>
          </span>
        </div>
      </div>
    </div>

    <confirm-modal
      :show-modal="showDeleteConfirmModal"
      modal-title="Delete Room"
      :modal-text="getDeleteString"
      confirm-btn-txt="Delete"
      @closeModal="showDeleteConfirmModal = false"
      @confirmAction="deleteRoom"
    />
  </div>
</template>

<script>
import ConfirmModal from "./ConfirmModal.vue";

export default {
  components: {
    ConfirmModal
  },
  props: {
    showModal: Boolean,
    roomData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      file: "",
      preview: "",
      showDeleteConfirmModal: false,
      editRoomErrors: {}
    };
  },
  computed: {
    getDeleteString() {
      return `Are you sure you want to delete ${this.roomData.name}?`;
    },
    project() {
      return this.$store.state.project.project;
    }
  },
  methods: {
    onChangeFileUpload() {
      this.file = this.$refs.roomImage.files[0];
      this.preview = URL.createObjectURL(this.file);
      this.roomData.img = this.file;
    },
    updateRoom() {
      const formData = new FormData();

      for (const key in this.roomData) {
        if (this.roomData[key]) {
          formData.append(key, this.roomData[key]);
        }
      }

      if (!this.roomData['is_comms']){
        formData.append('is_comms', 0);
      }

      // formData.append("name", this.roomData.name);
      // formData.append("distance_from_rack", this.roomData.distance_from_rack);
      // formData.append("ceiling_depth", this.roomData.ceiling_depth);
      // formData.append("ethernet_points", this.roomData.ethernet_points);
      // formData.append("phone_points", this.roomData.phone_points);
      // formData.append(
      //   "integrated_audio_video",
      //   this.roomData.integrated_audio_video
      // );
      // formData.append("scope", this.roomData.scope);
      // formData.append("img", this.file ? this.file : null);

      const roomId = this.roomData.id;
      this.axios.post(`/api/projects/${this.$route.params.projectId}/room/${roomId}`, formData).then(res => {
        this.$emit("updateRoom", res.data);
        this.$emit("closeModal");
      }).finally(() => {
        this.$emit("success", "updated");
      });
    },
    editRoomSubmit() {
      const formData = new FormData();

      for (const key in this.editRoomData) {
        if (this.editRoomData[key]) {
          formData.append(key, this.editRoomData[key]);
        }
      }
      if(!this.roomData['is_comms']){
        formData.append('is_comms', 0);
      }

      const roomId = this.editRoomData.id;
      this.axios.post(`/api/projects/${this.$route.params.projectId}/room/${roomId}`, formData).then((res) => {
        const floors = [...this.project.floors];
        const floorIndex = floors.findIndex((floor) => floor.id === this.editRoomData.floor_id);
        const floor = floors[floorIndex];
        const updRoomIndex = floor.rooms.findIndex((room) => room.id === this.editRoomData.id);
        floor.rooms[updRoomIndex] = res.data;
        floors[floorIndex] = floor;
        this.project.floors = floors;
        this.cancelEditRoom();
        this.$Notification.success('Success', 'Room updated');
      });
    },
    deleteRoom() {
      const roomId = this.roomData.id;
      this.axios.delete(`/api/projects/${this.$route.params.projectId}/room/${roomId}`).then(() => {
        this.$emit("closeModal");
        this.$emit("removeRoom", this.roomData.id);
      }).catch(() => {
        this.$notify({
          type: "error",
          title: "Something went wrong",
          text: "Please try again"
        });
      }).finally(() => {
        this.$emit("success", "deleted");
      });
    },
    closeModal() {
      this.file = "";
      this.preview = "";
      this.$emit("closeModal");
    },
    selectValue(value) {
      this.roomData.name = value;
    },
    duplicateProjectRoom() {
      this.axios.post(`/api/projects/${this.$route.params.projectId}/room/${this.roomData.id}/duplicate`).then(room => {
        this.$notify({
          type: "success",
          title: "Success",
          text: "Room duplicated successfully."
        });
        this.$store.dispatch('project/addRoom',  { room: room.data });
        this.$emit("closeModal");
      });
    }
  }
};
</script>