<template>
  <div :class="showModal ? 'show' : 'hide'" class="modal-window fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
    <div :class="showModal ? 'ease-in duration-200 opacity-100' : 'ease-out duration-200 opacity-0'" class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>

    <div
      class="modal-window-split bg-white rounded-lg overflow-hidden shadow-xl transform transition-all"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
      :class="showModal ? 'ease-in duration-200 opacity-100 translate-y-0 sm:scale-100' : 'ease-out duration-300 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'"
    >
      <div class="modal-window-split--img">
        <img src="@/assets/img/placeholders/floor.jpg">
      </div>

      <div class="modal-window-split--content">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-8 sm:pb-4">
          <div class="mb-4 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-lime sm:mx-0 sm:h-10 sm:w-10">
            <svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
          </div>
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center w-full sm:mt-0 sm:text-left">
              <div class="flex justify-between items-center mb-4">
                <h3 id="modal-headline" class="text-lg xl:text-2xl leading-8 font-bold text-gray-900">
                  Edit Floor
                </h3>

                <button
                  type="button"
                  class="inline-flex justify-center items-center w-auto rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  @click="duplicateProjectFloor"
                >
                  <svg class="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
                  </svg>
                  Duplicate Floor
                </button>
              </div>

              <div class="mt-2">
                <form class="w-full">
                  <div class="flex flex-wrap -mx-3 mb-4">
                    <div class="w-full px-3 mb-6 md:mb-0">
                      <div class="flex justify-between">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">Floor name</label>
                        <span id="email-optional" class="text-sm leading-5 text-gray-500">Leave blank to use floor numbers</span>
                      </div>
                      <input
                        v-model="editFloorData.name"
                        class="appearance-none block w-full bg-gray-200 text-xs xl:text-base text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text" 
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-gradient text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              @click="saveFloor"
            >Save Floor</button>
          </span>
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto sm:ml-3">
            <button
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              @click="closeModal"
            >Cancel</button>
          </span>
          <span class="mt-3 flex w-full sm:mt-0 sm:w-auto">
            <button
              type="button"
              class="inline-flex justify-center w-full px-4 py-2 text-base leading-6 font-medium text-red-600 hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              @click="showDeleteConfirmModal = true"
            >Delete Floor</button>
          </span>
        </div>
      </div>
    </div>

    <confirm-modal 
      :show-modal="showDeleteConfirmModal"
      modal-title="Delete Floor"
      :modal-text="getDeleteString"
      confirm-btn-txt="Delete"
      @closeModal="showDeleteConfirmModal = false"
      @confirmAction="deleteFloor"
    />
  </div>
</template>

<script>
import ConfirmModal from './ConfirmModal.vue';

export default {
  components: {
    ConfirmModal
  },
  props: {
    showModal: Boolean,
    projectId: {
      type: Number,
      default: 0,
      required: false
    },
    editFloorData: {
      type: Object,
      required: true
    },
    projectrooms: {
      type: Array,
      default: () => [],
      required: false
    }
  },
  data() {
    return  {
      floorName: '',
      showDeleteConfirmModal: false
    };
  },
  computed: {
    getDeleteString() {
      return `Are you sure you want to delete <strong>${this.editFloorData.name || 'Floor ' + this.editFloorData.floor}</strong>?`;
    },
    project() {
      return this.$store.state.project.project;
    }
  },
  methods: {
    saveFloor() {
        this.axios.put(`/api/projects/${this.$route.params.projectId}/update-floor/${this.editFloorData.id}`, this.editFloorData).then((response) => {
        const updFloor = response.data;
        const floors = [...this.project.floors];
        const updFloorIndex = floors.findIndex(floor => floor.id === updFloor.id);
        floors[updFloorIndex] = updFloor;
        this.project.floors = floors;
        this.closeModal();
          this.$notify({ 
            type: 'success', 
            title: 'Floor Updated', 
            text: 'Floor has been successfully updated.'
          });
      });
    },
    deleteFloor() {
      const floorId = this.editFloorData.id;
      this.axios.delete(`/api/projects/${this.project.id}/delete-floor/${floorId}`).then(() => {
        const floors = [...this.project.floors];
        const delFloorIndex = floors.findIndex(floor => floor.id === floorId);
        floors.splice(delFloorIndex, 1);
        this.project.floors = floors;
        this.$emit("selectFirstAvailableFloor");
          this.$notify({ 
            type: 'success', 
            title: 'Floor Deleted', 
            text: 'Floor has been successfully removed.'
          });
      }).catch(() => {
          this.$notify({ 
            type: 'error', 
            title: 'Something went wrong', 
            text: 'Please try again'
          });
      }).finally(() => {
         this.closeModal();
      });
    },
    closeModal() {
      this.$emit("closeEditFloorModal");
    },
    duplicateProjectFloor() {
      this.axios.post(`/api/projects/${this.project.id}/floor/${this.editFloorData.id}/duplicate`).then((response) => {
        const floors = [...this.project.floors];
        floors.push(response.data);
        this.project.floors = floors;
        this.$notify({ 
            type: 'success', 
            title: 'Floor duplicated', 
            text: 'Floor has been successfully duplicated.'
          });
        this.closeModal();
      });
    }
  }
};
</script>