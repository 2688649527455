<template>
  <div class="slide-modal bottom" :class="showModal ? 'slide-modal-active' : ''">
    <div class="slide-modal--overlay" @click="closeModal()" />
    <div class="slide-modal--container">
      <div class="slide-modal--container-content flex justify-between">
        <div class="flex items-center">
          <h4 class="sans-serif text-3xl text-white font-semibold mr-4">
            Floors
          </h4>
          <button v-if="editingFloors" class="bg-lime hover:bg-white-500 font-bold py-2 px-2 rounded-full" @click="stopEditFloors">
            <svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <button v-else class="bg-lime hover:bg-white-500 font-bold py-2 px-2 rounded-full" @click="editFloors">
            <svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
            </svg>
          </button>
        </div>

        <button class="bg-lime hover:bg-white-500 font-bold py-2 px-2 rounded-full" @click="closeModal()">
          <img src="@/assets/img/icons/close.svg" alt="Close">
        </button>
      </div>

      <swiper
        ref="floorSwiper"
        class="swiper floor-selector f-7"
        :options="swiperOptions"
        :auto-update="true"
        :auto-destroy="false"
        :delete-instance-on-destroy="false"
        :cleanup-styles-on-destroy="true"
      >
        <swiper-slide v-for="(floor, index) in floors" :key="index">
          <span class="slide--title sans-serif" :class="[currentFloor === index ? 'active' : '', (editingFloors) && 'animate__animated animate__pulse animate__infinite']" @click="selectFloor(index)">

            <span>
              <span class="slide--title-floor text-xs block text-gray-600 leading-none">Floor</span>
              <span v-if="floor.name !== ''" class="slide--title-index text-xs xl:text-xl font-bold leading-tight block my-2">{{ floor.name }}</span>
              <span v-else class="slide--title-index text-xs xl:text-xl font-bold leading-tight block my-2">{{ index }}</span>
              <span class="slide--title-rooms text-sm leading-tight block text-gray-600">{{ floor.rooms.length }} {{ 'Room' | pluralize(floor.rooms) }}</span>
            </span>
          </span>
          <button v-if="editingFloors" class="absolute bg-lime -top-2 -right-2 hover:bg-white-500 font-bold py-2 px-2 rounded-full shadow-sm" @click="editSelectedFloor(floor)">
            <svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
            </svg>
          </button>
        </swiper-slide>
        <swiper-slide>
          <span class="slide--title sans-serif new-floor" @click="addFloor">
            <span>
              <span class="slide--title-floor text-xs block text-gray-600 leading-none">New Floor</span>
              <span class="slide--title-index text-4xl font-bold leading-tight block">+</span>
              <span class="slide--title-rooms text-sm leading-tight block text-gray-600">Add Floor</span>
            </span>
          </span>
        </swiper-slide>

        <div slot="button-prev" :class="$isTouchDevice && 'hide'" class="swiper-btn swiper-btn-prev">
          <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-12 h-12">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
        </div>
        <div slot="button-next" :class="$isTouchDevice && 'hide'" class="swiper-btn swiper-btn-next">
          <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-12 h-12">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </div>
      </swiper>
    </div>

    <new-floor-modal 
      :show-modal="showAddFloorModal"
      @closeAddFloorModal="showAddFloorModal = false"
      @storeRoomFromFloor="storeRoomFromFloor"
    />

    <edit-floor-modal 
      :edit-floor-data="editFloorData"
      :show-modal="showEditFloorModal"
      @closeEditFloorModal="closeEditFloorModal"
      @selectFirstAvailableFloor="selectFirstAvailableFloor"
      @storeRoomsFromFloor="storeRoomsFromFloor"
    />
  </div>
</template>

<script>
import NewFloorModal from './NewFloorModal.vue';
import EditFloorModal from './EditFloorModal.vue';

export default {
  components: {
    NewFloorModal,
    EditFloorModal
  },
  props: {
    showModal: Boolean,
    floors: {
      type: Array,
      required: true
    },
    currentFloor: {
      type: Number,
      required: true
    },
    projectrooms: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-btn-next',
          prevEl: '.swiper-btn-prev'
        },
        spaceBetween: 18,
        slidesPerView: "auto",
        slidesPerGroup: 6,
        touchRatio: 1
      },
      showAddFloorModal: false,
      showEditFloorModal: false,
      editingFloors: false,
      editFloorData: {}
    };
  },
  methods: {
    selectFloor(floor) {
      this.$emit("selectFloor", floor);
      if(!this.editingFloors) {
        this.closeModal();
      }
    },
    selectFirstAvailableFloor() {
      this.$emit("selectFirstAvailableFloor");
    },
    addFloor() {
      this.editingFloors = false;
      this.showAddFloorModal = true;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    editFloors() {
      this.editingFloors = true;
    },
    stopEditFloors() {
      this.editingFloors = false;
    },
    editSelectedFloor(floor) {
      this.editingFloors = false;
      this.editFloorData = floor;
      this.showEditFloorModal = true;
    },
    closeEditFloorModal() {
      this.showEditFloorModal = false;
      this.editFloorData = {};
    },
    storeRoomFromFloor(room) {
      this.$emit("storeRoom", room);
    },
    storeRoomsFromFloor(rooms) {
      rooms.forEach((room) => {
        this.$emit("storeRoom", room);
      });
    }
  }
};
</script>