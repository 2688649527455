<template>
  <div class="w-full">
    <div v-if="products.length > 0" class="flex -space-x-2">
      <span v-for="(product, index) in previewProducts" :key="index">
        <img v-if="product.type" :src="product.product.image_url" class="object-contain p-1 w-10 h-10 bg-gradient-to-br from-blue-light to-blue-dark rounded-full ring-2 ring-gray-200 shadow-lg">
        <img v-else-if="product.product && product.product.first_image" :src="product.product.first_image.url" class="object-contain p-1 w-10 h-10 bg-gradient-to-br from-blue-light to-blue-dark rounded-full ring-2 ring-gray-200 shadow-lg">
      </span>
      <div v-if="numRemainingProducts > 0" class="inline-flex justify-center items-center text-xs font-medium text-white">
        <span class="p-1 bg-blue-dark bg-opacity-25 rounded-md shadow-lg">
          +{{ numRemainingProducts }} more
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    products: {
      type: Array,
      required: true
    },
    totalProducts: {
      type: Number,
      required: true
    },
    customProducts: {
      type: Array,
      required: true
    },
    totalCustomProducts: {
      type: Number,
      required: true
    }
  },
  computed: {
    numRemainingProducts() {
      return this.uniqueProducts.length - 3;
    },
    uniqueProducts() {
      const firstId = this.products.length > 0 ? this.products[0].product_id : -1;
      const filteredProducts = this.products.filter(el => el.product_id !== firstId);
      const uniqueProducts = Array.from(new Set(filteredProducts.map(a => a.product_id))).map(id => {
        return filteredProducts.find(a => a.product_id === id);
      });

      const firstCustomId = this.customProducts.length > 0 ? this.customProducts[0].product.id : -1;
      const filteredCustomProducts = this.products.length === 0 ? this.customProducts.filter(el => el.product.id !== firstCustomId) : this.customProducts;
      const uniqueCustomProducts = Array.from(new Set(filteredCustomProducts.map(a => a.product.id))).map(id => {
        return filteredCustomProducts.find(a => a.product.id === id);
      });

      uniqueCustomProducts.forEach((el) => {
        el.type = 'custom';
        uniqueProducts.push(el);
      });

      return uniqueProducts;
    },
    previewProducts() {
      const previewProducts = [];
      this.uniqueProducts.forEach((el) => {
        if (previewProducts.length < 3) {
          previewProducts.push(el);
        }
      });
      return previewProducts;
    }
  }
};
</script>
