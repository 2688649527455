<template>
  <div>
    <div v-if="loaded && project" class="shell-container">
      <header class="router-header flex flex-wrap content-center items-center transition ease-in-out container px-8">
        <div class="flex-1">
          <button class="bg-gradient hover:opacity-50 text-white font-bold p-1 xl:p-2 rounded-full" @click="routerBack()">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4xl:h-6 xl:w-6" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>

        <ul class="breadcrumbs bg-transparent items-center text-center self-auto">
          <li @click="$router.push({ name: 'projects' })">
            <span class="block uppercase tracking-wide text-xxs xl:text-xs font-semibold opacity-75">Project</span>
            <span class="block tracking-wide text-xxs xl:text-xs font-bold">{{ project.name }}</span>
            <div class="p-0.5 mt-1" />
          </li>
          <li class="divider">
            <svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-gray-600">
              <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
            </svg>
          </li>
          <li @click="$router.push({ name: 'projectRooms', params: { projectId: project.id } })">
            <span class="block uppercase tracking-wide text-xxs xl:text-xs font-semibold opacity-75">Floor</span>
            <span class="block tracking-wide text-xxs xl:text-xs font-bold">{{ currentFloorName }}</span>
            <div class="p-0.5 mt-1" />
          </li>
          <li class="divider">
            <svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-gray-600">
              <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
            </svg>
          </li>
          <li>
            <span class="block uppercase tracking-wide text-xxs xl:text-xs font-semibold opacity-75">Room</span>
            <span class="block tracking-wide text-xxs xl:text-xs font-bold">{{ room.name }}</span>
            <div class="bg-gradient-green p-0.5 mt-1 rounded-lg" />
          </li>
        </ul>

        <ul class="flex flex-1 text-right flex-col fabs">
          <li>
            <span class="font-semibold text-xs xl:text-base">{{ room.name }}</span>
            <button class="bg-gradient text-white font-bold p-1 xl:p-2 rounded-full hover:opacity-50" @click="routerBack()">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 xl:h-6 xl:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
            </button>
          </li>
          <li>
            <span class="font-semibold text-xs xl:text-base">New Product</span>
            <button class="bg-gradient text-white font-bold p-1 xl:p-2 rounded-full hover:opacity-50" @click="showAddProduct = true">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 xl:h-6 xl:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
            </button>
          </li>
        </ul>
      </header>

      <product-swiper
        :products="groupedProducts"
        :custom-products="groupedCustomProducts"
        :placeholder-products="room.room_placeholder_products"
        :floor="room.floor_id"
        :bundle-products="groupedProductBundles"
        @removeProduct="removeProduct"
        @addProduct="addProduct"
        @updateProductDetailsRoom="updateProductDetailsRoom"
        @showAddProduct="showAddProduct = true"
        @upgradeProductAddedToRoom="upgradeProductAddedToRoom"
        @productUpgradedToRoom="productUpgradedToRoom"
        @upgradeCustomData="customProductUpgrade"
        @removeAllProduct="removeAllProduct"
      />

      <add-product-modal
        :show-modal="showAddProduct"
        @closeModal="showAddProduct = false"
      />
      <!-- <room-errors-modal
        :show-modal="showRoomErrors"
        :errors="room.product_warnings"
        :room-name="room.name"
        @closeModal="closeRoomErrors"
      /> -->
    </div>
    <div v-else class="flex justify-center py-20 my-20">
      <img src="@/assets/img/specifi_logo_blue.svg" width="350" height="175" alt="specifi">
      <svg id="L4" height="100px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 -25 60 100" enable-background="new 0 0 0 0" xml:space="preserve">
        <circle fill="#77d183" stroke="none" cx="6" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"
          />
        </circle>
        <circle fill="#85b177" stroke="none" cx="26" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.2"
          />
        </circle>
        <circle fill="#0e2d29" stroke="none" cx="46" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.3"
          />
        </circle>
      </svg>
    </div>
  </div>
</template>

<script>
import ProductSwiper from "../../../components/swipers/ProductSwiper.vue";
import AddProductModal from "../../../components/modals/AddProductModal.vue";
// import RoomErrorsModal from "../../../components/modals/RoomErrorsModal.vue";

export default {
  components: {
    AddProductModal,
    ProductSwiper
    // RoomErrorsModal
  },
  data() {
    return {
      showAddProduct: false,
      loaded: false,
      room: {},
      showRoomErrors: false
    };
  },
  computed: {
    project() {
      return this.$store.state.project.project;
    },
    groupedProducts() {
      if (!this.room || !this.room.room_products) return [];

      const ggs = this.groupBy(this.room.room_products);

      return Object.keys(ggs).map((ind) => {
        const prodG = ggs[ind];
        return {
          id: prodG[0].product.id,
          name: prodG[0].product.name,
          brand: prodG[0].product.brand,
          qty: prodG.length,
          img: prodG[0].product.image_url,
          customer_price: prodG[0].customer_price,
          nameOverride: prodG[0].name_override,
          upgrades: prodG[0].upgrade_products,
          supplier: {
            id: prodG[0].supplier.id,
            name: prodG[0].supplier.name
          },
          products: prodG,
          installTimeToShow: this.checkIfInstallTimeExist(prodG[0].install_times),
          xeroConfig: prodG[0].xero_config,
          quickBooksConfig: prodG[0].quick_books_config
        };
      });
    },
    groupedCustomProducts() {
      if (!this.room || !this.room.room_custom_products) return [];

      const ggs = this.groupByCustom(this.room.room_custom_products);

      return Object.keys(ggs).map((ind) => {
        const prodG = ggs[ind];
        return {
          id: prodG[0].product.id,
          name: prodG[0].product.name,
          brand: prodG[0].product.brand,
          qty: prodG.length,
          install_time: prodG[0].product.install_time,
          install_stage: prodG[0].product.stage_id,
          customer_price: prodG[0].customer_price,
          description: prodG[0].product.description,
          img: prodG[0].product.image_url ? prodG[0].product.image_url : '',
          install_times: prodG[0].install_times,
          products: prodG,
          upgrades: prodG[0].upgrade_products,
          installTimeToShow: this.checkIfInstallTimeExist(prodG[0].install_times),
          deleted_at: prodG[0].product.deleted_at,
          xeroConfig: prodG[0].xero_config,
          quickBooksConfig: prodG[0].quick_books_config
        };
      });
    },
    groupedProductBundles() {
     if (!this.room || !this.room.room_product_bundles) return [];

     const ggs = this.groupByBundle(this.room.room_product_bundles);
     return Object.keys(ggs).map((ind) => {
         const prodG = ggs[ind];

        // Get the pricing that the bundle was added with:
        // i.e. not the update product bundle price if it has been changed.
        let customerPrice = 0;
        let tradePrice = 0;
        prodG[0].grouped_products.forEach((el) => {
          customerPrice += el.customer_price * el.qty;
          tradePrice += el.trade_price * el.qty;
        });

        prodG[0].grouped_custom_products.forEach((el) => {
          customerPrice += el.customer_price * el.qty;
          tradePrice += el.trade_price * el.qty;
        });

        return {
          id: prodG[0].team_product_bundle.id,
          name: prodG[0].team_product_bundle.name,
          brands: prodG[0].team_product_bundle.product_brands,
          suppliers: prodG[0].team_product_bundle.product_suppliers,
          qty: prodG.length,
          customer_price: customerPrice,
          trade_price: tradePrice,
          description: prodG[0].team_product_bundle.description,
          deleted_at: prodG[0].team_product_bundle.deleted_at,
          grouped_products: prodG[0].grouped_products,
          grouped_custom_products: prodG[0].grouped_custom_products
        };
      });
    },
    currentFloorName() {
      const floorName = this.project && this.project.floors ? this.project.floors.find(floor => floor.id === this.room.floor_id) : '';
      return floorName.name;
    }
  },
  mounted() {
     this.getRoom();
  },
  methods: {
    checkIfInstallTimeExist(product){
      return Object.keys(product).filter(key => product[key].install_time_mins > 0);
    },
    productUpgradedToRoom(upgradeProduct, newProducts){
      this.getRoom();
    },
    upgradeProductAddedToRoom(product, productDetails){
      this.getRoom();
    },
    routerBack() {
      this.$router.go(-1);
    },
    getRoom() {
      this.loaded = false;
      this.axios.get(`/api/projects/${this.$route.params.projectId}/room/${this.$route.params.roomId}`).then((room) => {
        this.room = room.data.data;
        this.loaded = true;
      }).catch(() => {
        this.$router.push({ name: 'projects' });
      });
    },
    groupBy(arr) {
      return arr.reduce(function (obj, item) {
        const key = `${item.product_id}_${item.supplier.id}`;

        // If the key doesn't exist yet, create it
        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
          obj[key] = [];
        }

        // Push the value to the object
        obj[key].push(item);

        // Return the object to the next item in the loop
        return obj;
      }, {});
    },
    groupByCustom(arr) {
      return arr.reduce(function (obj, item) {
        const key = `custom_${item.product_id}`;

        // If the key doesn't exist yet, create it
        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
          obj[key] = [];
        }

        // Push the value to the object
        obj[key].push(item);

        // Return the object to the next item in the loop
        return obj;
      }, {});
    },
    groupByBundle(arr) {
      return arr.reduce((obj, item) => {
        const key = `bundle_${item.team_product_bundle_id}`;

        // If the key doesn't exist yet, create it
        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
          obj[key] = [];
        }

        // Push the value to the object
        obj[key].push(item);

        // Return the object to the next item in the loop
        return obj;
      }, {});
    },
    viewRoomErrors() {
      this.showRoomErrors = true;
    },
    closeRoomErrors() {
      this.showRoomErrors = false;
    },
    customProductUpgrade(productData) {
      const customProductIdx = this.room.room_custom_products.findIndex(el => el.product_id === productData.product_id);
      const updCustomProducts = [...this.room.room_custom_products];
      updCustomProducts[customProductIdx] = productData;
      this.room.room_custom_products = updCustomProducts;
    },
    updateProductDetailsRoom(productData) {
      const ProductIdx = this.room.room_products.findIndex(el => el.product_id === productData.product_id);
      const updProducts = [...this.room.room_products];
      updProducts[ProductIdx] = productData;
      this.room.room_products = updProducts;
    },
    removeProduct(product){
      const productRoomIndex = this.lastIndexOf(this.room.room_products, product.product_id, product.supplier_id);
      this.room.room_products.splice(productRoomIndex, 1);
    },
    addProduct(product){
      this.room.room_products.push(product);
    },
    lastIndexOf(array, product, supplier) {
      for(let i = array.length - 1; i >= 0; i--){
        if((array[i].product_id === product) && (array[i].supplier_id === supplier))
          return i;
      }
      return -1;
    },
    removeAllProduct(product, supplier) {
      const updProducts = this.room.room_products.filter(el => el.product_id !== product || el.supplier_id !== supplier);
      this.room.room_products = updProducts;
    }
  }
};
</script>