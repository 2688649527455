<template>
  <div class="modal-window fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center" :class="showModal ? 'show' : 'hide'">
    <div class="fixed inset-0 transition-opacity" :class="showModal ? 'ease-in duration-200 opacity-100' : 'ease-out duration-200 opacity-0'">
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>

    <div
      class="modal-window-split bg-white rounded-lg overflow-hidden shadow-xl transform transition-all"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
      :class="showModal ? 'ease-in duration-200 opacity-100 translate-y-0 sm:scale-100' : 'ease-out duration-300 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'"
    >
      <div v-if="editProject.image_url || preview" class="modal-window-split--img">
        <img v-if="preview" :src="preview" :alt="editProject.name">
        <img v-else :src="editProject.image_url" :alt="editProject.name">
        <div class="modal-window-split--img-change">
          <label for="new-file-upload-edit" class="inline-flex justify-center w-full rounded-md border border-gray-900 px-4 py-2 bg-gray-900 text-base leading-6 font-medium text-white shadow-sm hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
            </svg>
            Change image
          </label>
          <input id="new-file-upload-edit" ref="fileUpdate" type="file" accept="image/*" capture="camera" @change="onChangeFileUpload()">
        </div>
      </div>
      <div v-else class="modal-window-split--img bg-gradient">
        <div class="modal-window-split--img-upload flex items-center">
          <label for="new-file-upload-edit">
            <div class="bg-white bg-opacity-25 text-white font-bold py-2 px-2 rounded-full focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150">
              <div class="bg-white bg-opacity-25 text-white font-bold py-1 px-1 rounded-full">
                <div class="bg-white text-white font-bold py-4 px-4 rounded-full">
                  <img src="@/assets/img/icons/camera-upload.svg" alt="Rooms">
                </div>
              </div>
            </div>
            <span class="text-xl text-white">Take or upload a photo</span>
          </label>
          <input id="new-file-upload-edit" ref="fileUpdate" type="file" accept="image/*" capture="camera" @change="onChangeFileUpload()">
        </div>
      </div>
      <div class="modal-window-split--content">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-8 sm:pb-4">
          <div class="mb-4 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-lime sm:mx-0 sm:h-10 sm:w-10">
            <svg width="20px" height="19px" viewBox="0 0 20 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Home" fill="#000000" fill-rule="nonzero">
                  <path id="Path" d="M16.6406152,18.7486719 L3.35936523,18.7486719 C2.36858398,18.7486719 1.56249023,17.9426172 1.56249023,16.9517969 L1.56249023,7.49789062 C1.56249023,7.15273437 1.84229492,6.87289062 2.18749023,6.87289062 C2.53268555,6.87289062 2.81249023,7.15273437 2.81249023,7.49789062 L2.81249023,16.9517969 C2.81249023,17.2533594 3.05780273,17.4986719 3.35936523,17.4986719 L16.6406152,17.4986719 C16.9421777,17.4986719 17.1874902,17.2533594 17.1874902,16.9517969 L17.1874902,7.49789062 C17.1874902,7.15273437 17.4672949,6.87289062 17.8124902,6.87289062 C18.1576855,6.87289062 18.4374902,7.15273437 18.4374902,7.49789062 L18.4374902,16.9517969 C18.4374902,17.9425781 17.6314355,18.7486719 16.6406152,18.7486719 Z" />
                  <path id="Path" d="M19.3749902,9.68522461 C19.2150293,9.68522461 19.0551074,9.62417969 18.9330762,9.50210937 L11.1600684,1.72917969 C10.5204199,1.08949219 9.47959961,1.08949219 8.83991211,1.72917969 L1.06694336,9.50214844 C0.822880859,9.74625 0.427138672,9.74625 0.183076172,9.50214844 C-0.0610253906,9.25808594 -0.0610253906,8.86234375 0.183076172,8.61828125 L7.95604492,0.845273437 C9.08307617,-0.281757813 10.9169434,-0.281757813 12.0439355,0.845273437 L19.8169043,8.61824219 C20.0610059,8.86234375 20.0610059,9.25804687 19.8169043,9.50210937 C19.6949121,9.62417969 19.5349512,9.68522461 19.3749902,9.68522461 L19.3749902,9.68522461 Z" />
                  <path id="Shape" d="M12.4999902,18.7486719 L7.49999023,18.7486719 C7.15479492,18.7486719 6.87499023,18.4688281 6.87499023,18.1236719 L6.87499023,12.5767969 C6.87499023,11.4998437 7.75116211,10.6236719 8.82811523,10.6236719 L11.1718652,10.6236719 C12.2488184,10.6236719 13.1249902,11.4998437 13.1249902,12.5767969 L13.1249902,18.1236719 C13.1249902,18.4688281 12.8451855,18.7486719 12.4999902,18.7486719 Z M8.12499023,17.4986719 L11.8749902,17.4986719 L11.8749902,12.5767969 C11.8749902,12.1891016 11.5595605,11.8736719 11.1718652,11.8736719 L8.82811523,11.8736719 C8.44041992,11.8736719 8.12499023,12.1891016 8.12499023,12.5767969 L8.12499023,17.4986719 Z" />
                </g>
              </g>
            </svg>
          </div>
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center w-full sm:mt-0 sm:text-left">
              <div class="flex justify-between items-center mb-4">
                <h3 id="modal-headline" class="text-lg xl:text-2xl leading-8 font-bold text-gray-900">
                  Edit Project
                </h3>

                <button
                  type="button"
                  class="inline-flex justify-center items-center w-auto rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  @click="openDuplicateModal"
                >
                  <svg class="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
                  </svg>
                  Duplicate Project
                </button>
              </div>

              <div class="mt-2">
                <form class="w-full">
                  <div class="flex flex-wrap -mx-3 mb-4">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="projectName">Project name</label>
                      <input
                        id="projectName"
                        v-model="editProject.name"
                        class="appearance-none block w-full text-xs xl:text-base bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                      >
                    </div>
                    <div class="w-full md:w-1/2 px-3">
                      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="editProjFormProjPriority">Project Priority</label>
                      <div class="relative">
                        <select
                          id="editProjFormProjPriority"
                          v-model="editProject.priority"
                          class="block appearance-none w-full bg-gray-200 border text-xs xl:text-base border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          <option disabled selected>
                            -- Choose priority --
                          </option>
                          <option value="Enquiry Only">
                            Enquiry Only
                          </option>
                          <option value="Confirmed">
                            Confirmed
                          </option>
                          <option value="Urgent">
                            Urgent
                          </option>
                        </select>
                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-gradient-to-br from-blue-dark to-blue text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              @click="updateProject()"
            >Update Project</button>
          </span>
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto sm:ml-3">
            <button
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              @click="closeModal()"
            >Cancel</button>
          </span>
          <span class="mt-3 flex w-full sm:mt-0 sm:w-auto">
            <button
              type="button"
              class="inline-flex justify-center w-full px-4 py-2 text-base leading-6 font-medium text-red-600 hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              @click="showDeleteConfirmModal = true"
            >Delete Project</button>
          </span>
        </div>
      </div>
    </div>

    <confirm-modal 
      :show-modal="showDeleteConfirmModal" 
      modal-title="Delete Project" 
      :modal-text="getDeleteString" 
      confirm-btn-txt="Delete"
      @closeModal="showDeleteConfirmModal = false"
      @confirmAction="deleteProject"
    />

    <duplicate-project-modal 
      :show-modal="showDuplicateProjectModal"
      :project-duplicate-data="projectDuplicate"
      @closeModal="showDuplicateProjectModal = false"
    />
  </div>
</template>

<script>
import ConfirmModal from "./ConfirmModal.vue";
import DuplicateProjectModal from "./DuplicateProjectModal.vue";

export default {
  components: {
    ConfirmModal,
    DuplicateProjectModal
  },
  props: {
    showModal: Boolean,
    projectId: {
      type: Number,
      default: 0,
      required: false
    },
    editProject: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      file: '',
      preview: '',
      project: {
        name: "",
        priority: ""
      },
      showDuplicateProjectModal: false,
      projectDuplicate: {
        name: null,
        existing_customer: false,
        priority: null,
        customer: null
      },
      duplicateProjectErrors: {},
      showDeleteConfirmModal: false
    };
  },
  computed: {
    getDeleteString() {
      return `Are you sure you want to delete <strong>${this.editProject.name}</strong>?`;
    }
  },
  methods: {
    onChangeFileUpload() {
      this.file = this.$refs.fileUpdate.files[0];
      this.preview = URL.createObjectURL(this.file);
    },
    updateProject() {
      const projectData = { ...this.editProject };
      const formData = new FormData();
      if (this.file) {
        const data = new FormData();
        data.append('projectImg', this.file);
        this.axios.post(`/api/projects/${this.editProject.id}/upload-image`, data).then((res) => {
          projectData.image_url = res.data; 
        });
      }
      formData.append('name', projectData.name);
      formData.append('priority', projectData.priority);

      this.axios.post(`/api/projects/${this.editProject.id}/update`, formData, { headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {
        this.$store.dispatch('project/updateProject', projectData);
        this.$emit("closeModal");
      }).finally(() => {
        this.$emit("success", 'updated');
      });
    },
    deleteProject() {
      this.axios.delete(`/api/projects/${this.editProject.id}`).then(() => {
        this.showDeleteConfirmModal = false;
        this.$store.dispatch('project/removeProject', this.editProject.id);
        this.$emit("closeModal");
      }).finally(() => {
        this.$emit("success", 'deleted');
      }).catch(() => {
            this.$notify({ 
            type: 'error', 
            title: 'Error', 
            text: 'Something went wrong. Please Try Again' 
          });
        });
    },
    closeModal() {
      this.file = '';
      this.preview = '';
      this.$emit("closeModal");
    },
    openDuplicateModal() {
      this.projectDuplicate = { ...this.editProject };
      this.projectDuplicate.name = `${this.projectDuplicate.name} Duplicate`;
      this.showDuplicateProjectModal = true;
    },
    selectFile(event) {
      this.projectImg = event.target.files[0];
      const data = new FormData();
      data.append('projectImg', this.projectImg);

      this.axios.post(`/api/projects/${this.projectId}/upload-image`, data).then((res) => {
        this.project.image_url = res.data;
        this.projectImg = null;
        this.$refs.projectImageUpload.value = '';
      });
    }
  }
};
</script>