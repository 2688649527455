<template>
  <div>
    <Modal title="Edit Bundle Product" :show="show" size="large" :close="closeEditProductModal" :loading="loading" loading-msg="Loading Product Bundle">
      <template v-slot:content>
        <div v-if="editProductData && (editProductData.groupedProducts.length > 0 || editProductData.groupedCustomProducts.length > 0)" class="relative">
          <div class="my-4">
            <div class="flex justify-between items-center w-full">
              <div class="flex items-center mb-4 sm:mb-0 w-1/2">
                <div class="w-3/4">
                  <h4 class="text-lg font-medium">
                    {{ editProductData.product.name }}
                  </h4>
                  <p class="text-xs text-gray-400">
                    {{ editProductData.product.brands }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div v-for="(product, productIndex) in editProductData.groupedProducts" :key="productIndex" class="p-2 mb-2 rounded-md border">
            <div class="flex items-center">
              <div class="flex justify-center items-center mr-4 w-20 h-20 bg-gradient-to-br from-gray-100 to-gray-400 rounded-md border">
                <img v-if="product.product && product.product.first_image" :src="product.product.first_image.url" alt="" class="object-cover">
                <svg v-else class="w-16 h-16 opacity-25" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                </svg>
              </div>
              <div>
                <h4 class="text-sm font-bold text-blue-dark">
                  {{ product.product.name }} - ({{ product.products.length }})
                </h4>
                <p class="text-xs text-gray-400">
                  {{ product.product.brand }}
                </p>
              </div>
            </div>
            <div class="overflow-hidden bg-white rounded-t-sm mt-6">
              <div class="flex relative justify-between items-center p-4 text-white bg-black cursor-pointer" @click="selectAccordion(`global-properties-${productIndex}`)">
                <div class="absolute inset-0 bg-gradient-to-br from-blue-dark via-green-dark to-blue-dark opacity-75" />

                <div class="flex relative z-20 items-center">
                  <div class="flex-shrink-0 p-3 bg-blue-light bg-opacity-25 rounded-sm">
                    <HeroIcon icon="Globe" class="w-6 h-6 text-blue-light" />
                  </div>

                  <div class="ml-4">
                    <h3 class="text-lg font-medium">
                      Global Properties
                    </h3>
                    <p class="text-xs">
                      Anything set here will affect all Products in this Project. Some settings like 'Product Description' will impact all Projects.
                    </p>
                  </div>
                </div>

                <div class="flex relative z-20 justify-center items-center w-6 h-6 bg-blue-light rounded-sm">
                  <svg v-if="activeAccordion === `global-properties-${productIndex}`" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                  </svg>
                  <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>
              
              <div v-show="activeAccordion === `global-properties-${productIndex}`" class="bg-gray-50 grid grid-cols-6 gap-4 py-6 px-4">
                <div class="col-span-3">
                  <label for="customer_price" class="block text-sm font-medium text-gray-700">Customer Price</label>
                  <div class="relative mt-1 rounded-md shadow-sm">
                    <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                      <span class="sm:text-sm text-gray-500">
                        {{ currency }}
                      </span>
                    </div>
                    <input id="customer_price" v-model="product.customer_price" type="number" step="0.01" name="customer_price" class="pl-7 form-input">
                  </div>
                  <p class="mt-2 text-xs text-gray-500">
                    This will be the price regardless of Supplier.
                  </p>
                </div>

                <div v-if="projectStages.length > 0" class="col-span-3">
                  <label for="project_stage" class="block text-sm font-medium text-gray-700">Project Install Stage</label>
                  <select id="project_stage" v-model="product.install_stage" name="project_stage" required class="select-input">
                    <option :value="null" selected disabled>
                      -- Choose Project Stage --
                    </option>
                    <option v-for="(projectStage, index) in projectStages" :key="index" :value="projectStage.id">
                      {{ projectStage.name }}
                    </option>
                  </select>
                  <p class="mt-2 text-xs text-gray-500">
                    The stage at which this Product will be installed.
                  </p>
                </div>

                <div v-if="networkRanges && networkRanges.length > 0" class="col-span-3">
                  <label for="network_category" class="block text-sm font-medium text-gray-700">Network IP</label>
                  <select id="network_category" v-model="product.networkRange" name="network_category" required class="select-input">
                    <option :value="null" selected>
                      -- Choose Category --
                    </option>
                    <option v-for="(avNetworkRange, index) in networkRanges" :key="index" :value="avNetworkRange.id">
                      {{ avNetworkRange.category }} - 192.168.0.[{{ avNetworkRange.start }} - {{ avNetworkRange.end }}]
                    </option>
                  </select>
                  <div class="inline-flex items-center mt-2 text-xs text-gray-500">
                    <input id="saveAsDefaultNetworkRange" v-model="product.saveNetworkRangesAsDefault" name="saveAsDefaultNetworkRange" :value="true" type="checkbox" class="w-4 h-4 text-blue-light rounded border-blue-dark focus:ring-blue-light">
                    <p class="ml-2 text-xs text-gray-700">
                      These Network IPs will become the default for <span class="font-bold">{{ product.product.name }}</span>.
                    </p>
                  </div>
                  <span class="inline-flex items-center py-1 px-2 text-xs text-blue-light bg-blue-light bg-opacity-20 rounded-sm">
                    <InformationCircleIcon class="mr-2 w-4 h-4" /> Note: Previously specified {{ product.product.name }} in existing Projects will not be affected.
                  </span>
                </div>

                <div class="block col-span-6" aria-hidden="true">
                  <div class="py-5">
                    <div class="border-t border-gray-200" />
                  </div>
                </div>

                <div class="col-span-6">
                  <div class="flex justify-between items-center mb-4">
                    <div>
                      <h3 class="text-sm font-medium text-gray-700">
                        Cables
                      </h3>
                      <p class="mt-1 text-xs text-gray-500">
                        Cables specified here will appear on your cable schedule.
                      </p>
                    </div>

                    <button type="button" class="mr-1 btn-primary-sm" @click="showAddCableModal(product)">
                      <PlusIcon class="-ml-1 w-4 h-4 " />
                      Add Cable
                    </button>
                  </div>

                  <div class="flex items-start p-4 my-4 bg-blue-dark rounded-md border">
                    <div class="flex items-center h-5">
                      <input id="saveAsDefaultCables" v-model="product.saveCablesAsDefault" name="saveAsDefaultCables" :value="true" type="checkbox" class="w-4 h-4 text-blue-light rounded border-blue-dark focus:ring-blue-light">
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="saveAsDefaultCables" class="font-bold text-white">Save as Product Default</label>
                      <p class="text-xs text-gray-50">
                        These cables will become the default for <span class="font-bold">{{ editProductData.name }}</span>.
                      </p>
                      <span class="inline-flex items-center py-1 px-2 mt-2 text-xs text-white bg-blue-light bg-opacity-25 rounded-md">
                        <InformationCircleIcon class="mr-2 w-4 h-4 text-lime" /> Note: Previously specified {{ editProductData.name }} in existing Projects will not be affected.
                      </span>
                    </div>
                  </div>

                  <div class="flex flex-col">
                    <div class="overflow-x-auto -my-2 sm:-mx-6 lg:-mx-8">
                      <div class="inline-block py-2 sm:px-6 lg:px-8 min-w-full align-middle">
                        <div class="overflow-hidden sm:rounded-lg border">
                          <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                              <tr>
                                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                  Cable
                                </th>
                                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                  Purpose
                                </th>
                                <th scope="col" class="relative py-3 px-6">
                                  <span class="sr-only">Edit</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="product.cables.length > 0">
                              <tr v-for="(cable, cableIdx) in product.cables" :key="cableIdx" class="bg-white" :class="(cableIdx % 2) === 0 ? 'bg-white' : 'bg-gray-50'">
                                <td class="py-3 px-6 text-xs font-medium text-gray-900 whitespace-nowrap">
                                  {{ cable.cable }}
                                </td>
                                <td class="py-3 px-6 text-xs text-gray-500 whitespace-nowrap">
                                  {{ cable.purpose }}
                                </td>
                                <td class="py-3 px-6 text-sm font-medium text-right whitespace-nowrap">
                                  <button type="button" class="p-2 rounded-full" @click="deleteCable(product, cableIdx)">
                                    <svg class="w-5 h-5 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr class="bg-white">
                                <td colspan="3" class="py-4 px-6 text-xs font-medium text-center text-gray-500 whitespace-nowrap">
                                  No Cables
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="overflow-hidden bg-white">
              <div class="flex relative justify-between items-center p-4 text-white bg-black cursor-pointer" @click="selectAccordion(`installation-time-${productIndex}`)">
                <div class="absolute inset-0 bg-gradient-to-br from-blue-dark via-green-dark to-blue-dark opacity-75" />

                <div class="flex relative z-20 items-center">
                  <div class="flex-shrink-0 p-3 bg-blue-light bg-opacity-25 rounded-sm">
                    <HeroIcon icon="Clock" class="w-6 h-6 text-blue-light" />
                  </div>

                  <div class="ml-4">
                    <h3 class="text-lg font-medium">
                      Installation Time
                    </h3>
                    <p class="text-xs">
                      Setting an installation time will allow you to better quote for your labour.
                    </p>
                  </div>
                </div>

                <div class="flex relative z-20 justify-center items-center w-6 h-6 bg-blue-light rounded-sm">
                  <svg v-if="activeAccordion === `installation-time-${productIndex}`" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                  </svg>
                  <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>  
              </div>

              <div v-show="activeAccordion === `installation-time-${productIndex}`" class="py-6 px-4">
                <p class="text-sm text-gray-600">
                  This will be the installation times for all occurances of a <span class="font-bold text-blue-light">{{ product.product.name }}</span>.
                </p>

                <div v-if="teamPositions.length > 0">
                  <div class="flex items-center p-4 my-6 bg-gradient-to-br from-black via-green-dark to-black rounded-sm">
                    <input id="saveAsDefaultInstallTime" v-model="editProductData.updateDefaultInstallInfo" type="checkbox" :value="true" class="mr-4 text-blue-dark rounded border-gray-300 focus:ring-indigo-500">
                    <div class="flex flex-col">
                      <label for="saveAsDefaultInstallTime" class="font-bold text-white">Save as Install Time Defaults</label>
                      <p class="text-xs text-gray-200">
                        This will update your default install time(s) for <span class="font-bold text-blue-light">{{ product.product.name }}</span>.
                      </p>
                      <p class="mt-2 text-xs text-gray-200">
                        <span class="font-bold">Note:</span> Previously added install times in other Projects will remain unaffected.
                      </p>
                    </div>
                  </div>

                  <div class="overflow-hidden sm:rounded-lg border border-gray-200">
                    <table class="w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                            Installation Type
                          </th>
                          <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                            Install Time
                          </th>
                          <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                            Total Install Time
                          </th>
                        </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="(installTime, posIdx) in product.installTimeDetails" :key="posIdx">
                          <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                            {{ installTime.position }}
                          </td>
                          <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                            <div class="flex rounded-md shadow-sm">
                              <input v-model="installTime.install_time_mins" type="number" class="block flex-1 mt-0 w-full sm:text-sm text-right rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 form-input">
                              <span class="inline-flex items-center px-3 text-sm text-gray-500 bg-gray-50 rounded-r-md border border-l-0 border-gray-300">
                                {{ 'minute' | pluralize(installTime.install_time_mins) }}
                              </span>
                            </div>
                          </td>
                          <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                            {{ 'minute' | pluralize((installTime.install_time_mins * product.products.length), true) }}
                          </td>
                        </tr>
                      </tbody>
                      <tfoot class="bg-gray-50">
                        <tr>
                          <td scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                            Total Install Time:
                          </td>
                          <td scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                            {{ 'minute' | pluralize(productInstallTime(product.installTimeDetails), true) }}
                          </td>
                          <td scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                            {{ 'minute' | pluralize(productTotalInstallTime(product.installTimeDetails, product.products.length), true) }}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <div v-else>
                  You haven't added any team positions. To associate install time with products, add at least one Team Position.
                </div>
              </div>
            </div>
            <div class="overflow-hidden rounded-b-sm">
              <div class="flex relative justify-between items-center p-4 text-white bg-black cursor-pointer" @click="selectAccordion(`set-label-${productIndex}`)">
                <div class="absolute inset-0 bg-gradient-to-br from-blue-dark via-green-dark to-blue-dark opacity-75" />

                <div class="flex relative z-20 items-center">
                  <div class="flex-shrink-0 p-3 bg-blue-light bg-opacity-25 rounded-sm">
                    <HeroIcon icon="ClipboardList" class="w-6 h-6 text-blue-light" />
                  </div>

                  <div class="ml-4">
                    <h3 class="font-medium">
                      Serial Number
                    </h3>
                    <p class="text-xs text-gray-200">
                      Set serial number &amp; product associated position, module or label name.
                    </p>
                  </div>
                </div>

                <div class="flex relative z-20 justify-center items-center w-6 h-6 bg-blue-light rounded-sm">
                  <svg v-if="activeAccordion === `set-label-${productIndex}`" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                  </svg>
                  <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>

              <div v-show="activeAccordion === `set-label-${productIndex}`" class="py-6 px-4">
                <div class="overflow-hidden sm:rounded-lg border">
                  <table class="w-full">
                    <thead class="table-head">
                      <tr>
                        <th scope="col" class="table-heading">
                          {{ 'Product' | pluralize(product.qty) }}
                        </th>
                        <th scope="col" class="table-heading">
                          Label
                        </th>
                        <th scope="col" class="table-heading">
                          Serial No
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="isLabel(product.product, product)" class="bg-white divide-y divide-gray-200">
                      <tr v-for="(item, index) in product.products" :key="index">
                        <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                          <label class="form-label">Label {{ index + 1 }}</label>
                        </td>
                        <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                          <input v-model="item.label" type="text" :placeholder="`Enter Label ${index + 1} Name`" class="form-input">
                        </td>
                        <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                          <input v-model="item.serial_no" type="text" placeholder="Serial No" class="form-input">
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="(hasProductSpecificQuestions(product).length > 0)" class="bg-white divide-y divide-gray-200">
                      <tr v-for="(item, index) in product.products" :key="index">
                        <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                          <div v-if="item.product.system_design.numberOfSpeakers === 'Pair'">
                            <label class="border-0 form-input">Location 1</label>
                            <label class="border-0 form-input">Location 2</label>
                          </div>
                          <div v-else-if="item.product.system_design.numberOfSpeakers">
                            <label class="form-label">Input {{ index + 1 }} Purpose</label>
                          </div>
                        </td>
                        <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                          <div v-if="item.product.system_design.numberOfSpeakers === 'Pair'">
                            <input v-model="item.details.details.position" type="text" placeholder="Positon 1" class="form-input">
                            <input v-model="item.details.details.position2" type="text" placeholder="Position 2" class="form-input">
                          </div>
                          <div v-else-if="item.product.system_design.numberOfSpeakers">
                            <input v-model="item.details.details.position" type="text" placeholder="Position" class="form-input">
                          </div>
                        </td>
                        <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                          <input v-model="item.serial_no" type="text" placeholder="Serial No" class="form-input">
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="isRakoWiringModule(product.product)" class="bg-white divide-y divide-gray-200">
                      <tr v-for="(item, index) in product.products" :key="index">
                        <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                          <label class="form-label">Module {{ index + 1 }} Name</label>
                        </td>
                        <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                          <input v-model="item.details.details.moduleName" type="text" :placeholder="`Enter Module ${index + 1} Name`" class="form-input">
                        </td>
                        <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                          <input v-model="item.serial_no" type="text" placeholder="Serial No" class="form-input">
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="isKeypad(product.product)" class="bg-white divide-y divide-gray-200">
                      <tr v-for="(item, index) in product.products" :key="index">
                        <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                          <label class="form-label">Keypad Name {{ index + 1 }}</label>
                        </td>
                        <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                          <input v-model="item.details.details.name" type="text" :placeholder="`Enter Keypad ${index + 1} Name`" class="form-input">
                        </td>
                        <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                          <input v-model="item.serial_no" type="text" placeholder="Serial No" class="form-input">
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="isRakoLEDModule(product.product)" class="bg-white divide-y divide-gray-200">
                      <tr v-for="(item, index) in product.products" :key="index">
                        <div v-for="(idx, t) in numberOfChannels" :key="idx" class="row conts--input">
                          <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                            <label class="form-label">Channel Name {{ idx }}</label>
                          </td>
                          <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                            <input v-model="item.details.details.channels[t]" type="text" placeholder="Enter channel name" class="form-input">
                          </td>
                          <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                            <input v-model="item.serial_no" type="text" placeholder="Serial No" class="form-input">
                          </td>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div v-for="(product, productIndex) in editProductData.groupedCustomProducts" :key="`custom_${productIndex}`" class="p-2 mb-2 rounded-md border">
            <div class="flex items-center">
              <div class="flex justify-center items-center mr-4 w-20 h-20 bg-gradient-to-br from-gray-100 to-gray-400 rounded-md border">
                <img v-if="product.product && product.product.image_url" :src="product.product.image_url" alt="" class="object-cover">
                <svg v-else class="w-16 h-16 opacity-25" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                </svg>
              </div>
              <div>
                <h4 class="text-sm font-bold text-blue-dark">
                  {{ product.product.name }} - ({{ product.products.length }})
                </h4>
                <p class="text-xs text-gray-400">
                  {{ product.product.brand }}
                </p>
              </div>
            </div>

            <div class="overflow-hidden mt-4 rounded-t-sm">
              <div class="flex relative justify-between items-center p-4 text-white bg-black cursor-pointer" @click="selectAccordion(`global-properties-custom_${productIndex}`)">
                <div class="absolute inset-0 bg-gradient-to-br from-blue-dark via-green-dark to-blue-dark opacity-75" />
                <div class="flex relative z-20 items-center">
                  <div class="flex-shrink-0 p-3 bg-blue-light bg-opacity-25 rounded-sm">
                    <HeroIcon icon="Globe" class="w-6 h-6 text-blue-light" />
                  </div>

                  <div class="ml-4">
                    <h3 class="text-lg font-medium">
                      Global Properties
                    </h3>
                    <p class="text-xs">
                      Anything set here will affect all Products in this Project. Some settings like 'Product Description' will impact all Projects.
                    </p>
                  </div>
                </div>

                <div class="fflex relative z-20 justify-center items-center w-6 h-6 bg-blue-light rounded-sm">
                  <svg v-if="activeAccordion === `global-properties-custom_${productIndex}`" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                  </svg>
                  <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>
              <div v-show="activeAccordion === `global-properties-custom_${productIndex}`" class="grid grid-cols-6 gap-4 py-6 px-4 bg-gray-50">
                <div class="col-span-3">
                  <label for="customer_price" class="block text-sm font-medium text-gray-700">Customer Price</label>
                  <div class="relative mt-1 rounded-md shadow-sm">
                    <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                      <span class="sm:text-sm text-gray-500">
                        {{ currency }}
                      </span>
                    </div>
                    <input id="customer_price" v-model="product.customer_price" type="number" step="0.01" name="customer_price" class="pl-7 form-input">
                  </div>
                  <p class="mt-2 text-xs text-gray-500">
                    This will be the price regardless of Supplier.
                  </p>
                </div>
              </div>
            </div>

            <div class="overflow-hidden bg-white">
              <div class="flex relative justify-between items-center p-4 text-white bg-black cursor-pointer" @click="selectAccordion(`installation-time--custom_${productIndex}`)">
                <div class="absolute inset-0 bg-gradient-to-br from-blue-dark via-green-dark to-blue-dark opacity-75" />

                <div class="flex relative z-20 items-center">
                  <div class="flex-shrink-0 p-3 bg-blue-light bg-opacity-25 rounded-sm">
                    <HeroIcon icon="Clock" class="w-6 h-6 text-blue-light" />
                  </div>

                  <div class="ml-4">
                    <h3 class="text-lg font-medium">
                      Installation Time
                    </h3>
                    <p class="text-xs">
                      Setting an installation time will allow you to better quote for your labour.
                    </p>
                  </div>
                </div>

                <div class="flex relative z-20 justify-center items-center w-6 h-6 bg-blue-light rounded-sm">
                  <svg v-if="activeAccordion === `installation-time--custom_${productIndex}`" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                  </svg>
                  <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>

              <div v-show="activeAccordion === `installation-time--custom_${productIndex}`" class="py-6 px-4 bg-gray-50">
                <p class="text-sm text-gray-600">
                  This will be the installation times for all occurances of a <span class="font-bold text-blue-light">{{ product.product.name }}</span>.
                </p>

                <div v-if="teamPositions.length > 0">
                  <div class="flex items-center p-4 my-6 bg-gradient-to-br from-black via-green-dark to-black rounded-sm">
                    <input id="saveAsDefaultInstallTime" v-model="editProductData.updateDefaultInstallInfo" type="checkbox" :value="true" class="mr-4 text-blue-dark rounded border-gray-300 focus:ring-indigo-500">
                    <div class="flex flex-col">
                      <label for="saveAsDefaultInstallTime" class="font-bold text-white">Save as Install Time Defaults</label>
                      <p class="text-xs text-gray-200">
                        This will update your default install time(s) for <span class="font-bold text-blue-light">{{ product.product.name }}</span>.
                      </p>
                      <p class="text-xs text-gray-200">
                        <span class="font-bold">Note:</span> Previously added install times in other Projects will remain unaffected.
                      </p>
                    </div>
                  </div>

                  <div class="overflow-hidden sm:rounded-lg border-b border-gray-200 shadow">
                    <table class="w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                            Installation Type
                          </th>
                          <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                            Install Time
                          </th>
                          <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                            Total Install Time
                          </th>
                        </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="(installTime, posIdx) in product.installTimeDetails" :key="posIdx">
                          <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                            {{ installTime.position }}
                          </td>
                          <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                            <div class="flex rounded-md shadow-sm">
                              <input v-model="installTime.install_time_mins" type="number" min="0" class="block flex-1 mt-0 w-full sm:text-sm text-right rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 form-input">
                              <span class="inline-flex items-center px-3 text-sm text-gray-500 bg-gray-50 rounded-r-md border border-l-0 border-gray-300">
                                {{ 'minute' | pluralize(installTime.install_time_mins) }}
                              </span>
                            </div>
                          </td>
                          <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                            {{ 'minute' | pluralize((installTime.install_time_mins * product.products.length), true) }}
                          </td>
                        </tr>
                      </tbody>
                      <tfoot class="bg-gray-50">
                        <tr>
                          <td scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                            Total Install Time:
                          </td>
                          <td scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                            {{ 'minute' | pluralize(productInstallTime(product.installTimeDetails), true) }}
                          </td>
                          <td scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                            {{ 'minute' | pluralize(productTotalInstallTime(product.installTimeDetails, product.products.length), true) }}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <div v-else>
                  You haven't added any team positions. To associate install time with products, add at least one Team Position.
                </div>
              </div>
            </div>
            <div class="overflow-hidden rounded-b-sm">
              <div class="flex relative justify-between items-center p-4 text-white bg-black cursor-pointer" @click="selectAccordion(`set-label--custom_${productIndex}`)">
                <div class="absolute inset-0 bg-gradient-to-br from-blue-dark via-green-dark to-blue-dark opacity-75" />

                <div class="flex relative z-20 items-center">
                  <div class="flex-shrink-0 p-3 bg-blue-light bg-opacity-25 rounded-sm">
                    <HeroIcon icon="ClipboardList" class="w-6 h-6 text-blue-light" />
                  </div>

                  <div class="ml-4">
                    <h3 class="font-medium">
                      Serial Number
                    </h3>
                    <p class="text-xs text-gray-200">
                      Set serial number &amp; product associated position, module or label name.
                    </p>
                  </div>
                </div>

                <div class="flex relative z-20 justify-center items-center w-6 h-6 bg-blue-light rounded-sm">
                  <svg v-if="activeAccordion === `set-label--custom_${productIndex}`" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                  </svg>
                  <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>

              <div v-show="activeAccordion === `set-label--custom_${productIndex}`" class="py-6 px-4">
                <div class="overflow-hidden sm:rounded-lg border">
                  <table class="w-full">
                    <thead class="table-head">
                      <tr>
                        <th scope="col" class="table-heading">
                          {{ 'Product' | pluralize(product.products.length) }}
                        </th>
                        <th scope="col" class="table-heading">
                          Label
                        </th>
                        <th scope="col" class="table-heading">
                          Serial No
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="(item, index) in product.products" :key="index">
                        <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                          <label class="form-label">Label {{ index + 1 }}</label>
                        </td>
                        <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                          <input v-model="item.label" type="text" :placeholder="`Enter Label ${index + 1} Name`" class="form-input">
                        </td>
                        <td class="py-4 px-6 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                          <input v-model="item.serial_no" type="text" placeholder="Serial No" class="form-input">
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </template>
      <template v-slot:buttons>
        <button type="button" class="w-full sm:w-auto btn-primary-md" @click="save">
          Save {{ 'Product' | pluralize(editProductData.groupedProducts.length) }}
        </button>
        <button type="button" class="mt-2 sm:mt-0 sm:mr-2 w-full sm:w-auto btn-white-md" @click="closeEditProductModal">
          Cancel
        </button>
      </template>
    </Modal>
    <AddCableToProductModal
      form-name="addCableToEditProduct"
      :show="showAddCableToProductModal"
      :close="closeAddCableToProductModal"
      :cables="cables"
      :cable-purposes="cablePurposes"
      @addCableToProduct="addCableToProduct"
    />
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import AddCableToProductModal from './AddCableToProductModal.vue';
import { PlusIcon, InformationCircleIcon } from "@vue-hero-icons/solid";

export default {
  components: {
    Modal,
    PlusIcon,
    InformationCircleIcon,
    AddCableToProductModal
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    editProductData: {
      type: Object,
      required: true
    },
    save: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      activeAccordion: null,
      upgradingProduct: false,
      newInstallDetails: {
        team_position_id: null,
        install_time_mins: 0
      },
      showAddCableToProductModal: false,
      productToAddCableTo: null
    };
  },
  computed: {
    projectStages() {
      return this.$store.state.system.projectStages;
    },
    currency() {
      return this.$store.state.auth.user.team.team_currency.symbol;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    roomId() {
      return this.$route.params.roomId;
    },
    teamPositions () {
      return this.$store.state.system.teamPositions;
    },
    cables() {
      return this.$store.state.system.productSettings ? this.$store.state.system.productSettings.cables : [];
    },
    cablePurposes() {
      return this.$store.state.system.productSettings ? this.$store.state.system.productSettings.cablePurposes : [];
    },
    networkRanges(){
       return this.$store.state.system.productSettings ? this.$store.state.system.productSettings.networkRanges : [];
    },
    projectNetworkRanges() {
      return [this.$store.state.project.project.network_range_1, this.$store.state.project.project.network_range_2, this.$store.state.project.project.network_range_3];
    }
  },
  methods: {
    closeEditProductModal() {
      this.activeAccordion = null;
      this.$emit("close");
    },
    productInstallTime(installTimes) {
      let installTime = 0;
      installTimes.forEach((el) => {
        installTime += parseInt(el.install_time_mins);
      });
      return installTime;
    },
    productTotalInstallTime(installTimes, qty) {
      const totalInstallTime = this.productInstallTime(installTimes) * qty;
      return totalInstallTime;
    },
    selectAccordion(accordion) {
      if (this.activeAccordion === accordion) {
        this.activeAccordion = null;
      } else {
        this.activeAccordion = accordion;
      }
    },
    calculateMargin(tradePrice, retailPrice) {
      const taxRate = this.$store.state.auth.taxRate.product + 1;
      return ((retailPrice / taxRate)) - tradePrice;
    },
    showAddCableModal(product) {
      this.productToAddCableTo = product;
      this.showAddCableToProductModal = true;
    },
    closeAddCableToProductModal() {
      this.showAddCableToProductModal = false;
      this.productToAddCableTo = null;
    },
    addCableToProduct(cable, purpose) {
      this.productToAddCableTo.cables.push({cable, purpose});
      this.productToAddCableTo = null;
    },
    deleteCable(product, index) {
      product.cables.splice(index, 1);
    },
    isRakoWiringModule(product) {
      if (!product.system_design || !product.system_design.rakoProductType) {
        return false;
      }
      return product.system_design.rakoProductType === 'Wiring Module';
    },
    isKeypad(product) {
      if (!product.system_design || !product.system_design.rakoProductType || product.system_design.rakoProductType !== 'Keypad') {
        return false;
      }
      return product.system_design.rakoProductType === 'Keypad';
    },
    isRakoLEDModule(product) {
      if (!product.system_design || !product.system_design.rakoProductType) {
        return false;
      }
      return product.system_design.rakoProductType === 'LED Driver';
    },
    hasProductSpecificQuestions(product) {
      if (!product.products.length > 0 || !product.products[0].product.system_design || !product.products[0].product.system_design.cables) {
        return false;
      }

      const usCables = product.products[0].product.system_design.cables.filter((cab) => cab.purpose === 'User Selected');
 
      // Check the product has been configured correctly
      product.products.forEach((el, index) => {
        if (el.product.system_design.numberOfSpeakers === 'Single') {
          if (!el.details.hasOwnProperty('details')) {
            el.details = Object.assign({}, el.details, { details: { position: null } });
          }
        } else if (el.product.system_design.numberOfSpeakers === 'Pair') {
          if (!el.details.hasOwnProperty('details')) {
            el.details = Object.assign({}, el.details, { details: { position: null, position2: null } });
          }
        }
      });
      
      return usCables;
    },
    isLabel(product, allProducts) {
      if ((this.hasProductSpecificQuestions(allProducts).length > 0 )|| this.isRakoLEDModule(product) || this.isKeypad(product) ||  this.isRakoWiringModule(product)) {
        return false;
      } else return true;
    }
  }
};
</script>