<template>
  <Modal title="Edit Placeholder Product" :show="show" size="large" :close="closeEditProductModal">
    <template v-slot:content>
      <div v-if="editProductData && editProductData.placeholder_product" class="relative">
        <div>
          <form id="updatePlaceholderProduct" @submit.prevent="updatePlaceholderProduct">
            <div class="grid grid-cols-3 gap-4">
              <div>
                <label class="form-label">Category</label>
                <input v-model="editProductData.placeholder_product.category" type="text" placeholder="Category Name" class="form-input">
              </div>

              <div>
                <label class="form-label">Product Name</label>
                <input v-model="editProductData.placeholder_product.name" type="text" placeholder="Enter Product Name" class="form-input">
              </div>
              <div>
                <label class="form-label">Brand</label>
                <input v-model="editProductData.placeholder_product.brand" type="text" placeholder="Enter Brand" class="form-input">
              </div>
              <div>
                <label class="form-label">Retail Price</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span class="text-gray-500 sm:text-sm">
                      {{ currency }}
                    </span>
                  </div>
                  <input v-model="editProductData.placeholder_product.retail_price" type="number" step="0.01" name="placeholderRetailPrice" class="form-input pl-7">
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </template>
    <template v-slot:buttons>
      <button type="submit" form="updatePlaceholderProduct" class="w-full inline-flex justify-center rounded-md border border-transparent text-white bg-blue-light shadow-sm px-4 py-2 text-base font-medium hover:bg-blue-dark hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
        Save {{ 'Product' | pluralize(editProductData.qty) }}
      </button>

      <button type="button" class="w-full inline-flex justify-center rounded-md border border-blue-light text-blue-light shadow-sm px-4 py-2 text-base font-medium hover:bg-blue-light hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" @click="closeEditProductModal">
        Cancel
      </button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';

export default {
  components: {
    Modal
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    editProductData: {
      type: Object,
      required: true
    }
  },
  computed: {
    currency() {
      return this.$store.state.auth.user.team.team_currency.symbol;
    },
    categories() {
      return this.$store.state.system.categories;
    },
    placeholderProductId() {
      return this.editProductData.placeholder_product_id;
    },
    teamsAvailableSuppliers() {
      const suppliers = this.$store.state.auth.user.team.suppliers;
      const availableSuppliers = [];
      suppliers.map(el => availableSuppliers.push(el.supplier));
      return availableSuppliers;
    }
  },
  methods: {
    closeEditProductModal() {
      this.$emit('close');
    },
    updatePlaceholderProduct() {
      this.editProductData.placeholder_product.trade_price = 0;
      this.axios.put(`/api/products/placeholder-product/${this.placeholderProductId}`, this.editProductData.placeholder_product).then(() => {
        this.$notify({ 
          type: 'success', 
          title: 'Success',
          text: 'Placeholder Product updated'
        });
        this.closeEditProductModal();
      });
    }
  }
};
</script>