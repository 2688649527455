<template>
  <div v-if="projects">
    <div class="shell-container animate-second">
      <header class="router-header flex flex-wrap content-center items-center transition ease-in-out container px-4 md:px-8">
        <div class="flex-1">
        &nbsp;
        </div>
        <h3 class="flex-10 sans-serif text-2xl xl:text-4xl text-center font-bold">
          Your Projects
        </h3>

        <ul class="flex flex-1 text-right flex-col fabs">
          <li>
            <span class="invisible sm:visible font-semibold text-xs xl:text-base">New Project</span>
            <button class="bg-gradient hover:opacity-50 text-white font-bold p-1 xl:p-2 rounded-full" @click="newProjectModalShow = true">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 xl:w-6 xl:h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
            </button>
          </li>
          <li>
            <div class="h-full bg-gray-200 -mr-4 rounded-l-full transition-all duration-1000 ease-in-out" :class="showSearch ? 'w-4/5' : 'w-0'">
              <input v-model="searchTerm" :class="!showSearch ? 'hidden' : ''" class="w-full py-2 px-6 bg-transparent outline-none" placeholder="Search...">
            </div>
            <button class="bg-gradient hover:opacity-50 text-white font-bold p-1 xl:p-2 rounded-full" @click="showSearch = !showSearch">
              <svg v-if="!showSearch" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="search w-5 h-5 xl:w-6 xl:h-6">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
              <svg v-else fill="none" viewBox="0 0 24 24" stroke="currentColor" class="x w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </li>
        </ul>
      </header>

      <swiper
        ref="projectsSwiper"
        class="swiper list-selector featured-list f-5"
        :options="swiperOptions"
        :auto-update="true"
        :auto-destroy="false"
        :delete-instance-on-destroy="false"
        :cleanup-styles-on-destroy="true"
      >
        <swiper-slide v-for="project in filteredProjects()" :key="project.id">
          <div class="absolute bottom-5 right-5 z-50">
            <button class="bg-lime text-white font-bold py-3 px-3 w-8 h-8 xl:w-10 xl:h-10 rounded-full" @click="editProject(project.id)">
              <img src="@/assets/img/icons/edit.svg" alt="Edit">
            </button>
          </div>
          <router-link :to="{ name: 'projectRooms', params: { projectId: project.id }}">
            <div class="slide--top flex justify-between p-4 absolute w-full z-50">
              <span class="text-xxs xl:text-xs bg-gray-900 opacity-75 py-1 px-2 text-semibold rounded-md flex items-center justify-between">
                <img src="@/assets/img/icons/time.svg" alt="Time" class="mr-1 w-4 h-4">
                {{ project.updated_at | moment("from", "now") }}
              </span>
              <div v-if="project.priority === 'Confirmed'" class="mx-2 flex items-center text-xxs xl:text-xs font-medium capitalize">
                <svg class="flex-shrink-0 m-0.5 h-4 w-4 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                </svg>
                Confirmed
              </div>
              <div v-else-if="project.priority === 'Enquiry Only'" class="mx-2 flex items-center text-xxs xl:text-xs text-white font-medium capitalize">
                <svg class="flex-shrink-0 h-4 w-4 text-blue-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                </svg>
                Enquiry Only
              </div>
              <div v-else-if="project.priority === 'Urgent'" class="mx-2 flex items-center text-xxs xl:text-xs font-medium capitalize">
                <svg class="flex-shrink-0 mr-0.5 h-4 w-4 text-red-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
                Urgent
              </div>
            </div>

            <div v-if="project.image_url" class="slide--image">
              <img :src="project.image_url" alt="Specifi">
            </div>

            <div class="slide--padded p-6 text-center">
              <span :style="!project.image_url && 'display: inline-block; margin-top: 100px; margin-bottom: 50px;'">
                <div class="slide--subtitle">
                  <span v-if="project.customer" class="text-xs xl:text-base">{{ project.customer.fullname }}</span>
                </div>
                <h3 class="text-lg xl:text-3xl sans-serif mb-2">{{ project.name }}</h3>
              </span>
              <products-preview v-if="project.project_products && project.project_products.products.length > 0" :products="project.project_products.products" :total-products="project.project_products.totalNumProducts" />
            </div>
          </router-link>
        </swiper-slide>

        <div slot="button-prev" :class="$isTouchDevice && 'hide'" class="swiper-btn swiper-btn-prev">
          <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-12 h-12">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
        </div>
        <div slot="button-next" :class="$isTouchDevice && 'hide'" class="swiper-btn swiper-btn-next">
          <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-12 h-12">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </div>
      </swiper>
    </div>

    <new-project-modal 
      v-if="newProjectModalShow"
      :show-modal="newProjectModalShow" 
      @closeModal="newProjectModalShow = false" 
    />

    <edit-project-modal
      v-if="editProjectModalShow"
      :show-modal="editProjectModalShow"
      :project-id="currentProjectId"
      :edit-project="editProjectData"
      @closeModal="editProjectModalShow = false"
      @success="projectSuccessfullyUpdated"
    />

    <loader :is-loading="loading" />
  </div>
</template>

<script>
import NewProjectModal from "../../../components/modals/NewProjectModal.vue";
import EditProjectModal from "../../../components/modals/EditProjectModal.vue";
import Loader from "../../../components/Loader.vue";
import ProductsPreview from "../../../components/ProductsPreview.vue";

export default {
  components: {
    Loader,
    NewProjectModal,
    EditProjectModal,
    ProductsPreview
  },
  data() {
    return {
      showSearch: false,
      searchTerm: '',
      showLoader: true,
      loading: true,
      newProjectModalShow: false,
      editProjectModalShow: false,
      editProjectData: {},
      currentProjectId: null,
      showSuccessNotification: false,
      projectNotificationTitle: '',
      projectNotificationMsg: '',
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-btn-next',
          prevEl: '.swiper-btn-prev'
        },
        init: false,
        loop: false,
        loopedSlides: 5,
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: "auto",
        touchRatio: 1,
        slideToClickedSlide: true,
        freeMode: true,
        freeModeSticky: true,
        freeModeMomentumRatio: 0.2,
        keyboard: {
          enabled: true
        }
      },
       swiperOptionsOnSearch: {
        navigation: {
          nextEl: '.swiper-btn-next',
          prevEl: '.swiper-btn-prev'
        },
        init: false,
        loop: false,
        loopedSlides: 5,
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: "auto",
        touchRatio: 1,
        slideToClickedSlide: true,
        freeMode: true,
        freeModeSticky: true,
        freeModeMomentumRatio: 0.2,
        keyboard: {
          enabled: true
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.projectsSwiper.$swiper;
    },
    projects() {
      return this.$store.state.project.projects;
    }
  },
  mounted() {
    // this.$store.dispatch('project/getProject');
    this.$store.dispatch('project/getProjects').then(() => {
      this.$refs.projectsSwiper.$swiper.init();
    }).finally(() => {
      this.loading = false;
    });
  },
  methods: {
    filteredProjects() {
      let filtered = this.searchTerm ? this.projects.filter(project => project.name.replace(/-|\s/g,"").toLowerCase().includes(this.searchTerm.replace(/-|\s/g,"").toLowerCase())) : this.projects;
      return filtered;
    },
    editProject(projectId) {
      this.editProjectData = this.projects.find(element => element.id === projectId);
      this.editProjectModalShow = true;
    },
    projectSuccessfullyUpdated(type) {
      let updTitle = 'Project Updated';
      let updMsg = `${this.editProjectData.name} successfully updated.`;
      if (type === 'deleted') {
        updTitle = 'Project Deleted';
        updMsg = `${this.editProjectData.name} successfully deleted.`;
      }

      this.$notify({ type: 'success', title: updTitle, text: updMsg });
    }
  }
};
</script>