<template>
  <Modal title="Add Upgrade Product" :show="show" size="large" :close="closeAddProductModal">
    <template v-slot:content>
      <div v-if="!selectedProduct">
        <div class="mb-8">
          <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <select id="tabs" v-model="activeTab" name="tabs" class="select-input">
              <option :value="0">
                Product Categories
              </option>
              <option :value="1">
                Custom Products
              </option>
            </select>
          </div>

          <div class="hidden sm:block">
            <div class="pb-4 space-x-8 border-b">
              <nav class="flex justify-start items-center" aria-label="Tabs">
                <button
                  v-for="(tab, index) in tabs"
                  :key="index"
                  class="inline-flex items-center py-3 px-4 mr-4 text-xs font-medium bg-opacity-25 rounded-sm focus:outline-none"
                  :class="activeTab === index ? 'bg-blue-light text-blue-light' : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'"
                  @click="selectTab(index)"
                >
                  <component :is="tab.icon" class="mr-2 -ml-1 w-4 h-4" />
                  {{ tab.name }}
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <CustomProducts v-if="activeTab === 1" :room-id="roomId" :product-to-upgrade="productToUpgrade" @upgradeCustomProductAdded="upgradeCustomProductAdded" />
      <div v-if="activeTab === 0">
        <div v-if="!selectedProduct">
          <!-- Search Filter -->
          <div v-if="!filteredByWalkthrough" class="grid grid-cols-6 gap-4 bg-gray-50 rounded-md shadow border p-4 mb-4">
            <div class="col-span-6">
              <ais-instant-search index-name="products" :search-client="searchClient">
                <ais-autocomplete>
                  <div slot-scope="{ currentRefinement, indices, refine }" class="relative">
                    <div class="relative rounded-md shadow-sm">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none" aria-hidden="true">
                        <svg class="mr-3 w-4 h-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                        </svg>
                      </div>

                      <input
                        type="search"
                        :value="currentRefinement"
                        placeholder="Search Products"
                        class="block pl-9 w-full sm:text-sm rounded-md border-gray-300 form-input"
                        :class="currentRefinement ? 'rounded-b-none' : ''"
                        @input="refine($event.currentTarget.value)"
                      >
                    </div>

                    <div v-if="currentRefinement" class="overflow-y-auto absolute z-50 -mt-1 w-full max-h-96 bg-white rounded-b-md border border-gray-300 shadow-lg">
                      <ul v-for="index in indices" :key="index.indexId">
                        <li>
                          <ul class="divide-y">
                            <li v-for="product in index.hits" :key="product.objectID" class="overflow-hidden py-2 px-4 hover:bg-gray-50 cursor-pointer" @click="selectProductFromAutoComplete(product.id)">
                              <div class="flex flex-col justify-between items-center">
                                <div class="flex items-center w-full">
                                  <div v-if="product.first_image" class="flex justify-center items-center mr-4 w-16 h-16 bg-gray-50 rounded-md border">
                                    <img class="object-contain w-12 h-12" :src="`${apiUrl}/${product.first_image.src}`">
                                  </div>
                                  <div class="w-3/4">
                                    <h4 class="font-medium">
                                      {{ product.name }}
                                    </h4>
                                    <p class="text-xs text-gray-400">
                                      {{ product.brand }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ais-autocomplete>
              </ais-instant-search>
            </div>

            <div class="col-span-6 sm:col-span-3">
              <select id="searchBrand" v-model="searchBrand" name="searchBrand" class="select-input" @change="filterProducts">
                <option :value="null">
                  -- Choose Brand --
                </option>
                <option v-for="(numberOfProducts, brand) in brands.linkedBrands" :key="brand" :value="brand">
                  {{ brand }} - ({{ 'Product' | pluralize(numberOfProducts, true) }})
                </option>
              </select>
            </div>

            <div class="col-span-6 sm:col-span-3">
              <select id="searchCategory" v-model="searchCategory" name="searchCategory" class="select-input" @change="filterProducts">
                <option :value="null">
                  -- Choose Category --
                </option>
                <option v-for="(category, index) in availableCategories" :key="index" :value="category.id">
                  {{ category.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="flex my-6" aria-label="Breadcrumb">
            <button v-if="filteredByWalkthrough" class="mr-6 btn-primary-md" @click="breadcrumbNavigation(categoryAnswers.length - 1)">
              <svg class="flex-shrink-0 mr-1 -ml-1 w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
              </svg>
              Back
            </button>

            <ol v-if="productSelectionBreadcrumbs.breadcrumbs[0].title !== ''" class="flex items-center space-x-4">
              <li v-for="(breadcrumb, index) in productSelectionBreadcrumbs.breadcrumbs" :key="index">
                <div class="flex items-center">
                  <svg v-if="index !== 0" class="flex-shrink-0 w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                  <button :disabled="index === productSelectionBreadcrumbs.breadcrumbs.length - 1" class="text-sm font-medium text-gray-500 hover:text-gray-700" :class="index !== 0 && 'ml-4'" @click="breadcrumbNavigation(index)">
                    {{ breadcrumb.title }}
                  </button>
                </div>
              </li>
            </ol>
          </div>

          <div v-if="!selectedCategory && !searchQuery && searchResults.length === 0 && !searchBrand && !searchCategory" class="grid grid-cols-2 sm:grid-cols-4 gap-4">
            <button v-for="(category, index) in categories" :key="index" type="button" class="group relative p-6 text-left text-blue-dark hover:text-white bg-gray-50 hover:bg-blue-light rounded-md transition-all ease-in-out focus:outline-none" @click="selectCategory(category)">
              <h3 class="text-lg font-semibold text-center sm:text-left">
                {{ category.name }}
              </h3>
              <h4 class="mt-1 text-xs font-normal text-center sm:text-left text-gray-400 group-hover:text-white">
                {{ 'Product' | pluralize(category.linked_products_count, true) }}
              </h4>

              <div class="flex justify-center sm:justify-end w-full">
                <div v-if="category.image_url" class="flex items-center w-14 h-14">
                  <img :src="category.image_url" class="object-cover">
                </div>
              </div>
            </button>
          </div>

          <div v-if="selectedCategory && selectedCategory.questions">
            <div v-for="(question, index) in selectedCategory.questions.questions" :key="index">
              <div v-if="currentQuestion === index">
                <h3 class="font-bold my-6 text-xl">
                  {{ question.question }}
                </h3>

                <div class="grid grid-cols-2 sm:grid-cols-4 gap-4">
                  <button v-for="(answer, idx) in question.answers" :key="idx" type="button" class="px-4 py-2 text-center text-gray-900 border-gray-300 border shadow rounded-md text-sm" @click="selectAnswer(question.dbField, idx)">
                    <h3 class="text-xl font-semibold">
                      {{ idx }}
                    </h3>
                    <h4 class="mt-1 text-xs font-normal text-center text-gray-400 group-hover:text-white">
                      {{ 'Product' | pluralize(answer, true) }}
                    </h4>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="searchResults && searchResults.data && searchResults.data.length > 0">
            <div class="flex justify-between items-center pb-4">
              <h3 class="text-sm text-gray-500">
                Showing <span class="font-bold">{{ searchResults.data.length }}</span> results
              </h3>
              <button type="button" class="flex items-center text-sm text-blue-light" @click="clearSearchFilters">
                <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                </svg>
                Clear Filters
              </button>
            </div>
            <div class="grid grid-cols-9 gap-2">
              <div v-for="(product, index) in searchResults.data" :key="index" class="flex flex-col col-span-9 sm:col-span-3 justify-between p-4 w-full text-sm hover:bg-gray-50 rounded-md border border-gray-200 shadow-lg cursor-pointer" @click="selectProduct(product)">
                <div class="flex flex-col justify-between items-center">
                  <div class="flex items-center w-full">
                    <div v-if="product.first_image" class="flex justify-center items-center mr-4 w-16 h-16 bg-gray-50 rounded-md border">
                      <img class="object-contain w-12 h-12" :src="product.first_image.url">
                    </div>
                    <div class="w-3/4">
                      <h4 class="font-medium">
                        {{ product.name }}
                      </h4>
                      <p class="text-xs text-gray-400">
                        {{ product.brand }}
                      </p>
                      <div v-if="product.supplier_pricing.pricing.length > 0">
                        <p class="mt-1 text-xs">
                          {{ product.supplier_pricing.highestRetailPrice | currency }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex justify-between items-center mt-4 w-full">
                  <span>
                    <span v-if="product.previously_used" class="flex items-center py-1 px-2 text-xs text-blue-dark bg-blue-dark bg-opacity-10 rounded-md">
                      <svg class="mr-1 -ml-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z" />
                        <path d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z" />
                      </svg>
                      Previously Used
                    </span>
                  </span>
                  <svg class="w-5 h-5" :class="product.is_favourite ? 'text-yellow-300' : 'text-gray-300'" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="flex justify-center pt-8">
              <button v-if="searchResults.last_page !== searchResults.current_page" type="button" class="py-2 px-3 text-sm text-white bg-blue-dark rounded-md focus:outline-none" @click="loadMoreProducts">
                Load More Products
              </button>
              <h3 v-else class="text-sm text-gray-500">
                All Products Loaded
              </h3>
            </div>
          </div>
          <div v-else-if="!searching && ((searchQuery !== null && searchQuery !== '') || searchBrand !== null || searchCategory !== null) && (searchResults && searchResults.data && searchResults.data.length === 0)" class="flex flex-col items-center">
            <p class="p-4 text-center text-gray-700">
              No results found

              <span v-if="searchQuery">
                for <span class="font-bold">{{ searchQuery }}</span>
              </span>

              <span v-if="searchBrand">
                by <span class="font-bold">{{ searchBrand }}</span>
              </span>

              <span v-if="searchCategory">
                in <span class="font-bold">{{ getSearchCategoryName(searchCategory) }}</span>
              </span>
            </p>
            <button type="button" class="text-sm focus:outline-none btn-primary-outline-md" @click="clearSearchFilters">
              <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
              </svg>
              Clear Filters
            </button>
          </div>
        </div>

        <div v-else-if="selectedProduct && selectedProduct.has_variants">
          <nav aria-label="Back" class="flex justify-start">
            <button class="flex items-center text-sm font-medium bg-blue-light hover:bg-blue-dark text-white px-4 py-2 rounded-md" @click="selectedProduct = null">
              <svg class="flex-shrink-0 -ml-1 mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
              </svg>
              Back
            </button>
          </nav>

          <div class="mt-4 border-2 rounded-md overflow-hidden">
            <div v-for="(product, index) in selectedProduct.variants" :key="index" class="cursor-pointer w-full p-4 text-sm" :class="index !== searchResults.length - 1 && 'border-b-2 border-gray-200'" @click="selectProduct(product)">
              <div class="flex flex-col sm:flex-row items-center justify-between">
                <div class="w-full sm:w-3/4 flex items-center mb-4 sm:mb-0">
                  <div v-if="product.first_image" class="flex items-center w-16 h-16 mr-4">
                    <img class="object-cover" :src="product.first_image.url">
                  </div>
                  <div class="w-3/4">
                    <h4 class="font-medium text-lg text-left">
                      {{ product.name }}
                    </h4>
                    <p class="text-gray-400 text-xs text-left">
                      {{ product.brand }}
                    </p>
                    <div v-if="product.supplier_pricing.pricing.length > 0">
                      <p class="mt-2">
                        {{ product.supplier_pricing.numAvailableSuppliers > 1 ? 'From' : '' }} {{ product.supplier_pricing.lowestTradePrice | currency }}
                      </p>
                    </div>
                  </div>
                </div>

                <span v-if="product.supplier_pricing.numAvailableSuppliers" class="w-auto sm:w-1/2 relative z-0 inline-flex justify-end">
                  <div class="relative flex flex-col items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700">
                    <h4 class="text-sm text-gray-400">Retail Price {{ product.supplier_pricing.numAvailableSuppliers > 1 ? 'From' : '' }}</h4>
                    <h3 class="font-bold text-lg">{{ product.supplier_pricing.lowestRetailPrice | currency }}</h3>
                  </div>
                </span>
                <span v-else class="w-auto sm:w-1/2 relative z-0 inline-flex justify-end">
                  <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                    Available from {{ product.supplier_pricing.totalNumSuppliers }} unlinked {{ 'supplier' | pluralize(product.supplier_pricing.totalNumSuppliers) }}.
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="!selectedSupplier && selectedProduct">
          <button class="flex items-center text-sm font-medium bg-blue-light hover:bg-blue-dark text-white px-4 py-2 rounded-md" @click="selectedProduct = null">
            <svg class="flex-shrink-0 -ml-1 mr-1 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
            Back
          </button>

          <div class="my-4">
            <div class="w-full sm:w-3/4 flex items-center mb-4 sm:mb-0">
              <div v-if="selectedProduct.first_image" class="flex items-center w-16 h-16 mr-4">
                <img class="object-cover" :src="selectedProduct.first_image.url">
              </div>
              <div class="w-3/4">
                <h4 class="font-medium text-lg text-left">
                  {{ selectedProduct.name }}
                </h4>
                <p class="text-gray-400 text-xs text-left">
                  {{ selectedProduct.brand }}
                </p>
              </div>
            </div>
          </div>

          <h2 class="font-medium py-2">
            Please select a Supplier:
          </h2>

          <div class="border-2 rounded-md overflow-hidden">
            <button v-for="(supplier, index) in selectedProduct.supplier_pricing.pricing" :key="index" :disabled="!supplier.trade_price" class="p-4 w-full text-sm bg-blue-dark border-2 border-blue-dark hover:border-blue-light cursor-pointer disabled:cursor-not-allowed border-b-2 border-gray-200" :class="index !== selectedProduct.supplier_pricing.pricing.length - 1 && 'border-b-2 border-gray-200'" @click="selectProductSupplier(supplier.supplier.id)">
              <div class="flex flex-col sm:flex-row items-center justify-between">
                <div class="w-full sm:w-3/4 flex items-center mb-4 sm:mb-0">
                  <div v-if="supplier.supplier.logo_url" class="flex items-center justify-center w-16 h-16 mr-4 rounded-md overflow-hidden border">
                    <img class="w-12 h-12 object-contain" :src="supplier.supplier.logo_url">
                  </div>
                  <div class="w-3/ text-left">
                    <h4 class="font-medium text-white text-lg mb-1">
                      {{ supplier.supplier.name }}
                    </h4>
                    <div v-if="supplier.trade_price" class="flex items-center">
                      <span v-if="supplier.in_stock" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                        In Stock
                      </span>
                      <span v-else-if="supplier.special_order === 'Special Order'" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                        Special Order
                      </span>
                      <span v-else-if="supplier.special_order === 'Pre-Order'" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                        Pre-Order
                      </span>
                      <span v-else-if="supplier.special_order === 'Back Order'" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-pink-100 text-pink-800">
                        Back Order
                      </span>
                      <span v-else-if="!supplier.in_stock && supplier.in_stock !== null" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                        Out of Stock
                      </span>
                      <span v-else class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                        Stock Levels Not Linked
                      </span>
                    </div>
                    <h5 v-else class="font-medium text-xs text-gray-500">
                      Link your account with <span class="font-bold">{{ supplier.supplier.name }}</span> to view pricing.
                    </h5>
                  </div>
                </div>

                <span class="w-auto sm:w-1/2 relative z-0 inline-flex justify-end">
                  <div class="relative flex flex-col items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700">
                    <h4 class="text-sm text-gray-400">Retail Price</h4>
                    <h3 v-if="supplier.retail_price" class="font-bold text-lg">{{ supplier.retail_price | currency }}</h3>
                    <h3 v-else class="hiddenText font-bold text-lg">£123.45</h3>
                  </div>
                </span>
              </div>
            </button>
          </div>
        </div>
        <div v-else-if="selectedSupplier">
          <nav aria-label="Back">
            <button class="flex items-center text-sm font-medium bg-blue-light hover:bg-blue-dark text-white px-4 py-2 rounded-md" @click="selectedSupplier = null">
              <svg class="flex-shrink-0 -ml-1 mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
              </svg>
              Back
            </button>
          </nav>

          <div class="my-4">
            <div class="w-full flex justify-between items-center">
              <div class="w-full flex items-center mb-4 sm:mb-0">
                <div v-if="selectedProduct.first_image" class="flex items-center w-16 h-16 mr-4">
                  <img class="object-cover" :src="selectedProduct.first_image.url">
                </div>
                <div class="w-3/4">
                  <h4 class="font-medium text-lg text-left">
                    {{ selectedProduct.name }}
                  </h4>
                  <p class="text-gray-400 text-xs text-left">
                    {{ selectedProduct.brand }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="rounded-t-md border border-blue-light overflow-hidden">
            <div class="flex justify-between items-center cursor-pointer bg-gradient-to-r from-blue-dark to-gray-700 hover:from-gray-700 p-4 text-white" @click="selectAccordion('global-properties')">
              <div class="flex items-center">
                <div class="flex-shrink-0 bg-gradient-to-br from-blue-light to-blue-dark rounded-md p-3">
                  <svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clip-rule="evenodd" />
                  </svg>
                </div>

                <div class="ml-4">
                  <h3 class="font-medium">
                    Global Properties
                  </h3>
                  <p class="text-xs text-lime">
                    Anything set here will affect all Products in this Project. Some settings like 'Product Description' will impact all Projects.
                  </p>
                </div>
              </div>

              <div class="bg-blue-light rounded-full w-6 h-6 flex items-center justify-center">
                <svg v-if="activeAccordion === 'global-properties'" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
                <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            </div>
            <div v-show="activeAccordion === 'global-properties'" class="bg-gray-50 px-4 py-6 grid grid-cols-6 gap-4">
              <div class="col-span-6">
                <div class="flex justify-between">
                  <label for="name_override" class="block text-xs xl:text-sm font-medium text-gray-700">Product Name Override</label>
                  <span class="text-xs text-gray-500">Optional</span>
                </div>
                <div class="mt-1">
                  <input v-model="selectedProduct.name_override" type="text" name="name_override" class="form-input" placeholder="e.g. In-Ceiling Speaker" aria-describedby="product-name-override">
                </div>
                <p class="mt-2 text-xs text-gray-500">
                  The Product Name shown on the Customers Sales Quote.
                </p>
              </div>

              <div class="col-span-3">
                <label for="customer_price" class="block text-xs xl:text-sm font-medium text-gray-700">Customer Price</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span class="text-gray-500 sm:text-sm">
                      {{ currency }}
                    </span>
                  </div>
                  <input id="customer_price" v-model="selectedProductData.customerPrice" type="number" step="0.01" name="customer_price" class="form-input pl-7">
                </div>
                <p class="mt-2 text-xs text-gray-500">
                  This will be the price regardless of Supplier.
                </p>
              </div>
            </div>
          </div>
          <div class="rounded-b-md border border-blue-light overflow-hidden mb-4">
            <div class="flex justify-between items-center cursor-pointer bg-gradient-to-r from-blue-dark to-gray-700 hover:from-gray-700 p-4 text-white" @click="selectAccordion('installation-time')">
              <div class="flex items-center">
                <div class="flex-shrink-0 bg-gradient-to-br from-green-light to-green-dark rounded-md p-3">
                  <svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
                  </svg>
                </div>

                <div class="ml-4">
                  <h3 class="font-medium">
                    Installation Time
                  </h3>
                  <p class="text-xs text-lime">
                    Setting an installation time will allow you to better quote for your labour.
                  </p>
                </div>
              </div>

              <div class="bg-blue-light rounded-full w-6 h-6 flex items-center justify-center">
                <svg v-if="activeAccordion === 'installation-time'" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
                <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            </div>
            <div v-show="activeAccordion === 'installation-time'" class="bg-gray-50 px-4 py-6">
              <p class="text-xs xl:text-sm text-gray-600">
                This will be the installation times for all occurances of a <span class="text-xs xl:text-base text-blue-light font-bold">{{ selectedProduct.name }}</span> in this room when upgraded.
              </p>
              <div v-if="teamPositions && teamPositions.length > 0">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Installation Type
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Install Time
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Total Install Time
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="(installTime, posIdx) in selectedProductData.installTimeDetails" :key="posIdx">
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {{ installTime.position }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-900">
                          <div class="flex rounded-md shadow-sm">
                            <input v-model="installTime.install_time_mins" type="number" class="text-right form-input mt-0 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-l-md text-xxs xl:text-sm border-gray-300">
                            <span class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-xxs xl:text-sm">
                              {{ 'minute' | pluralize(installTime.install_time_mins) }}
                            </span>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-900">
                          {{ 'minute' | pluralize(getTotalInstallTypeTime(installTime.id), true) }}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot class="bg-gray-50">
                      <tr>
                        <td scope="col" class="px-6 py-3 text-left text-xxs xl:text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Total Install Time:
                        </td>
                        <td scope="col" class="px-6 py-3 text-right text-xxs xl:text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {{ 'minute' | pluralize(productInstallTime(), true) }}
                        </td>
                        <td scope="col" class="px-6 py-3 text-right text-xxs xl:text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {{ 'minute' | pluralize(productTotalInstallTime(), true) }}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div v-else>
                You haven't added any team positions. To associate install time with products, add at least one Team Position.
              </div>
            </div>
          </div>
          <div v-if="hasProductSpecificQuestions" class="grid grid-cols-2 sm:grid-cols-3 gap-4">
            <div v-for="item in parseInt(selectedProductData.qty || 0)" :key="item">
              <div v-if="selectedProduct.system_design.numberOfSpeakers === 'Pair'">
                <div class="input-group">
                  <label class="block text-xs xl:text-sm font-medium text-gray-700">Location 1</label>
                  <input v-model="selectedProductData.details.position" type="text" class="form-input">
                </div>
                <div class="input-group">
                  <label class="block text-xs xl:text-sm font-medium text-gray-700">Location 2</label>
                  <input v-model="selectedProductData.details.position2" type="text" class="form-input">
                </div>
              </div>
              <div v-else-if="selectedProduct.system_design.numberOfSpeakers">
                <label class="block text-xs xl:text-sm font-medium text-gray-700">Input {{ item }} Purpose</label>
                <input v-model="selectedProductData.details.position" type="text" class="form-input">
              </div>
            </div>
          </div>

          <!-- Module Name -->
          <div v-if="isRakoWiringModule" class="grid grid-cols-3 gap-4">
            <div v-for="(item, idx) in parseInt(selectedProductData.qty || 0)" :key="idx">
              <label>Module {{ idx + 1 }} Name</label><br>
              <input v-model="selectedProductData.details.moduleName" type="text" :placeholder="`Enter Module ${idx + 1} Name`" class="form-input">
            </div>
          </div>

          <!-- Keypad Name -->
          <div v-if="isKeypad" class="grid grid-cols-3 gap-4">
            <div v-for="(item, index) in parseInt(selectedProductData.qty || 0)" :key="item">
              <label>Keypad Name {{ index + 1 }}</label>
              <input v-model="selectedProductData.details.name" type="text" placeholder="Enter Keypad Name" class="form-input">
            </div>
          </div>

          <!-- Channels -->
          <div v-for="(item, index) in parseInt(selectedProductData.qty || 0)" :key="index" class="grid grid-cols-3 gap-4">
            <div v-for="(idx, t) in numberOfChannels" :key="idx" class="row conts--input">
              <label>Channel Name {{ idx }}</label>
              <input v-model="selectedProductData.details.channels[t]" type="text" placeholder="Enter channel name" class="form-input">
            </div>
          </div>

          <button type="button" class="w-full sm:w-auto inline-flex justify-center bg-blue-light hover:bg-blue-dark text-white p-4 rounded-md text-sm items-center mt-4" @click="addUpgradeProductToProject">
            <svg class="flex-shrink-0 -ml-1 mr-1 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z" />
            </svg>
            Add to Project
          </button>
        </div>
      </div>
    </template>
    <template v-slot:buttons>
      <button type="button" class="w-full inline-flex justify-center rounded-md border border-blue-light text-blue-light shadow-sm px-4 py-2 text-base font-medium hover:bg-blue-light hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" @click="closeAddProductModal">
        Cancel
      </button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import { GlobeIcon, ClockIcon, ArchiveIcon, CollectionIcon, StarIcon } from "@vue-hero-icons/outline";
import algoliasearch from 'algoliasearch/lite';
import CustomProducts from './CustomProducts.vue';

export default {
  components: {
    Modal,
    CustomProducts,
    GlobeIcon, ClockIcon, ArchiveIcon, CollectionIcon, StarIcon
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    productToUpgrade: {
      type: Object,
      required: false,
      default: null
    },
    roomId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data() {
    return {
      searchClient: algoliasearch(
        '6WTNRX540U',
        'ee6109b19eceae3d3dad150ea8d4251e'
      ),
      activeAccordion: null,
      searchQuery: null,
      searchResults: [],
      selectedCategory: null,
      selectedProduct: null,
      selectedSupplier: null,
      selectedProductData: {
        qty: 1,
        installTimeMins: 0,
        customerPrice: 0,
        nameOverride: '',
        details: {},
        installTimeDetails: []
      },
      currentQuestion: 0,
      categoryAnswers: [],
      filteredByWalkthrough: false,
      debounce: null,
      searchBrand: null,
      searchCategory: null,
       supplierType: 'linked_suppliers',
      searching: false,
      activeTab: 0,
      tabs: [
        {
          icon: 'CollectionIcon',
          name: 'Product Categories'
        },
        {
          icon: 'ArchiveIcon',
          name: 'Custom Products'
        }
      ]
    };
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_HTTPS;
    },
    teamPositions () {
      return this.$store.state.system.teamPositions;
    },
    currency() {
      return this.$store.state.auth.user.team.team_currency.symbol;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    categories() {
      return this.$store.state.system.categories;
    },
    brands() {
      return this.$store.state.system.brands;
    },
    availableCategories() {
      return this.$store.state.system.categories.filter(el => el.linked_products_count > 0);
    },
    hasProductSpecificQuestions() {
      if (!this.selectedProduct || !this.selectedProduct.system_design || !this.selectedProduct.system_design.cables) {
        return false;
      }
      const usCables = this.selectedProduct.system_design.cables.filter(cable => cable.purpose === 'User Selected').length > 0;
      return usCables;
    },
    isRakoWiringModule() {
      if (!this.selectedProduct || !this.selectedProduct.inputs_outputs || !this.selectedProduct.inputs_outputs.inputs) {
        return false;
      }

      const inputs = this.selectedProduct.inputs_outputs.inputs.filter(input => ['RAK8 modules', 'RxLink input'].includes(input.type)).length > 0;
      if (inputs) {
        this.$set(this.selectedProductData.details, 0, {});
      }
      return inputs;
    },
    isKeypad() {
      if (!this.selectedProduct || !this.selectedProduct.system_design || !this.selectedProduct.system_design.rakoProductType || this.selectedProduct.system_design.rakoProductType !== 'Keypad') {
        return false;
      }

      this.$set(this.selectedProductData.details, 0, {});

      return true;
    },
    numberOfChannels() {
      if (!this.selectedProduct || !this.selectedProduct.system_design || !this.selectedProduct.system_design.numberOfChannels) {
        return 0;
      }

      this.$set(this.selectedProductData.details, 1, { channels: {} });

      return parseInt(this.selectedProduct.system_design.numberOfChannels);
    },
    productSelectionBreadcrumbs() {
      const breadcrumbs = {
        mobileLink: '/projects',
        breadcrumbs: [
          {
            url: '/projects',
            title: this.selectedCategory ? this.selectedCategory.name : ''
          }
        ],
        currentPage: 'Create New Project'
      };

      if (this.categoryAnswers.length > 0) {
        this.categoryAnswers.forEach((el) => {
          breadcrumbs.breadcrumbs.push({url: '', title: el.answer});
        });
      }

      return breadcrumbs;
    },
    hasSearchTerms() {
      return (this.searchQuery !== null && this.searchQuery !== '') || (this.searchCategory !== null) || (this.searchBrand !== null);
    }
  },
  methods: {
    breadcrumbNavigation(indexQuestion)
    {
      if (indexQuestion == -1) {
        this.clearSearchFilters();
      } else {
        this.searchResults = [];
        this.currentQuestion = indexQuestion;
        this.categoryAnswers.splice(indexQuestion, this.categoryAnswers.length - indexQuestion);
        const questionNumber = this.currentQuestion;
        let question = this.selectedCategory.questions.questions[questionNumber];

        if (question && question.if) {
          question = this.doAnswersFulfillCriteria(question, questionNumber);
        }

        if (!question || this.categoryAnswers.length === this.selectedCategory.questions.questions.length) {
          this.axios.post(`/api/products/filter-by-walkthrough/${this.projectId}`, { category: this.selectedCategory.id, categoryAnswers: this.categoryAnswers, supplierType: this.supplierType }).then((res) => {
            this.searchResults = res.data;
          });
        } else {
          this.axios.post(`/api/products/filter-answers/${this.selectedCategory.id}`, { filter: question, prevAnswers: this.categoryAnswers, supplierType: this.supplierType }).then((res) => {
            this.selectedCategory.questions.questions[this.currentQuestion].answers = res.data;
          });
        }
      }
    },
    loadMoreProducts() {
      if (this.selectedCategory && this.selectedCategory.type === 'Questionnaire') {
        this.axios.post(`/api/products/filter-by-walkthrough/${this.projectId}`,
          {
            page: this.searchResults.current_page + 1,
            category: this.selectedCategory.id,
            categoryAnswers: this.categoryAnswers,
            supplierType: this.supplierType
          }
        ).then((res) => {
          const newResults = this.searchResults.data.concat(res.data.data);
          this.searchResults.current_page = res.data.current_page;
          this.searchResults.data = newResults;
        }).finally(() => {
          this.loading = false;
        });
      } else if (this.selectedCategory){
        this.axios.get(`/api/products/get-by-category/${this.selectedCategory.id}`, {
          params: {
            page: this.searchResults.current_page + 1,
            supplierType: this.supplierType
          }
        }).then((res) => {
          const newResults = this.searchResults.data.concat(res.data.data);
          this.searchResults.current_page = res.data.current_page;
          this.searchResults.data = newResults;
        }).finally(() => {
          this.loading = false;
        });
      } else if (this.searchCategory || this.searchBrand) {
        this.axios.get(`/api/products/filter-search`, {
          params: {
            page: this.searchResults.current_page + 1,
            project: this.$route.params.projectId,
            category: this.searchCategory,
            brand: this.searchBrand
          }
        }).then((res) => {
          const newResults = this.searchResults.data.concat(res.data.data);
          this.searchResults.current_page = res.data.current_page;
          this.searchResults.data = newResults;
        }).finally(() => {
          this.searching = false;
        });
      }
    },
    filterProducts() {
      this.searching = true;

      if (!this.hasSearchTerms) {
        this.searching = false;
        return;
      }

      this.axios.get('/api/products/filter-search', {
        params: {
          project: this.$route.params.projectId,
          category: this.searchCategory,
          brand: this.searchBrand
        }
      }).then((res) => {
        this.searchResults = res.data;
      }).finally(() => {
        this.searching = false;
      });
    },
    calculateMargin(tradePrice, retailPrice) {
      const taxRate = this.$store.state.auth.taxRate.product + 1;
      return Math.abs(((retailPrice / taxRate)) - tradePrice);
    },
    selectProduct(product) {
      this.selectedProduct = product;
      this.selectedProductData.installTimeMins = product.install_time || 0;
       // Setup Install Times
      this.teamPositions.forEach((el) => {
        const details = { ...el };
        details.install_time_mins = 0;
        const defaultInstallTime = product.team_install_info_default.find(s => s.team_position_id === details.id);
        details.install_time_mins = defaultInstallTime ? defaultInstallTime.install_time_mins : 0;
        this.selectedProductData.installTimeDetails.push(details);
      });
    },
    selectProductFromAutoComplete(productId) {
      this.axios.get(`/api/products/select-from-autocomplete/${productId}`).then((res) => {
        this.selectedProduct = res.data;

        // Setup Install Times
        this.teamPositions.forEach((el) => {
          const details = { ...el };
          const defaultInstallTime = res.data.team_install_info_default.find((s) => s.team_position_id === details.id);
          details.install_time_mins = defaultInstallTime ? defaultInstallTime.install_time_mins : 0;
          this.selectedProductData.installTimeDetails.push(details);
        });

        this.selectedProductData.installStage = res.data.install_stage || null;
        this.selectedProductData.description = res.data.product_description || null;
      });
    },
    getSearchCategoryName(catId) {
      const category = this.categories.find((el) => el.id === catId);
      if (category) return category.name;
      return '';
    },
    selectProductSupplier(supplier) {
      const supplierPricing = this.selectedProduct.supplier_pricing.pricing.find(el => el.supplier_id === supplier);
      this.selectedProductData.customerPrice = parseFloat(supplierPricing.retail_price).toFixed(2);
       this.selectedProductData.tradePrice = parseFloat(supplierPricing.trade_price).toFixed(2);
      this.selectedSupplier = supplier;
    },
    addUpgradeProductToProject() {
      const productDetails = {
        current_product: this.productToUpgrade,
        upgrade_product: this.selectedProduct.id,
        supplier: this.selectedSupplier,
        details: this.selectedProductData.details,
        customer_price: this.selectedProductData.customerPrice,
        name_override: this.selectedProduct.name_override,
        install_info: this.selectedProductData.installTimeDetails,
        trade_price: this.selectedProductData.tradePrice
      };

      this.axios.post(`/api/projects/${this.projectId}/room/${this.roomId}/add-upgrade-product`, productDetails).then((res) => {
        this.$emit('upgradeProductAdded', this.productToUpgrade, res.data);
        this.closeAddProductModal();
      });
    },
    upgradeCustomProductAdded(productToUpgrade, data) {
      this.$emit('upgradeProductAdded', productToUpgrade, data);
      this.closeAddProductModal();
    },
    closeAddProductModal() {
      this.$emit('close');
      Object.assign(this.$data, this.$options.data.call(this));
    },
    decreaseSelProdQty() {
      if (this.selectedProductData.qty > 1) {
        this.selectedProductData.qty--;
      }
    },
    increaseSelProdQty() {
      this.selectedProductData.qty++;
    },
    selectCategory(category) {
      this.filteredByWalkthrough = true;
      if (category.type === 'Questionnaire') {
        const questionOne = category.questions.questions[0];
        this.axios.post(`/api/products/filter-answers/${category.id}`, { filter: questionOne, supplierType: this.supplierType }).then((res) => {
          category.questions.questions[0].answers = res.data;
          this.selectedCategory = category;
        });
      } else {
        this.axios.get(`/api/products/get-by-category/${category.id}`, {
           params: {
            supplierType: this.supplierType
          }
        }).then((res) => {
          this.searchResults = res.data;
          this.selectedCategory = category;
        });
      }
    },
    selectAnswer(dbField, answer) {
      this.categoryAnswers.push({ dbField: dbField, answer: answer });
      this.currentQuestion++;
      let questionNumber = this.currentQuestion;
      let question = this.selectedCategory.questions.questions[questionNumber];

      if (question && question.if) {
        question = this.doAnswersFulfillCriteria(question, questionNumber);
      }

      if (!question || this.categoryAnswers.length === this.selectedCategory.questions.questions.length) {
        this.axios.post(`/api/products/filter-by-walkthrough/${this.projectId}`, { category: this.selectedCategory.id, categoryAnswers: this.categoryAnswers, supplierType: this.supplierType }).then((res) => {
           this.searchResults = res.data;
        });
      } else {
        this.axios.post(`/api/products/filter-answers/${this.selectedCategory.id}`, { filter: question, prevAnswers: this.categoryAnswers, supplierType: this.supplierType }).then((res) => {
          this.selectedCategory.questions.questions[this.currentQuestion].answers = res.data;
        });
      }
    },
    doAnswersFulfillCriteria(question, questionNumber) {
      let checkingCriteria = true;

      while (checkingCriteria === true) {
        const b = this.categoryAnswers.find((el) => {
          return el.dbField === question.if[0].dbField && el.answer === question.if[0].answer;
        });

        if (!b) {
          this.currentQuestion = ++questionNumber;
          question = this.selectedCategory.questions.questions[this.currentQuestion];

          if (!question || !question.if) {
            checkingCriteria = false;
          }
        } else {
          checkingCriteria = false;
        }
      }

      return question;
    },
     clearSearchFilters() {
      this.searchQuery = null;
      this.searchBrand = null;
      this.searchCategory = null;
      this.searchResults = [];
      this.filteredByWalkthrough = false;
      this.selectedCategory = null;
      this.categoryAnswers = [];
      this.currentQuestion = 0;
    },
    selectAccordion (accordion) {
      if (this.activeAccordion === accordion) {
        this.activeAccordion = null;
      } else {
        this.activeAccordion = accordion;
      }
    },
    productInstallTime () {
      let installTime = 0;
      this.selectedProductData.installTimeDetails.forEach((el) => {
        installTime += parseInt(el.install_time_mins);
      });
      return installTime;
    },
    productTotalInstallTime () {
      const totalInstallTime = this.productInstallTime() * this.selectedProductData.qty;
      return totalInstallTime;
    },
    getTotalInstallTypeTime (positionId) {
      const posInstallTimeDetails = this.selectedProductData.installTimeDetails.find(el => el.id === positionId);
      return (posInstallTimeDetails.install_time_mins * this.selectedProductData.qty);
    },
    selectTab(index) {
      this.clearSearchFilters();
      this.activeTab = index;
    }
  }
};
</script>