<template>
  <div>
    <Modal title="Add Products" :show="showModal" size="large" :close="closeModal">
      <template v-slot:content>
        <div>
          <!-- Search Filter -->
          <div v-if="!filteredByWalkthrough" class="grid grid-cols-6 gap-4 p-4 mb-4 bg-gray-50 rounded-md border shadow">
            <div class="col-span-6">
              <ais-instant-search index-name="products" :search-client="searchClient">
                <ais-autocomplete>
                  <div slot-scope="{ currentRefinement, indices, refine }" class="relative">
                    <div class="relative mt-1 rounded-md shadow-sm">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none" aria-hidden="true">
                        <svg class="mr-3 w-4 h-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                        </svg>
                      </div>
                      <input type="search" :value="currentRefinement" placeholder="Search Products" class="block pl-9 w-full sm:text-sm rounded-md border-gray-300 form-input" :class="currentRefinement ? 'rounded-b-none' : ''" @input="refine($event.currentTarget.value)">
                    </div>
                    <div v-if="currentRefinement" class="overflow-y-auto absolute z-50 -mt-1 w-full max-h-96 bg-white rounded-b-md border border-gray-300 shadow-lg">
                      <ul v-for="index in indices" :key="index.indexId">
                        <li>
                          <ul class="divide-y">
                            <li v-for="product in index.hits" :key="product.objectID" class="overflow-hidden py-2 px-4 hover:bg-gray-50 cursor-pointer" @click="goToProduct(product.id)">
                              <div class="flex flex-col justify-between items-center">
                                <div class="flex items-center w-full">
                                  <div v-if="product.first_image" class="flex justify-center items-center mr-4 w-16 h-16 bg-gray-50 rounded-md border">
                                    <img class="object-contain w-12 h-12" :src="`${apiUrl}/${product.first_image.src}`">
                                  </div>
                                  <div class="w-3/4">
                                    <h4 class="font-medium">
                                      {{ product.name }}
                                    </h4>
                                    <p class="text-xs text-gray-400">
                                      {{ product.brand }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ais-autocomplete>
              </ais-instant-search>
            </div>
            <div v-if="customProducts.length > 0" class="col-span-2">
              <button type="button" class="group p-2 w-full relative text-left text-white bg-gradient-to-br from-blue-dark to-blue hover:bg-blue-light rounded-md transition-all ease-in-out focus:outline-none" @click="goToCustom">
                <div class="flex justify-center items-center w-full">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-lime" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
                    <path fill-rule="evenodd" d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" clip-rule="evenodd" />
                  </svg>
                  <h3 class="text-lg ml-2 font-semibold text-center sm:text-left">
                    Custom Products
                  </h3>
                </div>
                <h4 class="mt-1 text-xs font-medium flex justify-center w-full text-center sm:text-left text-gray-200 group-hover:text-white">
                  {{ 'Product' | pluralize(customProducts.length, true) }}
                </h4>
              </button>
            </div>
            <div v-if="favouriteProducts.length > 0" class="col-span-2">
              <button type="button" class="group p-2 w-full relative text-left text-white bg-gradient-to-br from-blue-dark to-blue hover:bg-blue-light rounded-md transition-all ease-in-out focus:outline-none" @click="goToFavourite">
                <div class="flex items-center justify-center w-full">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-lime" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                  <h3 class="text-lg ml-2 font-semibold text-center sm:text-left">
                    Favourite Products
                  </h3>
                </div>
                <h4 class="mt-1 text-xs font-medium flex justify-center w-full text-center sm:text-left text-gray-200 group-hover:text-white">
                  {{ 'Product' | pluralize(favouriteProducts.length, true) }}
                </h4>
              </button>
            </div>
            <div v-if="productBundles.length > 0" class="col-span-2">
              <button type="button" class="group p-2 w-full relative text-left text-white bg-gradient-to-br from-blue-dark to-blue hover:bg-blue-light rounded-md transition-all ease-in-out focus:outline-none" @click="goToBundleProducts">
                <div class="flex items-center justify-center w-full">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-lime" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z" />
                  </svg>
                  <h3 class="text-lg ml-2 font-semibold text-center sm:text-left">
                    Product Bundles
                  </h3>
                </div>
                <h4 class="mt-1 text-xs font-medium flex justify-center w-full text-center sm:text-left text-gray-200 group-hover:text-white">
                  {{ 'Product' | pluralize(productBundles.length, true) }}
                </h4>
              </button>
            </div>
          </div>
          <div class="grid grid-cols-2 sm:grid-cols-4 gap-2">
            <button v-for="(category, index) in categories" :key="index" type="button" class="group relative p-6 text-left text-blue-dark hover:text-white bg-gray-50 hover:bg-blue-light rounded-md transition-all ease-in-out focus:outline-none" @click="goToCategory(category, category.id)">
              <h3 class="text-lg font-semibold text-center sm:text-left">
                {{ category.name }}
              </h3>
              <h4 class="mt-1 text-xs font-normal text-center sm:text-left text-gray-400 group-hover:text-white">
                {{ 'Product' | pluralize(category.linked_products_count, true) }}
              </h4>
              <div class="flex justify-center sm:justify-end w-full">
                <div v-if="category.image_url" class="flex items-center w-14 h-14">
                  <img :src="category.image_url" class="object-cover">
                </div>
              </div>
            </button>
          </div>
        </div>
      </template>
      <template v-slot:buttons>
        <button type="button" class="btn-primary-outline-md" @click="closeModal">
          Cancel
        </button>
      </template>
    </Modal>
    <placeholder-product-modal
      :show-modal="showAddPlaceholderProduct"
      @close="showAddPlaceholderProduct = false"
    />
  </div>
</template>
<script>
import PlaceholderProductModal from "../modals/AddPlaceholderProduct.vue";
import Modal from '@/components/Modal';
import algoliasearch from 'algoliasearch/lite';

export default {
  components: {
    PlaceholderProductModal,
    Modal
  },
  props: {
    showModal: Boolean,
    group: {
      type: String,
      default: '',
      required: false
    }
  },
  data() {
    return {
      searchClient: algoliasearch(
        '6WTNRX540U',
        'ee6109b19eceae3d3dad150ea8d4251e'
      ),
      searching: false,
      filteredByWalkthrough: false,
      awaitingSearch: false,
      searchTerm: "",
      searchResults: [],
      searchQuery: null,
      searchCategory: null,
      debounce: null,
      showAddPlaceholderProduct: false
    };
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_HTTPS;
    },
    categories() {
      return this.$store.state.system.categories;
    },
    customProducts() {
      return this.$store.state.system.customProducts;
    },
    hasSearchTerms() {
      return (this.searchQuery !== null && this.searchQuery !== '');
    },
    favouriteProducts() {
      return this.$store.state.system.favouriteProducts;
    },
    productBundles() {
      return this.$store.state.system.productBundles;
    }
  },
  methods: {
    showAddPlaceholderProductModal(){
      this.showAddPlaceholderProduct = true;
      this.$emit("closeModal");
    },
    searchProducts() {
      this.searching = true;
      this.searchResults = [];

      if (!this.hasSearchTerms) {
        this.searchResults = [];
        this.searching = false;
        return;
      }
      this.axios.get('/api/products/search', {
        params: {
          query: this.searchQuery,
          project: this.$route.params.projectId,
          category: this.searchCategory
        }
      }).then((res) => {
        this.searchResults = res.data;
      }).finally(() => {
        this.searching = false;
      });
    },
    debounceSearch() {
      this.searching = true;

      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchProducts();
      }, 600);
    },
    closeModal() {
      this.$emit("closeModal");
    },
    goToCategory(category, catId) {
      this.$store.dispatch("system/getCurrentCategory", catId).then(() => {
        this.$router.push({
          name: "projectRoomProductCategory",
          params: { categorySlug: this.getCategorySlug(category.name) }
        });
      });
    },
    goToCustom() {
      this.$router.push({
        name: "projectRoomProductCategory",
        params: { categorySlug: "custom-product" }
      });
    },
    goToFavourite() {
      this.$router.push({
        name: "projectRoomProductCategory",
        params: { categorySlug: "favourite-product" }
      });
    },
    goToBundleProducts() {
      this.$router.push({
        name: "projectRoomProductCategory",
        params: { categorySlug: "bundle-product" }
      });
    },
    getCategorySlug(category) {
      return category.replace(/\s+/g, "-").toLowerCase();
    },
    goToProduct(productId) {
      this.$router.push({
        name: "projectRoomProduct",
        params: { productId: productId }
      });
    },
    clearSearch() {
      this.searchQuery = "";
      this.searchResults = [];
    }
  }
};
</script>