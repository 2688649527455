<template>
  <div
    class="modal-window absolute sm:fixed top-1 sm:top-0 sm:bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center"
    :class="showModal ? 'show' : 'hide'"
  >
    <div class="fixed inset-0 transition-opacity" :class="showModal ? 'ease-in duration-200 opacity-100' : 'ease-out duration-200 opacity-0'">
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>

    <!-- New Project Form -->
    <div
      class="w-full sm:w-1/2 bg-white rounded-lg overflow-hidden shadow-xl transform transition-all"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
      :class="showModal ? 'ease-in duration-200 opacity-100 translate-y-0 sm:scale-100' : 'ease-out duration-300 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'"
    >
      <div class="modal-window-split--content">
        <div class="bg-white px-4 pt-3 pb-2 xl:p-8 xl:pb-4">
          <div
            class="mb-4 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-lime sm:mx-0 sm:h-10 sm:w-10"
          >
            <svg
              width="20px"
              height="19px"
              viewBox="0 0 20 19"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Home" fill="#000000" fill-rule="nonzero">
                  <path
                    id="Path"
                    d="M16.6406152,18.7486719 L3.35936523,18.7486719 C2.36858398,18.7486719 1.56249023,17.9426172 1.56249023,16.9517969 L1.56249023,7.49789062 C1.56249023,7.15273437 1.84229492,6.87289062 2.18749023,6.87289062 C2.53268555,6.87289062 2.81249023,7.15273437 2.81249023,7.49789062 L2.81249023,16.9517969 C2.81249023,17.2533594 3.05780273,17.4986719 3.35936523,17.4986719 L16.6406152,17.4986719 C16.9421777,17.4986719 17.1874902,17.2533594 17.1874902,16.9517969 L17.1874902,7.49789062 C17.1874902,7.15273437 17.4672949,6.87289062 17.8124902,6.87289062 C18.1576855,6.87289062 18.4374902,7.15273437 18.4374902,7.49789062 L18.4374902,16.9517969 C18.4374902,17.9425781 17.6314355,18.7486719 16.6406152,18.7486719 Z"
                  />
                  <path
                    id="Path"
                    d="M19.3749902,9.68522461 C19.2150293,9.68522461 19.0551074,9.62417969 18.9330762,9.50210937 L11.1600684,1.72917969 C10.5204199,1.08949219 9.47959961,1.08949219 8.83991211,1.72917969 L1.06694336,9.50214844 C0.822880859,9.74625 0.427138672,9.74625 0.183076172,9.50214844 C-0.0610253906,9.25808594 -0.0610253906,8.86234375 0.183076172,8.61828125 L7.95604492,0.845273437 C9.08307617,-0.281757813 10.9169434,-0.281757813 12.0439355,0.845273437 L19.8169043,8.61824219 C20.0610059,8.86234375 20.0610059,9.25804687 19.8169043,9.50210937 C19.6949121,9.62417969 19.5349512,9.68522461 19.3749902,9.68522461 L19.3749902,9.68522461 Z"
                  />
                  <path
                    id="Shape"
                    d="M12.4999902,18.7486719 L7.49999023,18.7486719 C7.15479492,18.7486719 6.87499023,18.4688281 6.87499023,18.1236719 L6.87499023,12.5767969 C6.87499023,11.4998437 7.75116211,10.6236719 8.82811523,10.6236719 L11.1718652,10.6236719 C12.2488184,10.6236719 13.1249902,11.4998437 13.1249902,12.5767969 L13.1249902,18.1236719 C13.1249902,18.4688281 12.8451855,18.7486719 12.4999902,18.7486719 Z M8.12499023,17.4986719 L11.8749902,17.4986719 L11.8749902,12.5767969 C11.8749902,12.1891016 11.5595605,11.8736719 11.1718652,11.8736719 L8.82811523,11.8736719 C8.44041992,11.8736719 8.12499023,12.1891016 8.12499023,12.5767969 L8.12499023,17.4986719 Z"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center w-full sm:mt-0 sm:text-left">
              <h3 id="modal-headline" class="text-lg xl:text-2xl leading-8 font-bold text-gray-900 m-2">
                Create New Project
              </h3>
              <div class="mt-2">
                <form id="newProjectForm" class="w-full" @submit.prevent="createProject">
                  <div class="overflow-hidden sm:rounded-md">
                    <div class="px-4 py-2 bg-white xl:p-6">
                      <div class="grid grid-cols-6 gap-4 xl:gap-6">
                        <div class="col-span-6">
                          <label for="name" class="block text-xs xl:text-sm font-medium text-gray-700">Project Name</label>
                          <input
                            id="name"
                            v-model="projectData.name"
                            type="text"
                            name="name"
                            required
                            placeholder="Project Name"
                            class="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="priority"
                            class="block text-xs xl:text-sm font-medium text-gray-700"
                          >Project Type</label>
                          <select
                            id="priority"
                            v-model="projectData.type"
                            name="priority"
                            required
                            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                            <option :value="null" selected disabled>
                              -- Choose Project Type --
                            </option>
                            <option>Normal</option>
                            <!-- <option>Quick Quote</option> -->
                          </select>
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="priority"
                            class="block text-xs xl:text-sm font-medium text-gray-700"
                          >Project Priority</label>
                          <select
                            id="priority"
                            v-model="projectData.priority"
                            name="priority"
                            required
                            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                            <option :value="null" selected disabled>
                              -- Choose Priority --
                            </option>
                            <option>Enquiry Only</option>
                            <option>Confirmed</option>
                            <option>Urgent</option>
                          </select>
                        </div>

                        <template v-if="customers.length > 0">
                          <div class="col-span-6 sm:col-span-3">
                            <label
                              for="name"
                              class="block text-xs xl:text-sm font-medium text-gray-700"
                            >Existing Customer</label>
                            <div class="flex items-center mt-4">
                              <div class="flex mr-4">
                                <input
                                  id="is_existing_customer"
                                  v-model="projectData.existing_customer"
                                  name="existing_customer"
                                  type="radio"
                                  :value="true"
                                  class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                >
                                <label
                                  for="is_existing_customer"
                                  class="ml-3 block text-xs xl:text-sm font-medium text-gray-700"
                                >Yes</label>
                              </div>
                              <div class="flex items-center">
                                <input
                                  id="not_existing_customer"
                                  v-model="projectData.existing_customer"
                                  name="existing_customer"
                                  type="radio"
                                  :value="false"
                                  class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                >
                                <label
                                  for="not_existing_customer"
                                  class="ml-3 block text-xs xl:text-sm font-medium text-gray-700"
                                >No</label>
                              </div>
                            </div>
                          </div>

                          <div
                            v-if="projectData.existing_customer"
                            class="col-span-6 sm:col-span-3"
                          >
                            <label
                              for="customer_name"
                              class="block text-xs xl:text-sm font-medium text-gray-700"
                            >Customer</label>
                            <select
                              id="priority"
                              v-model="projectData.customer"
                              name="priority"
                              required
                              class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                              <option :value="null" selected disabled>
                                -- Choose Customer --
                              </option>
                              <option
                                v-for="(customer, index) in customers"
                                :key="index"
                                :value="customer.id"
                              >
                                {{ customer.fullname }}
                              </option>
                            </select>
                          </div>

                          <br>
                        </template>

                        <div v-if="!projectData.existing_customer" class="col-span-6 sm:col-span-3">
                          <label class="block text-xs xl:text-sm font-medium text-gray-700">Customer First Name</label>

                          <input
                            id="customer_firstname"
                            v-model="projectData.customer_firstname"
                            type="text"
                            name="customer_firstname"
                            required
                            placeholder="e.g. John"
                            class="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                        </div>

                        <div v-if="!projectData.existing_customer" class="col-span-6 sm:col-span-3">
                          <label
                            for="customer_surname"
                            class="block text-xs xl:text-sm font-medium text-gray-700"
                          >Customer Surname</label>

                          <input
                            id="customer_surname"
                            v-model="projectData.customer_surname"
                            type="text"
                            name="customer_surname"
                            required
                            placeholder="e.g. Doe"
                            class="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                        </div>

                        <div v-if="!projectData.existing_customer" class="col-span-6 sm:col-span-3">
                          <label
                            class="block text-xs xl:text-sm font-medium text-gray-700"
                          >Customer Phone Number</label>

                          <input
                            id="customer_phone"
                            v-model="projectData.customer_phone"
                            type="text"
                            name="customer_phone"
                            placeholder="e.g. 028 9145 5565"
                            class="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                        </div>

                        <div v-if="!projectData.existing_customer" class="col-span-6 sm:col-span-3">
                          <label class="block text-xs xl:text-sm font-medium text-gray-700">Customer Email</label>

                          <input
                            id="customer_email"
                            v-model="projectData.customer_email"
                            type="email"
                            name="customer_email"
                            placeholder="e.g. john@example.com"
                            class="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                        </div>

                        <div v-if="!projectData.existing_customer" class="col-span-6 sm:col-span-3">
                          <label class="block text-xs xl:text-sm font-medium text-gray-700">Address Line 1</label>
                          <input
                            id="street_address_line"
                            v-model="projectData.address_line_1"
                            type="text"
                            name="street_address_line"
                            placeholder="e.g. Dunlop Commercial Park"
                            class="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                        </div>

                        <div v-if="!projectData.existing_customer" class="col-span-6 sm:col-span-3">
                          <label class="block text-xs xl:text-sm font-medium text-gray-700">Address Line 2</label>
                          <input
                            id="street_address_line2"
                            v-model="projectData.address_line_2"
                            type="text"
                            name="street_address_line2"
                            placeholder="e.g. 3 Balloo Link"
                            class="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                        </div>

                        <div
                          v-if="!projectData.existing_customer"
                          class="col-span-6 sm:col-span-6 lg:col-span-2"
                        >
                          <label for="city" class="block text-xs xl:text-sm font-medium text-gray-700">City</label>
                          <input
                            id="street_address_city"
                            v-model="projectData.city"
                            type="text"
                            name="street_address_city"
                            placeholder="e.g. Bangor"
                            class="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                        </div>

                        <div
                          v-if="!projectData.existing_customer"
                          class="col-span-6 sm:col-span-3 lg:col-span-2"
                        >
                          <label
                            for="state"
                            class="block text-xs xl:text-sm font-medium text-gray-700"
                          >State / Province</label>
                          <input
                            id="street_address_state"
                            v-model="projectData.county"
                            type="text"
                            name="street_address_state"
                            placeholder="e.g. Co. Down"
                            class="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                        </div>

                        <div
                          v-if="!projectData.existing_customer"
                          class="col-span-6 sm:col-span-3 lg:col-span-2"
                        >
                          <label
                            for="postal_code"
                            class="block text-xs xl:text-sm font-medium text-gray-700"
                          >ZIP / Postal</label>
                          <input
                            id="street_address_zip"
                            v-model="projectData.postcode"
                            type="text"
                            name="street_address_zip"
                            placeholder="BT19 7HJ"
                            class="mt-1 uppercase appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              type="submit" 
              form="newProjectForm"
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-gradient-to-br from-blue-dark to-blue text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >Create Project</button>
          </span>
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto sm:ml-3">
            <button
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              @click="closeModal"
            >Cancel</button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: Boolean,
    projectId: {
      type: Number,
      default: 0,
      required: false
    }
  },
  data() {
    return {
      file: "",
      preview: "",
      customers: [],
      projectData: {
        name: null,
        type: 'Normal',
        existing_customer: false,
        priority: null,
        customer: null,
        type: 'Normal'
      },
      newProjectErrors: {}
    };
  },
  mounted() {
    this.getCustomers();
  },
  methods: {
    onChangeFileUpload() {
      this.file = this.$refs.file.files[0];
      this.preview = URL.createObjectURL(this.file);
    },
    getCustomers() {
      this.axios.get("/api/customers").then(customers => {
        this.customers = customers.data;
      });
    },
    createProject() {
      this.axios.post("/api/projects/create", this.projectData).then(res => {
        this.$store.dispatch("project/addProject");
        this.$notify({
          type: "success",
          title: `Project Created`
        });
        this.$router.push({
          name: "projectRooms",
          params: { projectId: res.data.id }
        });
      });
    },
    closeModal() {
      this.projectData = this.$options.data().projectData;
      this.$emit("closeModal");
    }
  }
};
</script>